import React from 'react'
import {
  FormTab,
  TextField, SelectField, NumberField
} from 'react-admin'
import { Typography, Divider, makeStyles } from '@material-ui/core'
import FaceIcon from '@material-ui/icons/ContactMail';

import { MenteeTypeChoices } from '../../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: 20
  }
}))

const MentoringInfoTab = (props) => {
  const classes = useStyles()

  return (
    <FormTab label="Mentoring info" icon={<FaceIcon/>} {...props}>
      <Typography variant="h5" gutterBottom>
          Mentoring preferences
        </Typography>
        <TextField label="Introduction" source="introduction" />
        <NumberField label="Expected number of seeds" source="expected_number_of_seeds" />
        <SelectField label="Prefer mentee type" source="prefer_mentee_type" choices={MenteeTypeChoices}/>
        <TextField label="Applying reason" source="applied_mentor_reason" />
        <TextField label="Mentee requirement" source="mentee_requirement" />

        <Divider className={classes.divider} />

        <Typography variant="h5" gutterBottom>
          Mentor matching status
        </Typography>
        <NumberField label="Number of mentor bookings:" source="mentor_booking_count" />
        <NumberField label="Number of mentor requests:" source="mentor_request_count" />
        <NumberField label="Number of mentees:" source="number_of_mentees" />
    </FormTab>
  )
}

export default MentoringInfoTab