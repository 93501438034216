const defaultValue = {
  email: '',
  main_email: '',
  passcode: '',
  region: '',

  // general information
  personal_info: {
    first_name: '',
    last_name: '',
    gender: {
      gender_type: '',
    },
    address: '',
    phone_number: '',
  },

  experience_info: {
    vietseeds_mentoring: {
      vietseeds_mentor: '',
      vietseeds_mentor_candidate: '',
    },
    mentoring_experienced: '',
    mentoring_time: '',
    has_mentored: '',
    mentoring_relationship: '',
    required_meet_mentee: '',
    required_mentoring_bond: '',
    academic_info: {
      academic_level: '',
      note: '',
    },
    quiz: [ // 11 questions
      { question_id: 0, answer: '', note: '' },
      { question_id: 1, answer: '', note: '' },
      { question_id: 2, answer: '', note: '' },
      { question_id: 3, answer: '', note: '' },
      { question_id: 4, answer: '', note: '' },
      { question_id: 5, answer: '', note: '' },
      { question_id: 6, answer: '', note: '' },
      { question_id: 7, answer: '', note: '' },
      { question_id: 8, answer: '', note: '' },
      { question_id: 9, answer: '', note: '' },
      { question_id: 10, answer: '', note: '' },
    ]
  },

  // personal statement
  essays: { question_id: 0, answer: '', note: '' },
}

export default defaultValue