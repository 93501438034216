import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  FormTab, DataProviderContext
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { get } from 'lodash'
import html2pdf from 'html2pdf.js'
import { useFormState } from 'react-final-form';
import { cloneDeep } from 'lodash'
import * as Sentry from "@sentry/react";

import ProfileInformationSection from './ApplicationShowSections/ProfileInformationSection'
import AcademicInfoSection from './ApplicationShowSections/AcademicInfoSection'
import FinanceSection from './ApplicationShowSections/FinanceSection'
import ExperienceSection from './ApplicationShowSections/ExperienceSection'
import FamilySection from './ApplicationShowSections/FamilySection'
import EssaySection from './ApplicationShowSections/EssaySection'
import PersonalSharingSection from './ApplicationShowSections/PersonalSharingSection'
import VietSeedsQuizSection from './ApplicationShowSections/VietSeedsQuizSection'
import ImageSection from './ApplicationShowSections/ImageSection'
import ImageSectionForExporting from './ApplicationShowSections/ImageSectionForExporting'
import RecommendationLetterSection from './ApplicationShowSections/RecommendationLetterSection'


const PdfExportWrapper = (props) => {
  const { record } = props
  const id = get(record, 'id', 'application_id')
  const display_name = get(record, 'display_name', 'student_application')

  const [isExporting, setIsExporting] = useState(false)

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: 10 }}
        onClick={() => setIsExporting(true)}
      >
        Download as PDF
      </Button>
      {isExporting &&
        <React.Fragment>
          <Button variant="contained" color="secondary" style={{ margin: 10 }}
            onClick={() => {
              const element = document.getElementById("student-application")
              html2pdf(element, {
                filename: `${id}-${display_name}`,
                html2canvas: {
                  imageTimeout: 0,
                  useCORS: true,
                },
              }).then(() => {
                setIsExporting(false)
              })
            }}
          >
            Save
          </Button>
          <Button variant="outlined" color="secondary" style={{ margin: 10 }}
            onClick={() => setIsExporting(false)}
          >
            Cancel
          </Button>
          <div id="student-application">
            <ProfileInformationSection {...props}/>
            <AcademicInfoSection {...props}/>
            <FinanceSection {...props}/>
            <ExperienceSection {...props}/>
            <PersonalSharingSection {...props}/>
            <EssaySection {...props}/>
            <ImageSectionForExporting {...props}/>
          </div>
        </React.Fragment>
      }
    </div>
  )
}


const StudentApplicationShowTab = (props) => {
  const formState = useFormState()
  const dataProvider = useContext(DataProviderContext);

  const autoSave = useCallback(
    async () => {
      try {
        const autoSaveData = cloneDeep(formState.values)
        const originalRecord = cloneDeep(formState.initialValues)
        dataProvider.update('student-application', {
          id: autoSaveData.id,
          data: autoSaveData,
          previousData: originalRecord,
        })
        .then(() => {
          console.log('Auto saved at ' + new Date().toString())
        })
      } catch (error) {
        console.log(error)
      }
    },
    [formState],
  );

  useEffect(() => {
    const autoSaveInterval = setInterval(autoSave, 1000 * 60 * 5) // 5 mins

    // returned function will be called on component unmount 
    return () => {
      try {
        clearInterval(autoSaveInterval)
      } catch (error) {
        Sentry.captureException(new Error(error.name + ': ' + error.message), {
          extra: error
        })
      }
    }
  }, []);

  return (
    <FormTab label='Applicant profile' {...props}>
      <PdfExportWrapper/>
      <ProfileInformationSection/>
      <AcademicInfoSection/>
      <FinanceSection/>
      <ExperienceSection/>
      <FamilySection/>
      <PersonalSharingSection/>
      <EssaySection/>
      <VietSeedsQuizSection/>
      <ImageSection/>
      <RecommendationLetterSection/>
    </FormTab>
  )
}

export default StudentApplicationShowTab