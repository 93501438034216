import React from 'react'
import {
  List, Datagrid, TextField, NumberField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import renderInputFields from '../../../helpers/renderInputFields'

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  },
}));

const listShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'VI', source: 'title.vi', component: TextField,
  },
  { 
    label: 'EN', source: 'title.en', component: TextField, 
  },
  {
    label: 'Title translation id', source: 'title.id', component: NumberField,
  },
]

const ArticleCategoryList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Article Category List'}
      pagination={null}
      // perPage={100}
    >
      <Datagrid className={classes.table}>
        {
          renderInputFields(listShowFields)
        }
        {/* {
          listShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        } */}
      </Datagrid>
    </List>
  );
}

export default ArticleCategoryList