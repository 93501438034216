import React from 'react'
import { 
  Create, SimpleForm, TextInput,
  required as requiredValidator, email,
} from 'react-admin'

const Title = ({ record }) => {
  return <span>{`Create Sponsor Invitation`}</span>
};


const sponsorInvitationFields = [
  { label: 'Name', source: 'display_name', component: TextInput,required: true, },
  { 
    label: 'Email', source: 'email', component: TextInput, required: true,
    validate: [email()],
  },
  { label: 'Phone', source: 'phone', component: TextInput, required: true, },
  { 
    label: 'Note', source: 'note', component: TextInput,
    multiline: true, rows: 5,
  },
]

const SponsorInvitationCreate = (props) => (
  <Create 
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm warnWhenUnsavedChanges>
      {
        sponsorInvitationFields.map(field => {
          const { label, source, component: Component, required, validate = [], ...others } = field
          return (
            <Component
              key={source} source={source} label={label}
              variant='outlined' fullWidth
              validate={[
                ...validate,
                ...required ? [requiredValidator()] : []
              ]}
              {...others}
            />
          )
        })
      }
    </SimpleForm>
  </Create>
);

export default SponsorInvitationCreate