import { sha256 } from 'js-sha256'
import jwtDecode from 'jwt-decode'
import { get, intersectionWith, isEqual } from 'lodash'

import { backendURL, ResponseCode, Roles } from '../constants/constants'

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${backendURL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ 
        username, 
        encrypted_password: sha256(password) // hash password using sha256
      }),
      headers: new Headers({ 
        'Content-Type': 'application/json',
        'X-VS-CLIENT-TYPE': '1'
      }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result, reply }) => {
        if (result !== ResponseCode.SUCCESS) {
          if (result === ResponseCode.ERROR_INVALID_LOGIN_CREDENTIAL) {
            throw new Error('Sai tên đăng nhập hoặc mật khẩu');
          }
          throw new Error(result);
        }
        const { access_token, refresh_token } = get(reply, 'credentials', {})
        // store user profile to local storage
        const userProfile = {
          ...jwtDecode(access_token),
          username
        }
        localStorage.setItem('profile', JSON.stringify(userProfile))

        // store token to local storage
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
      });
  },
  checkAuth: (e) => {
    return (
      localStorage.getItem('access_token')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' })
    )
  },
  logout: () => {
    // call API to clear token in BE and redux store

    // clear token in local storage
    localStorage.removeItem('profile');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject(); // auto trigger log out here
    }
    return Promise.resolve();
  },
  getPermissions: params => {
    const profile = localStorage.getItem('profile');
    return profile ? Promise.resolve([...JSON.parse(profile).roles, Roles.unAuth]) : Promise.resolve([Roles.unAuth])
  },
  refreshToken: () => {
    const refresh_token = localStorage.getItem('refresh_token')

    const request = new Request(`${backendURL}/auth/refresh_access_token`, {
      method: 'POST',
      body: JSON.stringify({ refresh_token }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-VS-CLIENT-TYPE': '1',
      }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const { reply, result } = response
        // if fail: logout + throw error
        if (result !== ResponseCode.SUCCESS) {
          if (result === ResponseCode.ERROR_INVALID_TOKEN) {
            throw new Error('Đã hết thời hạn đăng nhập. Vui lòng đăng nhập lại.');
          }
          throw new Error(result);
        }

        // if success: store back new access_token & refresh_token
        const access_token = get(reply, 'access_token', null)
        // store user profile to local storage
        const userProfile = jwtDecode(access_token)
        localStorage.setItem('profile', JSON.stringify(userProfile))

        // store token to local storage
        localStorage.setItem('access_token', access_token);
      })
  },
  changePassword: ({ username, oldPassword, newPassword }) => {
    const request = new Request(`${backendURL}/auth/change_password`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        encrypted_password: sha256(oldPassword), // hash password using sha256
        encrypted_new_password: sha256(newPassword)
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-VS-CLIENT-TYPE': '1'
      }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        if (result !== ResponseCode.SUCCESS) {
          if (result === ResponseCode.ERROR_INVALID_LOGIN_CREDENTIAL) {
            throw new Error('Sai tên đăng nhập hoặc mật khẩu cũ');
          }
          throw new Error(result);
        }
      });
  },
  changePasswordWithToken: ({ token, newPassword }) => {
    const request = new Request(`${backendURL}/auth/change_password_with_token`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        encrypted_new_password: sha256(newPassword)
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-VS-CLIENT-TYPE': '1'
      }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        if (result !== ResponseCode.SUCCESS) {
          if (result === ResponseCode.ERROR_INVALID_LOGIN_CREDENTIAL) {
            throw new Error('Mã không hợp lệ hoặc quá hạn');
          }
          throw new Error(result);
        }
      });
  },
  getUid: () => {
    const profile = localStorage.getItem('profile');
    if (profile) {
      const uid = get(JSON.parse(profile), 'uid', null)
      return uid
    }
    return null
  },
  getIdentity: () => {
    let profile = localStorage.getItem('profile');
    if (profile) {
      profile = JSON.parse(profile)
      return Promise.resolve(profile)
    }
    return Promise.resolve()
  },
  checkUserPermission: (userRoles, whiteListRoles) => {
    return intersectionWith(userRoles, whiteListRoles, isEqual).length > 0
  },
  forgotPassword: ({ username, captcha_response }) => {
    const request = new Request(`${backendURL}/auth/forgot_password`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        captcha_response
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-VS-CLIENT-TYPE': '1'
      }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ result }) => {
        if (result !== ResponseCode.SUCCESS) {
          if (result === ResponseCode.ERROR_INVALID_LOGIN_CREDENTIAL) {
            throw new Error('Tên đăng nhập hoặc email không tồn tại');
          }
          if (result === ResponseCode.ERROR_CAPTCHA) {
            throw new Error('Captcha bị lỗi vui lòng thử lại');
          }
          throw new Error(result);
        }
      });
  },
}

export default authProvider