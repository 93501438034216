import { get } from 'lodash'

import httpClient from './httpClient'
import { ResponseCode } from '../constants/constants'


const mentorDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', { })
    const { filter } = params
    const { fullSearch } = filter

    const getMentorIdsUrl = `/mentor/get_mentor_ids`;
    const getMentorInfosUrl = `/mentor/get_mentor_infos`;

    return httpClient(getMentorIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_mentor_ids = get(json, 'reply.mentor_ids', [])
      const paged_mentor_ids = (perPage && !fullSearch) ? all_mentor_ids.slice((page - 1) * perPage, page * perPage) : all_mentor_ids

      return {
        mentor_ids: paged_mentor_ids,
        total: all_mentor_ids.length
      }
    }).then(({ mentor_ids, total }) => {
      return httpClient(getMentorInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          mentor_ids
        })
      }).then(({ json }) => {
        const mentors = get(json, 'reply.mentor_infos', [])

        return {
          data: mentors,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getMentorInfosUrl = `/mentor/get_mentor_infos`;

    return httpClient(getMentorInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        mentor_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let mentor = get(json, 'reply.mentor_infos[0]', {})

      return {
        data: mentor
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getMentorInfosUrl = `/mentor/get_mentor_infos`;

    return httpClient(getMentorInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        mentor_ids: ids
      })
    }).then(({ json }) => {
      let mentor_infos = get(json, 'reply.mentor_infos', [])

      return {
        data: mentor_infos
      }
    })
  },

  update: (params) => {
    let { data } = params

    const setMentorOccupationTagUrl = `/admin/set_mentor_occupation_tags`;

    const mentor_id = get(data, 'id', 0)
    const occupation_tag_ids = get(data, 'occupation_tag_ids', 0)

    return httpClient(setMentorOccupationTagUrl, {
      method: 'POST',
      body: JSON.stringify({
        mentor_id,
        occupation_tag_ids
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default mentorDataProvider
