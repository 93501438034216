import React from 'react'
import { List, Datagrid, TextField, SelectField, 
  EmailField, UrlField, Pagination,
  DateField, ReferenceField, NumberField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'
import moment from 'moment'

import StudentApplicationFilter from './components/StudentApplicationFilter'
import ListBulkActionButtons from './components/ListBulkActionButtons'
import StudentListActions from './components/StudentListActions'
import colors from '../../../constants/colors'
import exporter from './exporter'

import { ApplicationStatusChoices, ReviewResultChoices, InterviewResultChoices } from '../constants'
import { RegionChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        '& td:nth-of-type(-n+4)': {
          position: 'sticky',
          background: 'white',
          zIndex: 100
        },
        '& th:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 200
        },
        '& th': {
          background: colors.primaryLight,
          fontWeight: 'bold'
        },
        '& td:nth-of-type(1), th:nth-of-type(1)': { // Checkbox
          '& span': {
            width: 20,
          },
          left: 0,
        },
        '& .column-id, th:nth-of-type(2)': { // STT
          '& span': {
            width: 20
          },
          left: 48,
        },
        '& td:nth-of-type(3), th:nth-of-type(3)': { // Khu vực
          '& span': {
            width: 40
          },
          left: 108
        },
        '& .column-display_name, th:nth-of-type(4)': { // Họ và tên
          '& span': {
            width: 200
          },
          left: 188,
        },
      },
      '& .hd-application-status, .application-status': {
        background: '#e0ecff'
      },
      '& .review-reviewer1, .review-reviewer2, .review-result1, .review-result2, .review-result': {
        background: '#fffdbd'
      },
      '& .hd-review-reviewer1, .hd-review-reviewer2, .hd-review-result1, .hd-review-result2, .hd-review-result': {
        background: '#fff940'
      },
      '& .hd-assessor-1, .hd-assessor-2, .hd-assessor-3, .hd-assessor-result-1, .hd-assessor-result-2, .hd-assessor-result-3': {
        background: '#03fca9'
      },
      '& .interview-datetime, .interview-interviewer1, .interview-interviewer2, .interview-result1, .interview-result2, .interview-result': {
        background: '#c9f7ff'
      },
      '& .hd-interview-datetime, .hd-interview-interviewer1, .hd-interview-interviewer2, .hd-interview-result1, .hd-interview-result2, .hd-interview-result': {
        background: '#7decff'
      },
      '& .assessment-day-result': {
        background: '#fcce88' 
      },
      '& .hd-assessment-day-result': {
        background: '#ffb94f'
      },
      '& .final-result': {
        background: '#96ccff'
      },
      '& .hd-final-result': {
        background: '#54acff'
      },
    },
  },
  list: {
    '& > div:first-child': {
      justifyContent: 'flex-start', // filter, actions css
    },
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const studentListShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Khu vực', source: 'region', component: SelectField,
    choices: RegionChoices,
  },
  { label: 'Họ và tên', source: 'display_name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Điện thoại', source: 'personal_info.phone_number[0].phone', component: TextField },
  { 
    label: 'Quê quán', source: 'personal_info.address.province_id', 
    component: (props) =>
      <ReferenceField {...props} reference='province'>
        <TextField source='name' />
      </ReferenceField>,
  },
  {
    label: 'Giới tính', source: 'personal_info.gender.gender_type', component: SelectField, 
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ]
  },
  { label: 'Link Facebook', source: 'personal_info.facebook_url', component: UrlField },
  { 
    label: 'Status', source: 'status', component: SelectField, 
    choices: ApplicationStatusChoices,
    headerClassName: 'hd-application-status', cellClassName: 'application-status'
  },
  { 
    label: 'Reviewer 1', source: 'reviewer_1', headerClassName: 'hd-review-reviewer1', cellClassName: 'review-reviewer1', 
    component: (props) =>
      <ReferenceField {...props} reference='student-application-reviewer'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result reviewer 1', source: 'reviewer_1_data.review_result', headerClassName: 'hd-review-result1', cellClassName: 'review-result1', component: SelectField, choices: ReviewResultChoices },
  { 
    label: 'Reviewer 2', source: 'reviewer_2', headerClassName: 'hd-review-reviewer2', cellClassName: 'review-reviewer2', 
    component: (props) =>
      <ReferenceField {...props} reference='student-application-reviewer'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result review 2', source: 'reviewer_2_data.review_result', headerClassName: 'hd-review-result2',  cellClassName: 'review-result2', component: SelectField, choices: ReviewResultChoices },

  { label: 'Final Result - Review', source: 'review_status', headerClassName: 'hd-review-result', cellClassName: 'review-result', component: SelectField, choices: ReviewResultChoices  },


  { label: 'Ngày giờ PV', source: 'interview_datetime', headerClassName: 'hd-interview-datetime', cellClassName: 'interview-datetime', component: DateField, showTime: true },

  { 
    label: 'Interviewer 1', source: 'interviewer_1', headerClassName: 'hd-interview-interviewer1',  cellClassName: 'interview-interviewer1', 
    component: (props) => 
      <ReferenceField {...props} reference='student-application-interviewer'>
        <TextField source='display_name'/>
      </ReferenceField>,
  },
  { label: 'Result interviewer 1', source: 'interviewer_1_data.interview_result', headerClassName: 'hd-interview-result1', cellClassName: 'interview-result1', component: SelectField, choices: InterviewResultChoices },

  { 
    label: 'Interviewer 2', source: 'interviewer_2', headerClassName: 'hd-interview-interviewer2', cellClassName: 'interview-interviewer2', 
    component: (props) =>
      <ReferenceField {...props} reference='student-application-interviewer'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result interviewer 2', source: 'interviewer_2_data.interview_result', headerClassName: 'hd-interview-result2', cellClassName: 'interview-result2', component: SelectField, choices: InterviewResultChoices },

  { label: 'Final Result - Interview', source: 'interview_status', headerClassName: 'hd-interview-result', cellClassName: 'interview-result', component: SelectField, choices: InterviewResultChoices },

  {
    label: 'Assessor 1', source: 'assessor_1', headerClassName: 'hd-assessor-1', cellClassName: 'assessor-1',
    component: (props) =>
      <ReferenceField {...props} reference='student-application-assessor'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result assessor 1', source: 'assessor_1_data.assessment_score', headerClassName: 'hd-assessor-result-1', cellClassName: 'assessor-result-1', component: NumberField },

  {
    label: 'Assessor 2', source: 'assessor_2', headerClassName: 'hd-assessor-2', cellClassName: 'assessor-2',
    component: (props) =>
      <ReferenceField {...props} reference='student-application-assessor'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result assessor 2', source: 'assessor_2_data.assessment_score', headerClassName: 'hd-assessor-result-2', cellClassName: 'assessor-result-2', component: NumberField },

  {
    label: 'Assessor 3', source: 'assessor_3', headerClassName: 'hd-assessor-3', cellClassName: 'assessor-3',
    component: (props) =>
      <ReferenceField {...props} reference='student-application-assessor'>
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Result assessor 3', source: 'assessor_3_data.assessment_score', headerClassName: 'hd-assessor-result-3', cellClassName: 'assessor-result-3', component: NumberField },

  // { label: 'Assessment Day Result', source: 'assessment-day-result', headerClassName: 'hd-assessment-day-result', cellClassName: 'assessment-day-result', component: BooleanField },

  // { label: 'FINAL RESULT', source: 'final-result', headerClassName: 'hd-final-result', cellClassName: 'final-result', component: BooleanField },
]

const StudentApplicationPagination = props => {
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  )
}

const StudentApplicationList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Student Application List 2021'}
      filters={<StudentApplicationFilter/>}
      bulkActionButtons={<ListBulkActionButtons/>}
      actions={<StudentListActions/>}
      pagination={<StudentApplicationPagination className={classes.pagination}/>}
      perPage={100}
      exporter={exporter}
      filterDefaultValues={{ year: moment().year() }}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          studentListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default StudentApplicationList