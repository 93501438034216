import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SectionWrapper from './SectionWrapper'
import { get } from 'lodash'

import colors from '../../../../../constants/colors'
import { essayQuestionsByYear } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  essayAnswerContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  essayQuestion: {
    display: 'block',
    lineHeight: 1.5,
    fontWeight: 'bold',
    color: colors.primary
  },
  essayAnswer: {
    marginTop: 10,
    lineHeight: 1.5,
  }
}));

const EssayAnswerField = ({ applicationYear, data }) => {
  const { answer, question_id } = data
  const classes = useStyles()

  return (
    <div className={classes.essayAnswerContainer}>
      <div className={classes.essayQuestion}>
        {essayQuestionsByYear[applicationYear][question_id].label}
      </div>
      <div className={classes.essayAnswer}>
        {answer}
      </div>
    </div>
  )
}

const EssaySection = (props) => {
  const { record, ...other } = props

  const applicationYear = get(record, 'year', 2020)
  const essays = get(record, 'essays', []).slice(5, 9)

  return (
    <SectionWrapper title='G. Phần bài luận' record={{ essays }} {...other}>
      {
        essays.map(answer => {
          return (
            <EssayAnswerField key={`essays_${answer.question_id}`} data={answer} applicationYear={applicationYear}/>
          )
        })
      }
    </SectionWrapper>
  )
};

export default EssaySection