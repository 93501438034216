import React from 'react'
import {
  List, Datagrid, TextField
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  },
}));

const occupationTagListShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Ngành nghề', source: 'name', component: TextField,
  },
  { 
    label: 'Occupation tag', source: 'name_en', component: TextField, 
  },
]

const UniversityList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Occupation tag list'}
      pagination={null}
      perPage={100}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          occupationTagListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default UniversityList