import React from 'react'
import { Edit, TabbedForm, useNotify, useRefresh } from 'react-admin'

import StudentProfileTab from './components/StudentProfileTab'
import StudentApplicationInfosTab from './components/StudentApplicationInfosTab'

const Title = ({ record }) => {
  return <span>{`Student: ${record.display_name}`}</span>
};

const StudentEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = ({ data }) => {
    notify(`Changes to student "${data.display_name}" are saved`, { type: 'success' })
    refresh();
  }

  return (
  <Edit 
    undoable={false}
    title={<Title />} 
    variant="standard"
    onSuccess={onSuccess}
    {...props}
  >
    <TabbedForm warnWhenUnsavedChanges>
      <StudentProfileTab/>
      <StudentApplicationInfosTab/>
    </TabbedForm>
  </Edit>
);
}

export default StudentEdit