// in src/MyError.js
import * as React from 'react';
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import History from '@material-ui/icons/History';
import { Title, useTranslate } from 'react-admin';
import { useHistory } from "react-router-dom"
import * as Sentry from "@sentry/react";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

import ErrorImage from '../static/images/error.gif'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    textAlign: 'center',
    margin: 'auto'
  },
  title: {
    color: theme.palette.error
  },
  errorMessage: {
    lineHeight: 1.5
  },
  btnContainer: {
    marginTop: 20,
  },
  memeImage: {
    marginTop: 20,
    width: '50%',
    height: 'auto',
  }
}))

const ErrorPage = ({
  error,
  errorInfo,
  ...rest
}) => {
  const translate = useTranslate();
  const history = useHistory()
  const classes = useStyles()

  React.useEffect(() => {
    Sentry.captureException(new Error(error.toString()), {
      extra: errorInfo
    })
  }, [])

  return (
    <div className={classes.root}>
      <Title title="Error"/>
      <div>
        <h1 className={classes.title}>
          Something Went Wrong <SentimentVeryDissatisfiedIcon fontSize="large"/> 
        </h1>
        <div className={classes.errorMessage}>
          A error occurred! Sorry for this inconvenience :( <br/>
          Don't worry, your data is saved and the error is reported with the technical team.
          The technical team will fix this soon.
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            startIcon={<History />}
            onClick={() => history.go(-1)}
            color="primary"
          >
            Back
          </Button>
        </div>
        <img src={ErrorImage} alt='error' className={classes.memeImage}/>
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <details>
          <h2>{translate(error.toString())}</h2>
          {errorInfo.componentStack}
        </details>
      )}
    </div>
  );
};

export default ErrorPage;