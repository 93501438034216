import React from 'react'
import {
  Create, SimpleForm,
  TextInput, ReferenceInput, SelectInput, TranslatableInputs,
  required
} from 'react-admin'
import UploadImageInput from '../../../../Components/CustomInputs/UploadImageInput'
import HtmlInput from '../../../../Components/CustomInputs/HtmlInput'

const Title = ({ record }) => {
  return <span>{`Create new article`}</span>
};

const ArticleCreate = (props) => {
  return (
    <Create
      undoable={false}
      title={<Title />}
      variant="outlined"
      sanitizeEmptyValues={false}
      {...props}
    >
      <SimpleForm warnWhenUnsavedChanges>
        <ReferenceInput label="Category" source="category_id" reference="article-category">
          <SelectInput variant="outlined" optionText={`title.vi`} fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
        <UploadImageInput
          source="cover" label="Cover" fullWidth
          uploadUrl='/admin/homepage/upload_article_image'
        />
        <UploadImageInput
          source="thumb" label="Thumbnail" fullWidth
          uploadUrl='/admin/homepage/upload_article_image'
        />
        <TranslatableInputs locales={['vi', 'en']}>
          <TextInput source="title" label="Title" fullWidth validate={[required()]}/>
          <HtmlInput
            source="content" label="Content" validate={[required()]}
            uploadUrl='/admin/homepage/upload_article_image'
          />
        </TranslatableInputs>
        <TextInput source="facebook" label="Facebook URL" fullWidth/>
        <TextInput source="linkedin" label="LinkedIn URL" fullWidth/>
        <TextInput source="pinterest" label="Pinterest URL" fullWidth/>
      </SimpleForm>
    </Create>
  );
}

export default ArticleCreate