import React from 'react'
import {
  FormTab,
} from 'react-admin'

import ProfileInformationSection from './StudentProfileTab/ProfileInformationSection'
import AcademicInfoSection from './StudentProfileTab/AcademicInfoSection'
import PersonalStorySection from './StudentProfileTab/PersonalStorySection'
import ImageSection from './StudentProfileTab/ImageSection'
import VietSeedsDataSection from './StudentProfileTab/VietSeedsDataSection'

const StudentProfileTab = (props) => {
  return (
    <FormTab label='Student profile' variant="outlined" {...props}>
      <ProfileInformationSection/>
      <AcademicInfoSection/>
      <PersonalStorySection/>
      <ImageSection/>
      <VietSeedsDataSection/>
    </FormTab>
  )
}

export default StudentProfileTab