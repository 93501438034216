import React from 'react'
import {
  List, Datagrid,
  ReferenceField, TextField, SelectField, NumberField,
  Pagination,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import StudentFilter from './components/StudentFilter'
import StudentActions from './components/StudentActions'
import colors from '../../../constants/colors'

import { RegionChoices, GenerationChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        '& td:nth-of-type(-n+4)': {
          position: 'sticky',
          background: 'white',
          zIndex: 100
        },
        '& th:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 200
        },
        '& th': {
          background: colors.primaryLight,
          fontWeight: 'bold'
        },
        '& td:nth-of-type(1), th:nth-of-type(1)': { // Checkbox
          '& span': {
            width: 20,
          },
          left: 0,
        },
        '& .column-id, th:nth-of-type(2)': { // STT
          '& span': {
            width: 20
          },
          left: 48,
        },
        '& td:nth-of-type(3), th:nth-of-type(3)': { // Khu vực
          '& span': {
            width: 40
          },
          left: 108
        },
        '& .column-display_name, th:nth-of-type(4)': { // Họ và tên
          '& span': {
            width: 200
          },
          left: 188,
        },
      },
    },
  },
  list: {
    '& > div:first-child': {
      justifyContent: 'flex-start', // filter, actions css
    },
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const studentListShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Khu vực', source: 'vietseeds_region', component: SelectField,
    choices: RegionChoices,
  },
  { label: 'Họ và tên', source: 'display_name', component: TextField },
  { label: 'Full name', source: 'display_name_en', component: TextField },
  {
    label: 'Nhóm', source: 'vietseeds_group', component: NumberField,
  },
  {
    label: 'Generation', source: 'vietseeds_generation', component: SelectField,
    choices: GenerationChoices,
  },
  { 
    label: 'Quê quán', source: 'province_id', 
    component: (props) =>
      <ReferenceField {...props} reference='province'>
        <TextField source='name' />
      </ReferenceField>,
  },
  { 
    label: 'Đại học', source: 'university_id', 
    component: (props) =>
      <ReferenceField {...props} reference='university'>
        <TextField source='name' />
      </ReferenceField>,
  },
  { label: 'Năm', source: 'university_year', component: TextField, },
  { label: 'Chuyên ngành', source: 'study_major', component: TextField, },
  { label: 'Major', source: 'study_major_en', component: TextField, },
]

const StudentPagination = props => {
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  )
}

const StudentList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Student List'}
      filters={<StudentFilter/>}
      actions={<StudentActions/>}
      pagination={<StudentPagination className={classes.pagination}/>}
      perPage={100}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          studentListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default StudentList