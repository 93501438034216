import React from 'react'
import { FunctionField } from 'react-admin'

import SectionWrapper from './SectionWrapper'
import GridListImageShow from '../../../../../Components/GridListImageShow'

const photoFields = [
  { label: '1. Hình của ứng viên', key: 'portrait_picture'},
  { label: '2. Hình ảnh toàn cảnh căn nhà mà ứng viên và gia đình đang ở', key: 'house_inside'},
  { label: '3. Hình ảnh bên trong căn nhà mà ứng viên và gia đình đang ở', key: 'house_outside'},
  { label: '4. Hình ảnh khác của ứng viên', key: 'additional_pictures'},
]

const ImageSection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='I. Phần hình ảnh' record={record} {...other}>
      {
        photoFields.map(field => {
          const { label, key } = field
          return (
            <FunctionField
              key={key}
              label={label}
              render={record => <GridListImageShow source={key} record={record.photos[key]} />}
            />
          )
        })
      }
    </SectionWrapper>
  )
}

export default ImageSection