import React from 'react'
import {
  FormTab, TextInput, SelectInput, DateTimeInput,
  DateField, BooleanField, TextField, SelectField, SimpleShowLayout,
  useGetList, usePermissions, ReferenceInput,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import authProvider from '../../../../Providers/authProvider'
import { InterviewResultChoices } from '../../constants'
import { Roles } from '../../../../constants/constants'
import EvaluationEditTable from './EvaluationEditTable'
import EvaluationShowTable from './EvaluationShowTable'
import colors from '../../../../constants/colors'
import { minLengthWords } from '../../../../helpers/validators'

const useStyles = makeStyles((theme) => ({
  interviewContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  interviewSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: 10
  },
  datetimeContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  overallResultContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    margin: 10,
  },
  fullWidth: {
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
  },
  flexOne: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  divider: {
    width: 1,
    height: 200,
    background: 'gray',
  },
}));

const DateTimeInputSection = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.datetimeContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Schedule</div>
      <DateTimeInput
        {...props}
        label='Giờ bắt đầu phỏng vấn' source='interview_datetime' variant='outlined'
        className={classes.fullWidth}
      />
    </div>
  )
}

const DateTimeFieldSection = (props) => {
  const { record } = props
  const classes = useStyles()
  return (
    <SimpleShowLayout record={record} className={classes.datetimeContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Schedule</div>
      <DateField
        showTime
        label='Giờ bắt đầu phỏng vấn' source='interview_datetime'
        className={classes.fullWidth}
      />
    </SimpleShowLayout>
  )
} 

const OverallResultInputSection = (props) => {
  const { interviewerList, ...others } = props
  const { record } = others
  const classes = useStyles()
  return (
    <div className={classes.overallResultContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Final result</div>
      <SimpleShowLayout record={record}>
        <SelectField
          label='Final Interview result'
          source='interview_status'
          variant='outlined'
          choices={InterviewResultChoices}
          className={classes.fullWidth}
        />
      </SimpleShowLayout>
      <div className={`${classes.header} ${classes.fullWidth}`}>Interviewers' response</div>
      <ReferenceInput
        {...others}
        label='Interviewer 1'
        source='interviewer_1'
        variant='outlined'
        reference='student-application-interviewer'
        className={classes.flexOne}
      >
        <SelectInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
      <SelectInput
        {...others}
        label='Interviewer 2' source='interviewer_2' variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
    </div>
  )
}

const OverallResultFieldSection = (props) => {
  const { record, interviewerList } = props

  const classes = useStyles()
  return (
    <SimpleShowLayout record={record} className={classes.overallResultContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Final result</div>
      <SelectField
        label='Final Interview result'
        source='interview_status'
        variant='outlined'
        choices={InterviewResultChoices}
        className={classes.fullWidth}
      />
      <div className={`${classes.header} ${classes.fullWidth}`}>Interviewers' response</div>
      <SelectField
        label='Interviewer 1'
        source='interviewer_1'
        variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
      <SelectField
        label='Interviewer 2'
        source='interviewer_2'
        variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
    </SimpleShowLayout>
  )
}

const InterviewerInputSection = (props) => {
  const { studentAdminPermission, interviewer } = props
  const classes = useStyles()

  return (
    <div {...props} className={classes.interviewSection} style={{ width: '95%' }}>
      <div className={`${classes.header} ${classes.fullWidth}`}>My interview</div>
      <SelectInput
        {...props}
        source={`${interviewer}.interview_result`}
        label='Interviewer Result'
        variant='outlined'
        choices={InterviewResultChoices}
        className={classes.fullWidth}
        validate={studentAdminPermission ? undefined : [required('Bắt buộc chọn kết quả interview')]}
      />
      <TextInput
        {...props}
        source={`${interviewer}.overall_feeling`}
        label='Overall feelings'
        helperText='Cảm giác tổng quan của phỏng vấn viên sau buổi phỏng vấn'
        variant='outlined'
        multiline
        className={classes.fullWidth}
        rows={2}
        validate={studentAdminPermission ? undefined : [minLengthWords(50, 'Bắt buộc nhập nhận xét ít nhất 50 từ')]}
      />
      <TextInput
        {...props}
        source={`${interviewer}.potential_candidate_note`}
        label='Ứng viên có tiềm năng vào nhóm TOP STAR VietSeeds không?'
        variant='outlined'
        multiline
        className={classes.fullWidth}
        rows={2}
      />
      <EvaluationEditTable {...props} interviewer={interviewer}/>
    </div>
  )
}

const InterviewerFieldSection = (props) => {
  const { record, interviewer } = props
  const classes = useStyles()

  const getSumScoreboard = (scoreboard) => {
    return scoreboard.reduce((total, { section_summary }) => {
      if (section_summary.max_score > 0) {
        return total + (section_summary.score || 0)
      } else {
        return total
      }
    }, 0)
  }
  
  const scoreboard = get(record, `${interviewer}.scoreboard`, [])

  return (
    <SimpleShowLayout {...props} record={record} className={classes.flexOne}>
      <SelectField
        source={`${interviewer}.interview_result`}
        label='Interviewer Result'
        choices={InterviewResultChoices}
      />
      <div>
        <span>Total score: </span>
        <span>{getSumScoreboard(scoreboard)}</span>
      </div>
      <BooleanField
        source={`${interviewer}.yes_for_circumstances`}
        label='YES cho hoàn cảnh gia đình'
      />
      <TextField
        source={`${interviewer}.overall_feeling`}
        label='Overall feelings'
        fullWidth
        rows={2}
      />
      <TextField
        source={`${interviewer}.potential_candidate_note`}
        label='Ứng viên có tiềm năng vào nhóm TOP STAR VietSeeds không?'
        fullWidth
        rows={2}
      />
    </SimpleShowLayout>
  )
}

const InterviewEditTab = (props) => {
  const classes = useStyles()
  const { record } = props

  const { data: interviewerListObject = {} } = useGetList('student-application-interviewer')
  const interviewerList = Object.keys(interviewerListObject).map(key => interviewerListObject[key])
  const { permissions } = usePermissions()
  const uid = authProvider.getUid()

  const studentAdminPermission = authProvider.checkUserPermission(permissions, [Roles.root, Roles.admin, Roles.adminStudentApp])

  return (
    <FormTab label='Interview' {...props}>
      {studentAdminPermission ?
        <React.Fragment>
          <DateTimeInputSection/>
          <OverallResultInputSection interviewerList={interviewerList}/>
        </React.Fragment>
        :
        <React.Fragment>
          <DateTimeFieldSection {...props}/>
          <OverallResultFieldSection {...props} interviewerList={interviewerList}/>
        </React.Fragment>
      }
      {(studentAdminPermission
        || uid === get(record, 'accessor_1')
        || uid === get(record, 'accessor_2')
        || uid === get(record, 'accessor_3')
      ) && // admin & accessor can see interviewers' results
        <>
        <div className={classes.interviewContainer}>
          <InterviewerFieldSection interviewer={'interviewer_1_data'} {...props} />
          <div className={classes.divider}></div>
          <InterviewerFieldSection interviewer={'interviewer_2_data'} {...props} />
        </div>
        <EvaluationShowTable interviewerList={interviewerList} {...props}/>
        </>
      }
      {(uid === get(record, 'interviewer_1') || uid === get(record, 'interviewer_2')) &&
        <InterviewerInputSection interviewer='my_interview' studentAdminPermission={studentAdminPermission}/>
      }
    </FormTab>
  )
}

export default InterviewEditTab