import { merge, get, cloneDeep, find } from 'lodash'

import httpClient from './httpClient'

import mentorApplicationDefaultValue from './defaultValues/mentorApplicationDefaultValue'
import MentorApplicationReviewerDataProvider from './MentorApplicationReviewerDataProvider'
import MentorApplicationInterviewerDataProvider from './MentorApplicationInterviewerDataProvider'
import authProvider from './authProvider'

const getReviewerResultByUid = (application, uid) => {
  return find(get(application, 'review.reviewer', []), {
    reviewer_uid: uid
  }) || {}
}

const getInterviewerResultByUid = (application, uid) => {
  return find(get(application, 'interview.interviewer', []), {
    interviewer_uid: uid
  }) || {}
}


const mentorApplicationDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    let filter = get(params, 'filter', {})

    // clear empty filters
    Object.keys(filter).forEach(key => {
      if (filter[key] === null || filter[key] === undefined) {
        delete filter[key]
      }
    })

    const getMentorApplicationIdsUrl = `/admin/mentor/get_application_ids`;
    const getMentorApplicationInfosUrl = `/admin/mentor/get_application_infos`;

    return httpClient(getMentorApplicationIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_application_ids = get(json, 'reply.application_ids', [])
      const paged_application_ids = perPage ? all_application_ids.slice((page - 1) * perPage, page * perPage) : all_application_ids
      
      return {
        application_ids: paged_application_ids,
        total: all_application_ids.length
      }
    }).then(({ application_ids, total }) => {
      return httpClient(getMentorApplicationInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          application_ids
        })
      }).then(({ json }) => {
        const applications = get(json, 'reply.applications', [])
        const merged_applications = applications.map((application) => {
          let merged_application = merge(cloneDeep(mentorApplicationDefaultValue), application)

          merged_application.reviewer_1_data = getReviewerResultByUid(merged_application, get(application, 'reviewer_1', ''))
          merged_application.reviewer_2_data = getReviewerResultByUid(merged_application, get(application, 'reviewer_2', ''))

          merged_application.interviewer_1_data = getInterviewerResultByUid(merged_application, get(application, 'interviewer_1', ''))
          merged_application.interviewer_2_data = getInterviewerResultByUid(merged_application, get(application, 'interviewer_2', ''))

          return merged_application
        })

        return {
          data: merged_applications,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getMentorApplicationInfosUrl = `/admin/mentor/get_application_infos`;

    return httpClient(getMentorApplicationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let application = get(json, 'reply.applications[0]', {})

      application.my_review = getReviewerResultByUid(application, authProvider.getUid())
      application.reviewer_1_data = getReviewerResultByUid(application, get(application, 'reviewer_1', ''))
      application.reviewer_2_data = getReviewerResultByUid(application, get(application, 'reviewer_2', ''))

      application.my_interview = getInterviewerResultByUid(application, authProvider.getUid())
      application.interviewer_1_data = getInterviewerResultByUid(application, get(application, 'interviewer_1', ''))
      application.interviewer_2_data = getInterviewerResultByUid(application, get(application, 'interviewer_2', ''))

      return {
        data: merge(cloneDeep(mentorApplicationDefaultValue), application)
      }
    })
  },

  getMany: (params) => {
    const { ids } = params
    const getMentorApplicationInfosUrl = `/admin/mentor/get_application_infos`;

    return httpClient(getMentorApplicationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids
      })
    }).then(({ headers, json }) => {
      const applications = get(json, 'reply.applications', [])

      const merged_applications = applications.map((application) => {
        application.reviewer_1_data = getReviewerResultByUid(application, get(application, 'reviewer_1', ''))
        application.reviewer_2_data = getReviewerResultByUid(application, get(application, 'reviewer_2', ''))

        application.interviewer_1_data = getInterviewerResultByUid(application, get(application, 'interviewer_1', ''))
        application.interviewer_2_data = getInterviewerResultByUid(application, get(application, 'interviewer_2', ''))

        return merge(cloneDeep(mentorApplicationDefaultValue), application)
      })
      return {
        data: merged_applications
      }
    })
  },

  update: async (params) => {
    const { id, data , previousData} = params
    const parsedId = JSON.parse(id)

    await Promise.all([
      MentorApplicationReviewerDataProvider.update({ id: parsedId, data, previousData }),
      MentorApplicationInterviewerDataProvider.update({ id: parsedId, data, previousData }),
    ])
      
    return Promise.resolve({ data })
  },

  setMentorApplicationsStatusToReviewing: async () => {
    const setMentorApplicationsStatusToReviewingUrl = `/admin/mentor/set_applications_status_to_reviewing`;
    
    return httpClient(setMentorApplicationsStatusToReviewingUrl, {
      method: 'POST',
      body: JSON.stringify({})
    }).then(({ json }) => {
      const result = get(json, 'reply.result', [])

      return result
    })
  },

  setMentorApplicationsStatusToInterviewing: async () => {
    const setMentorApplicationsStatusToInterviewingUrl = `/admin/mentor/set_applications_status_to_interviewing`;

    return httpClient(setMentorApplicationsStatusToInterviewingUrl, {
      method: 'POST',
      body: JSON.stringify({})
    }).then(({ json }) => {
      const result = get(json, 'reply.result', [])

      return result
    })
  },
};

export default mentorApplicationDataProvider
