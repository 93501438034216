import React from 'react'
import {
  List, Datagrid, TextField, EmailField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'
import { get } from 'lodash'

import SponsorInvitationFilter from './components/SponsorInvitationFilter'
import SponsorInvitationActions from './components/SponsorInvitationActions'
import ListPagination from '../../../../Components/ListPagination'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));


const sponsorInvitationShowFields = [
  { label: 'ID', source: 'id', component: TextField, },
  { label: 'Họ và tên', source: 'display_name', component: TextField },
  { label: 'Invitation code', source: 'invitation_code', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Phone', source: 'phone', component: TextField },
]


const sponsorInvitationRowStyle = (record, index) => {
  const expiry_time = get(record, 'expiry_time', 0)
  var date = new Date()

  if (expiry_time < date) { // expired
    return {
      background: '#ffbdbd'
    }
  }
  return {
    
  }
}

const SponsorInvitationList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Sponsor Invitation List'}
      filters={<SponsorInvitationFilter/>}
      actions={<SponsorInvitationActions/>}
      pagination={<ListPagination/>}
      perPage={100}
    >
      <Datagrid 
        rowClick='edit' 
        className={classes.table}
        rowStyle={sponsorInvitationRowStyle}
      >
        {
          sponsorInvitationShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default SponsorInvitationList