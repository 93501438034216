import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  ReferenceArrayInput,
  SelectInput,
  SearchInput,
  TextInput,
  NumberInput,
  SelectArrayInput,
} from 'react-admin';

import { RegionChoices, GenerationChoices, StudentTypeChoices } from '../../../../constants/constants'

const styles = theme => ({
})

const StudentFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <SelectInput source='vietseeds_region' label='Region' choices={RegionChoices}/>
      <NumberInput source='vietseeds_group' label='Nhóm' />
      <SelectInput source='vietseeds_generation' label='Generation' choices={GenerationChoices}/>
      <SelectInput source='vietseeds_student_type' label='Student type' choices={StudentTypeChoices} />
      <ReferenceArrayInput label='Đại học' source="university_ids" reference="university">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput label='Ngành' source='study_major' />
      <TextInput label='Major' source='study_major_en' />
      <NumberInput label='Năm' source='university_year'/>
      <ReferenceArrayInput label='Quê quán' source="province_ids" reference="province">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </Filter>
  )
}

export default withStyles(styles)(StudentFilter)