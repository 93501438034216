import React from 'react'

import SectionWrapper from './SectionWrapper'
import GridTableShow from '../../../../../Components/GridTableShow'


const FamilySection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='E. Thông tin về các thành viên trong gia đình' record={record} {...other}>
      <GridTableShow 
        source='family_members'
        title={'Các thành viên trong gia đình'}
        instruction={`
          Liệt kê các thành viên trong gia đình. 
          Không thêm lố các dòng trống và xóa các dòng trống (nếu đã thêm vào).
        `}
        columns={[
          { label: 'Họ tên', key: 'full_name', size: 4 },
          { label: 'Quan hệ với em', key: 'relationship', size: 2 },
          {  label: 'Công việc', key: 'job', size: 2, },
          { label: 'Tên trường (ghi rõ cấp học) hoặc tên cơ quan/công ty', key: 'work_place', size: 4 },
        ]}
      />
    </SectionWrapper>
  );
}

export default FamilySection;