import React from 'react'
import {
  List, Datagrid, TextField, EmailField,
  ReferenceArrayField, SingleFieldList, ChipField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import UserFilter from './components/UserFilter'
import ListPagination from '../../../Components/ListPagination'

const useStyles = makeStyles((theme) => ({

}));

const userListShowFields = [
  {
    label: 'ID', source: 'id', component: TextField,
  },
  {
    label: 'Name', source: 'display_name', component: TextField,
  },
  { 
    label: 'Email', source: 'email', component: EmailField, 
  },
  { 
    label: 'Roles', source: 'role_ids',
    component: (props) => 
      <ReferenceArrayField {...props} reference="user-role">
        <SingleFieldList>
          <ChipField source="role_name" />
        </SingleFieldList>
      </ReferenceArrayField>
  }
]

const UserList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'User List'}
      filters={<UserFilter/>}
      pagination={<ListPagination/>}
      perPage={100}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          userListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default UserList