import { get, } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

import { SponsorRegistrationStatus } from '../Pages/Sponsor/SponsorRegistration/constants'

const sponsorRegistrationDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter
    
    const getSponsorRegistrationIdsUrl = `/admin/get_sponsor_registration_ids`;
    const getSponsorRegistrationInfosUrl = `/admin/get_sponsor_registration_infos`;

    return httpClient(getSponsorRegistrationIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_registration_ids = get(json, 'reply.registration_ids', [])
      const paged_registration_ids = (page && !fullSearch) ? all_registration_ids.slice((page - 1) * perPage, page * perPage) : all_registration_ids

      return {
        registration_ids: paged_registration_ids,
        total: all_registration_ids.length
      }
    }).then(({ registration_ids, total }) => {
      return httpClient(getSponsorRegistrationInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          registration_ids
        })
      }).then(({ json }) => {
        let invitations = get(json, 'reply.registration_infos', [])

        return {
          data: invitations,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getSponsorRegistrationInfosUrl = `/admin/get_sponsor_registration_infos`;

    return httpClient(getSponsorRegistrationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        registration_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let registration = get(json, 'reply.registration_infos[0]', {})

      return {
        data: registration
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getSponsorInvitationInfosUrl = `/admin/get_sponsor_invitation_infos`;

    return httpClient(getSponsorInvitationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        invitation_ids: ids
      })
    }).then(({ json }) => {
      let invitations = get(json, 'reply.invitations', [])

      return {
        data: invitations
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setSponsorInvitationUrl = `/admin/set_sponsor_invitation`;

    return httpClient(setSponsorInvitationUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const invitation_id = get(json, 'reply.invitation_id', {})
      return {
        data: {
          ...data,
          id: invitation_id,
        }
      }
    })
  },

  update: (params) => {
    const { data } = params
    const { id, status } = data

    const confirmSponsorRegistrationUrl = `/admin/confirm_sponsor_registration`;
    const cancelSponsorRegistrationUrl = `/admin/cancel_sponsor_registration`;

    const requestUrl = (status === SponsorRegistrationStatus[1].id) ? confirmSponsorRegistrationUrl : cancelSponsorRegistrationUrl

    return httpClient(requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        registration_ids: [parseInt(id)],
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default sponsorRegistrationDataProvider
