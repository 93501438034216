import React from "react";
import { useGetList, useGetMany } from 'react-admin'
import ReactExport from "@ibrahimrahmani/react-export-excel";
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { get, sortBy } from 'lodash'
import moment from 'moment'

import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'
import { MentorMatchingStatusChoices } from '../../../../constants/constants'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExportExcel = (props) => {
  const { filterValues } = props
  const { data, ids, loading, error } = useGetList(
    'mentor-matching-request',
    {},
    filterValues
  );
  const matchingRequests = ids.map(key => data[key])

  const { data: students, loading: isLoadingStudents } = useGetMany(
    'student',
    ids.map(id => data[id].student_id),
    // run only if the first query returns non-empty result
    { enabled: ids.length > 0 }
  );
  const studentDict = {}
  students.forEach(student => studentDict[student?.id] = student)

  const { data: mentors, loading: isLoadingMentors } = useGetMany(
    'mentor',
    ids.map(id => data[id].mentor_id),
    // run only if the first query returns non-empty result
    { enabled: ids.length > 0 }
  );
  const mentorDict = {}
  mentors.forEach(mentor => mentorDict[mentor?.id] = mentor)

  const requestsByYear = {}
  matchingRequests.forEach(request => {
    if (!requestsByYear[request?.year]) {
      requestsByYear[request?.year] = []
    }
    requestsByYear[request?.year].push(request)
  })

  const years = sortBy(Object.keys(requestsByYear))

  return (
    <ExcelFile
      filename={"mentor_matching_requests"}
      element={
        <Button
          color="primary"
          size="small"
          startIcon={<DownloadIcon />}
          disabled={loading || error || isLoadingStudents || isLoadingMentors}
        >
          Export Excel
        </Button>
      }
    >
      {years.map((year) => (
        <ExcelSheet data={requestsByYear[year]} name={year}>
          <ExcelColumn label="ID" value="id" />
          <ExcelColumn label="Student ID" value="student_id" />
          <ExcelColumn
            label="Student"
            value={(col) => get(studentDict, `[${col.student_id}].display_name`, '')}
          />
          <ExcelColumn label="Mentor ID" value="mentor_id" />
          <ExcelColumn
            label="Mentor"
            value={(col) => get(mentorDict, `[${col.mentor_id}].display_name`, '')}
          />
          <ExcelColumn
            label="Status"
            value={(col) => mapChoicesToValue(MentorMatchingStatusChoices, get(col, 'status', 0))}
          />
          <ExcelColumn
            label="Updated date"
            value={(col) => moment(col.update_time).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Updated time"
            value={(col) => moment(col.update_time).format("HH:mm:ss")}
          />
          <ExcelColumn
            label="Created date"
            value={(col) => moment(col.create_time).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Created time"
            value={(col) => moment(col.create_time).format("HH:mm:ss")}
          />
        </ExcelSheet>
      ))}
    </ExcelFile>
  );
}

export default ExportExcel