import React from 'react'
import { List, Datagrid, TextField, SelectField, 
  EmailField, Pagination,
  DateField, ReferenceField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import MentorApplicationFilter from './components/MentorApplicationFilter'
import MentorListActions from './components/MentorListActions'
import colors from '../../../constants/colors'
import exporter from './exporter'

import { ReviewResultChoices, InterviewResultChoices, MentorRegionChoices, ApplicationStatus,
  FinalInterviewResultChoices,
} from '../constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        '& td:nth-of-type(-n+4)': {
          position: 'sticky',
          background: 'white',
          zIndex: 100
        },
        '& th:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 200
        },
        '& th': {
          background: colors.primaryLight,
          fontWeight: 'bold'
        },
        '& td:nth-of-type(1), th:nth-of-type(1)': { // Checkbox
          '& span': {
            width: 20,
          },
          left: 0,
        },
        '& .column-id, th:nth-of-type(2)': { // STT
          '& span': {
            width: 20
          },
          left: 48,
        },
        '& td:nth-of-type(3), th:nth-of-type(3)': { // Khu vực
          '& span': {
            width: 40
          },
          left: 108
        },
        '& .column-display_name, th:nth-of-type(4)': { // Họ và tên
          '& span': {
            width: 200
          },
          left: 188,
        },
      },
      '& .application-status': {
        // background: '#FFA07A'
      },
      '& .hd-application-status': {
        background: '#FFA07A'
      },
      '& .review-reviewer1, .review-reviewer2, .review-result1, .review-result2, .review-result': {
        background: '#fffdbd'
      },
      '& .hd-review-reviewer1, .hd-review-reviewer2, .hd-review-result1, .hd-review-result2, .hd-review-result': {
        background: '#fff940'
      },
      '& .interview-datetime, .interview-interviewer1, .interview-interviewer2, .interview-result1, .interview-result2, .interview-result': {
        background: '#c9f7ff'
      },
      '& .hd-interview-datetime, .hd-interview-interviewer1, .hd-interview-interviewer2, .hd-interview-result1, .hd-interview-result2, .hd-interview-result': {
        background: '#7decff'
      },
      '& .final-result': {
        background: '#96ccff'
      },
      '& .hd-final-result': {
        background: '#54acff'
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const mentorListShowFields = [
  {
    label: 'ID', source: 'id', component: TextField,
  },
  {
    label: 'Khu vực', source: 'region', component: SelectField,
    choices: MentorRegionChoices,
  },
  { label: 'Họ và tên', source: 'display_name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Điện thoại', source: 'personal_info.phone_number[0].phone', component: TextField },
  {
    label: 'Giới tính', source: 'personal_info.gender.gender_type', component: SelectField, 
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ]
  },
  { label: 'Application status', source: 'status', headerClassName: 'hd-application-status', cellClassName: 'application-status', component: SelectField, choices: ApplicationStatus },
  { 
    label: 'Reviewer 1', source: 'reviewer_1', headerClassName: 'hd-review-reviewer1', cellClassName: 'review-reviewer1', 
    component: (props) =>
      <ReferenceField {...props} reference='mentor-application-reviewer'>
        <TextField source='name' />
      </ReferenceField>,
  },
  { label: 'Result reviewer 1', source: 'reviewer_1_data.review_result', headerClassName: 'hd-review-result1', cellClassName: 'review-result1', component: SelectField, choices: ReviewResultChoices },
  { 
    label: 'Reviewer 2', source: 'reviewer_2', headerClassName: 'hd-review-reviewer2', cellClassName: 'review-reviewer2', 
    component: (props) =>
      <ReferenceField {...props} reference='mentor-application-reviewer'>
        <TextField source='name' />
      </ReferenceField>,
  },
  { label: 'Result review 2', source: 'reviewer_2_data.review_result', headerClassName: 'hd-review-result2',  cellClassName: 'review-result2', component: SelectField, choices: ReviewResultChoices },

  { label: 'Final Result - Review', source: 'review_status', headerClassName: 'hd-review-result', cellClassName: 'review-result', component: SelectField, choices: ReviewResultChoices  },


  { label: 'Ngày giờ PV', source: 'interview_datetime', headerClassName: 'hd-interview-datetime', cellClassName: 'interview-datetime', component: DateField, showTime: true },

  { 
    label: 'Interviewer 1', source: 'interviewer_1', headerClassName: 'hd-interview-interviewer1',  cellClassName: 'interview-interviewer1', 
    component: (props) => 
      <ReferenceField {...props} reference='mentor-application-interviewer'>
        <TextField source='name'/>
      </ReferenceField>,
  },
  { label: 'Result interviewer 1', source: 'interviewer_1_data.interview_result', headerClassName: 'hd-interview-result1', cellClassName: 'interview-result1', component: SelectField, choices: InterviewResultChoices },

  { 
    label: 'Interviewer 2', source: 'interviewer_2', headerClassName: 'hd-interview-interviewer2', cellClassName: 'interview-interviewer2', 
    component: (props) =>
      <ReferenceField {...props} reference='mentor-application-interviewer'>
        <TextField source='name' />
      </ReferenceField>,
  },
  { label: 'Result interviewer 2', source: 'interviewer_2_data.interview_result', headerClassName: 'hd-interview-result2', cellClassName: 'interview-result2', component: SelectField, choices: InterviewResultChoices },

  { label: 'Final Result - Interview', source: 'interview_status', headerClassName: 'hd-interview-result', cellClassName: 'interview-result', component: SelectField, choices: FinalInterviewResultChoices },
]

const MentorApplicationPagination = props => {
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  )
}

const MentorApplicationList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Mentor Application List 2021'}
      filters={<MentorApplicationFilter/>}
      actions={<MentorListActions/>}
      pagination={<MentorApplicationPagination className={classes.pagination}/>}
      perPage={100}
      exporter={exporter}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          mentorListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default MentorApplicationList