const SponsorAmountChoices = [
  { id: 0, name: 'None', },
  { id: 1, name: 'Half scholarship', },
  { id: 2, name: 'Full scholarship', },
]

const SponsorTypeChoices = [
  { id: 1, name: 'Student sponsor'},
  { id: 2, name: 'Operation cost sponsor'},
]

const SponsorStatusChoices = [
  { id: 1, name: 'Pending', },
  { id: 2, name: 'Confirmed', },
  { id: 5, name: 'On-going', }, // sponsor split donation into multiple times
  { id: 3, name: 'Done', },
  { id: 4, name: 'Canceled', },
]

const DonationTypeChoices = [
  { id: 1, name: 'Vietcombank Transfer' },
  { id: 2, name: 'Paypal' },
  { id: 3, name: 'Wells Fargo' },
  { id: 4, name: 'Checks' },
]

const PreferredContactMethodChoices = [
  { id: 1, name: 'phone' },
  { id: 2, name: 'email' },
]

export {
  SponsorAmountChoices,
  SponsorTypeChoices,
  SponsorStatusChoices,
  DonationTypeChoices,
  PreferredContactMethodChoices,
}