import {
  TextField, SelectField, EmailField
} from 'react-admin'

import { MentorRegionChoices } from '../../../constants'

const profileFields = [
  { label: 'ID', source: 'id', component: TextField },
  { label: 'Họ và tên đệm', source: 'personal_info.last_name', component: TextField },
  { label: 'Tên', source: 'personal_info.first_name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Địa chỉ email anh/chị dùng thường xuyên nhất?', source: 'main_email', component: EmailField },
  {
    label: 'Giới tính', source: 'personal_info.gender.gender_type', component: SelectField,
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ]
  },
  {
    label: 'Anh/chị đang sống ở đâu', source: 'region', component: SelectField,
    choices: MentorRegionChoices,
  },
  {
    label: `Nếu anh/chị không sống ở TP.HCM, Hà Nội hay Huế, 
            thành phố và đất nước anh/ chị đang sống là ?`,
    source: 'personal_info.address',
    component: TextField
  },
  { label: 'Số điện thoại', source: 'personal_info.phone_number', component: TextField },
]

export default profileFields