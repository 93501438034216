import { merge, get, cloneDeep, } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

import { infosDefaultValue, summariesDefaultValue } from './defaultValues/studentDefaultValue'


const studentDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', { })
    const { filter } = params
    const { fullSearch } = filter

    const getStudentIdsUrl = `/student/get_student_ids`;
    const getStudentInfosUrl = `/admin/get_student_infos`;

    return httpClient(getStudentIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_student_ids = get(json, 'reply.student_ids', [])
      const paged_student_ids = (perPage && !fullSearch) ? all_student_ids.slice((page - 1) * perPage, page * perPage) : all_student_ids

      return {
        student_ids: paged_student_ids,
        total: all_student_ids.length
      }
    }).then(({ student_ids, total }) => {
      return httpClient(getStudentInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          student_ids
        })
      }).then(({ json }) => {
        const students = get(json, 'reply.student_summaries', [])
        const merged_students = students.map((student) => {
          let merged_student = merge(cloneDeep(summariesDefaultValue), student)

          return merged_student
        })

        return {
          data: merged_students,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getStudentInfosUrl = `/admin/get_student_infos`;

    return httpClient(getStudentInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        student_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let student = get(json, 'reply.student_summaries[0]', {})

      return {
        data: merge(cloneDeep(infosDefaultValue), student)
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getStudentInfosUrl = `/admin/get_student_infos`;

    return httpClient(getStudentInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        student_ids: ids
      })
    }).then(({ json }) => {
      let student_infos = get(json, 'reply.student_summaries', [])

      return {
        data: student_infos
      }
    })
  },

  update: (params) => {
    let { data } = params

    const setStudentUrl = `/admin/set_student_profile`;

    // TODO: realign with BE, just temporary solution for urgent updating F10
    const vietseeds_generation = get(data, 'vietseeds_generation', 0)
    if (vietseeds_generation === 10) {
      const id = get(data, 'id', 0)
      const university_id = get(data, 'university_id', 0)
      const profile_picture_url = get(data, 'profile_picture_url', '')
      data = {
        ...data,
        id, 
        university_id,
        profile_picture_url,
      }
    }

    return httpClient(setStudentUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default studentDataProvider
