import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 'bold',
    color: '#a1a1a1'
  }
}));

const StatusDrawer = (props) => {
  const { 
    open, handleClose, handleClickEvent, 
    unassignedApplications = [], events = [], step 
  } = props
  const classes = useStyles()

  return (
    <Drawer open={open} anchor='right' onClose={handleClose}>
      <List>
        <ListItem className={classes.header}>{`Unassigned Applications (${unassignedApplications.length})`}</ListItem>
        {
          unassignedApplications.map((application) => {
            const { id, display_name, interview_datetime } = application
            const formattedStart = moment(interview_datetime).format("DD-MM-YYYY hh:mm")
            const formattedEnd = moment(interview_datetime).add(step, 'minutes').format("DD-MM-YYYY hh:mm")
            return (
              <ListItem key={`unassigned_applications_${id}`} 
                button component="a" href={`/#/student-application/${id}`} target="_blank"
              >
                <ListItemText primary={`${id}. ${display_name}`} secondary={`${formattedStart} - ${formattedEnd}`}/>
              </ListItem>
            )
          })
        }
      </List>
      <Divider/>
      <List>
        <ListItem className={classes.header}>{`Assigned applications (${events.length})`}</ListItem>
        {
          events.map((event) => {
            const { id, title, start, end } = event
            const formattedStart = moment(start).format("DD-MM-YYYY hh:mm")
            const formattedEnd = moment(end).format("DD-MM-YYYY hh:mm")
            return (
              <ListItem key={`events_${id}`} button onClick={() => handleClickEvent(event)}>
                <ListItemText primary={title} secondary={`${formattedStart} - ${formattedEnd}`}/>
                {/* <ListItemIcon>
                  <RemoveIcon onClick={(e) => handleRemove(e, event)}/>
                </ListItemIcon> */}
              </ListItem>
            )
          })
        }
      </List>
    </Drawer>
  )
}

export default StatusDrawer