import MentorMatchingRequestList from './MentorMatchingRequestList/index'
import MentorMatchingRequestShow from './MentorMatchingRequestShow/index'
import MentorMatchingRequestCreate from './MentorMatchingRequestCreate/index'
import MentorMatchingRequestEdit from './MentorMatchingRequestEdit/index'
import MentorMatchingRequestIcon from '@material-ui/icons/ThumbsUpDown'

export default {
  list: MentorMatchingRequestList,
  show: MentorMatchingRequestShow,
  create: MentorMatchingRequestCreate,
  edit: MentorMatchingRequestEdit,
  icon: MentorMatchingRequestIcon,
  options: { label: 'Mentor matching request' }
}