import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  ReferenceField, DateField,
} from 'react-admin';
import UserField from '../../../../Components/CustomFields/UserField';

const UidReferenceField = (props) => {
  const { record } = props
  return (
    record ? (
      <Typography>
        <UserField {...props} source='display_name' />
      </Typography>
    ) : null
  )
}

const DatetimeInfo = ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom align="left">
          Create
        </Typography>
        <Typography gutterBottom align="left">
          <ReferenceField
            resource="mentor-matching-request"
            reference="user"
            source="create_uid"
            basePath="/user"
            record={record}
            link={false}
          >
            <UidReferenceField />
          </ReferenceField>
        </Typography>
        <Typography gutterBottom align="left">
          <b>At: {' '}</b>
          <DateField
            record={record}
            showTime
            source='create_time'
          />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom align="left">
          Last update
        </Typography>
        <Typography gutterBottom align="left">
          <ReferenceField
            resource="mentor-matching-request"
            reference="user"
            source="update_uid"
            basePath="/user"
            record={record}
            link={false}
          >
            <UidReferenceField />
          </ReferenceField>
        </Typography>
        <Typography gutterBottom align="left">
          <b>At: {' '}</b>
          <DateField
            record={record}
            showTime
            source='update_time'
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DatetimeInfo;
