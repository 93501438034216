import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { TextField, NumberField } from 'react-admin'

import ProvinceField from '../../../../../Components/ProvinceField'
import colors from '../../../../../constants/colors'


const styles = (theme => ({
  title: {
    color: '#FFF',
    backgroundColor: colors.primary,
    fontWeight: 'bold'
  },
  instruction: {
    padding: 15,
    lineHeight: 1.5,
    color: colors.error,
    fontWeight: 'bold'
  },
  header: {
    textAlign: 'center',
    backgroundColor: colors.light,
    fontWeight: 'bold',
    lineHeight: 1.5,
    padding: 20
  },
  item: {
    height: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 'solid #f5f5f5 0.1px'
  },
  addButton: {
    marginTop: 20,
    width: '100%'
  },
}))

const UniversityResult = (props) => {
  const { classes, record } = props

  const fieldPrefix = 'academic_info.university'

  return (
    <Grid container style={{ marginBottom: 40 }}>
      <Grid container item>
        <Grid item xs={12} className={`${classes.title} ${classes.item}`}>
          Phần dành cho sinh viên đang ôn thi lại Đại học
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12} className={`${classes.instruction} ${classes.item}`}>
          Lưu ý: các điểm số được ghi ở thang điểm 10
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={`${classes.header} ${classes.item}`} xs={6}>Thông tin trường ĐH</Grid>
        <Grid item className={`${classes.header} ${classes.item}`} xs={6}>Kết quả học tập (2 học kì gần nhất)</Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Tên trường</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.name`} />
        </Grid>
        <Grid item className={classes.item} xs={2}>Học kì</Grid>
        <Grid item className={classes.item} xs={1}>
          <NumberField record={record} source={`${fieldPrefix}.results[0].semester`}/>
        </Grid>
        <Grid item className={classes.item} xs={2}>năm học</Grid>
        <Grid item className={classes.item} xs={1}>
          <NumberField record={record} source={`${fieldPrefix}.results[0].year`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Địa chỉ trường</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.address.address_text`} />
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm trung bình</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.results[0].avg_grade`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Ngành học</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.major`}/>
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm rèn luyện</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.results[0].conduct_grade`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Tỉnh/Thành phố</Grid>
        <Grid item className={classes.item} xs={3}>
          <ProvinceField
            record={record}
            source={`${fieldPrefix}.address.province_id`}
          />
        </Grid>
        <Grid item className={classes.item} xs={2}>Học kì</Grid>
        <Grid item className={classes.item} xs={1}>
          <NumberField record={record} source={`${fieldPrefix}.results[1].semester`}/>
        </Grid>
        <Grid item className={classes.item} xs={2}>năm học</Grid>
        <Grid item className={classes.item} xs={1}>
          <NumberField record={record} source={`${fieldPrefix}.results[1].year`} />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Số điện thoại</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.phone`} />
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm trung bình</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.results[1].avg_grade`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={6}></Grid>
        <Grid item className={classes.item} xs={3}>Điểm rèn luyện</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.results[1].conduct_grade`}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(UniversityResult)