import * as React from 'react';
import { useState } from 'react';
import { useRedirect, useNotify, Notification, useGetIdentity } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import authProvider from '../../Providers/authProvider'

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { NearMeRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  field: {
    marginTop: 20,
  },
  form: {
    padding: 20,
  }
}))

const PasswordField = (props) => {
  const { name, label, onChange, value } = props
  const [ showPassword, setShowPassword ] = useState(false)

  const classes = useStyles()
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  
  return (
    <TextField name={name} label={label}
      type={showPassword ? 'text' : 'password'}
      required fullWidth
      InputProps={{
        startAdornment:
          <InputAdornment position="start">
            <IconButton
              aria-label={NearMeRounded}
            >
              <LockIcon />
            </IconButton>
          </InputAdornment>,
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label={`toggle ${name} visibility`}
              onClick={toggleShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
      }}
      onChange={onChange}
      value={value}
      className={classes.field}
    />
  )
}

const ChangePassword = ({ theme }) => {
  const classes = useStyles()
  const notify = useNotify();
  const redirect = useRedirect();
  const { identity = {}, loading: identityLoading } = useGetIdentity();
  const { username = '' } = identity

  const [ password, setPassword ] = useState({
    'current-password': '',
    'new-password': '',
    'confirm-new-password': '',
  })

  const submit = (e) => {
    e.preventDefault();
    if (password['new-password'] !== password['confirm-new-password'] || password['new-password'] === '') {
      notify('Invalid new password or confirm password does not match', 'warning')
      return;
    }
    authProvider.changePassword({ 
      username, 
      oldPassword: password['current-password'], 
      newPassword: password['new-password'] 
    })
      .then(() => {
        redirect('/');
        notify('Change password successfully', 'success')
      })
      .catch(() => notify('Invalid username or password', 'warning'));
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setPassword({
      ...password,
      [name]: value
    })
  }

  if (identityLoading) return <>Loading...</>;

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={submit} className={classes.form}>
        <div className={classes.header}>Change password</div>
        <TextField name={`username`} label='Username' required fullWidth disabled
          className={classes.field}
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                <IconButton
                  aria-label="username"
                >
                  <AccountCircle />
                </IconButton>
              </InputAdornment>
          }}
          value={username}
        />
        <PasswordField  
          name="current-password" 
          label="Current password" 
          onChange={handleChange} 
          value={password['current-password']}
        />
        <PasswordField
          name="new-password"
          label="New password"
          onChange={handleChange}
          value={password['new-password']}
        />
        <PasswordField
          name="confirm-new-password"
          label="Confirm new password"
          onChange={handleChange}
          value={password['confirm-new-password']}
        />
        <Button color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 40 }}>
          Change password
        </Button>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

export default ChangePassword;