import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import getFileExtension from '../helpers/getFileExtension'

const styles = (theme => ({
  referenceReview: {
    width: '100%',
    height: 500,
    marginTop: 20,
    marginBottom: 20,
  }
}))

const documentExtensions = ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx']

const DocumentPreview = (props) => {
  const { classes, fileUrl, ...other } = props
  const fileExtension = getFileExtension(fileUrl)
  const isDocumentFile = documentExtensions.includes(fileExtension.toLowerCase())
  // no file
  if (!fileExtension) return null

  return (
    <React.Fragment>
      <p>Ghi chú: </p>
      <ul>
        <li>Nếu hiện "AccessDenied": Ứng viên chưa nộp thư giới thiệu</li>
        <li>Nếu không xem được thư giới thiệu phía dưới: Bấm "TẢI FILE" để tải file về máy</li>
      </ul>
      <Button 
        color="primary"
        onClick={() => window.open(`https://docs.google.com/gview?url=${fileUrl}&embedded=true`, "_blank")} // open file in new tab
      >
        Xem file trong tab mới
      </Button>
      <Button
        color="primary"
        onClick={() => window.open(`${fileUrl}`, "_blank")} // download file
      >
        Tải file
      </Button>
      <iframe
        title='reference_review'
        className={classes.referenceReview}
        src={isDocumentFile ? `https://docs.google.com/gview?url=${fileUrl}&embedded=true` : fileUrl}
        {...other}>
      </iframe>
    </React.Fragment>
  )
}

export default withStyles(styles)(DocumentPreview)