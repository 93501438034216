import swal from 'sweetalert'
import { get } from 'lodash'

import { ResponseCode } from '../constants/constants'

const mapErrorToAlert = {
  [ResponseCode.SUCCESS]: {
    title: 'Thao tác thành công',
    text: '',
    icon: 'success',
  },

  [ResponseCode.ERROR_PARAMS]: {
    title: 'Thiếu thông tin',
    text: 'Vui lòng kiểm tra điền đầy đủ thông tin và thử lại',
    icon: 'error',
  },
  [ResponseCode.ERROR_FORBIDDEN]: {
    title: 'Không có quyền truy cập',
    text: `Bạn không có quyền truy cập tính năng này! Nếu bạn nghĩ có sai sót,
    vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
    để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_UNAUTHORIZED]: {
    title: 'Bạn chưa đăng nhập',
    text: `Vui lòng đăng nhập để có thể thực hiện tính năng này`,
    icon: 'error',
  },
  [ResponseCode.ERROR_CAPTCHA]: {
    title: 'Captcha đã hết hạn',
    text: 'Vui lòng xác minh captcha lại để tiếp tục!',
    icon: 'error',
  },
  [ResponseCode.ERROR_INVALID_RESET_PASSWORD_TOKEN]: {
    title: 'Mã reset password không hợp lệ',
    text: 'Vui lòng kiểm tra lại mã đã nhập hoặc lấy mã mới sau khi hết hạn!',
    icon: 'error',
  },
  [ResponseCode.ERROR_INVALID_ACCOUNT_USERNAME_OR_EMAIL]: {
    title: 'Username hoặc email đã tồn tại',
    text: 'Vui lòng chọn username khác hoặc reset password đối với email đã có tài khoản!',
    icon: 'error',
  },
  [ResponseCode.ERROR_INVALID_FILE_FORMAT]: {
    title: 'Định dạng file không được hỗ trợ',
    text: 'Kiểm tra lại định dạng file đúng yêu cầu!',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_IMAGE_UPLOAD_FAILED]: {
    title: 'Có lỗi xảy ra trong quá trình upload ảnh',
    text: 'Vui lòng thử lại sau hoặc chọn ảnh khác!',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_FILE_UPLOAD_FAILED]: {
    title: 'Có lỗi xảy ra trong quá trình upload file',
    text: 'Vui lòng thử lại sau hoặc chọn file khác!',
    icon: 'error',
  }, 

  [ResponseCode.ERROR_DUPLICATED_APPLICATION]: {
    title: 'Email đã được đăng ký',
    text: 'Vui lòng kiểm tra email (kể cả hộp thư SPAM) và làm theo hướng dẫn để hoàn tất form đăng ký. Nếu vẫn không nhận được email, bấm chọn nút "Không nhận được email" để gửi lại.',
    icon: 'error',
  }, 
  [ResponseCode.ERROR_INVALID_APPLICATION_CREDENTIAL]: {
    title: 'Sai email hoặc passcode',
    text: 'Vui lòng kiểm tra lại email và passcode!',
    buttons: {
      cancel: {
        text: "Đăng ký",
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: "Thử lại",
        value: true,
        visible: true,
        closeModal: true
      }
    },
    icon: 'error',
    closeOnClickOutside: false,
  }, 
  [ResponseCode.ERROR_NOT_ALLOWED_TO_EDIT_APPLICATION]: {
    title: 'Lưu ý',
    text: 'Bạn đã nộp đơn và không thể chỉnh sửa nữa',
    icon: 'warning',
  }, 
  [ResponseCode.ERROR_NOT_ALLOWED_TO_REGISTER_APPLICATION]: {
    title: 'Hết hạn nộp đơn',
    text: `Đã hết hạn nộp đơn! Nếu bạn nghĩ có sai sót, 
      vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
    để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_SUBMIT_APPLICATION_FAILED]: {
    title: 'Có lỗi xảy ra khi nộp đơn',
    text: 'Vui lòng kiểm tra và điền đầy đủ các thông tin để nộp đơn.',
    icon: 'error',
  },
  [ResponseCode.ERROR_INVALID_APPLICATION_YEAR]: {
    title: 'Hết hạn nộp đơn',
    text: `Đã hết hạn nộp đơn! Nếu bạn nghĩ có sai sót, 
      vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
      để được hỗ trợ.`,
    icon: 'error',
  },
  [ResponseCode.ERROR_RETRY_LIMIT_EXCEEDED]: {
    title: 'Đã hết giới hạn gửi lại email',
    text: `Bạn đã chọn gửi lại email quá nhiều lần! 
      Vui lòng liên hệ trang facebook: https://www.facebook.com/Vietseed/ 
      để được hỗ trợ.`,
    icon: 'error',
  }, 
}

// receive response.data or error
const handleResponse = async (response, defaultSuccess) => {
  if (get(response, 'result', null) === ResponseCode.SUCCESS && defaultSuccess) {
    return swal(defaultSuccess)
  } else {
    const responseAlert = get(mapErrorToAlert, response.result, null)
    if (responseAlert) {
      return swal(responseAlert)
    } else {
      return swal({
        title: 'Có lỗi xảy ra!',
        text: JSON.stringify(get(response, 'result', response)),
        icon: 'error',
      })
    }
  }
}

const getErrorMessage = (error) => {
  const msg = get(error, 'message', error) || get(error, 'name', error)
  let errorMessage = get(mapErrorToAlert, `${msg}.title`, msg)
    + '! '
    + get(mapErrorToAlert[msg], 'text', '')
  if (typeof errorMessage !== "string") {
    errorMessage = JSON.stringify(errorMessage)
  }
  return errorMessage
}

export default handleResponse
export {
  getErrorMessage
}