const ReviewResultChoices = [
  { id: 1, name: 'PASS' },
  { id: 2, name: 'FAIL' },
  { id: 3, name: 'CONSIDER' },
]

const InterviewResultChoices = [
  { id: 1, name: 'PASS' },
  { id: 2, name: 'FAIL' },
  { id: 3, name: 'CONSIDER' },
]

const ApplicationStatusChoices = [
  { id: 1, name: 'Draft' },
  { id: 2, name: 'Submitted' },
  { id: 3, name: 'Reviewing' },
  { id: 4, name: 'Interviewing' },
  { id: 5, name: 'Passed' },
  { id: 6, name: 'Failed' },
  { id: 7, name: 'Pending interview' },
  { id: 8, name: 'Assessing' },
  { id: 101, name: 'Trashed' }
]

const essayQuestionsByYear = {
  2020: {
    1: {
      label: `1. Em thích học gì nhất và giải thích lý do cho sự lựa chọn của mình? [Tối thiểu 100 từ]`,
    },
    2: {
      label: `2. Em dự định sẽ học (những) ngành/chuyên ngành nào và vì sao? [Tối thiểu 100 từ]`
    },
    3: {
      label: `3. Tại sao việc đi học đại học lại quan trọng với em? [Tối thiểu 100 từ]`
    },
    4: {
      label: `4. Vì sao em nghĩ rằng mình xứng đáng được trao tặng học bổng VietSeeds? [Tối thiểu 100 từ]`,
    },
    5: {
      label: `5. Nếu có điều gì chưa được nhắc đến trong hồ sơ mà em muốn 
              Hội Đồng Tuyển Chọn biết để cân nhắc hồ sơ của em, 
              em vui lòng chia sẻ ở đây. (Không bắt buộc)`
    },
    6: {
      label: `1. Vui lòng chia sẻ với chúng tôi về bản thân em? 
              Với em điều gì là quan trọng nhất trong cuộc sống? Tại sao? [Tối thiểu 150 từ]`,
    },
    7: {
      label: `2. Nếu có thể thay đổi bất cứ điều gì trong cuộc sống của mình, 
              em muốn thay đổi điểu gì nhất? Em cần những sự hỗ trợ, 
              giúp đỡ nào để có thể thực hiện sự thay đổi đó? [Tối thiểu 150 từ]`
    },
    8: {
      label: `3. Giả sử em đậu 2 trường đại học A và B. Trường A hứa sẽ miễn học phí và cấp thêm một khoản tiền
              trợ cấp hàng tháng tuy nhiên họ lại không có ràng buộc gì sau khi ra trường (họ chỉ yêu cầu em tham
              gia các kì thi để lấy danh tiếng cho trường). Học trường B thì em sẽ có cơ hội nhận được học bổng khác
              (đủ để trả học phí nhưng chưa chắc chắn sẽ được). Trường A rất xa nhà em còn trường B gần nhà. Bố
              mẹ mong muốn em học trường B; bạn bè lại thuyết phục em học trường A và trường A rất muốn em
              học ở trường của họ. Em sẽ chọn trường nào? Giải thích lý do em lại chọn như vậy. [Tối thiểu 150 từ]`
    }
  },
  2021: {
    1: {
      label: `1. Nếu chọn 3 từ để nói về em, 3 từ đó là gì? Hãy giải thích rõ hơn về 3 từ đó. [Tối thiểu 100 từ]`,
    },
    2: {
      label: `2. Ước mơ của em sau khi ra trường là gì? Ngành học em đang chọn có liên quan thế nào đến ước mơ đó? [Tối thiểu 100 từ]`,
    },
    3: {
      label: `3. Nếu em không đậu đại học thì tại thời điểm này, em nghĩ là em đang làm gì? [Tối thiểu 100 từ]`
    },
    4: {
      label: `4. Với những gì em đã tìm hiểu về VietSeeds, em nghĩ mình có phải là một thành viên phù hợp với VietSeeds không? Tại sao? [Tối thiểu 100 từ]`,
    },
    5: {
      label: `5. Ai là người truyền cảm hứng cho em nhiều nhất? Tại sao? [Tối thiểu 100 từ]`
    },
    6: {
      label: `1. Hãy chia sẻ về điểm mạnh và điểm yếu của bản thân em với những ví dụ cụ thể
              liên quan đến các điểm mạnh và điểm yếu đó. [Tối thiểu 150 từ]`,
    },
    7: {
      label: `2. Viết về một quyển sách mà em thích nhất. [Tối thiểu 150 từ]`
    },
    8: {
      label: `3. Hãy chia sẻ về giai đoạn khó khăn nhất trong cuộc sống của em
              và làm cách nào để em vượt qua được giai đoạn đó. [Tối thiểu 150 từ]`
    }
  }
}

export {
  ApplicationStatusChoices,
  ReviewResultChoices,
  InterviewResultChoices,
  essayQuestionsByYear,
}