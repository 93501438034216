import React from 'react'
import {
  List, Datagrid, TextField, EmailField, SelectField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'
import { get } from 'lodash'

import SponsorRegistrationFilter from './components/SponsorRegistrationFilter'
import SponsorRegistrationActions from './components/SponsorRegistrationActions'
import ListPagination from '../../../../Components/ListPagination'

import { SponsorRegistrationStatus } from '../constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));


const sponsorRegistrationShowFields =  [
  { label: 'ID', source: 'id', component: TextField, },
  { label: 'Họ và tên', source: 'name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Phone', source: 'phone', component: TextField },
  { label: 'Status', source: 'status', component: SelectField, choices: SponsorRegistrationStatus },
]

const sponsorRegistrationRowStyle = (record, index) => {
  const status = get(record, 'status', 0)

  if (status === 1) { // created
    return {
      background: '#fffdd1'
    }
  }
  if (status === 2) { // confirmed
    return {
      background: '#b8ffbf'
    }
  }
  if (status === 3) { // canceled
    return {
      background: '#e6e6e6'
    }
  }
}

const SponsorRegistrationList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Sponsor Registration List'}
      filters={<SponsorRegistrationFilter/>}
      actions={<SponsorRegistrationActions/>}
      pagination={<ListPagination/>}
      perPage={100}
    >
      <Datagrid 
        rowClick='edit'
        className={classes.table}
        rowStyle={sponsorRegistrationRowStyle}
      >
        {
          sponsorRegistrationShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default SponsorRegistrationList