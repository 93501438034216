import React from 'react'
import {
  FormTab,
  TextField, SelectField, EmailField,
  ReferenceArrayInput, SelectArrayInput,
} from 'react-admin'
import { Typography, Divider, makeStyles } from '@material-ui/core'
import FaceIcon from '@material-ui/icons/Face';

import { GenderChoices, RegionChoices, AcademicLevelChoices } from '../../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: 20
  }
}))

const MentorProfileTab = (props) => {
  const classes = useStyles()

  return (
    <FormTab label="Profile" icon={<FaceIcon/>} {...props}>
      <Typography variant="h5" gutterBottom>
        Mentor identity
      </Typography>
      <TextField label="Name" source="display_name" />
      <SelectField label="Gender" source="gender" choices={GenderChoices} />
      <EmailField label="Email" source="email" />
      <TextField label="Phone" source="phone" />
      <SelectField label="Region" source="vietseeds_region" choices={RegionChoices} />

      <Divider className={classes.divider} />

      <Typography variant="h5" gutterBottom>
        Profession
      </Typography>
      <TextField label="Name" source="study_major" />
      <TextField label="Occupations" source="occupations" />
      <ReferenceArrayInput
        label="Occupation tag" source="occupation_tag_ids" reference="occupation-tag"
        fullWidth
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextField label="Job title" source="job_title" />
      <TextField label="Company" source="company" />
      <TextField label="Year of experience" source="year_of_experience" />
      <SelectField label="Academic level" source="academic_level" choices={AcademicLevelChoices} />

    </FormTab>
  )
}

export default MentorProfileTab