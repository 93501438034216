import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  ReferenceArrayInput,
  SelectInput,
  SearchInput,
  SelectArrayInput,
} from 'react-admin';

import { RegionChoices, GenderChoices, YesNoChoices } from '../../../../constants/constants'

const styles = theme => ({
})

const MentorFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <ReferenceArrayInput label='Occupation tags' source="occupation_tag_ids" reference="occupation-tag">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <SelectInput source='available_for_mentor_booking' label='Available for booking?' choices={YesNoChoices}/>
      <SelectInput source='available_for_mentor_request' label='Available for requesting?' choices={YesNoChoices}/>
      <SelectInput source='number_of_mentees' label='Number of mentees' choices={[
        { id: 0, name: '0'},
        { id: 1, name: '1'},
        { id: 2, name: '2'},
      ]}/>
      <SelectInput source='vietseeds_region' label='Region' choices={RegionChoices}/>
      <SelectInput source='gender' label='Gender' choices={GenderChoices}/>
    </Filter>
  )
}

export default withStyles(styles)(MentorFilter)