import React, { useEffect } from 'react'
import { 
  ImageField, 
  ImageInput, 
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { uploadImage } from '../../../../../Providers/fileProvider'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'

const ImageSection = (props) => {
  const { record, ...other } = props
  const form = useForm()
  const formState = useFormState()
  let { profile_picture, profile_picture_url } = formState.values


  useEffect(() => {
    let { profile_picture, profile_picture_url } = formState.values

    const setImageUrl = async () => {
      try {
        const uploadedImage = await uploadImage('/student/upload_profile_image', profile_picture.rawFile)
        const newProfilePictureUrl = get(uploadedImage, 'image_url', profile_picture_url)
        
        form.change('profile_picture_url', newProfilePictureUrl)
      } catch (error) {
        console.log(error)
      }
    }

    if (profile_picture) {
      setImageUrl()
    }
  }, [formState?.values?.profile_picture])

  return (
    <SectionWrapper title='D. Phần hình ảnh' {...other}>
      <ImageInput source='profile_picture' label="Upload ảnh mới của sinh viên" accept="image/*">
        <ImageField source='src' title="title" />
      </ImageInput>
      {!profile_picture && 
        <ImageField record={{
          profile_picture_url
        }} label="Ảnh của sinh viên" source='profile_picture_url' title="title" />
      }
    </SectionWrapper>
  )
}

export default ImageSection