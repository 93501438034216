import React from 'react'
import {
  List, Datagrid, TextField, ReferenceField, DateField
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import ListFilter from './ListFilter'
import renderInputFields from '../../../helpers/renderInputFields'

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  },
}));

const listShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Title VI', source: 'title.vi', component: TextField,
  },
  { 
    label: 'Title EN', source: 'title.en', component: TextField, 
  },
  {
    label: 'Category', source: 'category_id',
    component: (props) =>
      <ReferenceField {...props} reference='article-category'>
        <TextField source='title.vi' />
      </ReferenceField>,
  },
  {
    label: 'Created on', source: 'created_on',
    component: DateField
  },
]

const ArticleList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Article List'}
      pagination={null}
      perPage={100}
      filters={<ListFilter/>}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          renderInputFields(listShowFields)
        }
      </Datagrid>
    </List>
  );
}

export default ArticleList