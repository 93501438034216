import React from 'react'
import {
  TextInput, SelectInput, DateInput, ReferenceInput, AutocompleteInput,
  required as requiredValidator,
} from 'react-admin'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import { RegionChoices, GenerationChoices, SponsorStatusChoices } from '../../../../../constants/constants'

const profileFields = [
  { label: 'STT', source: 'id', component: TextInput, disabled: true },
  { label: 'Họ tên', source: 'display_name', component: TextInput, required: true, },
  { label: 'Full name', source: 'display_name_en', component: TextInput, required: true, },
  { label: 'Họ + tên đệm', source: 'extra_data.last_name', component: TextInput, required: true, },
  { label: 'Last name', source: 'extra_data_en.last_name_en', component: TextInput, required: true, },
  { label: 'Tên', source: 'extra_data.first_name', component: TextInput, required: true, },
  { label: 'First name', source: 'extra_data_en.first_name_en', component: TextInput, required: true, },
  {
    label: 'Giới tính', source: 'gender', component: SelectInput,
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ],
    required: true, 
  },
  { label: 'Ngày sinh', source: 'dob', component: DateInput, required: true, },
  {
    label: 'Tỉnh/Thành phố', source: 'province_id', required: true,
    component: (props) =>
      <ReferenceInput {...props} reference="province">
        <AutocompleteInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
  },
  {
    label: 'Generation', source: 'vietseeds_generation', component: SelectInput,
    choices: GenerationChoices,
    required: true, 
  },
  {
    label: 'Sponsor status', source: 'sponsor_status', component: SelectInput,
    choices: SponsorStatusChoices, 
    disabled: true,
  },
  {
    label: 'Khu vực (HCM/HN/Huế)', source: 'vietseeds_region', component: SelectInput,
    choices: RegionChoices,
    required: true, 
  },
]

// TODO: F10
const fromF10ProfileFields = [
  { label: 'STT', source: 'id', component: TextInput, disabled: true },
  { label: 'Họ tên', source: 'display_name', component: TextInput, required: true, },
  {
    label: 'Giới tính', source: 'gender', component: SelectInput,
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ],
    required: true,
  },
  { label: 'Ngày sinh', source: 'dob', component: DateInput, required: true, },
  {
    label: 'Tỉnh/Thành phố', source: 'province_id', required: true,
    component: (props) =>
      <ReferenceInput {...props} reference="province">
        <AutocompleteInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
  },
  {
    label: 'Generation', source: 'vietseeds_generation', component: SelectInput,
    choices: GenerationChoices,
    required: true,
  },
  {
    label: 'Sponsor status', source: 'sponsor_status', component: SelectInput,
    choices: SponsorStatusChoices,
    disabled: true,
  },
  {
    label: 'Khu vực (HCM/HN/Huế)', source: 'vietseeds_region', component: SelectInput,
    choices: RegionChoices,
    required: true,
  },
]


const ProfileInformationSection = (props) => {
  const { record, ...other } = props
  const generation = get(record, 'vietseeds_generation', 0)

  if (generation < 10) {
    return (
      <SectionWrapper title='A. Thông tin cá nhân' record={record} {...other}>
        {
          profileFields.map(field => {
            const { label, source, component: Component, required, ...others } = field
            return (
              <Component 
                key={source} source={source} label={label} 
                variant='outlined' fullWidth
                required={required} 
                {...required ?
                  { validate: requiredValidator() }
                  :
                  {}
                }
                {...others} 
              />
            )
          })
        }
      </SectionWrapper>
    )
  }
  return (
    <SectionWrapper title='A. Thông tin cá nhân' record={record} {...other}>
      {
        fromF10ProfileFields.map(field => {
          const { label, source, component: Component, required, ...others } = field
          return (
            <Component
              key={source} source={source} label={label}
              variant='outlined' fullWidth
              {...required ?
                { validate: requiredValidator() }
                :
                {}
              }
              {...others}
            />
          )
        })
      }
    </SectionWrapper>
  )
};

export default ProfileInformationSection