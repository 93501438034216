const evaluationTableFields = [
  {
    section_summary: {
      max_score: 0,
      name: 'A. Hoàn cảnh gia đình',
      note: 'Nếu chỉ dựa vào hoàn cảnh gia đình: Đồng ý hay Không đồng ý?',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 0,
        name: 'Chia sẻ về bản thân em (sở thích, điểm mạnh/điểm yếu,…) & gia đình',
        note: '',
      },
      {
        max_score: 0,
        name: 'Xác nhận là SV năm 1 của trường.... ',
        note: '',
      },
      {
        max_score: 0,
        name: 'Ứng viên có đang nhận học bổng dài hạn nào không?',
        note: '',
      }
    ]
  },
  {
    section_summary: {
      max_score: 20,
      name: 'B. Thành tích học tập',
      note: 'Đánh giá khả năng giữ học bổng theo tiêu chí GPA >7.5'
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 2,
        name: 'Có giải thưởng cấp tỉnh trong 3 năm cấp 3? Chỉ cần có giải là được 2đ thưởng',
        note: '',
      },
      {
        max_score: 2,
        name: 'Có giải thưởng cấp quốc gia trong 3 năm cấp 3? Chỉ cần có giải là được 2đ thưởng',
        note: '',
      },
      {
        max_score: 8,
        name: `Tại sao em chọn trường/ngành em đang chuẩn bị vào học? 
          (hỏi để biết ứng viên có hiểu tại sao mình chọn trường đó hay ko? Có định hướng học tập ko?)`,
        note: '',
      },
      {
        max_score: 4,
        name: `Chia sẻ về trường/lớp cấp 3 của em/Vai trò của em trong lớp. 
          (Đánh giá thái độ học tập của em trong 3 năm cấp 3, 
          có phải là thành viên tích cực của lớp không, có hòa đồng với tập thể không)`,
        note: '',
      },
      {
        max_score: 4,
        name: `Hiểu rõ mục tiêu ngắn hạn và dài hạn của mình?`,
        note: '',
      }
    ]
  },
  {
    section_summary: {
      max_score: 20,
      name: 'C. Hiểu biết về VietSeeds',
      note: 'Interviewer nói rõ về 3 modules của gói học bổng: Finance, Training, Mentoring',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Em biết gì về VietSeeds (để xem ứng viên có tìm hiểu kỹ về học bổng trước khi đi pv ko?)',
        note: '',
      },
      {
        max_score: 10,
        name: 'Tại sao em nghĩ em xứng đáng với học bổng VietSeeds',
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 25,
      name: 'D. Maturity / Community',
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Vai trò của em trong gia đình, em làm gì để giúp đỡ gia đình',
        note: '',
      },
      {
        max_score: 15,
        name: `Cam kết  của em đối với các hoạt động của VietSeeds 
          (ví dụ, em đi làm thêm nhiều, học nhiều, hoạt động ở trường nhiều thì 
          tham gia hoạt động training/mentoring của VietSeeds ntn?)
          Em có quan tâm đến các vấn đề xã hội ngày nay không? Theo em vấn đề gì là đang “hot” nhất hiện nay?
          Em có tham gia các hoạt động xã hội và thiện nguyện bao giờ chưa? Chi tiết nếu có`,
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 25,
      name: 'E. Ability to overcome difficulties',
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 5,
        name: 'Kể về 1 việc làm/điều khiến em tự hào nhất',
        note: '',
      },
      {
        max_score: 10,
        name: 'Kể về 1 khó khăn lớn nhất mà em gặp phải và em đã làm gì để vượt qua được khó khăn đó',
        note: '',
      },
      {
        max_score: 10,
        name: `Nếu không nhận được hb VietSeeds thì em sẽ làm gì?
          Nếu trở thành 1 thành viên của gia đình VietSeeds thì em sẽ 
          làm gì để hòa nhập với gia đình VietSeeds?`,
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 10,
      name: 'F. English',
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 5,
        name: 'Tự giới thiệu bản thân bằng tiếng Anh',
        note: '',
      },
      {
        max_score: 5,
        name: 'Other',
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 0,
      name: 'G. Other',
      note: '',
      score: 0,
    },
    rows: []
  },
]

export default evaluationTableFields