import React from 'react'
import {
  FormTab, TextInput, SelectInput,
  TextField, SelectField, SimpleShowLayout,
  useGetList, usePermissions,
  ReferenceInput,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import authProvider from '../../../../Providers/authProvider'
import colors from '../../../../constants/colors'
import { ReviewResultChoices } from '../../constants'
import { Roles } from '../../../../constants/constants'
import objectToArray from '../../../../helpers/objectToArray'
import { minLengthWords } from '../../../../helpers/validators'

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  reviewSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: 10
  },
  overallResultContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  fullWidth: {
    width: '100%',
    margin: 10,
  },
  flexOne: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 20
  },
  divider: {
    width: 1,
    height: 120,
    background: 'gray',
  }
}));

const OverallResultFieldSection = (props) => {
  const { record, reviewerList } = props
  const classes = useStyles()
  return (
    <>
    <div className={`${classes.header} ${classes.fullWidth}`}>Final Review result</div>
    <SimpleShowLayout record={record} className={classes.overallResultContainer}>
      <SelectField 
        label='Final Review result' 
        source='review.review_result' 
        variant='outlined'
        choices={ReviewResultChoices}
        className={classes.fullWidth}
      />
      <SelectField 
        record={record}
        label='Reviewer 1' 
        source='reviewer_1' 
        variant='outlined' 
        choices={reviewerList}
        className={classes.flexOne}
      />
      <SelectField
        record={record}
        label='Reviewer 2'
        source='reviewer_2'
        variant='outlined'
        choices={reviewerList}
        className={classes.flexOne}
      />
    </SimpleShowLayout>
    </>
  )
}

const OverallResultInputSection = (props) => {
  const { ...others } = props
  const { record } = others
  const classes = useStyles()
  return (
    <div className={classes.overallResultContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Final Review result</div>
      <SimpleShowLayout record={record} className={classes.overallResultContainer}>
        <SelectField
          label='Final Review result'
          source='review.review_result'
          variant='outlined'
          choices={ReviewResultChoices}
          className={classes.fullWidth}
        />
      </SimpleShowLayout>
      <div className={`${classes.header} ${classes.fullWidth}`}>Reviewers' response</div>
      <ReferenceInput
        {...others}
        label='Reviewer 1'
        source='reviewer_1'
        variant='outlined'
        reference='student-application-reviewer'
        className={classes.flexOne}
      >
        <SelectInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
      <ReferenceInput
        {...others}
        label='Reviewer 2'
        source='reviewer_2'
        variant='outlined'
        reference='student-application-reviewer'
        className={classes.flexOne}
      >
        <SelectInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
    </div>
  )
}

const ReviewFieldSection = (props) => {
  const { record, reviewer } = props
  const classes = useStyles()

  return (
    <SimpleShowLayout record={record} className={classes.flexOne}>
      <SelectField label='Reviewer result' source={`${reviewer}.review_result`} choices={ReviewResultChoices} />
      <TextField label='Note' source={`${reviewer}.note`}
        fullWidth={true}
        rows={5}
      />
    </SimpleShowLayout>
  )
}

const ReviewInputSection = (props) => {
  const { record, reviewer, studentAdminPermission } = props
  const classes = useStyles()

  return (
    <div {...props} className={classes.reviewSection}>
      <div className={classes.header}>My review</div>
      <SelectInput 
        {...props}
        record={record} source={`${reviewer}.review_result`} 
        label='Reviewer Result' 
        variant='outlined'
        choices={ReviewResultChoices}
        validate={studentAdminPermission ? undefined : [required('Bắt buộc chọn kết quả review')]}
      />
      <TextInput 
        {...props}
        record={record} source={`${reviewer}.note`} label='Note'
        multiline={true}
        fullWidth={true}
        rows={5}
        variant={'outlined'}
        validate={studentAdminPermission ? undefined : [minLengthWords(30, 'Bắt buộc nhập nhận xét ít nhất 30 từ')]}
      />
    </div>
  )
}

const ReviewEditTab = (props) => {
  const classes = useStyles()
  const { record } = props

  const { data: reviewerListObject = {} } = useGetList('student-application-reviewer')
  const reviewerList = objectToArray(reviewerListObject)
  const { permissions } = usePermissions()
  const uid = authProvider.getUid()

  const studentAdminPermission = authProvider.checkUserPermission(permissions, [Roles.root, Roles.admin, Roles.adminStudentApp])

  return (
    <FormTab label='Review' {...props}>
      {studentAdminPermission ?
          <OverallResultInputSection/>
        : 
          <OverallResultFieldSection reviewerList={reviewerList}/>
      }
      {(studentAdminPermission
        || uid === get(record, 'interviewer_1')
        || uid === get(record, 'interviewer_2')
      ) && // admin & interviewer can see reviewers' results
        <div className={classes.reviewContainer}>
          <ReviewFieldSection reviewer={'reviewer_1_data'} {...props} />
          <div className={classes.divider}></div>
          <ReviewFieldSection reviewer={'reviewer_2_data'} {...props} />
        </div>
      }
      {(uid === get(record, 'reviewer_1') || uid === get(record, 'reviewer_2')) && 
        <ReviewInputSection reviewer={'my_review'} studentAdminPermission={studentAdminPermission} {...props} />
      }
    </FormTab>
  )
}

export default ReviewEditTab