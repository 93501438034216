import React, { useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  viewerRoot: {
    display: 'flex',
    boxSizing: 'border-box',
    height: '100% !important',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 10,
  },
  viewerRootError: {
    color: '#D8000C',
    backgroundColor: '#FFD2D2'
  },
  editorRoot: {
    display: 'block',
    height: '100% !important',
    border: 'none',
  }
}))

const NumberViewer = (props) => {
  const { value, cell: { validateSchema } } = props

  const classes = useStyles()
  const isError = validateSchema && !validateSchema.isValidSync(value)

  return (
    <span className={clsx(classes.viewerRoot, isError && classes.viewerRootError)}>{value}</span>
  )
}

const NumberEditor = (props) => {
  const { value, onChange } = props
  const classes = useStyles()
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  });

  const handleChange = (e) => {
    const value = e.target.value
    onChange(value !== '' ? JSON.parse(value) : '')
  }

  return (
    <input
      ref={inputEl}
      type='number'
      value={value}
      onChange={handleChange}
      className={clsx('data-editor', classes.editorRoot)}
    />
  )
}

export {
  NumberViewer,
  NumberEditor,
}