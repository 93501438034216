import {
  TextField, SelectField,
} from 'react-admin'

const YetChoices = [
  { id: 1, name: 'Đã từng' },
  { id: 2, name: 'Chưa từng' },
]

const CommitmentChoices = [
  { id: 1, name: 'Đồng ý' },
  { id: 2, name: 'Không đồng ý' },
  { id: 3, name: 'Có thể' },
]

const experienceFields = [
  { 
    label: 'Anh/chị đã từng là Mentor của VietSeeds chưa?', 
    source: 'experience_info.vietseeds_mentoring.vietseeds_mentor', 
    component: SelectField,
    choices: YetChoices,
  },
  { 
    label: 'Nếu CHƯA, trước đây anh/chị đã từng đăng ký làm mentor VietSeeds chưa?', 
    source: 'experience_info.vietseeds_mentoring.vietseeds_mentor_candidate',
    component: SelectField,
    choices: YetChoices,
  },
  { 
    label: 'Anh/chị đã từng có kinh nghiệm làm Mentor trước đó chưa?',
    source: 'experience_info.mentoring_experienced', 
    component: SelectField,
    choices: YetChoices,
  },
  { 
    label: 'Nếu có, trong bao lâu?',
    source: 'experience_info.mentoring_time', 
    component: TextField,
  },
  { 
    label: 'Anh/chị đã bao giờ có mentor chưa?', 
    source: 'experience_info.has_mentored',
    component: SelectField,
    choices: YetChoices, 
  },
  {
    label: 'Nếu có, anh/chị có thể chia sẻ ngắn gọn về mối quan hệ này không?', 
    source: 'experience_info.mentoring_relationship', 
    component: TextField,
  },
  {
    label: `Mỗi Mentor khi đăng ký trở thành Mentor của VietSeeds
      yêu cầu cần phải dành ít nhất mỗi tháng 1 tiếng đồng hồ gặp mặt
      trực tiếp với Mentee của mình (hoặc có cuộc gọi video đối
      với trường hợp Mentor không ở cùng thành phố với Mentee).
      Anh/chị có thể thực hiện cam kết này được không?`, 
    source: 'experience_info.required_meet_mentee',
    component: SelectField,
    choices: CommitmentChoices,
  },
  {
    label: `Anh/chị có khả năng duy trì mối quan hệ Mentoring
            với Mentee của mình trong thời gian (ít nhất)
            12 tháng liên tục không?`,
    source: 'experience_info.required_mentoring_bond',
    component: SelectField,
    choices: CommitmentChoices,
  },
  { 
    label: 'Cấp học cao nhất mà anh/chị đã học là?', 
    source: 'experience_info.academic_info.academic_level',
    component: SelectField,
    choices: [
      { id: 1, name: 'Cử nhân' },
      { id: 2, name: 'Thạc sĩ' },
      { id: 3, name: 'Tiến sĩ' },
      { id: 4, name: 'Khác' },
    ],
  },
  {
    label: 'Cấp học cao nhất khác:',
    source: 'experience_info.academic_info.note',
    component: TextField,
  }
]

export default experienceFields