import React from 'react'
import {
  TextInput, NumberInput,
} from 'react-admin'
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  number, minValue, maxValue,
} from 'react-admin'

import evaluationTableFields from './evaluationTableFields'
import colors from '../../../../../constants/colors'

const useRowStyles = makeStyles((theme) => ({
  sectionHead: {
    background: '#f7f7f7'
  },
  rowHeader: {
    fontWeight: 'bold'
  }
}));

const SumOfSectionField = (props) => {
  const { source } = props

  const {
    input: { value },
  } = useField(source);

  const scores = value || []
  const totalScore = scores.reduce((total, currentValue) => (total + currentValue.score || 0), 0)
  return <span>{totalScore}</span>
}

const TotalScoreField = (props) => {
  const { source } = props

  const {
    input: { value },
  } = useField(source);

  const scores = value || []
  const totalScore = scores.reduce((total, currentSection) => {
    return total + (currentSection.rows || []).reduce((rowTotal, currentValue) => (rowTotal + currentValue.score || 0), 0)
  }, 0)
  return <span>{totalScore}</span>
}


const Row = (props) => {
  const { record, meta_data: { section_summary, rows }, sectionIndex } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.sectionHead}>
        <TableCell className={classes.rowHeader}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right" className={classes.rowHeader}>
          {section_summary.max_score}
        </TableCell>
        <TableCell className={classes.rowHeader}>{section_summary.name}</TableCell>
        <TableCell className={classes.rowHeader} align="right">
          <SumOfSectionField record={record} source={`my_interview.scoreboard[${sectionIndex}].rows`}/>
        </TableCell>
        <TableCell className={classes.rowHeader}>{section_summary.note}</TableCell>
      </TableRow>
      {open && rows.map((row, index) => (
        <TableRow key={`${section_summary.name}_row_${index}`}>
          <TableCell style={{ width: 50 }}></TableCell>
          <TableCell align="right" style={{ width: 50 }}>{row.max_score > 0 && row.max_score}</TableCell>
          <TableCell style={{ width: 300 }}>{row.name}</TableCell>
          <TableCell align="right" style={{ width: 70}}>
            <NumberInput 
              label='' variant='outlined' 
              record={record}
              source={`my_interview.scoreboard[${sectionIndex}].rows[${index}].score`} 
              max={row.max_score} 
              min={0}
              validate={[number(), minValue(0), maxValue(row.max_score)]}
            />
          </TableCell>
          <TableCell style={{ width: 300 }}>
            <TextInput 
              label='' variant='outlined' multiline fullWidth 
              record={record}
              source={`my_interview.scoreboard[${sectionIndex}].rows[${index}].note`}
            />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    marginBottom: 30,
  },
  tableHeadRow: {
    background: colors.primaryLight,
  },
  tableHeadCell: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const EvaluationEditTable = (props) => {
  const classes = useStyles()
  const rowClasses = useRowStyles();
  const { record } = props

  return (
    <Table {...props} className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableHeadRow}>
          <TableCell className={classes.tableHeadCell}></TableCell>
          <TableCell className={classes.tableHeadCell} align="right">Điểm tối đa</TableCell>
          <TableCell className={classes.tableHeadCell}>Tiêu chí đánh giá</TableCell>
          <TableCell className={classes.tableHeadCell} align="right">Điểm</TableCell>
          <TableCell className={classes.tableHeadCell}>Ghi chú</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          evaluationTableFields.slice(0, 6).map((section, index) => {
            return (
              <Row record={record} 
                key={`scoreboard_${index}`} 
                meta_data={section}
                sectionIndex={index}
              />
            )
          })
        }
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="right" className={rowClasses.rowHeader}>100</TableCell>
          <TableCell className={rowClasses.rowHeader}>TỔNG ĐIỂM</TableCell>
          <TableCell align="right" className={rowClasses.rowHeader}>
            <TotalScoreField source={`my_interview.scoreboard`}/>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default EvaluationEditTable