import UserIcon from '@material-ui/icons/AccountCircle';
import UserList from './UserList/index'
import UserCreate from './UserCreate/index'
import UserEdit from './UserEdit/index'

export default {
  icon: UserIcon,
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
}