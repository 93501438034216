import React from 'react'
import { 
  Edit, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput,
  TopToolbar, ListButton, CreateButton
} from 'react-admin';

import renderInputFields from '../../../helpers/renderInputFields'

const Title = ({ record }) => {
  return <span>{`User ${record.id}. ${record.display_name}`}</span>
};


const userEditFields = [
  {
    label: 'ID', source: 'id', component: TextInput, disabled: true,
  },
  {
    label: 'Name', source: 'display_name', component: TextInput, disabled: true,
  },
  {
    label: 'Email', source: 'email', component: TextInput, disabled: true,
  },
  {
    label: 'Roles', source: 'role_ids',
    component: (props) =>
      <ReferenceArrayInput {...props} reference="user-role" helperText={'Only allow adding roles'}>
        <SelectArrayInput optionText="role_name" />
      </ReferenceArrayInput>
  }
]

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const UserEdit = (props) => {
  return (
    <Edit 
      title={<Title/>}
      actions={<UserEditActions/>}
      undoable={false}
      {...props}
    >
      <SimpleForm variant='outlined'>
        {
          renderInputFields(userEditFields)
        }
      </SimpleForm>
    </Edit>
  );
}

export default UserEdit