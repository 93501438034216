import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  DateField
} from 'react-admin'
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import BookedIcon from '@material-ui/icons/TurnedInNot';
import RequestedIcon from '@material-ui/icons/AssignmentLate';
import ApprovedIcon from '@material-ui/icons/ThumbUp';
import RejectedIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const mapStatusToIcon = {
  1: { icon: <BookedIcon />, color: 'inherit' },
  2: { icon: <RequestedIcon />, color: "secondary" },
  3: { icon: <ApprovedIcon />, color: "primary" },
  4: { icon: <RejectedIcon />, color: "grey" },
}

const HistoryTimeline = (props) => {
  const classes = useStyles();

  const { align = "left", historyRequests = [], fieldComponent: FieldComponent } = props

  return (
    <Timeline align={align}>
    {
      historyRequests.map(record => {
        const { id, status } = record
        const { icon, color } = mapStatusToIcon[status]
        return (
          <TimelineItem key={id}>
            <TimelineOppositeContent style={{ maxWidth: 60 }}>
              <Typography variant="body" color="textSecondary">
                <DateField record={record} source='update_time' showTime/>
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={color}>
                {icon}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "left" }}>
              <Paper elevation={3} className={classes.paper}>
                <FieldComponent record={record}/>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        )
      })
    }
    </Timeline>
  );
}


export default HistoryTimeline