import React from 'react'
import {
  List, Datagrid, TextField, EmailField, SelectField, DateField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'
import { get } from 'lodash'

import ContactRequestFilter from './components/ContactRequestFilter'
import ContactRequestActions from './components/ContactRequestActions'
import ListPagination from '../../../Components/ListPagination'

import renderInputFields from '../../../helpers/renderInputFields'
import { ContactRequestType } from '../constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));


const contactRequestRowStyle = (record, index) => {
  const contact_status = get(record, 'status', 0)

  if (contact_status === 1) { // created 
    return {
      background: '#fff'
    }
  }
  if (contact_status === 2) { // processing
    return {
      background: '#fffbe0'
    }
  }
  if (contact_status === 3) { // done
    return {
      background: '#ccfffe'
    }
  }
}

const contactRequestListShowFields =  [
  { label: 'ID', source: 'id', component: TextField, },
  { label: 'Type', source: 'contact_type', component: SelectField, choices: ContactRequestType, },
  { label: 'Họ và tên', source: 'name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Phone', source: 'phone_number', component: TextField },
  { label: 'Created at', source: 'create_time', component: DateField, },
]

const ContactRequestList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Contact Request List'}
      filters={<ContactRequestFilter/>}
      actions={<ContactRequestActions/>}
      pagination={<ListPagination/>}
      perPage={100}
    >
      <Datagrid 
        rowClick='edit' 
        className={classes.table}
        rowStyle={contactRequestRowStyle}
      >
        {
          renderInputFields(contactRequestListShowFields)
        }
      </Datagrid>
    </List>
  );
}

export default ContactRequestList