import React from 'react'
import { FunctionField } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import SectionWrapper from './SectionWrapper'

const useStyles = makeStyles(theme => ({
  image: {
    objectFit: 'contain',
    width: '100%',
  }
}))

const SimpleImageList = (props) => {
  const { record, source, } = props
  const classes = useStyles()

  return (
    <Grid container spacing ={3}>
      {record.map((image, index) => {
        return (
          <Grid
            item
            xs={3}
            className={classes.tile}
            key={`${source}_${index}`}
          >
            <img
              className={classes.image}
              src={image.image_url}
              alt={image.description}
            />
            <div>
              {image.description}
            </div>
          </Grid>
        )
      })}
    </Grid>
  );
}

const photoFields = [
  { label: '1. Hình của ứng viên', key: 'portrait_picture' },
  { label: '2. Hình ảnh toàn cảnh căn nhà mà ứng viên và gia đình đang ở', key: 'house_inside' },
  { label: '3. Hình ảnh bên trong căn nhà mà ứng viên và gia đình đang ở', key: 'house_outside' },
  { label: '4. Hình ảnh khác của ứng viên', key: 'additional_pictures' },
]

const ImageSectionForExporting = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='I. Phần hình ảnh' record={record} {...other}>
      {
        photoFields.map(field => {
          const { label, key } = field
          return (
            <FunctionField
              key={key}
              label={label}
              render={record => <SimpleImageList source={key} record={record.photos[key]} />}
            />
          )
        })
      }
    </SectionWrapper>
  )
}

export default ImageSectionForExporting