import React, { useMemo } from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReferenceField, useQueryWithStore,
} from 'react-admin';
import HistoryTimeline from './HistoryTimeline'
import MentorField from '../../MentorMatchingRequestList/components/MentorField'
import StudentField from '../../MentorMatchingRequestList/components/StudentField'

const MatchingRequestHistory = (props) => {
  const { mentor_id, student_id } = props
  const classes = useStyles();

  let { data: studentHistory, loading: loadingStudentHistory }
    = useQueryWithStore({
      type: 'getList',
      resource: 'mentor-matching-request',
      payload: {
        filter: { fullSearch: true, student_id },
      }
    })
  const studentHistoryArray = useMemo(() => studentHistory ? Object.keys(studentHistory).map(key => studentHistory[key]) : [], [studentHistory])

  const { data: mentorHistory, loading: loadingMentorHistory }
    = useQueryWithStore({
        type: 'getList',
        resource: 'mentor-matching-request',
        payload: { 
          filter: { fullSearch: true, mentor_id },
        }
      }      
    )
  const mentorHistoryArray = useMemo(() => mentorHistory ? Object.keys(mentorHistory).map(key => mentorHistory[key]) : [], [mentorHistory])


  return (
    (loadingStudentHistory || loadingMentorHistory) ? null
      :
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography href={`/student/${student_id}`} variant="h6" gutterBottom>
                Student
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography href={`/mentor/${mentor_id}`}  variant="h6" gutterBottom>
                Mentor
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} container alignContent="flex-start">
              <HistoryTimeline
                align="left"
                historyRequests={studentHistoryArray}
                fieldComponent={({ record }) =>
                  <ReferenceField
                    resource="mentor-matching-request"
                    reference="mentor"
                    source="mentor_id"
                    basePath="/mentor"
                    record={record}
                    link={false}
                  >
                    <MentorField />
                  </ReferenceField>
                }
              />
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              <HistoryTimeline
                align="left"
                historyRequests={mentorHistoryArray}
                fieldComponent={({ record }) =>
                  <ReferenceField
                    resource="mentor-matching-request"
                    reference="student"
                    source="student_id"
                    basePath="/student"
                    record={record}
                    link={false}
                  >
                    <StudentField />
                  </ReferenceField>
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}

export default MatchingRequestHistory

const useStyles = makeStyles({
  root: { width: '100%', margin: 'auto' },
  spacer: { height: 20 },
});
