import React from 'react'
import {
  Edit, TabbedForm,
  useNotify, useRefresh,
  TopToolbar, ListButton
} from 'react-admin'

import MentorProfileTab from './components/MentorProfileTab'
import MentoringInfoTab from './components/MentoringInfoTab'
import ApplicationReferenceTab from './components/ApplicationReferenceTab'

const Title = ({ record }) => {
  return <span>{`Mentor: ${record.display_name}`}</span>
};

const MentorEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);


const MentorEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = ({ data }) => {
    notify(`Changes to mentor "${data.display_name}" saved`, { type: 'success' });
    refresh();
  };

  return (
    <Edit
      undoable={false}
      title={<Title />}
      // TODO: Aside showing mentor request + mentee
      variant="standard"
      onSuccess={onSuccess}
      actions={<MentorEditActions/>}
      {...props}
    >
      <TabbedForm
        warnWhenUnsavedChanges
        sanitizeEmptyValues={false}
      >
        <MentorProfileTab/>
        <MentoringInfoTab/>
        <ApplicationReferenceTab/>
      </TabbedForm>
    </Edit>
  )
}


export default MentorEdit