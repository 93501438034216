import {
  TextField,
} from 'react-admin'

const majorAndPreferenceFields = [
  { 
    label: 'Ngành học mà anh/chị theo học là?',
    source: 'experience_info.quiz[0].answer',
    component: TextField,
  },
  { 
    label: 'Anh/chị đã có bao nhiêu năm làm việc? (Chỉ tính các kinh nghiệm làm các công việc full- time)',
    source: 'experience_info.quiz[1].answer',
    component: TextField,
  },
  { 
    label: 'Anh/chị đang làm việc trong lĩnh vực? (có thể có nhiều hơn 1 lĩnh vực)',
    source: 'experience_info.quiz[2].answer',
    component: TextField,
  },
  { 
    label: 'Tên công ty/tổ chức mà anh/chị đang làm việc?',
    source: 'experience_info.quiz[3].answer',
    component: TextField,
  },
  { 
    label: 'Vị trí công việc mà anh/chị đang đảm nhận là?',
    source: 'experience_info.quiz[4].answer',
    component: TextField,
  },
  {
    label: 'Tại sao anh/chị lại muốn tham gia chương trình Mentoring của VietSeeds?',
    source: 'experience_info.quiz[5].answer',
    component: TextField,
  },
  {
    label: `Anh/chị muốn được kết nối với bao nhiêu
      sinh viên (mentee) của VietSeeds`,
    source: 'experience_info.quiz[6].answer',
    component: TextField,
    // choices: [
    //   { id: 1, name: '01', },
    //   { id: 2, name: '02', },
    // ],
  },
  {
    label: `Anh/chị mong muốn ghép đôi với sinh viên
      năm nhất hay sinh viên từ năm 2 trở lên?`,
    source: 'experience_info.quiz[7].answer',
    component: TextField,
    // choices: [
    //   { id: 1, name: 'Sinh viên năm nhất' },
    //   { id: 2, name: 'Sinh viên từ năm 2 trở lên' },
    //   { id: 3, name: 'Tôi có thể mentor cho cả hai đối tượng trên' },
    // ],
  },
  { 
    label: 'Anh/chị có yêu cầu hoặc mong đợi gì đặc biệt đối với mentee của mình không?',
    source: 'experience_info.quiz[8].answer',
    component: TextField,
  },
  {
    label: 'Anh/chị biết đến chương trình Mentoring của VietSeeds như thế nào?',
    source: 'experience_info.quiz[9].answer',
    component: TextField,
  },
  {
    label: 'Những mong đợi/góp ý của anh/chị đối với chương trình Mentoring của VietSeeds là?',
    source: 'experience_info.quiz[10].answer',
    component: TextField,
  }
]

export default majorAndPreferenceFields