import React from 'react'
import { 
  Edit, TabbedForm, 
  TopToolbar, ListButton,
  useNotify, useRefresh,
} from 'react-admin'
import * as Sentry from "@sentry/react";


import StudentApplicationShowTab from './components/StudentApplicationShowTab'
import ReviewEditTab from './components/ReviewEditTab'
import InterviewEditTab from './components/InterviewEditTab'
import AssessmentEditTab from './components/AssessmentEditTab'
// import FinalResultEditTab from './components/FinalResultEditTab'

const Title = ({ record }) => {
  return <span>{`Student applicant: ${record.display_name}`}</span>
};

const StudentApplicationEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);


const StudentApplicationEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = ({ data }) => {
    notify(`Changes to student are saved`, 'success')
    refresh();
  }

  const onFailure = (error) => {
    Sentry.captureException(error);
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    refresh();
  }

  return (
    <Edit 
      title={<Title />} 
      actions={<StudentApplicationEditActions />}
      undoable={false}
      variant="standard"
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
    >
      {/* toolbar to edit buttons, save to handle submit*/}
      <TabbedForm 
        warnWhenUnsavedChanges 
        sanitizeEmptyValues={false}
        // style={{ maxWidth: '80vw' }}
      >
        <StudentApplicationShowTab/>
        <ReviewEditTab/>
        <InterviewEditTab/>
        <AssessmentEditTab/> 
        {/* <FinalResultEditTab/> */}
      </TabbedForm>
    </Edit>
  )
};

export default StudentApplicationEdit