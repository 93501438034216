import OccupationTagList from './OccupationTagList';
import OccupationTagCreate from './OccupationTagCreate';
import OccupationTagEdit from './OccupationTagEdit';
import OccupationTagIcon from '@material-ui/icons/Work';

export default {
  list: OccupationTagList,
  create: OccupationTagCreate,
  edit: OccupationTagEdit,
  
  icon: OccupationTagIcon,
}