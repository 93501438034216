import React from 'react'
import {
  TextField, SelectField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SectionWrapper from './SectionWrapper'

const vsQuizFields = [
  {
    label: 'Tên đúng của quỹ học bổng là:',
    source: 'vietseeds_quiz[0].answer',
    choices: [
      { name: 'Vietseed', id: 'Vietseed' },
      { name: 'VietSeed', id: 'VietSeed' },
      { name: 'VietSeeds', id: 'VietSeeds' },
      { name: 'Vietseeds', id: 'Vietseeds' },
    ],
    component: SelectField
  },
  {
    label: 'Em biết đến quỹ học bổng VietSeeds từ đâu?',
    source: 'vietseeds_quiz[1].answer',
    choices: [
      { name: 'Từ các anh/chị đã và đang nhận học bổng VietSeeds', id: 'member' },
      { name: 'Người thân, bạn bè', id: 'friend' },
      { name: 'Thầy cô giáo', id: 'teacher' },
      { name: 'Facebook', id: 'facebook' },
      { name: 'Khác', id: 'other' },
    ],
    component: SelectField
  },
  {
    label: '',
    source: 'vietseeds_quiz[1].note',
    component: TextField,
  },
  {
    label: 'Bạn có thể chia sẻ cụ thể hơn câu chuyện từ đâu mà bạn biết đến VietSeeds không?',
    source: 'vietseeds_quiz[2].answer',
    rows: 5,
    component: TextField,
  },
  {
    label: 'VietSeeds được thành lập vào năm nào?',
    source: 'vietseeds_quiz[3].answer',
    choices: [
      { name: '2005', id: '2005' },
      { name: '2009', id: '2009' },
      { name: '2011', id: '2011' },
      { name: '2014', id: '2014' },
    ],
    component: TextField
  },
  {
    label: 'Co-founders của VietSeeds là ai?',
    source: 'vietseeds_quiz[4].answer',
    rows: 2,
    component: TextField
  },
  {
    label: 'Bạn có biết bạn sẽ được hỗ trợ những gì nếu được nhận học bổng VietSeeds không?',
    source: 'vietseeds_quiz[5].answer',
    rows: 5,
    component: TextField,
  },
  {
    label: `Nếu có điều gì chưa được nhắc đến trong hồ sơ mà bạn muốn Hội Đồng Tuyển Chọn 
            biết để cân nhắc hồ sơ của bạn, bạn vui lòng chia sẻ ở đây. 
            (Không bắt buộc, nếu không vui lòng nhập "Không")`,
    source: 'vietseeds_quiz[6].answer',
    rows: 5,
    component: TextField,
  },
  {
    label: 'Em có anh/chị ruột nào đã nhận học bổng VietSeeds chưa?',
    source: 'vietseeds_quiz[7].note',
    component: TextField,
  }

]

const useStyles = makeStyles((theme) => ({
  field: {
    lineHeight: 1.5
  }
}));


const VietSeedsQuizSection = (props) => {
  const { record, ...other } = props
  const classes = useStyles()

  return (
    <SectionWrapper title='H. Hiểu về VietSeeds' record={record} {...other}>
      {
        vsQuizFields.map(field => {
          const { label, source, component: Component, ...others } = field
          return (
            <Component key={source} source={source} label={label} className={classes.field} {...others} />
          )
        })
      }
    </SectionWrapper>
  )
};

export default VietSeedsQuizSection