import * as React from 'react';
import { Fragment } from 'react';
import { BulkExportButton, usePermissions } from 'react-admin'
import authProvider from '../../../../Providers/authProvider'

import { Roles } from '../../../../constants/constants'
import SetReviewerDialog from './SetReviewerDialog'
import SetInterviewerDialog from './SetInterviewerDialog'

const ListBulkActionButtons = (props) => {

  const { permissions } = usePermissions()
  if (authProvider.checkUserPermission(permissions, [Roles.root, Roles.admin, Roles.adminStudentApp])) {
    return (
      <Fragment>
        <SetReviewerDialog {...props} />
        <SetInterviewerDialog {...props} />
        <BulkExportButton {...props}/>
      </Fragment>   
    )
  }
  return null
};

export default ListBulkActionButtons