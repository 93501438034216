import { cloneDeep } from 'lodash'
import moment from 'moment'

const parseTimeFields = (obj, fields) => {
  let clonedObj = cloneDeep(obj)
  fields.forEach(field => clonedObj[field] = moment.unix(obj[field]).toDate())

  return clonedObj
}

export default parseTimeFields