import React from 'react'
import { 
  Edit, SimpleForm, 
  TextInput, SelectInput, DateInput,
  ReferenceInput, AutocompleteInput,
} from 'react-admin'
import { get } from 'lodash'

import renderInputFields from '../../../helpers/renderInputFields'

import { ContactRequestStatus, PreferredContactMethodChoices } from '../constants'
import { DonationTypeChoices, } from '../../Sponsor/SponsorTransaction/constants'


const Title = ({ record }) => {
  return <span>{`Contact request: ${record.id}. ${record.name}`}</span>
};


const contactRequestFields = [
  {
    label: 'Status', source: 'status', component: SelectInput, required: true,
    choices: ContactRequestStatus,
  },
  {
    label: 'ID', source: 'id', component: TextInput, required: true, disabled: true,
  },
  { label: 'Name', source: 'name', component: TextInput, required: true, },
  { label: 'Email', source: 'email', component: TextInput, required: true, },
  { label: 'Phone', source: 'phone_number', component: TextInput, required: true, },
  {
    label: 'Preferred contact method', source: 'preferred_contact_method',
    component: SelectInput,
    choices: PreferredContactMethodChoices,
  },
  {
    label: 'Living country', source: 'country_id', component: (props) =>
      <ReferenceInput {...props} reference="nation"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={`name`}
        />
      </ReferenceInput>,
    required: true,
  },
  {
    label: 'Province', source: 'province_id', component: (props) =>
      <ReferenceInput {...props} reference="province"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={`name`}
        />
      </ReferenceInput>,
  },
  {
    label: 'Note', source: 'note', component: TextInput,
    multiline: true, rows: 5,
  },
  {
    label: 'Created at', source: 'create_time', component: DateInput,
    multiline: true, rows: 5,
    disabled: true,
  },
]

const extraFields = {
  1: [ // individual sponsor
    { 
      label: 'Payment method', source: 'extra_data.donate', component: SelectInput, 
      choices: DonationTypeChoices,
    },
  ], 
  2: [ // corporate sponsor
    { label: 'Company', source: 'extra_data.company', component: TextInput, },
    { label: 'Job', source: 'extra_data.job', component: TextInput, },
  ],
  3: [],
  4: [],
}

const ExtraFields = (props) => {
  const { record } = props
  const contact_type = get(record, 'contact_type', 0)

  return renderInputFields(extraFields[contact_type])
}

const ContactRequestEdit = (props) => (
  <Edit 
    undoable={false}
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm 
      warnWhenUnsavedChanges
      redirect="edit"
    >
      {
        renderInputFields(contactRequestFields)
      }
      <ExtraFields/>
    </SimpleForm>
  </Edit>
);

export default ContactRequestEdit