import SponsorRegistration from './SponsorRegistration/index'
import SponsorInvitation from './SponsorInvitation/index'
import SponsorTransaction from './SponsorTransaction/index'
import SponsorIcon from '@material-ui/icons/LocalAtm';

export default ({
  icon: SponsorIcon,
  SponsorRegistration,
  SponsorInvitation,
  SponsorTransaction,
})