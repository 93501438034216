const SponsorRegistrationStatus = [
  { id: 1, name: 'Created' },
  { id: 2, name: 'Confirmed' },
  { id: 3, name: 'Canceled' },
]

const PreferredContactMethodChoices = [
  { id: 1, name: 'phone' },
  { id: 2, name: 'email' },
]

export {
  SponsorRegistrationStatus,
  PreferredContactMethodChoices,
}