import React from 'react'
import { 
  Create, SimpleForm, TextInput, SelectInput
} from 'react-admin';

import { RegionChoices } from '../../../constants/constants'
import renderInputFields from '../../../helpers/renderInputFields'


const universityCreateFields = [
  {
    label: 'Đại học', source: 'name', component: TextInput, required: true,
  },
  { 
    label: 'University', source: 'name_en', component: TextInput, required: true,
  },
  {
    label: 'Abbreviation', source: 'abbreviation', component: TextInput, required: true,
  },
  {
    label: 'Region', source: 'vietseeds_region', component: SelectInput, required: true,
    choices: RegionChoices,
  },
]

const UniversityCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm variant='outlined'>
        {
          renderInputFields(universityCreateFields)
        }
      </SimpleForm>
    </Create>
  );
}

export default UniversityCreate