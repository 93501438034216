import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SectionWrapper from './SectionWrapper'
import { get } from 'lodash'

import colors from '../../../../../constants/colors'
import { essayQuestionsByYear } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  answerContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  question: {
    display: 'block',
    lineHeight: 1.5,
    fontWeight: 'bold',
    color: colors.primary
  },
  answer: {
    marginTop: 10,
    lineHeight: 1.5,
  }
}));

const AnswerField = ({ applicationYear, data }) => {
  const { answer, question_id } = data
  const classes = useStyles()

  return (
    <div className={classes.answerContainer}>
      <div className={classes.question}>
        {essayQuestionsByYear[applicationYear][question_id].label}
      </div>
      <div className={classes.answer}>
        {answer}
      </div>
    </div>
  )
}

const PersonalSharingSection = (props) => {
  const { record, ...other } = props

  const applicationYear = get(record, 'year', 2020)
  const personalSharing = get(record, 'essays', []).slice(0, 5)

  return (
    <SectionWrapper title='F. Chia sẻ về bản thân' record={{ personalSharing }} {...other}>
      {
        personalSharing.map(answer => {
          return (
            <AnswerField key={`personal_sharing_${answer.question_id}`} applicationYear={applicationYear} data={answer}/>
          )
        })
      }
    </SectionWrapper>
  )
};

export default PersonalSharingSection