import React from 'react'

import SectionWrapper from './SectionWrapper'

const ShowSectionWrapper = (props) => {
  const { title, fields, record, ...other } = props
  return (
    <SectionWrapper title={title} record={record} {...other}>
      {
        fields.map(field => {
          const { label, source, component: Component, ...others } = field
          return (
            <Component key={source} source={source} label={label} {...others} />
          )
        })
      }
    </SectionWrapper>
  )
};

export default ShowSectionWrapper