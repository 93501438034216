import React from 'react'
import { makeStyles } from '@material-ui/core'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import renderInputFields from '../../../../../helpers/renderInputFields'
import { personalStoryFieldsByGeneration } from './studentProfileFields'

// from F10 onwards
const FromF10PersonalStory = (props) => {
  const classes = useStyles()
  const { record, ...otherProps } = props

  const vietseeds_generation = get(record, 'vietseeds_generation', 0)
  const fields = get(personalStoryFieldsByGeneration, `[${vietseeds_generation}]`, [])

  return (
    <SectionWrapper title='C. Chia sẻ về bản thân' {...otherProps}>
      {
        renderInputFields(fields, {
            fullWidth: true,
            multiline: true,
            className: classes.textInput
          }
        )
      }
    </SectionWrapper>
  )
};

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& textarea': {
      lineHeight: 1.5
    }
  }
}))

export default FromF10PersonalStory