import React from 'react'
import {
  Edit, SimpleForm,
  TextField, TextInput, ReferenceInput, SelectInput, TranslatableInputs,
  useNotify, useRefresh, required
} from 'react-admin'
import HtmlInput from '../../../../Components/CustomInputs/HtmlInput'
import UploadImageInput from '../../../../Components/CustomInputs/UploadImageInput'

const Title = ({ record }) => {
  return <span>{`Article: ${record?.title?.vi}`}</span>
};

const ArticleEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = () => {
    notify(`Changes to article are saved`, { type: 'success' })
    refresh();
  }

  return (
    <Edit
      undoable={false}
      title={<Title />}
      variant="outlined"
      onSuccess={onSuccess}
      sanitizeEmptyValues={false}
      {...props}
    >
      <SimpleForm>
        <TextField source="id" label="ID" />
        <ReferenceInput label="Category" source="category_id" reference="article-category"
          fullWidth validate={[required()]}
        >
          <SelectInput variant="outlined" optionText={`title.vi`} fullWidth/>
        </ReferenceInput>
        <UploadImageInput 
          source="cover" label="Cover" fullWidth
          uploadUrl='/admin/homepage/upload_article_image'
        />
        <UploadImageInput
          source="thumb" label="Thumbnail" fullWidth
          uploadUrl='/admin/homepage/upload_article_image'
        />
        <TranslatableInputs locales={['vi', 'en']}>
          <TextInput source="title" label="Title" fullWidth validate={[required()]}/>
          <HtmlInput 
            source="content" label="Content" validate={[required()]}
            uploadUrl='/admin/homepage/upload_article_image'
          />
        </TranslatableInputs>
        <TextInput source="facebook" label="Facebook URL" fullWidth/>
        <TextInput source="linkedin" label="LinkedIn URL" fullWidth/>
        <TextInput source="pinterest" label="Pinterest URL" fullWidth/>
      </SimpleForm>
    </Edit>
  );
}

export default ArticleEdit