import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'
import { DialogTitle, DialogActions } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SimpleShowLayout, SelectField, DateField } from 'react-admin'

import colors from '../constants/colors'

const ViewButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: colors.primaryLight,
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
}))(Button);


const EventDialog = (props) => {
  const { event, handleClose, interviewerList = [], applicationType } = props

  if (!event) {
    return null
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        {event.title}
      </DialogTitle>
      <DialogContent>
        <SimpleShowLayout record={event}>
          <SelectField label='Interviewer 1' source='resource.interviewers.interviewer_1' choices={interviewerList}/>
          <SelectField label='Interviewer 2' source='resource.interviewers.interviewer_2' choices={interviewerList}/>
          <DateField label='Starting datetime' source='start' showTime/>
          <DateField label='Ending datetime' source='end' showTime/>
        </SimpleShowLayout>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <ViewButton color="primary" href={`/#/${applicationType}/${event.id}`} target="_blank" startIcon={<VisibilityIcon/>}>
          View Application
        </ViewButton>
      </DialogActions>
    </Dialog>
  )
}

export default EventDialog