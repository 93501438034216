import { find, get } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const occupationTagDataProvider = {
  getList: (params) => {
    const getOccupationTagListUrl = `/general/get_occupation_tag_list`;

    return httpClient(getOccupationTagListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const occupation_tags = get(json, 'reply.occupation_tags', [])

      return {
        data: occupation_tags,
        total: occupation_tags.length,
      }
    })
  },

  getMany: (params) => {
    const getOccupationTagListUrl = `/general/get_occupation_tag_list`;

    return httpClient(getOccupationTagListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const occupation_tags = get(json, 'reply.occupation_tags', [])

      return {
        data: occupation_tags,
      }
    })
  },

  getOne: (params) => {
    const { id } = params
    const parsedId = JSON.parse(id)

    const getOccupationTagListUrl = `/general/get_occupation_tag_list`;

    return httpClient(getOccupationTagListUrl, {
      method: 'GET',
    }).then(({ json }) => {
      const occupation_tags = get(json, 'reply.occupation_tags', [])
      const occupation_tag = find(occupation_tags, { id: parsedId })
      return {
        data: occupation_tag,
      }
    })
  },

  update: (params) => {
    const { data } = params

    const setOccupationTagUrl = `/admin/set_occupation_tag`;

    return httpClient(setOccupationTagUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      const data = get(json, 'reply', {})
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setOccupationTagUrl = `/admin/set_occupation_tag`;

    return httpClient(setOccupationTagUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const occupation_tag_id = get(json, 'reply.occupation_tag_id', {})
      return { 
        data: {
          ...data,
          id: occupation_tag_id,
        }
      }
    })
  },
};

export default occupationTagDataProvider
