import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  ReferenceField, TextField,
} from 'react-admin';
import UserField from '../../../../Components/CustomFields/UserField';

import { RegionChoices } from '../../../../constants/constants'
import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'

const StudentField = (props) => {
  const { record } = props
  return (
    record ? (
      <Typography>
        <UserField {...props} source='display_name' />
        <br />
        <b>Mentor:</b> <ReferenceField
          reference="mentor"
          source="mentor_id"
          basePath="/mentor"
          record={record}
        >
          <TextField source='display_name' />
        </ReferenceField>
        <br/>
        <b>Generation:</b> F{record.vietseeds_generation} - <b>Region:</b> {mapChoicesToValue(RegionChoices, record.vietseeds_region)}
        <br />
        <b>Major:</b> {record.study_major} - <b>Year:</b> {record.university_year}
        <br />
        <b>University:</b> <ReferenceField
          reference="university"
          source="university_id"
          basePath="/university"
          record={record}
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <br />
      </Typography>
    ) : null
  )
}

export default StudentField