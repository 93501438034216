import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  ReferenceArrayInput,
  SearchInput,
  SelectInput,
} from 'react-admin';

const styles = theme => ({
})

const ListFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='title' alwaysOn />
      <ReferenceArrayInput label='Category' source="category_id" reference="article-category">
        <SelectInput optionText="title.vi" />
      </ReferenceArrayInput>
    </Filter>
  )
}

export default withStyles(styles)(ListFilter)