import React from 'react'
import {
  NumberInput, SelectInput, ReferenceField, TextField
} from 'react-admin'
import SectionWrapper from './SectionWrapper'

import { StudentTypeChoices } from '../../../../../constants/constants'
import renderInputFields from '../../../../../helpers/renderInputFields'

const VietSeedsDataFields = [
  {
    label: 'Group', source: 'vietseeds_group',
    component: NumberInput, required: true,
  },
  {
    label: 'Student type', source: 'vietseeds_student_type',
    component: SelectInput, required: true, choices: StudentTypeChoices,
  },
  {
    label: 'Mentor',
    source: 'mentor_id',
    component: (props) =>
      <ReferenceField {...props} reference='mentor' basePath='/mentor'>
        <TextField source='display_name' variant='outlined' fullWidth />
      </ReferenceField>
  },
  // {
  //   label: 'Sponsor',
  //   source: 'vietseeds.sponsor',
  //   component: TextInput,
  // },
]


const VietSeedsDataSection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='E. VietSeeds Data' record={record} {...other}>
      {
        renderInputFields(VietSeedsDataFields, {
          fullWidth: true,
          variant: 'outlined'
        })
      }
    </SectionWrapper>
  )
};

export default VietSeedsDataSection