import React from 'react'
import {
  List, Datagrid, 
  ReferenceField,
  TextField, SelectField, NumberField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'
import { get } from 'lodash'

import SponsorTransactionFilter from './components/SponsorTransactionFilter'
import SponsorTransactionActions from './components/SponsorTransactionActions'
import ListPagination from '../../../../Components/ListPagination'
import { SponsorAmountChoices, SponsorStatusChoices } from '../constants';

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const sponsorTransactionShowFields = [
  { label: 'ID', source: 'id', component: NumberField, },
  {
    label: 'Invitation ID', source: 'sponsor_invitation_id', component: TextField,
  },
  { 
    label: 'Sponsor name', source: 'sponsor_invitation_id', 
    component: (props) =>
      <ReferenceField {...props} reference="sponsor-invitation">
        <TextField source='display_name' />
      </ReferenceField>,
  },
  { label: 'Student ID', source: 'student_id', component: NumberField, },
  { 
    label: 'Student name', source: 'student_id',
    component: (props) =>
      <ReferenceField {...props} reference="student">
        <TextField source='display_name' />
      </ReferenceField>,  
  },
  { label: 'Sponsor amount', source: 'transaction_amount', component: SelectField, choices: SponsorAmountChoices },
  { label: 'Status', source: 'transaction_status', component: SelectField, choices: SponsorStatusChoices },
  { 
    label: 'Created by', source: 'create_uid', 
    component: (props) => 
      <ReferenceField {...props} reference="user">
        <TextField source='display_name' />
      </ReferenceField>, 
  },
  { 
    label: 'Last updated by', source: 'update_uid',
    component: (props) =>
      <ReferenceField {...props} reference="user">
        <TextField source='display_name' />
      </ReferenceField>,
  },
]

const sponsorTransactionRowStyle = (record, index) => {
  const transaction_status = get(record, 'transaction_status', 0)
  const expiry_time = get(record, 'expiry_time', 0)
  var date = new Date()

  if (transaction_status === 3) { // done 
    return {
      background: '#b8ffbf'
    }
  } 
  if (transaction_status === 4) { // canceled
    return {
      background: '#e6e6e6'
    }
  }
  if (transaction_status === 5) { // on-going
    return {
      background: '#ccfffe'
    }
  }
  if (expiry_time < date) { // expired
    return {
      background: '#ffbdbd'
    }
  }
  if (transaction_status === 1) { // pending
    return {
      background: '#fffdd1'
    }
  }
  if (transaction_status === 2) { // confirmed
    return {
      background: '#ffae80'
    }
  }
}

const SponsorTransactionList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Sponsor Transaction List'}
      filters={<SponsorTransactionFilter/>}
      actions={<SponsorTransactionActions/>}
      pagination={<ListPagination/>}
      perPage={100}
    >
      <Datagrid 
        rowClick='edit' 
        className={classes.table}
        rowStyle={sponsorTransactionRowStyle}
      >
        {
          sponsorTransactionShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default SponsorTransactionList