import React from 'react'
import {
  TextField, NumberField, SelectField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SectionWrapper from '../../../../../Components/SectionWrapper'
import { get } from 'lodash'

import evaluationTableFields from './evaluationTableFields'
import colors from '../../../../../constants/colors'

const useRowStyles = makeStyles((theme) => ({
  sectionHead: {
    background: '#f7f7f7'
  },
  rowHeader: {
    fontWeight: 'bold'
  }
}));


const Row = (props) => {
  const {  
    meta_data: { section_summary, rows },
    data1: { section_summary: section_summary_1, rows: rows_1 }, 
    data2: { section_summary: section_summary_2, rows: rows_2 }, 
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.sectionHead}>
        <TableCell className={classes.rowHeader}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right" className={classes.rowHeader}>
          {section_summary.max_score ? section_summary.max_score : 'YES/NO'}
        </TableCell>
        <TableCell className={classes.rowHeader}>{section_summary.name}</TableCell>
        <TableCell className={classes.rowHeader} align="right">
          { get(section_summary_1, 'score', '') }
        </TableCell>
        <TableCell className={classes.rowHeader} align="right">
          { get(section_summary_2, 'score', '') }
        </TableCell>
        <TableCell className={classes.rowHeader}>{section_summary.note}</TableCell>
      </TableRow>
      {open && rows.map((row, index) => (
        <TableRow key={`${section_summary.name}_row_${index}`}>
          <TableCell style={{ width: 50 }}></TableCell>
          <TableCell align="right" style={{ width: 50 }}>{row.max_score > 0 && row.max_score}</TableCell>
          <TableCell style={{ width: 300 }}>{row.name}</TableCell>
          <TableCell align="right" style={{ width: 70}}>
            {row.max_score > 0 && <NumberField record={get(rows_1, `[${index}]`, {})} source='score'/>}
          </TableCell>
          <TableCell align="right" style={{ width: 70 }}>
            {row.max_score > 0 && <NumberField record={get(rows_2, `[${index}]`, {})} source='score' />}
          </TableCell>
          <TableCell style={{ width: 300 }}>
            <div>
              <b>Note 1:</b> <TextField multiline fullWidth record={get(rows_1, `[${index}]`, {})} source='note'/>
            </div>
            <div>
              <b>Note 2:</b> <TextField multiline fullWidth record={get(rows_2, `[${index}]`, {})} source='note'/>
            </div>
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    marginBottom: 30,
  },
  tableHeadRow: {
    background: colors.primaryLight,
  },
  tableHeadCell: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const EvaluationShowTable = (props) => {
  const classes = useStyles()
  const rowClasses = useRowStyles();

  const { record, interviewerList, ...others } = props
  const scoreboard_1 = get(record, 'interviewer_1_data.scoreboard', [])
  const scoreboard_2 = get(record, 'interviewer_2_data.scoreboard', [])

  const getSumScoreboard = (scoreboard) => {
    return scoreboard.reduce((total, { section_summary }) => {
      if (section_summary.max_score > 0) {
        return total + (section_summary.score || 0)
      } else {
        return total
      }
    }, 0)
  }

  return (
    <SectionWrapper title='Scoreboard result' record={record}>
      <Table {...others} className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.tableHeadCell}></TableCell>
            <TableCell className={classes.tableHeadCell} align="right">Điểm tối đa</TableCell>
            <TableCell className={classes.tableHeadCell}>Tiêu chí đánh giá</TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              <SelectField record={record} source='interviewer_1' choices={interviewerList} />
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              <SelectField record={record} source='interviewer_2' choices={interviewerList} />
            </TableCell>
            <TableCell className={classes.tableHeadCell}>Ghi chú</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            evaluationTableFields.slice(0, 6).map((meta_data, index) => {
              return (
                <Row key={`eval_table_${index}`}
                  meta_data={meta_data}
                  data1={get(scoreboard_1, `[${index}]`, {})} 
                  data2={get(scoreboard_2, `[${index}]`, {})}
                  sectionIndex={index}
                />
              )
            })
          }
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>100</TableCell>
            <TableCell className={rowClasses.rowHeader}>TỔNG ĐIỂM</TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>{getSumScoreboard(scoreboard_1)}</TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>{getSumScoreboard(scoreboard_2)}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SectionWrapper>
  )
}

export default EvaluationShowTable