import React from 'react'
import { 
  Edit, SimpleForm, 
  TextInput, SelectInput,
  ReferenceInput, AutocompleteInput
} from 'react-admin'

import { SponsorRegistrationStatus, PreferredContactMethodChoices } from '../constants'
import renderInputFields from '../../../../helpers/renderInputFields'

const Title = ({ record }) => {
  return <span>{`Sponsor registration: ${record.id}. ${record.name}`}</span>
};


const sponsorRegistrationFields = [
  {
    label: 'Status', source: 'status', component: SelectInput, required: true,
    choices: SponsorRegistrationStatus,
    helperText: `Cannot revert back to "Created" state`,
  },
  {
    label: 'ID', source: 'id', component: TextInput, required: true, disabled: true,
  },
  { label: 'Name', source: 'name', component: TextInput, required: true, disabled: true, },
  { label: 'Email', source: 'email', component: TextInput, required: true, disabled: true, },
  { label: 'Phone', source: 'phone', component: TextInput, required: true, disabled: true, },
  {
    label: 'Preferred contact method', source: 'extra_data.preferred_contact_method',
    component: SelectInput,
    choices: PreferredContactMethodChoices,
    disabled: true,
  },
  {
    label: 'Living country', source: 'extra_data.country_id', component: (props) =>
      <ReferenceInput {...props} reference="nation"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={`name`}
        />
      </ReferenceInput>,
    required: true,
    disabled: true,
  },
  {
    label: 'Province', source: 'extra_data.province_id', component: (props) =>
      <ReferenceInput {...props} reference="province"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={`name`}
        />
      </ReferenceInput>,
    disabled: true,
  },
  {
    label: 'Note', source: 'extra_data.note', component: TextInput,
    multiline: true, rows: 5,
    disabled: true,
  },
]

const SponsorRegistrationEdit = (props) => (
  <Edit 
    undoable={false}
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm warnWhenUnsavedChanges>
      {
        renderInputFields(sponsorRegistrationFields)
      }
    </SimpleForm>
  </Edit>
);

export default SponsorRegistrationEdit