import React from 'react'
import { TextField, NumberField, SelectField } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import colors from '../../../../../constants/colors'
import ProvinceField from '../../../../../Components/ProvinceField'


const styles = (theme => ({
  title: {
    color: '#FFF',
    backgroundColor: colors.primary,
    fontWeight: 'bold'
  },
  instruction: {
    padding: 15,
    lineHeight: 1.5,
    color: colors.error,
    fontWeight: 'bold'
  },
  header: {
    textAlign: 'center',
    backgroundColor: colors.light,
    fontWeight: 'bold',
    lineHeight: 1.5,
    padding: 20
  },
  item: {
    height: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 'solid #f5f5f5 0.1px'
  },
}))

const conductGradeOptions = [
  { name: 'TB', id: 1},
  { name: 'Khá', id: 2},
  { name: 'Tốt', id: 3},
]

const HighschoolResult = (props) => {
  const { classes, record } = props

  const fieldPrefix = `academic_info.high_school`

  return (
    <Grid container style={{ marginBottom: 40 }}>
      <Grid container item>
        <Grid item xs={12} className={`${classes.title} ${classes.item}`}>
          Các thông tin khi còn học cấp 3
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12} className={`${classes.instruction} ${classes.item}`}>
          Lưu ý: các điểm số được ghi ở thang điểm 10
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={`${classes.header} ${classes.item}`} xs={6}>Thông tin liên hệ</Grid>
        <Grid item className={`${classes.header} ${classes.item}`} xs={6}>Kết quả học tập</Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Tên trường THPT</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.name`} />
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm TB HK1 lớp 12</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.class_12.grade_1st_sem`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Địa chỉ trường</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.address.address_text`}/>
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm TB HK2 lớp 12</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.class_12.grade_2nd_sem`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Tỉnh/thành phố</Grid>
        <Grid item className={classes.item} xs={3}>
          <ProvinceField 
            record={record} 
            source={`${fieldPrefix}.address.province_id`} 
          />
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm TB HK1 lớp 11</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record}  source={`${fieldPrefix}.class_11.grade_1st_sem`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={3}>Điện thoại trường</Grid>
        <Grid item className={classes.item} xs={3}>
          <TextField record={record} source={`${fieldPrefix}.phone`}/>
        </Grid>
        <Grid item className={classes.item} xs={3}>Điểm TB HK2 lớp 11</Grid>
        <Grid item className={classes.item} xs={3}>
          <NumberField record={record} source={`${fieldPrefix}.class_11.grade_2nd_sem`}/>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={6}></Grid>
        <Grid item className={classes.item} xs={3}>Hạnh kiểm lớp 11</Grid>
        <Grid item className={classes.item} xs={3}>
          <SelectField 
            record={record} 
            source={`${fieldPrefix}.class_11.conduct_grade`}
            choices={conductGradeOptions}
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item className={classes.item} xs={6}></Grid>
        <Grid item className={classes.item} xs={3}>Hạnh kiểm lớp 12</Grid>
        <Grid item className={classes.item} xs={3}>
          <SelectField 
            record={record} 
            source={`${fieldPrefix}.class_12.conduct_grade`}
            choices={conductGradeOptions} 
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(HighschoolResult)