import React from 'react'
import { 
  Edit, TabbedForm, useNotify, useRefresh, 
  TopToolbar, ListButton,
} from 'react-admin'

import MentorApplicationShowTab from './components/MentorApplicationShowTab'
import ReviewEditTab from './components/ReviewEditTab'
import InterviewEditTab from './components/InterviewEditTab'

const Title = ({ record }) => {
  return <span>{`Mentor applicant: ${record.display_name}`}</span>
};

const MentorApplicationEditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const MentorApplicationEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = ({ data }) => {
    notify(`Changes to application "${data.display_name}" saved`, 'success')
    refresh();
  }

  return (
    <Edit 
      title={<Title />} 
      actions={<MentorApplicationEditActions/>}
      undoable={false}
      variant="standard"
      onSuccess={onSuccess}
      {...props}
    >
      <TabbedForm 
        warnWhenUnsavedChanges
        sanitizeEmptyValues={false}
      >
        <MentorApplicationShowTab/>
        <ReviewEditTab/>
        <InterviewEditTab/>
      </TabbedForm>
    </Edit>
  )
};

export default MentorApplicationEdit