import * as React from 'react';
import { useState } from 'react';
import { Loading, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core'
import { unionBy } from 'lodash'

import authProvider from '../../../Providers/authProvider'

import InterviewCalendar from '../../../Components/InterviewCalendar'
import Select from '../../../Components/Select'

import { studentInterviewDuration } from '../constants'

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },

}))


const InterviewCalendarRoot = ({ theme }) => {
  const classes = useStyles()

  const myUid = authProvider.getUid()
  const [interviewer, setInterviewer] = useState(myUid)

  const filter_1 = (interviewer === -1) ? { } : { interviewer_1: interviewer }
  const { data: interviewer_applications_1 = [], loading: loading_applications_1 } = useGetList('student-application', {}, {}, filter_1)
  const interviewer_applications_list_1 = Object.keys(interviewer_applications_1).map(key => interviewer_applications_1[key])

  const filter_2 = (interviewer === -1) ? { } : { interviewer_2: interviewer }
  const { data: interviewer_applications_2 = [], loading: loading_applications_2 } = useGetList('student-application', {}, {}, filter_2)
  const interviewer_applications_list_2 = Object.keys(interviewer_applications_2).map(key => interviewer_applications_2[key])


  const { data: interviewerListObject = {} } = useGetList('student-application-interviewer')
  const interviewerList = Object.keys(interviewerListObject).map(key => interviewerListObject[key])
  interviewerList.unshift({ id: -1, name: 'All' })
  interviewerList.unshift({ id: myUid, name: 'My interviews' })

  return (
    <div>
      <div className={classes.header}>STUDENT INTERVIEW CALENDAR</div>
      <div>
        <Select 
          name='interviewer' 
          value={interviewer}
          choices={interviewerList}
          onChange={(e) => {setInterviewer(e.target.value)}}
        />
      </div>
      {(loading_applications_1 || loading_applications_2) 
        ? <Loading/>
        : <InterviewCalendar
          applicationType='student-application'
          interviewerList={interviewerList}
          applications={unionBy(
            interviewer_applications_list_1,
            interviewer_applications_list_2,
            'id'
          )}
          interviewDuration={studentInterviewDuration}
        />
      }
    </div>
  )
};

export default InterviewCalendarRoot;