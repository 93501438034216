const assessmentTableFieldsByYear = {
  2020: [
    {
      section_summary: {
        max_score: 10,
        name: 'Introduction',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 5,
          name: 'Introduce yourself',
        },
        {
          max_score: 5,
          name: 'Story telling',
        },
      ]
    },
    {
      section_summary: {
        max_score: 40,
        name: 'Individual presentation',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 20,
          name: 'Homework prepared (Attitude & Commitment)',
        },
        {
          max_score: 10,
          name: 'Positive mindset (how he / she presents?)',
        },
        {
          max_score: 5,
          name: 'Presentation skill',
        },
        {
          max_score: 5,
          name: 'Maturity (presentation content)',
        },
      ]
    },
    {
      section_summary: {
        max_score: 50,
        name: 'Teamwork',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 20,
          name: 'Teamwork capability (Corporation & involvement)',
        },
        {
          max_score: 10,
          name: 'Attitude in group',
        },
        {
          max_score: 10,
          name: 'Influencing skill (Create positive vibe, good inputs)',
        },
        {
          max_score: 10,
          name: 'Leadership potential',
        },
      ]
    },
  ],
  2021: [
    {
      section_summary: {
        max_score: 5,
        name: 'Introduction',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 5,
          name: 'Introduce yourself',
        },
        {
          max_score: 0,
          name: "Note - candidate's introduction)",
          isText: true,
        },
      ]
    },
    {
      section_summary: {
        max_score: 40,
        name: 'Individual presentation',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 0,
          name: 'Presentation topic (note)',
          isText: true,
        },
        {
          max_score: 20,
          name: 'Homework prepared (Attitude & Commitment)',
        },
        {
          max_score: 10,
          name: 'Positive mindset (how he/she presents?)',
        },
        {
          max_score: 5,
          name: 'Presentation skill',
        },
        {
          max_score: 5,
          name: 'Maturity (presentation content)',
        },
      ]
    },
    {
      section_summary: {
        max_score: 55,
        name: 'Teamwork',
      },
      rows: [ // max_score, name, score,
        {
          max_score: 20,
          name: 'Teamwork capability (Corporation & involvement)',
        },
        {
          max_score: 10,
          name: 'Influencing skill/Attitude (Create positive vibe, good inputs)',
        },
        {
          max_score: 10,
          name: 'Leadership skill',
        },
        {
          max_score: 15,
          name: 'Potential (social changemaker)',
        },
      ]
    },
  ],
}

export default assessmentTableFieldsByYear