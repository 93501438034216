import React from 'react'
import { 
  Create, SimpleForm, ReferenceInput, AutocompleteInput,
  SelectInput, DateTimeInput, TextInput
} from 'react-admin'
import moment from 'moment'

import renderInputFields from '../../../../helpers/renderInputFields'
import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'
import { DonationTypeChoices, SponsorAmountChoices, PreferredContactMethodChoices } from '../constants'

const Title = ({ record }) => {
  return <span>{`Create Sponsor Transaction`}</span>
};

// Reference fields
const sponsorTransactionFields = [
  {
    label: 'Invitation', source: 'sponsor_invitation_id', component: (props) =>
      <ReferenceInput {...props} reference="sponsor-invitation"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={record => `${record?.id}. ${record?.display_name}`}
        />
      </ReferenceInput>,
    required: true,
  },
  {
    label: 'Student', source: 'student_id', component: (props) =>
      <ReferenceInput {...props} reference="student"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={record => `${record?.id}. ${record?.display_name} - F${record?.vietseeds_generation} ${mapChoicesToValue(SponsorAmountChoices, record?.sponsor_status)}`}
        />
      </ReferenceInput>,
    required: true,
  },
  { 
    label: 'Transaction amount', source: 'transaction_amount', 
    choices: SponsorAmountChoices,
    component: SelectInput, required: true, 
  },
  {
    label: 'Donation type', source: 'extra_data.donation_type',
    component: SelectInput,
    choices: DonationTypeChoices,
    required: true,
  },
  {
    label: 'Preferred contact method', source: 'extra_data.preferred_contact_method',
    component: SelectInput,
    choices: PreferredContactMethodChoices,
    required: true,
  },
  {
    label: 'Living country', source: 'extra_data.country_id',
    component: (props) =>
      <ReferenceInput {...props} reference="nation">
        <AutocompleteInput variant="outlined" fullWidth
          optionText={"name"}
        />
      </ReferenceInput>,
    required: true,
  },
  {
    label: 'Province', source: 'extra_data.province_id',
    component: (props) =>
      <ReferenceInput {...props} reference="province">
        <AutocompleteInput variant="outlined" fullWidth
          optionText={"name"}
        />
      </ReferenceInput>,
  },
  {
    label: 'Note', source: 'extra_data.note', component: TextInput,
    fullWidth: true, multiline: true, rows: 5,
  },
  {
    label: 'Expiry time', source: 'expiry_time', component: DateTimeInput,
    defaultValue: moment().add(5, 'days')
  },
]

const SponsorTransactionCreate = (props) => (
  <Create 
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm warnWhenUnsavedChanges>
      {
        renderInputFields(sponsorTransactionFields)
      }
    </SimpleForm>
  </Create>
);

export default SponsorTransactionCreate