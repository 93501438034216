import React from 'react'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme => ({
  root: {
    width: '100% !important',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100% !important',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: 'white',
  },
  tile: {
    height: 'unset'
  },
  image: {
    cursor: 'pointer',
  },
  titleBar: {
    // background: 'linear-gradient(to top, rgba(0,0,0,9) 0%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,0.5) 100%)',
  }
}))

const LargeTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 20
  }
}))(Tooltip);

const GridListImageShow = (props) => {
  const { record, source, classes } = props

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {record.map((image, index) => {
          return (
            <GridListTile
              className={classes.tile} 
              key={`${source}_${index}`}
            >
              <img 
                className={classes.image}
                src={image.image_url}
                alt={image.description}
                onClick={() => window.open(image.image_url, "_blank")} // open image in new tab
              />
              <LargeTooltip title={image.description} placement="bottom-end" interactive>
                <GridListTileBar
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  // title={image.description}
                />
              </LargeTooltip>
            </GridListTile>
          )
        })}
      </GridList>
    </div>
  );
}

export default withStyles(styles)(GridListImageShow);