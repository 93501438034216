const defaultValue = {
  email: '',
  passcode: '',

  // personal information
  personal_info: {
    first_name: '',
    last_name: '',
    dob: '',
    ethnic: '',
    gender: {
      gender_type: '', // enum(male, female, other)
      description: '',
    },
    address: {
      address_text: '',
      province_id: '',
    },
    phone_number: [
      { phone: '', owner: '', relationship: '' },
      { phone: '', owner: '', relationship: '' },
      { phone: '', owner: '', relationship: '' },
    ],
    facebook_url: '',
  },

  region: '', // enum(HCM, HN, HUE)

  academic_info: {
    academic_level: '',
    other_academic_level: '',
    high_school: {
      name: '',
      phone: '',
      address: {
        address_text: '',
        province_id: '',
      },
      class_11: {
        grade_1st_sem: '',
        grade_2nd_sem: '',
        conduct_grade: '',
      },
      class_12: {
        grade_1st_sem: '',
        grade_2nd_sem: '',
        conduct_grade: '',
      },
      graduation_exam: {
        has_score: true,
        note: '',
        scores: [], // { subject: '', score: '' },
      },
      achievements: [], // [{ name: '', description: ''}],
    },
    candidate_universities: [], // [{name: '', major: '', total_score: 0, is_accepted: true, is_applying: true }],
    university: {
      name: '',
      address: {
        address_text: '',
        province_id: '',
      },
      phone: '',
      major: '',
      results: [
        { semester: '', year: '', avg_grade: '', conduct_grade: '' },
        { semester: '', year: '', avg_grade: '', conduct_grade: '' },
      ],
      achievements: [], // [{ name: '', description: '' }],
    },
  },

  experience_info: {
    work_experiences: [], // [{ start_date: '', position: '', description: '', work_place: '', weekly_hours: 0 }],
    volunteer_experiences: [], // [{ start_date: '', title: '', description: '', position: '', reason: '' }],
  },

  finance_info: {
    family_income: {
      monthly_income: '',
      yearly_rank: '', // very low -> very high, 1->7
      yearly_income: '',
      note: ''
    },
    finance_supports: [
      { source: 'parents', amount: '' },
      { source: 'relatives', amount: '' },
      { source: 'other', amount: '', note: '' },
    ],
    scholarships: [], // [ { name: '', is_received: true, total_amount: 0, received_amount: 0, remaining_amount: 0, note: '' },],
  },

  family_members: [], // [{ 'full_name': '', 'relationship': '', 'job': '', 'work_place': '' }],

  essays: [
    { question_id: 1, answer: '', note: '' },
    { question_id: 2, answer: '', note: '' },
    { question_id: 3, answer: '', note: '' },
    { question_id: 4, answer: '', note: '' },
    { question_id: 5, answer: '', note: '' },
    { question_id: 6, answer: '', note: '' },
    { question_id: 7, answer: '', note: '' },
    { question_id: 8, answer: '', note: '' },
  ],

  vietseeds_quiz: [ // 8 questions
    { question_id: 9, answer: '', note: '' },
    { question_id: 10, answer: '', note: '' },
    { question_id: 11, answer: '', note: '' },
    { question_id: 12, answer: '', note: '' },
    { question_id: 13, answer: '', note: '' },
    { question_id: 14, answer: '', note: '' },
    { question_id: 15, answer: '', note: '' },
    { question_id: 16, answer: '', note: '' },
  ],

  photos: {
    // type Photo: { image_name: '', image_url: '', description: ''}
    portrait_picture: [],
    house_inside: [],
    house_outside: [],
    additional_pictures: []
  },

  recommendation_letter: {
    reference_type: 1,
    reference_value: "",
    note: ""
  },

  confirmation: {
    confirm_name: '',
    confirm_email: '',
    confirm_1: false,
    confirm_2: false
  },
}

export default defaultValue