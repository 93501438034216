import React from 'react'
import {
  Edit, TabbedForm
} from 'react-admin'

import RequestInfoTab from './components/RequestInfoTab'
import MatchingRequestHistoryTab from './components/MatchingRequestHistoryTab'

const Title = ({ record }) => {
  return <span>{`Edit mentor matching request`}</span>
};

const MentorMatchingRequestCreate = (props) => {
  return (
    <Edit
      undoable={false}
      title={<Title />}
      variant="outlined"
      {...props}
    >
      <TabbedForm>
        <RequestInfoTab/>
        <MatchingRequestHistoryTab/>
      </TabbedForm>
    </Edit>
  )
}


export default MentorMatchingRequestCreate