import { RegionChoices } from '../../constants/constants'

const ReviewResultChoices = [
  { id: 1, name: 'PASS' },
  { id: 2, name: 'FAIL' },
  { id: 3, name: 'CONSIDER' },
]

const InterviewResultChoices = [
  { id: 1, name: 'STRONGLY YES' },
  { id: 2, name: 'YES' },
  { id: 3, name: 'CONSIDER' },
  { id: 4, name: 'NO' },
]

const FinalInterviewResultChoices = [
  { id: 1, name: 'PASS' },
  { id: 2, name: 'FAIL' },
  { id: 3, name: 'CONSIDER' },
]

const MentorRegionChoices = [
  ...RegionChoices,
  { id: 4, name: 'Khác' }
]

const ApplicationStatus = [
  { id: 1, name: 'DRAFT' },
  { id: 2, name: 'SUBMITTED' },
  { id: 3, name: 'REVIEWING' },
  { id: 4, name: 'INTERVIEWING' },
  { id: 5, name: 'PASSED' },
  { id: 6, name: 'FAILED' },
]

export {
  ReviewResultChoices,
  InterviewResultChoices,
  FinalInterviewResultChoices,
  MentorRegionChoices,
  ApplicationStatus,
}