import React from 'react'
import {
  Create, SimpleForm, ReferenceInput, AutocompleteInput,
  SelectInput
} from 'react-admin'
import { Typography } from '@material-ui/core'

import renderInputFields from '../../../helpers/renderInputFields'
import { StudentTypeChoices, MentorMatchingStatusChoices } from '../../../constants/constants';

const Title = ({ record }) => {
  return <span>{`Create mentor matching request`}</span>
};

const mentorMatchingRequestFields = [
  {
    label: 'Student', source: 'student_id', component: (props) =>
      <ReferenceInput {...props} reference="student"
        filterToQuery={(searchText) => ({ name: searchText, vietseeds_student_type: StudentTypeChoices[0].id , fullSearch: true })}
      >
        <AutocompleteInput variant="outlined"
          optionText={record => `${record?.id}. ${record?.display_name} - F${record?.vietseeds_generation} - Has mentor: ${!!record?.mentor_id ? 'YES' : 'NO' }`}
        />
      </ReferenceInput>,
    fullWidth: true, required: true,
  },
  {
    label: 'Mentor', source: 'mentor_id', component: (props) =>
      <ReferenceInput {...props} reference="mentor"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined"
          optionText={record => `${record?.id}. ${record?.display_name}`}
          // optionText={record => `${record?.id}. ${record?.display_name} - # mentees: ${record?.number_of_mentees}`}
        />
      </ReferenceInput>,
    fullWidth: true, required: true,
  },
  {
    label: 'Status', source: 'status',
    choices: MentorMatchingStatusChoices,
    fullWidth: true, component: SelectInput, required: true,
  },
]

const MentorMatchingRequestCreate = (props) => {
  return (
    <Create
      title={<Title />}
      variant="standard"
      {...props}
    >
      <SimpleForm>
        <Typography variant="h5" gutterBottom fullWidth>
          New mentor matching request
        </Typography>
        {
          renderInputFields(mentorMatchingRequestFields)
        }
      </SimpleForm>
    </Create>
  )
}


export default MentorMatchingRequestCreate