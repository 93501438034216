import { get } from 'lodash'

import httpClient from './httpClient'

const nationDataProvider = {
  getList: (params) => {
    const getNationListUrl = `/general/get_nation_list`;
    return httpClient(getNationListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const nations = get(json, 'reply.nations', [])

      return {
        data: nations,
        total: nations.length,
      }
    })
  },

  getMany: (params) => {
    const getNationListUrl = `/general/get_nation_list`;
    return httpClient(getNationListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const nations = get(json, 'reply.nations', [])

      return {
        data: nations,
      }
    })
  }
};

export default nationDataProvider
