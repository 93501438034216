import { get, cloneDeep } from 'lodash'
import moment from 'moment'

import httpClient from './httpClient'
import { ResponseCode } from '../constants/constants'

const parseMentorMatchingRequest = (request) => {
  let parsedRequest = cloneDeep(request)

  parsedRequest.create_time = moment.unix(request.create_time).toDate();
  parsedRequest.update_time = moment.unix(request.update_time).toDate();

  return parsedRequest
}


const mentorMatchingRequestDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', { })
    const { filter } = params
    const { fullSearch } = filter

    const getMentorMatchingRequestIdsUrl = `/admin/get_mentor_matching_request_ids`;
    const getMentorMatchingRequestInfosUrl = `/admin/get_mentor_matching_request_infos`;

    return httpClient(getMentorMatchingRequestIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_request_ids = get(json, 'reply.mentor_matching_request_ids', [])
      const paged_request_ids = (perPage && !fullSearch) ? all_request_ids.slice((page - 1) * perPage, page * perPage) : all_request_ids

      return {
        mentor_matching_request_ids: paged_request_ids,
        total: all_request_ids.length
      }
    }).then(({ mentor_matching_request_ids, total }) => {
      return httpClient(getMentorMatchingRequestInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          mentor_matching_request_ids
        })
      }).then(({ json }) => {
        let mentor_matching_request_infos = get(json, 'reply.mentor_matching_request_infos', [])
        mentor_matching_request_infos = mentor_matching_request_infos.map(request => parseMentorMatchingRequest(request))

        return {
          data: mentor_matching_request_infos,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getMentorMatchingRequestInfosUrl = `/admin/get_mentor_matching_request_infos`;

    return httpClient(getMentorMatchingRequestInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        mentor_matching_request_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let mentor_matching_request_info = get(json, 'reply.mentor_matching_request_infos[0]', {})
      mentor_matching_request_info = parseMentorMatchingRequest(mentor_matching_request_info)

      return {
        data: mentor_matching_request_info
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getMentorMatchingRequestInfosUrl = `/admin/get_mentor_matching_request_infos`;

    return httpClient(getMentorMatchingRequestInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        mentor_matching_request_ids: ids
      })
    }).then(({ json }) => {
      let mentor_matching_request_infos = get(json, 'reply.mentor_matching_request_infos', [])
      mentor_matching_request_infos = mentor_matching_request_infos.map(request => parseMentorMatchingRequest(request))

      return {
        data: mentor_matching_request_infos
      }
    })
  },

  update: (params) => {
    const { id, data } = params

    const setMentorMatchingRequestUrl = `/admin/set_mentor_matching_request`;

    return httpClient(setMentorMatchingRequestUrl, {
      method: 'POST',
      body: JSON.stringify({
        id,
        ...data
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      const data = get(json, 'reply', {})
      if (result === ResponseCode.SUCCESS) {
        const mentor_matching_request_id = get(json, 'reply.​​mentor_matching_request_id', '')
        return { 
          data: {
            ...data,
            id: mentor_matching_request_id
          }
        }
      } else {
        throw new Error(result)
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setMentorMatchingRequestUrl = `/admin/set_mentor_matching_request`;

    return httpClient(setMentorMatchingRequestUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const mentor_matching_request_id = get(json, 'reply.mentor_matching_request_id', {})
      return {
        data: {
          ...data,
          id: mentor_matching_request_id,
        }
      }
    })
  },
};

export default mentorMatchingRequestDataProvider
