import React, { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, usePermissions } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import authProvider from '../Providers/authProvider'
import { appBarMenuItemList } from '../constants/rolesResources'

import Logo from '../static/images/logo_sm.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.primary.light
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}));

const CustomMenuItem = forwardRef((props, ref) => {
  const { onClick, ...others } = props
  return (
    <MenuItemLink
      ref={ref}
      onClick={onClick}
      {...others}
    />
  )
})

const CustomUserMenu = (props) => {
  const { permissions } = usePermissions()

  return (
    <UserMenu {...props}>
      {
        appBarMenuItemList
          .filter(({ roles }) => authProvider.checkUserPermission(permissions, roles))
          .map((menuItem) => {
            const { to, label, name, icon } = menuItem
            return (
              <CustomMenuItem
                key={name}
                to={to}
                primaryText={label}
                leftIcon={icon}
                onClick={props.onClick}
              />
            )
          })
      }
    </UserMenu>
  )
}

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} {...props} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={Logo}  style={{ height: 30 }} alt='logo'/>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
