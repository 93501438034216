import React from 'react'

import SettingsIcon from '@material-ui/icons/Settings';
import InterviewCalendarIcon from '@material-ui/icons/DateRange';
import InterviewCalendarViewIcon from '@material-ui/icons/EventAvailable';
import InterviewCalendarEditIcon from '@material-ui/icons/EventNote';
import AssessmentDayIcon from '@material-ui/icons/Assessment';
import AssignAssessorIcon from '@material-ui/icons/AssignmentInd';
import SetAssessmentResultIcon from '@material-ui/icons/Comment';

import User from '../Pages/User/index'
import UserRole from '../Pages/UserRole/index'
import General from '../Pages/General/index'
import University from '../Pages/General/University/index'
import OccupationTag from '../Pages/General/OccupationTag/index'
import Province from '../Pages/General/Province/index'
import Nation from '../Pages/General/Nation/index'

import Homepage from '../Pages/Homepage/index'
import ArticleCategory from '../Pages/Homepage/ArticleCategory/index'
import Article from '../Pages/Homepage/Article/index'

import StudentApplication from '../Pages/StudentApplication/index'
import Student from '../Pages/Student/index'
import Sponsor from '../Pages/Sponsor/index'
import ContactRequest from '../Pages/ContactRequest/index'
import MentorApplication from '../Pages/MentorApplication/index'
import Mentor from '../Pages/Mentor/index'
import MentorMatchingRequest from '../Pages/MentorMatchingRequest/index'

import { Roles } from './constants'

const {
  unAuth,
  root, 
  admin,
  adminStudentApp,
  adminSponsorPlatform,
  reviewerStudentApp,
  interviewerStudentApp,
  assessorStudentApp,
  groupLeader,
  adminMentorApp,
  reviewerMentorApp,
  interviewerMentorApp,
} = Roles

const whiteListRoles = {
  'all-auth-roles': [root, admin, adminStudentApp, adminSponsorPlatform, reviewerStudentApp, interviewerStudentApp, assessorStudentApp, groupLeader,],
  'all-roles': [unAuth, root, admin, adminStudentApp, adminSponsorPlatform, reviewerStudentApp, interviewerStudentApp, assessorStudentApp, groupLeader, ],

  'homepage': [root, admin],

  'user': [root, admin],

  'student': [root, admin, groupLeader, adminSponsorPlatform, ],

  'interview-calendar': [root, admin, adminStudentApp, interviewerStudentApp, adminMentorApp, interviewerMentorApp],

  'student-application': [root, admin, adminStudentApp, reviewerStudentApp, interviewerStudentApp, assessorStudentApp, ],
  'student-application-reviewer': [root, admin, adminStudentApp, reviewerStudentApp, interviewerStudentApp, assessorStudentApp],
  'student-application-interviewer': [root, admin, adminStudentApp, reviewerStudentApp, interviewerStudentApp, assessorStudentApp],
  'student-application-assessor': [root, admin, adminStudentApp, reviewerStudentApp, interviewerStudentApp, assessorStudentApp],
  'student-interview-calendar-view': [root, admin, adminStudentApp, interviewerStudentApp,],
  'student-interview-calendar-edit': [root, admin, adminStudentApp,],
  'assessment-day': [root, admin, adminStudentApp, assessorStudentApp, ],
  'assign-assessor': [root, admin, adminStudentApp, ],
  'set-assessment-result': [root, admin, adminStudentApp, assessorStudentApp,],


  'mentor-application': [root, admin, adminMentorApp, reviewerMentorApp, interviewerMentorApp, ],
  'mentor-application-reviewer': [root, admin, adminMentorApp, reviewerMentorApp, interviewerMentorApp,],
  'mentor-application-interviewer': [root, admin, adminMentorApp, reviewerMentorApp, interviewerMentorApp, ],
  'mentor-interview-calendar-view': [root, admin, adminMentorApp, interviewerMentorApp,],
  'mentor-interview-calendar-edit': [root, admin, adminMentorApp,],

  'sponsor': [root, admin, adminSponsorPlatform, ],
  'sponsor-registration': [root, admin, adminSponsorPlatform, ],
  'sponsor-invitation': [root, admin, adminSponsorPlatform, ],
  'sponsor-transaction': [root, admin, adminSponsorPlatform, ],

  'contact-request': [root, admin, adminSponsorPlatform, ],

  'mentor': [root, admin],
  'mentor-matching-request': [root, admin],
}

const resourceList = [
  {
    name: "user",
    resources: User,
    roles: whiteListRoles['all-auth-roles'],
  },
  {
    name: "user-role",
    resources: UserRole,
    roles: whiteListRoles['all-auth-roles'],
  },
  {
    name: "general",
    resources: General,
    roles: whiteListRoles['all-roles'],
  },
  {
    name: "university",
    resources: University,
    roles: whiteListRoles['all-roles'],
  },
  {
    name: "occupation-tag",
    resources: OccupationTag,
    roles: whiteListRoles['all-roles'],
  },
  {
    name: "province",
    resources: Province,
    roles: whiteListRoles['all-roles'],
  },
  {
    name: "nation",
    resources: Nation,
    roles: whiteListRoles['all-roles'],
  },
  {
    name: "homepage",
    resources: Homepage,
    roles: whiteListRoles['homepage'],
  },
  {
    name: "article-category",
    resources: ArticleCategory,
    roles: whiteListRoles['homepage'],
  },
  {
    name: "article",
    resources: Article,
    roles: whiteListRoles['homepage'],
  },
  {
    name: "mentor-application",
    resources: MentorApplication,
    roles: whiteListRoles['mentor-application'],
  },
  {
    name: "mentor-application-reviewer",
    resources: {},
    roles: whiteListRoles['mentor-application-reviewer'],
  },
  {
    name: "mentor-application-interviewer",
    resources: {},
    roles: whiteListRoles['mentor-application-interviewer'],
  },
  {
    name: "student-application",
    resources: StudentApplication,
    roles: whiteListRoles['student-application'],
  },
  {
    name: "student-application-reviewer",
    resources: {},
    roles: whiteListRoles['student-application-reviewer'],
  },
  {
    name: "student-application-interviewer",
    resources: {},
    roles: whiteListRoles['student-application-interviewer'],
  },
  {
    name: "student-application-assessor",
    resources: {},
    roles: whiteListRoles['student-application-assessor'],
  },
  {
    name: "student",
    resources: Student,
    roles: whiteListRoles['student'],
  },
  {
    name: "sponsor-registration",
    resources: Sponsor.SponsorRegistration,
    roles: whiteListRoles['sponsor-registration'],
  },
  {
    name: "sponsor-invitation",
    resources: Sponsor.SponsorInvitation,
    roles: whiteListRoles['sponsor-invitation'],
  },
  {
    name: "sponsor-transaction",
    resources: Sponsor.SponsorTransaction,
    roles: whiteListRoles['sponsor-transaction'],
  },
  {
    name: "contact-request",
    resources: ContactRequest,
    roles: whiteListRoles['contact-request'],
  },
  {
    name: "mentor",
    resources: Mentor,
    roles: whiteListRoles['mentor'],
  },
  {
    name: "mentor-matching-request",
    resources: MentorMatchingRequest,
    roles: whiteListRoles['mentor-matching-request']
  }
]

const menuItemList = [
  {
    to: '/user',
    name: 'user',
    label: 'User management',
    icon: <User.icon />,
    roles: whiteListRoles['user'],
  },
  {
    name: 'general',
    label: 'General',
    icon: <General.icon />,
    roles: whiteListRoles['all-roles'],
    subMenu: [
      {
        subTo: '/university',
        subName: 'university',
        subLabel: 'University',
        subIcon: <University.icon />,
        subRoles: whiteListRoles['all-roles'],
      },
      {
        subTo: '/occupation-tag',
        subName: 'occupation-tag',
        subLabel: 'Occupation tag',
        subIcon: <OccupationTag.icon />,
        subRoles: whiteListRoles['all-roles'],
      },
      {
        subTo: '/province',
        subName: 'province',
        subLabel: 'Province',
        subIcon: <Province.icon />,
        subRoles: whiteListRoles['all-roles'],
      },
      {
        subTo: '/nation',
        subName: 'nation',
        subLabel: 'Nation',
        subIcon: <Nation.icon />,
        subRoles: whiteListRoles['all-roles'],
      },
    ],
  },
  {
    name: 'homepage',
    label: 'Homepage',
    icon: <Homepage.icon />,
    roles: whiteListRoles['homepage'],
    subMenu: [
      {
        subTo: '/article-category',
        subName: 'article-category',
        subLabel: 'Article Category',
        subIcon: <ArticleCategory.icon />,
        subRoles: whiteListRoles['homepage'],
      },
      {
        subTo: '/article',
        subName: 'article',
        subLabel: 'Article',
        subIcon: <Article.icon />,
        subRoles: whiteListRoles['homepage'],
      },
    ],
  },
  {
    to: '/student',
    name: 'student',
    label: 'Student',
    icon: <Student.icon />,
    roles: whiteListRoles['student'],
  },
  {
    to: '/student-application',
    name: 'student-application',
    label: 'Student application',
    icon: <StudentApplication.icon/>,
    roles: whiteListRoles['student-application'],
  },
  {
    name: 'interview-calendar',
    label: 'Interview calendar',
    icon: <InterviewCalendarIcon/>,
    roles: whiteListRoles['interview-calendar'],
    subMenu: [
      {
        subTo: '/interview-calendar/student/view',
        subName: 'student-interview-calendar-view',
        subLabel: 'STUDENT Interview calendar',
        subIcon: <InterviewCalendarViewIcon/>,
        subRoles: whiteListRoles['student-interview-calendar-view'],
      },
      {
        subTo: '/interview-calendar/student/edit',
        subName: 'student-interview-calendar-edit',
        subLabel: 'Edit STUDENT interview calendar',
        subIcon: <InterviewCalendarEditIcon/>,
        subRoles: whiteListRoles['student-interview-calendar-edit'],
      },
      {
        subTo: '/interview-calendar/mentor/view',
        subName: 'mentor-interview-calendar-view',
        subLabel: 'MENTOR Interview calendar',
        subIcon: <InterviewCalendarViewIcon />,
        subRoles: whiteListRoles['mentor-interview-calendar-view'],
      },
      {
        subTo: '/interview-calendar/mentor/edit',
        subName: 'mentor-interview-calendar-edit',
        subLabel: 'Edit MENTOR interview calendar',
        subIcon: <InterviewCalendarEditIcon />,
        subRoles: whiteListRoles['mentor-interview-calendar-edit'],
      },
    ]
  },
  {
    name: 'assign-assessor',
    label: 'Assessment day',
    icon: <AssessmentDayIcon/>,
    roles: whiteListRoles['assessment-day'],
    subMenu: [
      {
        subTo: '/assessment-day/assign-assessor',
        subName: 'assign-assessor',
        subLabel: 'Assign assessor',
        subIcon: <AssignAssessorIcon/>,
        subRoles: whiteListRoles['assign-assessor'],
      },
      {
        subTo: '/assessment-day/set-assessment-result',
        subName: 'set-assessment-result',
        subLabel: 'Set assessment result',
        subIcon: <SetAssessmentResultIcon/>,
        subRoles: whiteListRoles['set-assessment-result'],
      },
    ]
  },
  {
    name: 'sponsor',
    label: 'Sponsor',
    icon: <Sponsor.icon />,
    roles: whiteListRoles['sponsor'],
    subMenu: [
      {
        subTo: '/sponsor-registration',
        subName: 'sponsor-registration',
        subLabel: 'Sponsor Registration',
        subIcon: <Sponsor.SponsorRegistration.icon />,
        subRoles: whiteListRoles['sponsor-registration'],
      },
      {
        subTo: '/sponsor-invitation',
        subName: 'sponsor-invitation',
        subLabel: 'Sponsor Invitation',
        subIcon: <Sponsor.SponsorInvitation.icon />,
        subRoles: whiteListRoles['sponsor-invitation'],
      },
      {
        subTo: '/sponsor-transaction',
        subName: 'sponsor-transaction',
        subLabel: 'Sponsor Transaction',
        subIcon: <Sponsor.SponsorTransaction.icon />,
        subRoles: whiteListRoles['sponsor-transaction'],
      },
    ],
  },
  {
    to: '/contact-request',
    name: 'contact-request',
    label: 'Contact request',
    icon: <ContactRequest.icon />,
    roles: whiteListRoles['contact-request'],
  },
  {
    to: '/mentor-application',
    name: 'mentor-application',
    label: 'Mentor application',
    icon: <MentorApplication.icon />,
    roles: whiteListRoles['mentor-application'],
  },
  {
    to: '/mentor',
    name: 'mentor',
    label: 'Mentor',
    icon: <Mentor.icon />,
    roles: whiteListRoles['mentor'],
  },
  {
    to: '/mentor-matching-request',
    name: 'mentor-matching-request',
    label: 'Mentor matching request',
    icon: <MentorMatchingRequest.icon />,
    roles: whiteListRoles['mentor-matching-request'],
  },
]

const appBarMenuItemList = [
  {
    to: '/change-password',
    name: 'change-password',
    label: 'Change Password',
    icon: <SettingsIcon />,
    roles: whiteListRoles['all-auth-roles'],
  },
]

export {
  resourceList,
  menuItemList,
  appBarMenuItemList,
}