import React, { useEffect } from 'react'
import {
  useInput,
  ImageField,
  ImageInput,
} from 'react-admin'
import { uploadImage } from '../../Providers/fileProvider'
import { get } from 'lodash'


const UploadImageInput = (props) => {
  const { record, source, label, uploadUrl, ...others } = props
  const {
    input: { name, value, onChange, ...rest },
  } = useInput(props);


  useEffect(() => {
    const setImageUrl = async () => {
      try {
        const uploadedImage = await uploadImage(uploadUrl, value.rawFile)
        const newImageUrl = get(uploadedImage, 'image_url', '')

        onChange(newImageUrl)
      } catch (error) {
        console.log(error)
      }
    }

    if (value?.rawFile) {
      setImageUrl()
    }
  }, [value])


  return (
    <>
      <ImageInput source={source} label={label} accept="image/*" {...rest} {...others}>
        <ImageField source='' />
      </ImageInput>
      <ImageField record={{ image_url: value }} source='image_url' />
    </>
  )
}

export default UploadImageInput