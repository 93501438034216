import { isEqual, get, cloneDeep, set } from 'lodash'
import moment from 'moment'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'
import { InterviewResultChoices } from '../Pages/StudentApplication/constants'

import evaluationTableFields from '../Pages/StudentApplication/StudentApplicationEdit/components/evaluationTableFields'

const studentApplicationInterviewerDataProvider = {
  getList: (params) => {
    const getStudentApplicationInterviewersUrl = `/admin/get_student_application_interviewers`;

    return httpClient(getStudentApplicationInterviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const interviewers = get(json, 'reply.interviewers', []).map(interviewer => {
        return {
          ...interviewer,
          name: interviewer.display_name || interviewer.email, // map field for displaying choices
        }
      })
      return {
        data: interviewers,
        total: interviewers.length
      }
    })
  },

  getOne: (params) => {
    
  },

  getMany: (params) => {
    const getStudentApplicationInterviewersUrl = `/admin/get_student_application_interviewers`;

    return httpClient(getStudentApplicationInterviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const interviewers = get(json, 'reply.interviewers', []).map(interviewer => {
        return {
          ...interviewer,
          name: interviewer.display_name || interviewer.email, // map field for displaying choices
        }
      })
      return {
        data: interviewers,
      }
    })
  },

  update: async (params) => {
    const { id, data, previousData } = params

    // if (!isEqual(get(data, 'interview.interview_result', ''), get(previousData, 'interview.interview_result', ''))) {
    //   await studentApplicationInterviewerDataProvider.setStudentApplicationInterviewOverallResult({
    //     id,
    //     data: {
    //       interview_result: data.interview.interview_result,
    //     }
    //   })
    // }

    if (!isEqual(data.interviewer_1, previousData.interviewer_1) 
      || !isEqual(data.interviewer_2, previousData.interviewer_2)
      || !isEqual(data.interview_datetime, previousData.interview_datetime)
    ) {
      await studentApplicationInterviewerDataProvider.updateMany({
        ids: [id],
        data: {
          interviewer_1: data.interviewer_1,
          interviewer_2: data.interviewer_2,
          interview_datetime: data.interview_datetime,
        }
      })
    }

    if (!isEqual(data.my_interview, previousData.my_interview)) {
      await studentApplicationInterviewerDataProvider.setStudentApplicationInterviewerResult({ id, data: data.my_interview })
    }
  },

  updateMany: async (params) => {
    const { ids, data } = params

    const assignStudentApplicationInterviewersUrl = `/admin/assign_student_application_interviewers`;

    let interviewersData = cloneDeep(data)
    if (!interviewersData.interviewer_1) delete interviewersData.interviewer_1
    if (!interviewersData.interviewer_2) delete interviewersData.interviewer_2
    if (!interviewersData.interview_datetime) {
      delete interviewersData.interview_datetime
    } else {
      interviewersData.interview_datetime = moment(interviewersData.interview_datetime).format().slice(0, -6)
    }

    return httpClient(assignStudentApplicationInterviewersUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids,
        ...interviewersData,
      })
    }).then(({ json }) => {
      const result = get(json, 'result', null)
      if (result === ResponseCode.SUCCESS) {
        return {
          data: ids
        }
      } else {
        throw new Error(result)
      }
    })
  },

  setStudentApplicationInterviewerResult: async ({ id, data}) => {
    const setStudentApplicationInterviewerResultUrl = `/admin/set_student_application_interviewer_result`;
      
    // fill with default value
    const inputScoreboard = get(data, 'scoreboard', [])
    const scoreboard = cloneDeep(evaluationTableFields)
    scoreboard.forEach((section, sectionId) => {
      section.rows.forEach((row, rowId) => {
        set(scoreboard, `[${sectionId}].rows[${rowId}].score`, get(inputScoreboard, `[${sectionId}].rows[${rowId}].score`, 0))
        set(scoreboard, `[${sectionId}].rows[${rowId}].note`, get(inputScoreboard, `[${sectionId}].rows[${rowId}].note`, ''))
      })
      if (section.section_summary.max_score) {
        const section_score = section.rows.reduce((total, currentValue) => (total + currentValue.score || 0), 0)
        set(section, 'section_summary.score', section_score)
      } else {
        // Yes/No field
        set(section, 'section_summary.score', get(inputScoreboard, `[${sectionId}].section_summary.score`, 0))
      }
      set(section, 'section_summary.note', get(inputScoreboard, `[${sectionId}].section_summary.note`, section.section_summary.note) || '')
    })

    // extract yes_for_circumstances from scoreboard
    set(data, 'yes_for_circumstances', Boolean(scoreboard[0].section_summary.score))

    // delete if not filled or it will send null
    // TODO: check if require these while using sanitizeEmptyValues
    if (!data.interview_result) delete data.interview_result
    data.interview_result = data.interview_result || InterviewResultChoices[2].id //default considering when auto-save
    if (!data.overall_feeling) delete data.overall_feeling
    if (!data.potential_candidate_note) delete data.potential_candidate_note

    return httpClient(setStudentApplicationInterviewerResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        interview_result: {
          ...data,
          scoreboard,
        }
      })
    })
  },

  setStudentApplicationInterviewOverallResult: async ({ id, data }) => {
    const setStudentApplicationInterviewOverallResultUrl = `/admin/set_student_application_interview_overall_result`;

    return httpClient(setStudentApplicationInterviewOverallResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        interview_result: data
      })
    })
  }
};

export default studentApplicationInterviewerDataProvider
