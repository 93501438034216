import React from 'react'
import { TextField, BooleanField, NumberField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

import SectionWrapper from './SectionWrapper'
import GridTableShow from '../../../../../Components/GridTableShow'
import FinanceInfo from './FinanceInfo'

const useStyles = makeStyles((theme) => ({
  textField: {
    lineHeight: 1.5
  }
}));

const FinanceSection = (props) => {
  const { record, ...other } = props
  const classes = useStyles()

  return (
    <SectionWrapper title='C. Thông tin tài chính' record={record} {...other}>
      <FinanceInfo/>
      <GridTableShow
        source='finance_info.scholarships'
        title={`Các học bổng mà đã hoặc/và đang nộp đơn ứng tuyển`}
        columns={[
          { label: 'Tên Học bổng', key: 'name', size: 2 },
          { label: ' Trạng thái', key: 'is_received', size: 2, component: BooleanField},
          { label: 'Số tiền tổng cộng trong 1 năm (VND)', key: 'total_amount', size: 2, component: NumberField, options:{ style: 'currency', currency: 'VND' }},
          { label: 'Số tiền đã nhận (VND)', key: 'received_amount', size: 2, component: NumberField, options: { style: 'currency', currency: 'VND' }},
          { label: 'Số tiền còn lại sẽ nhận (VND)', key: 'remaining_amount', size: 2, component: NumberField, options: { style: 'currency', currency: 'VND' }},
          { label: 'Ghi chú', key: 'note', size: 2 },
        ]}
      />
      <TextField 
        label={`Ngoài những thông tin em đã cung cấp, em muốn Ban xét duyệt học bổng 
        biết thêm những thông tin gì khác về em? (Ví dụ: tình trạng sức khỏe hạn chế của bố mẹ, 
        bố mẹ mất khả năng lao động, ốm đau, bệnh tật / bố mẹ đang phải chu cấp cho anh, chị, 
        em đang học THPT, đại học / gia đình còn nhiều khoản nợ chưa thanh toán hết, vv.)*`}
        source='finance_info.family_income.note'
        className={classes.textField}
      />
    </SectionWrapper>
  );
}

export default FinanceSection;