import * as React from 'react';
import { useState } from 'react';
import { useNotify, useGetList } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { cloneDeep, set, get } from 'lodash'
import moment from 'moment'

import MentorApplicationDataProvider from '../../../Providers/MentorApplicationDataProvider'
import MentorApplicationInterviewerDataProvider from '../../../Providers/MentorApplicationInterviewerDataProvider'

import { DateViewer, DateEditor } from '../../../Components/DataSheetComponents/DateComponents'
import { TimeViewer, TimeEditor } from '../../../Components/DataSheetComponents/TimeComponents'
import { SelectViewer, SelectEditor } from '../../../Components/DataSheetComponents/SelectComponents'
import { TextViewer, TextEditor } from '../../../Components/DataSheetComponents/TextComponents'

import { RegionChoices } from '../../../constants/constants'
import { mentorInterviewDuration as defaultInterviewDuration } from '../constants'
import objectToArray from '../../../helpers/objectToArray'


const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  root: {
    // width: '100%',
  },
}))

const columns = ({ interviewerList, }) => ([
  { label: 'ID', name: 'id', valueViewer: TextViewer, dataEditor: TextEditor, width: 50, },
  { label: 'Region', name: 'region', valueViewer: SelectViewer(RegionChoices), width: 50, isSelect: true, disableEvents: true, },
  { label: 'Name', name: 'display_name', width: 200, disableEvents: true, },
  { label: 'Date', name: 'interview_date', valueViewer: DateViewer, dataEditor: DateEditor, width: 150, },
  { label: 'Starting time', name: 'starting', valueViewer: TimeViewer, dataEditor: TimeEditor, width: 100, },
  { label: 'Ending time', name: 'ending', valueViewer: TimeViewer, dataEditor: TimeEditor, width: 100, },
  { 
    label: 'Interviewer 1', name: 'interviewer_1', 
    valueViewer: SelectViewer(interviewerList), dataEditor: SelectEditor(interviewerList), 
    width: 200, isSelect: true, 
  },
  { 
    label: 'Interviewer 2', name: 'interviewer_2', 
    valueViewer: SelectViewer(interviewerList), dataEditor: SelectEditor(interviewerList), 
    width: 200, isSelect: true, 
  },
  { label: 'Email', name: 'email', width: 100, disableEvents: true, },
  { label: 'Phone number', name: 'phone_number', width: 100, disableEvents: true, },
  { label: 'Job title', name: 'job_title', width: 100, disableEvents: true, },
])


const InterviewCalendarRoot = ({ theme }) => {
  const classes = useStyles()
  const notify = useNotify();
  const [interviewDuration, setInterviewDuration] = useState(defaultInterviewDuration)

  const initEmptyData = () => {
    let array = []
    for (let i = 0; i < 20; ++i) {
      array.push({})
    }
    return array
  }

  const [data, setData] = useState(initEmptyData())

  const { data: interviewerListObject = {} } = useGetList('mentor-application-interviewer')
  const interviewerList = objectToArray(interviewerListObject)

  const parsedColumns = columns({ interviewerList, })

  const handleChangeRowNumber = (e) => {
    const nRows = e.target.value
    let newData = cloneDeep(data)
    while (newData.length < nRows) {
      newData.push({})
    }
    if (newData.length > nRows) {
      newData = newData.slice(0, nRows)
    }
    setData(newData)
  }

  const mapDataToDataSheet = (data) => {
    return data.map(row => {
      return parsedColumns.map(col => {
        const { label, name, ...others } = col
        return { 
          value: row[name],
          ...others,
        }
      })
    })
  }

  const displayData = mapDataToDataSheet(data)

  const sheetRenderer = (props) => {
    const { className, children } = props
    return (
      <table className={className}>
        <thead>
          <tr>
            <th className='action-cell'>
              <button onClick={() => {
                setData([...data, {}])
              }}
              >
                Insert
              </button>
            </th>
            {parsedColumns.map(col => (
                <th className='cell read-only row-handle' style={{ minWidth: col.width }}>{col.label}</th>
              ))
            }
            <th className='action-cell'></th>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    )
  }

  const rowRenderer = (props) => {
    const { row, children, className } = props
    return (
      <tr className={className}>
        <td className='action-cell'>
          {row + 1}
        </td>
        {children}
        <td className='action-cell'>
          <button onClick={() => {
            let newData = cloneDeep(data)
            newData.splice(row, 1)
            setData(newData)
          }}>
            Delete
          </button>
        </td>
      </tr>
    )
  }

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const onCellsChanged = async changes => {
    const newData = cloneDeep(data)

    await asyncForEach(changes, async ({ cell, row, col, value }) => {
      if (parsedColumns[col].name === 'id') {
        // get application data
        await MentorApplicationDataProvider.getOne({ id: value })
          .then(({ data: application }) => {
            if (!application) return;

            set(newData, `${row}.id`, get(application, 'id', ''))
            set(newData, `${row}.region`, get(application, 'region', ''))
            set(newData, `${row}.display_name`, get(application, 'display_name', ''))
            set(newData, `${row}.email`, get(application, 'email', ''))
            set(newData, `${row}.phone_number`, get(application, 'personal_info.phone_number', ''))
            set(newData, `${row}.job_title`, get(application, 'experience_info.quiz[4].answer', ''))

            set(newData, `${row}.interviewer_1`, get(application, 'interviewer_1', ''))
            set(newData, `${row}.interviewer_2`, get(application, 'interviewer_2', ''))

            const interview_datetime = get(application, 'interview_datetime', '')
            set(newData, `${row}.interview_date`, interview_datetime.substring(0, 10))
            const starting = interview_datetime.substring(11)
            const endingMoment = moment(starting, "HH-mm-ss").add(interviewDuration, 'minutes') 
            const ending = endingMoment.format("HH:mm:ss")
            set(newData, `${row}.starting`, starting)
            set(newData, `${row}.ending`, ending)
          })
      } if (parsedColumns[col].name === 'starting') {        
        const endingMoment = moment(value, "HH:mm:ss").add(interviewDuration, 'minutes')
        const ending = endingMoment.format("HH:mm:ss")

        set(newData, `${row}.starting`, value)
        set(newData, `${row}.ending`, ending)
      } if (parsedColumns[col].name === 'ending') {
        const staringMoment = moment(value, "HH:mm:ss").subtract(interviewDuration, 'minutes')
        const starting = staringMoment.format("HH:mm:ss")

        set(newData, `${row}.starting`, starting)
        set(newData, `${row}.ending`, value)
      } else {
        set(newData, `${row}.${parsedColumns[col].name}`, value)
      }
    })

    setData(newData)
  }

  const handleSubmit = () => {
    try {
      asyncForEach(data, async (row) => {
        const { id, interviewer_1, interviewer_2, interview_date, starting, } = row
        if (id) {
          await MentorApplicationInterviewerDataProvider.updateMany({
            ids: [Number.parseInt(id)],
            data: {
              interviewer_1,
              interviewer_2,
              interview_datetime: new Date(`${interview_date} ${starting}`)
            },
          })
        }
      })
      notify('Changes are saved', 'success')
    } catch (error) {
      notify('Error: can not set interviewers' + JSON.stringify(error), 'warning')
    }
    
  }

  const exportJSON = () => {
    let savedData = JSON.stringify(data, undefined, 4)

    const blob = new Blob([savedData], { type: 'text/json' }),
    e = document.createEvent('MouseEvents'),
    a = document.createElement('a')

    a.download = 'mentor-interview-data.json'
    a.href = window.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    a.dispatchEvent(e)
  }

  const onUploadJSON = (event) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
  }

  function onReaderLoad(event) {
    const uploadedData = JSON.parse(event.target.result);
    setData(uploadedData)
  }

  return (
    <div>
      <div className={classes.header}>EDIT MENTOR INTERVIEW CALENDAR</div>
      
      <div>
        <input type='number' placeholder='# rows' onBlur={handleChangeRowNumber} />
        <input 
          type='number' 
          placeholder={`interview duration (default = ${defaultInterviewDuration} mins)`}
          onChange={(e) => setInterviewDuration(e.target.value)} 
          style={{ marginLeft: 20, width: 200 }}
        />
        <button onClick={exportJSON} style={{ margin: 20 }}>
          Export to JSON
        </button>
        <input 
          type="file" accept="application/json" 
          onChange={onUploadJSON} title='Import JSON'
          style={{ margin: 20 }}
        />
      </div>
      <ReactDataSheet
        className={classes.root}
        sheetRenderer={sheetRenderer}
        rowRenderer={rowRenderer}
        onCellsChanged={onCellsChanged}
        valueRenderer={(cell) => cell.value}
        data={displayData}
      />
      <Button onClick={handleSubmit} variant='contained' color='primary' style={{ margin: 30 }} size='large'>
        Submit
      </Button>
    </div>
  )
};

export default InterviewCalendarRoot;