const frontendURL = process.env.REACT_APP_FRONTEND_URL
const backendURL = process.env.REACT_APP_BACKEND_URL
const sentryDNS = process.env.REACT_APP_SENTRY_DNS
const reCaptchaSiteKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY

const ResponseCode = {
  SUCCESS: 'success',

  ERROR_PARAMS: 'error_params',
  ERROR_HEADER: 'error_header',
  ERROR_FORBIDDEN: 'error_forbidden',
  ERROR_CAPTCHA: 'error_captcha',
  ERROR_UNAUTHORIZED: 'error_unauthorized',

  // Authentication error
  ERROR_INVALID_LOGIN_CREDENTIAL: 'error_invalid_login_credential',
  ERROR_INVALID_TOKEN: 'error_invalid_token',
  ERROR_INVALID_RESET_PASSWORD_TOKEN: 'error_invalid_reset_password_token',
  ERROR_INVALID_ACCOUNT_USERNAME_OR_EMAIL: 'error_invalid_account_username_or_email',

  ERROR_INVALID_FILE_FORMAT: 'error_invalid_file_format',
  ERROR_IMAGE_UPLOAD_FAILED: 'error_image_upload_failed',
  ERROR_FILE_UPLOAD_FAILED: 'error_file_upload_failed',

  ERROR_DUPLICATED_APPLICATION: 'error_duplicated_application',
  ERROR_INVALID_APPLICATION_CREDENTIAL: 'error_invalid_application_credential',
  ERROR_NOT_ALLOWED_TO_EDIT_APPLICATION: 'error_not_allowed_to_edit_application',
  ERROR_NOT_ALLOWED_TO_REGISTER_APPLICATION: 'error_not_allowed_to_register_application',
  ERROR_SUBMIT_APPLICATION_FAILED: 'error_submit_application_failed',
  ERROR_INVALID_APPLICATION_YEAR: 'error_invalid_application_year',
  ERROR_RETRY_LIMIT_EXCEEDED: 'error_limit_exceeded',
}

const Roles = {
  unAuth: 'UN_AUTH',
  root: 'ROOT',
  admin: 'ADMIN',
  adminStudentApp: 'ADMIN_STUDENT_APP',
  adminSponsorPlatform: 'ADMIN_SPONSOR_PLATFORM',
  reviewerStudentApp: 'REVIEWER_STUDENT_APP',
  interviewerStudentApp: 'INTERVIEWER_STUDENT_APP',
  assessorStudentApp: 'ASSESSOR_STUDENT_APP',
  sponsor: 'SPONSOR',
  groupLeader: 'GROUP_LEADER',
  adminMentorApp: 'ADMIN_MENTOR_APP',
  reviewerMentorApp: 'REVIEWER_MENTOR_APP',
  interviewerMentorApp: 'INTERVIEWER_MENTOR_APP',

  mentor: 'MENTOR',
}

const YearChoices = [
  { id: 2020, name: '2020' },
  { id: 2021, name: '2021' },
]

const RegionChoices = [
  { id: 1, name: 'HCM' },
  { id: 2, name: 'HN' },
  { id: 3, name: 'Huế' },
]

const GenerationChoices = [
  { id: 1, name: 'F1' },
  { id: 2, name: 'F2' },
  { id: 3, name: 'F3' },
  { id: 4, name: 'F4' },
  { id: 5, name: 'F5' },
  { id: 6, name: 'F6' },
  { id: 7, name: 'F7' },
  { id: 8, name: 'F8' },
  { id: 9, name: 'F9' },
  { id: 10, name: 'F10' },
  { id: 11, name: 'F11' },
]

const SponsorStatusChoices = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Half scholarship' },
  { id: 2, name: 'Full scholarship' },
]

const GenderChoices = [
  { id: 1, name: 'Nam' },
  { id: 2, name: 'Nữ' },
  { id: 3, name: 'Khác' },
]

const StudentTypeChoices = [
  { id: 1, name: 'Student', },
  { id: 2, name: 'Alumni', },
  { id: 3, name: 'Deleted', },
]

const AcademicLevelChoices = [
  { id: 1, name: 'Cử nhân', },
  { id: 2, name: 'Thạc sĩ', },
  { id: 3, name: 'Tiến sĩ', },
  { id: 4, name: 'Khác', },
]

const MenteeTypeChoices = [
  { id: 1, name: 'Năm 1', },
  { id: 2, name: 'Từ năm 2 trở đi', },
  { id: 3, name: 'Bất kì', }
]

const YesNoChoices = [
  { id: 1, name: 'YES', },
  { id: 2, name: 'NO', },
]

const MentorMatchingStatusChoices = [
  { id: 1, name: 'BOOKED', },
  { id: 2, name: 'REQUESTED', },
  { id: 3, name: 'APPROVED', },
  { id: 4, name: 'REJECTED', },
]

export {
  frontendURL,
  backendURL,
  sentryDNS,
  reCaptchaSiteKey,
  ResponseCode,
  Roles,
  GenderChoices,
  YearChoices,
  RegionChoices,
  GenerationChoices,
  SponsorStatusChoices,
  StudentTypeChoices,
  AcademicLevelChoices,
  MenteeTypeChoices,
  YesNoChoices,
  MentorMatchingStatusChoices,
}