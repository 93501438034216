import React from 'react';
import { Layout } from 'react-admin';

import AppBar from './AppBar'
import Menu from './Menu';
import ErrorPage from './ErrorPage';

export default (props) => {
  return (
    <Layout
      {...props}
      appBar={AppBar}
      menu={Menu}
      error={ErrorPage}
    />
  );
};
