import { handleActions } from 'redux-actions'

import {
  storeUserProfile
} from '../actions/profileAction'

let defaultState = {
  // username, display_name, groups
}

const profileReducer = handleActions(
  {
    [storeUserProfile]: (state, { payload }) => {
      return {
        ...payload
      }
    },
    
  },
  defaultState
)

export default profileReducer