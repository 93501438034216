import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  viewerRoot: {
    display: 'flex',
    boxSizing: 'border-box',
    height: '100% !important',
    alignItems: 'center',
    justifyContent: 'flex-end',
    lineHeight: 1.4,
    padding: 10,
  },
  editorRoot: {
    display: 'block',
    height: '100% !important',
  }
}))

const DateViewer = (props) => {
  const { value } = props
  const classes = useStyles()

  return (
    <span className={classes.viewerRoot}>{value}</span>
  )
}

const DateEditor = (props) => {
  const { value, onChange } = props
  const classes = useStyles()

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <input
      type='date'
      value={value}
      onChange={handleChange}
      className={clsx('data-editor', classes.editorRoot)}
    />
  )
}

export {
  DateViewer,
  DateEditor
}