import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  SearchInput,
  TextInput,
  SelectInput,
} from 'react-admin';

import { SponsorRegistrationStatus } from '../../constants'

const styles = theme => ({
})

const SponsorRegistrationFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <TextInput source='email' label={'Email'} />
      <SelectInput source='status' label={'Status'} 
        choices={SponsorRegistrationStatus}
      />
    </Filter>
  )
}

export default withStyles(styles)(SponsorRegistrationFilter)