import React from 'react'
import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { get } from 'lodash'

import mapChoicesToValue from '../../helpers/mapChoicesToValue'


const useStyles = makeStyles(theme => ({
  viewerRoot: {
    display: 'flex',
    boxSizing: 'border-box',
    height: '100% !important',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1.4,
    padding: 10,
  },
  editorRoot: {
    display: 'block',
    height: '100% !important',
  }
}))


const SelectViewer = (choices) => {
  const classes = useStyles()
  return ((props) => {
    const { value } = props

    return (
      <span className={classes.viewerRoot}>
        {mapChoicesToValue(choices, value)}
      </span>
    )
  })
}

const SelectEditor = (choices) => (props) => {
  const { onChange } = props

  const handleChange = (value) => {
    const id = get(value, 'id', '')
    onChange(id !== '' ? JSON.parse(id) : id)
  }

  return (
    <Autocomplete
      disablePortal
      closeIcon={null}
      options={[
        { id: '', name: '' },
        ...choices
      ]}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} label='' />}
      onChange={(_, value) => handleChange(value)}
    />
  )
}

export {
  SelectViewer,
  SelectEditor
}