import React from 'react'
import {
  FormTab,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/icons/Comment'
import { get } from 'lodash'

const ApplicationReferenceTab = (props) => {
  const { record } = props
  const id = get(record, 'id', 0)

  return (
    <FormTab label='Application reference' icon={<Icon/>} {...props}>
      <Button variant='contained' color='primary' href={`/#/mentor-application/${id % 10000}`}>View mentor application</Button>
    </FormTab>
  )
}

export default ApplicationReferenceTab