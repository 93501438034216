import React from 'react'
import {
  TextInput, NumberInput, ReferenceInput, AutocompleteInput,
  ArrayInput, SimpleFormIterator,
} from 'react-admin'

import SectionWrapper from './SectionWrapper'
import CollapsibleWrapper from '../../../../../Components/CollapsibleWrapper'
import renderInputFields from '../../../../../helpers/renderInputFields'

const F10UniversityFields = [
  {
    label: 'Đại học', source: 'university_id', required: true,
    component: (props) =>
      <ReferenceInput {...props} reference="university">
        <AutocompleteInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
  },
  { label: 'Ngành', source: 'study_major', component: TextInput, required: true, },
  { label: 'Study major', source: 'study_major_en', component: TextInput, required: true },
  { label: 'Đại học năm', source: 'university_year', component: NumberInput, required: true, },
  { label: 'Điểm thi đại học', source: 'extra_data.entrance_score', component: NumberInput },
  { label: 'GPA', source: 'extra_data.university.gpa', component: TextInput },
]

// from F10 onwards
const FromF10AcademicInfoSection = (props) => {
  const { record, ...otherProps } = props

  return (
    <SectionWrapper title='B. Kết quả học tập và hoạt động xã hội' {...otherProps}>
      {
        renderInputFields(F10UniversityFields, {
            variant: 'outlined',
            fullWidth: true,
          }
        )
      }
      <CollapsibleWrapper title="Các giải thưởng THPT (VI)">
        <ArrayInput label="Giải thưởng THPT (VI)" source="extra_data.achievement.high_school">
          <SimpleFormIterator>
            <TextInput label="Giải thưởng" source="name" fullWidth/>
            <TextInput label="Mô tả" source="description" fullWidth/>
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>
      <CollapsibleWrapper title="Highschool Achievements(EN)">
        <ArrayInput label="Highschool Achievement(EN)" source="extra_data_en.achievement.high_school">
          <SimpleFormIterator>
            <TextInput label="Name" source="name" fullWidth/>
            <TextInput label="Description" source="description" fullWidth/>
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>

      <CollapsibleWrapper title="Các giải thưởng ĐH (VI)">
        <ArrayInput label="Giải thưởng ĐH(VI)" source="extra_data.achievement.university">
          <SimpleFormIterator>
            <TextInput label="Giải thưởng" source="name" fullWidth />
            <TextInput label="Mô tả" source="description" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>
      <CollapsibleWrapper title="University Achievement (EN)">
        <ArrayInput label="University Achievement (EN)" source="extra_data_en.achievement.university">
          <SimpleFormIterator>
            <TextInput label="Name" source="name" fullWidth />
            <TextInput label="Description" source="description" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>

      <CollapsibleWrapper title="Các hoạt động ngoại khoá (VI)">
        <ArrayInput label="Hoạt động ngoại khoá (VI)" source="extra_data.experience_info.volunteer_experiences">
          <SimpleFormIterator>
            <TextInput label="Hoạt động" source="title" fullWidth/>
            <TextInput label="Mô tả" source="description" fullWidth/>
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>
      <CollapsibleWrapper title="Volunteer activities (EN)">
        <ArrayInput label="Volunteer activity (EN)" source="extra_data_en.experience_info.volunteer_experiences">
          <SimpleFormIterator>
            <TextInput label="Title" source="title" fullWidth/>
            <TextInput label="Description" source="description" fullWidth/>
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>

      <CollapsibleWrapper title="Các kinh nghiệm làm việc (VI)">
        <ArrayInput label="Kinh nghiệm làm việc (VI)" source="extra_data.experience_info.work_experiences">
          <SimpleFormIterator>
            <TextInput label="Công việc" source="position" fullWidth />
            <TextInput label="Mô tả" source="description" fullWidth />
            <TextInput label="Nơi làm" source="work_place" fullWidth />
            <TextInput label="Số giờ mỗi tuần" source="weekly_hours" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>
      <CollapsibleWrapper title="Work experiences (EN)">
        <ArrayInput label="Work experience (EN)" source="extra_data_en.experience_info.work_experiences">
          <SimpleFormIterator>
            <TextInput label="Position" source="position" fullWidth />
            <TextInput label="Description" source="description" fullWidth />
            <TextInput label="Work place" source="work_place" fullWidth />
            <TextInput label="Weekly hours" source="weekly_hours" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </CollapsibleWrapper>
    </SectionWrapper>
  )
};

export default FromF10AcademicInfoSection