import React from 'react'
import {
  FormTab
} from 'react-admin'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReferenceField,
} from 'react-admin';
import StudentField from '../../MentorMatchingRequestList/components/StudentField'
import MentorField from '../../MentorMatchingRequestList/components/MentorField'
import SetStatusButtons from '../../MentorMatchingRequestList/components/SetStatusButtons'
import DatetimeInfo from '../../MentorMatchingRequestList/components/DatetimeInfo'

const RequestInfoTab = (props) => {
  const { record } = props
  const classes = useStyles();

  return (
    <FormTab {...props} label="Request info" >
      {(!record) ? null
        :
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ReferenceField
                  resource="mentor-matching-request"
                  reference="student"
                  source="student_id"
                  basePath="/student"
                  record={record}
                  link={true}
                >
                  <Typography variant="h6" gutterBottom>
                    Student
                  </Typography>
                </ReferenceField>
              </Grid>
              <Grid item xs={6}>
                <ReferenceField
                  resource="mentor-matching-request"
                  reference="mentor"
                  source="mentor_id"
                  basePath="/mentor"
                  record={record}
                  link={true}
                >
                  <Typography variant="h6" gutterBottom>
                    Mentor
                  </Typography>
                </ReferenceField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} container alignContent="flex-start">
                <ReferenceField
                  resource="mentor-matching-request"
                  reference="student"
                  source="student_id"
                  basePath="/student"
                  record={record}
                  link={false}
                >
                  <StudentField />
                </ReferenceField>
              </Grid>
              <Grid item xs={6} container alignContent="flex-start">
                <ReferenceField
                  resource="mentor-matching-request"
                  reference="mentor"
                  source="mentor_id"
                  basePath="/mentor"
                  record={record}
                  link={false}
                >
                  <MentorField />
                </ReferenceField>
              </Grid>
            </Grid>
            <div className={classes.spacer}>&nbsp;</div>
            <SetStatusButtons record={record} />
            <div className={classes.spacer}>&nbsp;</div>
            <DatetimeInfo record={record} />
          </CardContent>
        </Card>
      }
    </FormTab>
  )
}

export default RequestInfoTab

const useStyles = makeStyles({
  root: { width: '100%', margin: 'auto' },
  spacer: { height: 20 },
});
