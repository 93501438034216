import React from 'react'
import { useRedirect, useNotify, required } from 'react-admin';
import { Form, Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import authProvider from '../../Providers/authProvider';

import AccountCircle from '@material-ui/icons/AccountCircle';

import ReCaptcha from '../../Components/ReCaptcha'

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20,
  },
  textField: {
    marginTop: 20,
    marginBottom: 20,
  },
  loginContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  loginLink: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const InputUsernameForm = (props) => {
  const classes = useStyles()
  const notify = useNotify();
  const redirect = useRedirect()

  const onSubmit = async (values) => {
    return authProvider.forgotPassword({
      ...values,
      username: values.username.trim(),
    })
      .then(() => {
        notify(`Vui lòng kiểm tra email để nhận mã đổi mật khẩu`, 'success', undefined, false, 0);
        redirect('/forgot-password/2');
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          username: '',
          captcha_response: ''
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>Forgot password</div>
            <Field name="username" validate={required('Username or email is required')}>
              {({ input, meta }) => (
                <TextField {...input} label='Username/Email' required fullWidth
                  className={classes.textField}
                  placeholder='Username/Email'
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="email"
                        >
                          <AccountCircle />
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              )}
            </Field>
            <Field name="captcha_response" validate={required('Captcha is required')}>
              {({ input, meta }) => (
                <ReCaptcha
                  {...input}
                  onChange={(value) => input.onChange(value)}
                />
              )}
            </Field>
            <Button
              color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}
              disabled={submitting}
            >
              Confirm
            </Button>
          </form>
        )}
      />
      <div className={classes.loginContainer}>
        <a className={classes.loginLink} href='/#/login'>Login</a>
      </div>
    </>
  )
}


export default InputUsernameForm