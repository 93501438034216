import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { SimpleShowLayout } from 'react-admin'

import colors from '../constants/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    border: 'black solid 1px',
  },
  title: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    padding: 20,
    height: 20,
    background: colors.primaryLight,
    cursor: 'pointer',
  },
  titleText: {
    color: colors.primaryContrastText,
    marginLeft: 20,
    fontSize: 18,
    fontWeight: 'bold'
  },
  showLayout: {
    margin: 'auto',
    width: '90%'
  }
}));

const SectionWrapper = (props) => {
  const { title, children, record, ...other } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <div className={classes.container}>
      <div className={classes.title} onClick={() => setOpen(!open)}>
        <IconButton aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <div className={classes.titleText}>
          {title}
        </div>
      </div>
      <Collapse in={open}>
        <SimpleShowLayout record={record} {...other} className={classes.showLayout}>
          {children}
        </SimpleShowLayout>
      </Collapse>
    </div>
  )
};

export default SectionWrapper