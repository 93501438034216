import React from 'react';
import { Admin, Resource } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';

import dataProvider from './Providers/dataProvider'
import authProvider from './Providers/authProvider'
import customRoutes from './routes'

// reducers
import profileReducer from './redux/reducers/profileReducer'

import Layout from './Layout/index'
import Dashboard from './Pages/Dashboard/index'
import LoginPage from './Pages/Login/index'

import { resourceList } from './constants/rolesResources'
import theme, { createdTheme } from './constants/theme'

const App = () => {
  return (
    <ThemeProvider theme={createdTheme}>
      <Admin
        title='VietSeeds admin'
        dataProvider={dataProvider}
        authProvider={authProvider}
        customReducers={{ profile: profileReducer }}
        customRoutes={customRoutes}
        dashboard={Dashboard}
        loginPage={() => <LoginPage />}
        layout={Layout}
        theme={theme}
      >
        {permissions => {
          if (permissions === []) {
            return []
          }
          return (
            resourceList
            .filter(({ roles }) => authProvider.checkUserPermission(permissions, roles))
            .map(({ name, resources }) => {
              return (
                <Resource key={name} name={name} {...resources} />
              )
            })
          )
        }}
      </Admin>
    </ThemeProvider>
  )
}

export default App;
