import * as React from 'react';
import { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  ExportButton,
  Button,
  sanitizeListRestProps,
  usePermissions,
  useNotify,
  useRefresh,
} from 'react-admin';

import authProvider from '../../../../Providers/authProvider'
import MentorApplicationDataProvider from '../../../../Providers/MentorApplicationDataProvider'
import { Roles } from '../../../../constants/constants'

import ReviewingIcon from '@material-ui/icons/RateReview'
import InterviewingIcon from '@material-ui/icons/CallEnd'

const MentorListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  const notify = useNotify()
  const refresh = useRefresh()

  const { permissions } = usePermissions()

  const mentorAdminPermission = authProvider.checkUserPermission(permissions, [Roles.root, Roles.admin, Roles.adminMentorApp])

  const handleSetApplicationsToReviewing = async () => {
    try {
      await MentorApplicationDataProvider.setMentorApplicationsStatusToReviewing()
      notify('Success', 'success');
      refresh()
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  }

  const handleSetApplicationsToInterviewing = async () => {
    try {
      await MentorApplicationDataProvider.setMentorApplicationsStatusToInterviewing()
      notify('Success', 'success');
      refresh()
    } catch (error) {
      notify(`Error: ${error.message}`, 'warning');
    }
  }



  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {mentorAdminPermission &&
        <>
        <Button
          size="small"
          color="primary"
          startIcon={<ReviewingIcon />}
          onClick={handleSetApplicationsToReviewing}
          label={'Set status to REVIEWING'}
        />
        <Button
          size="small"
          color="primary"
          startIcon={<InterviewingIcon />}
          onClick={handleSetApplicationsToInterviewing}
          label={'Set status to INTERVIEWING'}
        />
        </>
      }
    </TopToolbar>
  );
};

export default MentorListActions