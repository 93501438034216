import SponsorInvitationList from './SponsorInvitationList'
import SponsorInvitationEdit from './SponsorInvitationEdit'
import SponsorInvitationCreate from './SponsorInvitationCreate'
import SponsorInvitationIcon from '@material-ui/icons/Send';

export default {
  list: SponsorInvitationList,
  edit: SponsorInvitationEdit,
  create: SponsorInvitationCreate,
  icon: SponsorInvitationIcon,
  options: { label: 'Sponsor Invitation' }
}