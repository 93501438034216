import React from 'react'
import {
  List, Datagrid, TextField, SelectField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import { RegionChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  },
}));

const universityListShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Đại học', source: 'name', component: TextField,
  },
  { 
    label: 'University', source: 'name_en', component: TextField, 
  },
  {
    label: 'Abbreviation', source: 'abbreviation', component: TextField,
  },
  {
    label: 'Region', source: 'vietseeds_region', component: SelectField,
    choices: RegionChoices,
  },
]

const UniversityList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'University List'}
      // filters={<UniversityFilter/>}
      // actions={<UniversityActions/>}
      pagination={null}
      // perPage={100}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          universityListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={`${source}_${id}`} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default UniversityList