import React from 'react';
import {
    Filter,
    SelectInput,
    NullableBooleanInput,
    SearchInput,
    ReferenceInput,
} from 'react-admin';

import { MentorRegionChoices } from '../../constants'

const ResultStatusChoices = [
  { id: 0, name: 'Undone'},
  { id: 1, name: 'Pass' },
  { id: 2, name: 'Failed' },
  { id: 3, name: 'Consider' },
]

const MentorApplicationFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source="name" alwaysOn />
      <SelectInput source='region' label='Region' choices={MentorRegionChoices} alwaysOn/>

      <SelectInput source='status' label='Application Status' choices={[
        { id: 1, name: 'Draft' },
        { id: 2, name: 'Submitted' },
        { id: 3, name: 'Reviewing' },
        { id: 4, name: 'Interviewing' },
        { id: 5, name: 'Passed' },
        { id: 6, name: 'Failed' },
      ]}/>
      <SelectInput source='review_status' label='Review Status' choices={ResultStatusChoices} />
      <SelectInput source='interview_status' label='Interview Status' choices={ResultStatusChoices} />
      <SelectInput source='final_status' label='Final Status' choices={ResultStatusChoices} />

      <ReferenceInput label='Reviewer 1' source='reviewer_1' reference='mentor-application-reviewer'>
        <SelectInput source='display_name'/>
      </ReferenceInput>
      <ReferenceInput label='Reviewer 2' source='reviewer_2' reference='mentor-application-reviewer'>
        <SelectInput source='display_name' />
      </ReferenceInput>

      <ReferenceInput label='Interviewer 1' source='interviewer_1' reference='mentor-application-interviewer'>
        <SelectInput source='display_name' />
      </ReferenceInput>
      <ReferenceInput label='Interviewer 2' source='interviewer_2' reference='mentor-application-interviewer'>
        <SelectInput source='display_name' />
      </ReferenceInput>

      <NullableBooleanInput source='my_review' label='My Review' defaultValue={true}/>
      <NullableBooleanInput source='my_interview' label='My Interview' defaultValue={true}/>
      <NullableBooleanInput source='my_assessment' label='My Assessment' defaultValue={true}/>
    </Filter>
  )
}

export default MentorApplicationFilter