import React from 'react'
import { NumberField, SelectField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SectionWrapper from './ApplicationShowSections/SectionWrapper'
import { get } from 'lodash'

import assessmentTableFieldsByYear from '../../../AssessmentDay/assessmentTableFields'
import colors from '../../../../constants/colors'

const useRowStyles = makeStyles((theme) => ({
  sectionHead: {
    background: '#f7f7f7'
  },
  rowHeader: {
    fontWeight: 'bold'
  }
}));

const Row = (props) => {
  const {  
    meta_data: { section_summary, rows },
    data1: { rows: rows_1 }, 
    data2: { rows: rows_2 }, 
    data3: { rows: rows_3 }, 
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.sectionHead}>
        <TableCell className={classes.rowHeader}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right" className={classes.rowHeader}>
          {section_summary.max_score }
        </TableCell>
        <TableCell className={classes.rowHeader}>{section_summary.name}</TableCell>
        <TableCell className={classes.rowHeader} align="right">
        </TableCell>
        <TableCell className={classes.rowHeader} align="right">
        </TableCell>
        <TableCell className={classes.rowHeader} align="right">
        </TableCell>
      </TableRow>
      {open && rows.map((row, index) => (
        <TableRow key={`${section_summary.name}_row_${index}`}>
          <TableCell style={{ width: 50 }}></TableCell>
          <TableCell align="right" style={{ width: 50 }}>{row.max_score}</TableCell>
          <TableCell style={{ width: 300 }}>{row.name}</TableCell>
          <TableCell align="right" style={{ width: 70}}>
            <NumberField record={get(rows_1, `[${index}]`, {})} source='score'/>
          </TableCell>
          <TableCell align="right" style={{ width: 70 }}>
            <NumberField record={get(rows_2, `[${index}]`, {})} source='score' />
          </TableCell>
          <TableCell align="right" style={{ width: 70 }}>
            <NumberField record={get(rows_3, `[${index}]`, {})} source='score' />
          </TableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    marginBottom: 30,
  },
  tableHeadRow: {
    background: colors.primaryLight,
  },
  tableHeadCell: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const EvaluationShowTable = (props) => {
  const classes = useStyles()
  const rowClasses = useRowStyles();

  const { record, assessorList, ...others } = props

  const applicationYear = get(record, 'year', 2020)
  const scoreboard_1 = get(record, 'assessor_1_data.assessment_detail.scoreboard', [])
  const scoreboard_2 = get(record, 'assessor_2_data.assessment_detail.scoreboard', [])
  const scoreboard_3 = get(record, 'assessor_3_data.assessment_detail.scoreboard', [])

  return (
    <SectionWrapper title='Scoreboard result' record={record}>
      <Table {...others} className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={classes.tableHeadCell}></TableCell>
            <TableCell className={classes.tableHeadCell} align="right">Điểm tối đa</TableCell>
            <TableCell className={classes.tableHeadCell}>Tiêu chí đánh giá</TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              <SelectField record={record} label="" source='accessor_1' choices={assessorList}/>
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              <SelectField record={record} label="" source='accessor_2' choices={assessorList} />
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              <SelectField record={record} label="" source='accessor_3' choices={assessorList} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            assessmentTableFieldsByYear[applicationYear].map((meta_data, index) => {
              return (
                <Row key={`assessment_table_${index}`}
                  meta_data={meta_data}
                  data1={get(scoreboard_1, `[${index}]`, {})}
                  data2={get(scoreboard_2, `[${index}]`, {})} 
                  data3={get(scoreboard_3, `[${index}]`, {})} 
                  sectionIndex={index}
                />
              )
            })
          }
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>100</TableCell>
            <TableCell className={rowClasses.rowHeader}>TỔNG ĐIỂM</TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>
              {get(record, 'assessor_1_data.assessment_detail.total_score', [])}
            </TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>
              {get(record, 'assessor_2_data.assessment_detail.total_score', [])}
            </TableCell>
            <TableCell align="right" className={rowClasses.rowHeader}>
              {get(record, 'assessor_3_data.assessment_detail.total_score', [])}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SectionWrapper>
  )
}

export default EvaluationShowTable