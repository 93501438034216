import UniversityIcon from '@material-ui/icons/School';
import UniversityList from './UniversityList';
import UniversityCreate from './UniversityCreate';
import UniversityEdit from './UniversityEdit';

export default {
  list: UniversityList,
  create: UniversityCreate,
  edit: UniversityEdit,
  
  icon: UniversityIcon,
}