import {
  TextField
} from 'react-admin'

const essayFields = [
  {
    label: `Vui lòng viết 1 phần giới thiệu bản thân (không quá 700 từ) 
            để giới thiệu với các mentee tương lai của anh/chị. 
            Phần giới thiệu của anh/chị có thể chia sẻ anh/chị là ai? 
            Nơi công tác hoặc sở thích và những mối quan tâm của anh/chị. 
            Anh/chị mong muốn mối quan hệ với mentee sẽ diễn ra như thế nào. 
            Những chia sẻ này sẽ được gửi đến tất cả các bạn sinh viên của VietSeeds 
            để tìm hiểu và kết nối mentee phù hợp nhất với anh/chị.`, 
    source: 'essays.answer',
    component: TextField 
  },
]

export default essayFields