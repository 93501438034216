import React, { useEffect } from 'react'
import {
  FormTab, TextInput, SelectInput, DateTimeInput,
  DateField, TextField, SelectField, SimpleShowLayout,
  useGetList, usePermissions,
  required, minLength,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { get, set, cloneDeep } from 'lodash'
import { useField } from 'react-final-form';

import authProvider from '../../../../Providers/authProvider'
import MentorApplicationInterviewerDataProvider from '../../../../Providers/MentorApplicationInterviewerDataProvider'
import { InterviewResultChoices } from '../../constants'
import { Roles } from '../../../../constants/constants'
import EvaluationEditTable from './EvaluationTables/EvaluationEditTable'
import EvaluationShowTable from './EvaluationTables/EvaluationShowTable'
import colors from '../../../../constants/colors'

import objectToArray from '../../../../helpers/objectToArray'

const useStyles = makeStyles(() => ({
  interviewContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  interviewSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: 10
  },
  datetimeContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  overallResultContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    margin: 10,
  },
  fullWidth: {
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
  },
  flexOne: {
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  divider: {
    width: 1,
    height: 200,
    background: 'gray',
  },
}));

const DateTimeInputSection = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.datetimeContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Schedule</div>
      <DateTimeInput
        {...props}
        label='Giờ bắt đầu phỏng vấn' source='interview_datetime' variant='outlined'
        className={classes.fullWidth}
      />
    </div>
  )
}

const DateTimeFieldSection = (props) => {
  const { record } = props
  const classes = useStyles()
  return (
    <SimpleShowLayout record={record} className={classes.datetimeContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Schedule</div>
      <DateField
        showTime
        label='Giờ bắt đầu phỏng vấn' source='interview_datetime'
        className={classes.fullWidth}
      />
    </SimpleShowLayout>
  )
} 

const OverallResultInputSection = (props) => {
  const { interviewerList,  ...others } = props
  const { record } = others
  const classes = useStyles()
  return (
    <>
    <div className={classes.overallResultContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Final Interview result</div>
      <SimpleShowLayout record={record} className={classes.overallResultContainer}>
        <SelectField
          label='Final Interview result'
          source='interview_status'
          variant='outlined'
          choices={InterviewResultChoices}
          className={classes.fullWidth}
        />
      </SimpleShowLayout>
      <div className={`${classes.header} ${classes.fullWidth}`}>Interviewers' response</div>
      <SelectInput
        {...others}
        label='Interviewer 1' source='interviewer_1' variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
      <SelectInput
        {...others}
        label='Interviewer 2' source='interviewer_2' variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
    </div>
    </>
  )
}

const OverallResultFieldSection = (props) => {
  const { interviewerList, record } = props

  const classes = useStyles()
  return (
    <SimpleShowLayout record={record} className={classes.overallResultContainer}>
      <div className={`${classes.header} ${classes.fullWidth}`}>Final result</div>
      <SelectField
        label='Final Interview result'
        source='interview_status'
        variant='outlined'
        choices={InterviewResultChoices}
        className={classes.fullWidth}
      />
      <div className={`${classes.header} ${classes.fullWidth}`}>Interviewers' response</div>
      <SelectField
        label='Interviewer 1'
        source='interviewer_1'
        variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
      <SelectField
        label='Interviewer 2'
        source='interviewer_2'
        variant='outlined'
        choices={interviewerList}
        className={classes.flexOne}
      />
    </SimpleShowLayout>
  )
}

const InterviewerInputSection = (props) => {
  const { interviewer, mentorAdminPermission } = props
  const classes = useStyles()

  return (
    <div {...props} className={classes.interviewSection} style={{ width: '95%' }}>
      <div className={`${classes.header} ${classes.fullWidth}`}>My interview</div>
      <SelectInput
        {...props}
        source={`${interviewer}.interview_result`}
        label='Overall Decision'
        variant='outlined'
        choices={InterviewResultChoices}
        className={classes.fullWidth}
        helperText={'Bắt buộc chọn kết quả interview'}
        validate={mentorAdminPermission ? null : [required()]}
      />
      <TextInput
        {...props}
        source={`${interviewer}.note`}
        label='Note'
        variant='outlined'
        multiline
        className={classes.fullWidth}
        rows={2}
        validate={mentorAdminPermission ? null : [minLength(100)]}
      />
      <EvaluationEditTable {...props}/>
    </div>
  )
}

const InterviewerFieldSection = (props) => {
  const { record, interviewer } = props
  const classes = useStyles()

  const getSumScoreboard = (scoreboard) => {
    return scoreboard.reduce((total, { section_summary }) => {
      if (section_summary.max_score > 0) {
        return total + (section_summary.score || 0)
      } else {
        return total
      }
    }, 0)
  }
  
  const scoreboard = get(record, `${interviewer}.scoreboard`, [])

  return (
    <SimpleShowLayout {...props} record={record} className={classes.flexOne}>
      <SelectField
        source={`${interviewer}.interview_result`}
        label='Overall Decision'
        choices={InterviewResultChoices}
      />
      <div>
        <span>Total score: </span>
        <span>{getSumScoreboard(scoreboard)}</span>
      </div>
      <TextField
        source={`${interviewer}.note`}
        label='Note'
        fullWidth
        rows={2}
      />
    </SimpleShowLayout>
  )
}

const InterviewEditTab = (props) => {
  const classes = useStyles()

  const { record: data } = props

  const { data: interviewerListObject = {} } = useGetList('mentor-application-interviewer')
  const interviewerList = objectToArray(interviewerListObject)
  const { permissions } = usePermissions()
  const uid = authProvider.getUid()
  
  const mentorAdminPermission = authProvider.checkUserPermission(permissions, [Roles.root, Roles.admin, Roles.adminMentorApp])


  useEffect(() => {
    const autoSaveInterval = setInterval(autoSave, 1000 * 60 * 5) // 5 mins

    // returned function will be called on component unmount 
    return () => {
      clearInterval(autoSaveInterval)
    }
  });

  const { input: { value: interviewResult } } = useField('interview.interview_result')
  const { input: { value: interviewer_1 } } = useField('interviewer_1')
  const { input: { value: interviewer_2 } } = useField('interviewer_2')
  const { input: { value: interview_datetime } } = useField('interview_datetime')
  const { input: { value: my_interview } } = useField('my_interview')
  const { input: { value: record } } = useField('')

  const autoSave = () => {
    const id = record.id
    let autoSaveData = cloneDeep(record)
    set(autoSaveData, 'interview.interview_result', interviewResult)
    set(autoSaveData, 'interviewer_1', interviewer_1)
    set(autoSaveData, 'interviewer_2', interviewer_2)
    set(autoSaveData, 'interview_datetime', interview_datetime)
    set(autoSaveData, 'my_interview', my_interview)
    try {
      MentorApplicationInterviewerDataProvider.update({
        id,
        data: autoSaveData,
        previousData: record,
      })
      console.log('Auto saved at ' + new Date().toString())
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <FormTab label='Interview' {...props}>
      {mentorAdminPermission ?
        <>
          <DateTimeInputSection/>
          <OverallResultInputSection interviewerList={interviewerList}/>
        </>
        :
        <>
          <DateTimeFieldSection {...props}/>
          <OverallResultFieldSection {...props} interviewerList={interviewerList}/>
        </>
      }
      {mentorAdminPermission &&
        <>
        <div className={classes.interviewContainer}>
          <InterviewerFieldSection interviewer={'interviewer_1_data'} {...props} />
          <div className={classes.divider}></div>
          <InterviewerFieldSection interviewer={'interviewer_2_data'} {...props} />
        </div>
        <EvaluationShowTable interviewerList={interviewerList} {...props}/>
        </>
      }
      {(uid === get(data, 'interviewer_1') || uid === get(data, 'interviewer_2')) &&
        <InterviewerInputSection interviewer='my_interview' mentorAdminPermission={mentorAdminPermission}/>
      }
    </FormTab>
  )
}

export default InterviewEditTab