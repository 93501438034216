import { TextInput } from 'react-admin'

const personalStoryFieldsByGeneration = {
  10: [
    {
      label: `Tại sao việc đi học đại học lại quan trọng với em? [Tối thiểu 100 từ]`,
      source: 'extra_data.essay.essay[0].answer',
      component: TextInput,
    },
    {
      label: `Why is going to university important to you? [Minimum 100 words]`,
      source: 'extra_data_en.essay.essay[0].answer',
      component: TextInput,
    },
    {
      label: `Vui lòng chia sẻ với chúng tôi về bản thân em?
            Với em điều gì là quan trọng nhất trong cuộc sống?
            Tại sao? [Tối thiểu 150 từ]`,
      source: 'extra_data.essay.essay[1].answer',
      component: TextInput
    },
    {
      label: `Please share with us about yourself?
            What is most important to you in life?
            Why? [Minimum 150 words]`,
      source: 'extra_data_en.essay.essay[1].answer',
      component: TextInput
    },
    {
      label: `Nếu có thể thay đổi bất cứ điều gì trong cuộc sống của mình, 
            em muốn thay đổi điểu gì nhất? Em cần những sự hỗ trợ, 
            giúp đỡ nào để có thể thực hiện sự thay đổi đó? [Tối thiểu 150 từ]`,
      source: 'extra_data.essay.essay[3].answer',
      component: TextInput
    },
    {
      label: `If you could change anything in your life, what would you like to change most? 
            What support and help do you need to make that change? [Minimum 150 words]`,
      source: 'extra_data_en.essay.essay[3].answer',
      component: TextInput
    },
    {
      label: `Nếu có điều gì chưa được nhắc đến trong hồ sơ mà
            em muốn Hội Đồng Tuyển Chọn biết để cân nhắc hồ sơ của em,
            em vui lòng chia sẻ ở đây. (Không bắt buộc)`,
      source: 'extra_data.essay.essay[2].answer',
      component: TextInput,
      defaultValue: `Các câu hỏi ở trên đã giúp em chia sẻ hết rồi ạ. Em cảm ơn các anh chị ạ.`
    },
    {
      label: `If there is something not mentioned in your application that you would like 
            the Selection Committee to know in order to consider your application, 
            please share it here. (Optional)`,
      source: 'extra_data_en.essay.essay[2].answer',
      component: TextInput,
      defaultValue: `The answers above have helped me share them all. Thank you.`
    },
  ],
  11: [
    {
      label: `Nếu chọn 3 từ để nói về em, 3 từ đó là gì? Hãy giải thích rõ hơn về 3 từ đó. [Tối thiểu 100 từ]`,
      source: 'extra_data.essay.essay[0].answer',
      component: TextInput,
    },
    {
      label: `If you could choose 3 words to describe you, what would those 3 words be? Please explain more about those 3 words. [Minimum 100 words]`,
      source: 'extra_data_en.essay.essay[0].answer',
      component: TextInput,
    },
    {
      label: `Ước mơ của em sau khi ra trường là gì? Ngành học em đang chọn có liên quan thế nào đến ước mơ đó? [Tối thiểu 100 từ]`,
      source: 'extra_data.essay.essay[1].answer',
      component: TextInput
    },
    {
      label: `What is your dream after graduation? How does your chosen field of study relate to that dream? [Minimum 100 words]`,
      source: 'extra_data_en.essay.essay[1].answer',
      component: TextInput
    },
    {
      label: `Ai là người truyền cảm hứng cho em nhiều nhất? Tại sao? [Tối thiểu 100 từ]`,
      source: 'extra_data.essay.essay[4].answer',
      component: TextInput
    },
    {
      label: `Who inspires you the most? Why? [Minimum 100 words]`,
      source: 'extra_data_en.essay.essay[4].answer',
      component: TextInput
    },
    {
      label: `Hãy chia sẻ về điểm mạnh và điểm yếu của bản thân em với những ví dụ cụ thể
              liên quan đến các điểm mạnh và điểm yếu đó. [Tối thiểu 150 từ]`,
      source: 'extra_data.essay.essay[5].answer',
      component: TextInput,
    },
    {
      label: `Please share your own strengths and weaknesses with specific examples 
              related to those strengths and weaknesses. [Minimum 150 words]`,
      source: 'extra_data_en.essay.essay[5].answer',
      component: TextInput,
    },
    {
      label: `Viết về một quyển sách mà em thích nhất. [Tối thiểu 150 từ]`,
      source: 'extra_data.essay.essay[6].answer',
      component: TextInput,
    },
    {
      label: `Write about a book you like best. [Minimum 150 words]`,
      source: 'extra_data_en.essay.essay[6].answer',
      component: TextInput,
    },
    {
      label: `Hãy chia sẻ về giai đoạn khó khăn nhất trong cuộc sống của em
              và làm cách nào để em vượt qua được giai đoạn đó. [Tối thiểu 150 từ]`,
      source: 'extra_data.essay.essay[7].answer',
      component: TextInput,
    },
    {
      label: `Share about the most difficult period in your life 
              and how you got through it. [Minimum 150 words]`,
      source: 'extra_data_en.essay.essay[7].answer',
      component: TextInput,
    },
  ]
}

export { personalStoryFieldsByGeneration }