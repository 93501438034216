import React, { useEffect } from 'react'
import {
  Create, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput,
  TopToolbar, ListButton, email as emailValidator
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';


import renderInputFields from '../../../helpers/renderInputFields'
import normalizeString from '../../../helpers/normalizeString'


const UsernameInput = (props) => {
  const { source } = props
  const form = useForm()
  const formState = useFormState()

  useEffect(() => {
    let { first_name = '', last_name = ''} = formState.values
    first_name = first_name.toLowerCase().split(' ').join('')
    last_name = last_name.toLowerCase().split(' ').join('')

    const username = normalizeString(first_name + '.' + last_name)

    form.change(source, username)
  }, [formState.values.first_name, formState.values.last_name])

  return (
    <TextInput
      {...props}
    />
  );
};


const userCreateFields = [
  {
    label: 'First name', source: 'first_name', component: TextInput, required: true,
  },
  {
    label: 'Last name', source: 'last_name', component: TextInput, required: true,
  },
  {
    label: 'Email', source: 'email', component: TextInput, required: true, validate: [emailValidator()]
  },
  {
    label: 'Username', source: 'username', component: UsernameInput, required: true,
  },
  {
    label: 'Phone', source: 'phone', component: TextInput,
  },
  {
    label: 'Roles', source: 'role_ids', required: true,
    component: (props) =>
      <ReferenceArrayInput {...props} reference="user-role">
        <SelectArrayInput optionText="role_name" />
      </ReferenceArrayInput>
  },
]

const UserCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const UserCreate = (props) => {
  return (
    <Create
      actions={<UserCreateActions/>}
      {...props}
    >
      <SimpleForm variant='outlined'>
        {
          renderInputFields(userCreateFields)
        }
      </SimpleForm>
    </Create>
  );
}

export default UserCreate