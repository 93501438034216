import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  useShowController,
  ReferenceField,
  ShowButton,
  EditButton,
} from 'react-admin';
import StudentField from './StudentField'
import MentorField from './MentorField'
import SetStatusButtons from './SetStatusButtons'
import DatetimeInfo from './DatetimeInfo'


const MentorMatchingRequestExpand = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();

  if (!record) return null;
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Student
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Mentor
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} container alignContent="flex-start">
            <ReferenceField
              resource="mentor-matching-request"
              reference="student"
              source="student_id"
              basePath="/student"
              record={record}
              link={false}
            >
              <StudentField />
            </ReferenceField>
          </Grid>
          <Grid item xs={6} container alignContent="flex-start">
            <ReferenceField
              resource="mentor-matching-request"
              reference="mentor"
              source="mentor_id"
              basePath="/mentor"
              record={record}
              link={false}
            >
              <MentorField />
            </ReferenceField>
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        <SetStatusButtons record={record}/>
        <div className={classes.spacer}>&nbsp;</div>
        <DatetimeInfo record={record}/>
      </CardContent>
      <CardActions>
        <ShowButton record={record} basePath={"/mentor-matching-request"}/>
        <EditButton record={record} basePath={"/mentor-matching-request"}/>
      </CardActions>
    </Card>
  );
};

export default MentorMatchingRequestExpand;

const useStyles = makeStyles({
  root: { width: 800, margin: 'auto' },
  spacer: { height: 20 },
});
