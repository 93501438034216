import React from 'react'
import {
  FormTab, useGetList,
  SimpleShowLayout, SelectField, TextField, NumberField,
} from 'react-admin'

import AssessmentShowTable from './AssessmentShowTable'

const AssessorFieldSection = (props) => {
  const { record, assessor, assessorIndex, assessorList } = props

  return (
    <SimpleShowLayout {...props} record={record}>
      <SelectField 
        source={`assessor_${assessorIndex}`}
        choices={assessorList}
        label={`Assessor ${assessorIndex}`}
      />
      <NumberField
        source={`${assessor}.assessment_score`}
        label='Overall score'
      />
      <NumberField
        source={`${assessor}.assessment_detail.total_score`}
        label='Total score'
      />
      <TextField
        source={`${assessor}.assessment_detail.note`}
        label='Note'
      />
    </SimpleShowLayout>
  )
}

const AssessmentEditTab = (props) => {
  const { data: assessorListObject = {} } = useGetList('student-application-assessor')
  const assessorList = Object.keys(assessorListObject).map(key => assessorListObject[key])

  return (
    <FormTab label='Assessment' {...props}>
      <div>
        <AssessorFieldSection assessorIndex={1} assessorList={assessorList} assessor={'assessor_1_data'} {...props} />
        <AssessorFieldSection assessorIndex={2} assessorList={assessorList} assessor={'assessor_2_data'} {...props} />
        <AssessorFieldSection assessorIndex={3} assessorList={assessorList} assessor={'assessor_3_data'} {...props} />
      </div>
      <AssessmentShowTable assessorList={assessorList} {...props}/>
    </FormTab>
  );
}

export default AssessmentEditTab