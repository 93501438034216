import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { NumberField, SelectField, SimpleShowLayout } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

import colors from '../../../../../constants/colors'

const styles = (theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40
  },
  question: {
    lineHeight: 2
  },
  item: {
    border: 'solid #c8c8c8 1px'
  },
  title: {
    backgroundColor: colors.primaryDark,
    color: '#FFF',
    fontWeight: 'bold',
    padding: 20,
  },
}))

const annuallyIncomeOptions = [
  { name: '', id: 0 },
  { name: 'Ít hơn 5.000.000 VNĐ', id: 1 },
  { name: 'Từ 5.000.000 VNĐ đến dưới 10.000.000 VNĐ', id: 2 },
  { name: 'Từ 10.000.000 VNĐ đến dưới 20.000.000 VNĐ', id: 3 },
  { name: 'Từ 20.000.000 VNĐ đến dưới 30.000.000 VNĐ', id: 4 },
  { name: 'Từ 30.000.000 VNĐ đến dưới 40.000.000 VNĐ', id: 5 },
  { name: 'Từ 40.000.000 VNĐ đến dưới 50.000.000 VNĐ', id: 6 },
  { name: 'Trên 50.000.000 VNĐ (vui lòng ghi rõ số tiền)', id: 7 },
];

const tuitionSources = [
  { label: 'Tiền chu cấp của bố mẹ (VND)', key: 'parent_support' },
  { label: 'Tiền chu cấp của họ hàng (VND)', key: 'relatives_support' },
  { label: 'Các khoản khác (Vui lòng ghi rõ) (VND)', key: 'other_support' },
]

const GridShowLayout = (props) => {
  const { record, children, ...others } = props
  return (
    <Grid {...others}>
      <SimpleShowLayout record={record}>
        {children}
      </SimpleShowLayout>
    </Grid>
  )
}

const FinanceInfo = (props) => {
  const { classes, record } = props;
  return (
    <Grid container spacing={4} className={classes.container}>
      <Grid item className={classes.title} xs={12}>Thu nhập của gia đình</Grid>
      <Grid container item spacing={4}>
        <Grid item xs={5} className={`${classes.question} ${classes.item}`}>
          Tổng thu nhập HÀNG THÁNG và HÀNG NĂM của cả gia đình em từ tất
          cả các nguồn, bao gồm cả bố mẹ kế (nếu có) và không tính thu nhập do em làm ra
        </Grid>
        <Grid item xs={7} className={classes.item}>
          <GridShowLayout record={record}>
            <NumberField
              label='Tổng thu nhập hàng tháng của cả gia đình (VND)'
              source='finance_info.family_income.monthly_income'
              options={{ style: 'currency', currency: 'VND' }}
            />
          </GridShowLayout>
          <GridShowLayout record={record}>
            <SelectField
              source='finance_info.family_income.yearly_rank'
              label='Tổng thu nhập hàng năm của cả gia đình'
              choices={annuallyIncomeOptions}
            />
          </GridShowLayout>
          {(get(record, 'finance_info.family_income.yearly_rank', 0) === 7) &&
            <GridShowLayout record={record}>
              <NumberField
                label='Tổng thu nhập hàng năm (VND): '
                source='finance_info.family_income.yearly_income'
                options={{ style: 'currency', currency: 'VND' }}
              />
            </GridShowLayout>
          }
        </Grid>
      </Grid>
      <Grid container item spacing={4}>
        <Grid item xs={5} className={`${classes.question} ${classes.item}`}>
          Nếu đậu đại học, tiền chu cấp hàng
          tháng của em lấy từ những nguồn nào?
          (ước tính)
        </Grid>
        <Grid container item xs={7} className={classes.item}>
          {
            tuitionSources.map((source, index) => {
              const { label, key } = source;
              return (
                <GridShowLayout record={record} container item xs={12} key={key}>
                  <NumberField
                    label={label}
                    source={`finance_info.finance_supports[${index}].amount`}
                    options={{ style: 'currency', currency: 'VND' }}
                  />
                </GridShowLayout>
              )
            })
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(FinanceInfo)