import React from 'react'
import {
  FormTab
} from 'react-admin'

import MatchingRequestHistory from './MatchingRequestHistory'

const MatchingRequestHistoryTab = (props) => {
  const { record } = props
  return (
    <FormTab {...props} label="Matching request history">
      {
        record ? 
          <MatchingRequestHistory mentor_id={record?.mentor_id} student_id={record?.student_id}/>
        : null
      }
    </FormTab>
  )
}

export default MatchingRequestHistoryTab
