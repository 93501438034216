import { fetchUtils, HttpError } from 'react-admin'

import authProvider from './authProvider'
import { getErrorMessage } from '../helpers/handleResponseAlert'
import { ResponseCode, backendURL } from '../constants/constants';
import { get } from 'lodash'

const httpClient = (url, options = {}) => {
  if (url[0] === '/') {
    url = backendURL + url
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  options.headers.set('X-VS-CLIENT-TYPE', `1001`);

  const access_token = localStorage.getItem('access_token');
  options.headers.set('X-VS-ACCESS-TOKEN', access_token);

  return fetchUtils.fetchJson(url, options)
    .then((response) => {
      // valid response => return the response
      // return response
      const result = get(response, 'json.result', null);

      if (result === ResponseCode.SUCCESS) {
        return response
      } else if (result === ResponseCode.ERROR_UNAUTHORIZED) {
        // catch access_token expire here => refresh token
        return authProvider.refreshToken()
        .then(response => {
          // resend request with new access token
          const new_access_token = localStorage.getItem('access_token')
          options.headers.set('X-VS-ACCESS-TOKEN', new_access_token);
          return fetchUtils.fetchJson(url, options)
        })
        .catch(error => {
          // session time out => log out
          authProvider.logout()
          window.location = '/'
          throw new Error(error)
        })
      }

      throw new Error(result)
    })
    .catch((error) => {
      throw new HttpError(
        getErrorMessage(error),
        400, // status
        error
      )
    })
};

export default httpClient