import ArticleIcon from '@material-ui/icons/Print';
import ArticleList from './ArticleList';
import ArticleEdit from './Edit/index';
import ArticleCreate from './Create/index';

export default {
  list: ArticleList,
  create: ArticleCreate,
  edit: ArticleEdit,

  icon: ArticleIcon,
}