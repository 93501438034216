const required = (message = 'Required') => (value) => {
  if (typeof value === "number") { // validate for number field > 0
    return value && value > 0 ? undefined : message
  } else {
    return value ? undefined : message;
  }
}

const minLengthWords = (minWords = 1, message = `Min ${minWords} words required`) => (value) => {
  if (typeof value !== "string") {
    return message
  }
  let arr = value.split(" ")
  return (arr.length >= minWords) ? undefined : message
}

export {
  required,
  minLengthWords
}