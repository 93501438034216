import { get, cloneDeep, isEqual } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const studentApplicationAssessorDataProvider = {
  getList: (params) => {
    const getStudentApplicationAssessorsUrl = `/admin/get_student_application_assessors`;

    return httpClient(getStudentApplicationAssessorsUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const assessors = get(json, 'reply.assessors', []).map(assessor => {
        return {
          ...assessor,
          name: assessor.display_name || assessor.email, // map field for displaying choices
        }
      })
      return {
        data: assessors,
        total: assessors.length
      }
    })
  },

  getOne: (params) => {
    
  },

  getMany: (params) => {
    const getStudentApplicationAssessorsUrl = `/admin/get_student_application_assessors`;

    return httpClient(getStudentApplicationAssessorsUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const assessors = get(json, 'reply.assessors', []).map(assessor => {
        return {
          ...assessor,
          name: assessor.display_name || assessor.email, // map field for displaying choices
        }
      })
      return {
        data: assessors,
      }
    })
  },

  update: async (params) => {
    const { id, data, previousData } = params

    if (!isEqual(data.assessor_1, previousData.assessor_1) 
      || !isEqual(data.assessor_2, previousData.assessor_2)
      || !isEqual(data.assessor_3, previousData.assessor_3)
    ) {
      await studentApplicationAssessorDataProvider.updateMany({
        ids: [id],
        data: {
          assessor_1: data.assessor_1,
          assessor_2: data.assessor_2,
          assessor_3: data.assessor_3,
        }
      })
    }

    if (!isEqual(data.my_assessment, previousData.my_assessment)) {
      await studentApplicationAssessorDataProvider.setStudentApplicationAssessorResult({ id, data: data.my_assessment })
    }
  },

  updateMany: async (params) => {
    const { ids, data } = params

    const assignStudentApplicationAssessorsUrl = `/admin/assign_student_application_assessors`;

    let assessorsData = cloneDeep(data)
    if (!assessorsData.assessor_1) delete assessorsData.assessor_1
    if (!assessorsData.assessor_2) delete assessorsData.assessor_2
    if (!assessorsData.assessor_3) delete assessorsData.assessor_3

    return httpClient(assignStudentApplicationAssessorsUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids,
        ...assessorsData,
      })
    }).then(({ json }) => {
      const result = get(json, 'result', null)
      if (result === ResponseCode.SUCCESS) {
        return {
          data: ids
        }
      } else {
        throw new Error(result)
      }
    })
  },

  setStudentApplicationAssessorResult: async ({ id, data}) => {
    const setStudentApplicationAssessorResultUrl = `/admin/set_student_application_assessment_result`;
      
    const assessment_score = get(data, 'assessment_score', 0)
    const assessment_detail = get(data, 'assessment_detail', {})

    return httpClient(setStudentApplicationAssessorResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        assessment_result: {
          assessment_score,
          assessment_detail,
        }
      })
    })
  },
};

export default studentApplicationAssessorDataProvider
