import UserDataProvider from './UserDataProvider'
import UserRoleDataProvider from './UserRoleDataProvider'

import GeneralDataProvider from './GeneralDataProvider'
import ProvinceDataProvider from './ProvinceDataProvider'
import NationDataProvider from './NationDataProvider'
import UniversityDataProvider from './UniversityDataProvider'
import OccupationTagDataProvider from './OccupationTagDataProvider'

import ArticleCategoryDataProvider from './ArticleCategoryDataProvider'
import ArticleDataProvider from './ArticleDataProvider'

import StudentDataProvider from './StudentDataProvider'

import StudentApplicationDataProvider from './StudentApplicationDataProvider'
import StudentApplicationReviewerDataProvider from './StudentApplicationReviewerDataProvider'
import StudentApplicationInterviewerDataProvider from './StudentApplicationInterviewerDataProvider'
import StudentApplicationAssessorDataProvider from './StudentApplicationAssessorDataProvider'

import MentorApplicationDataProvider from './MentorApplicationDataProvider'
import MentorApplicationReviewerDataProvider from './MentorApplicationReviewerDataProvider'
import MentorApplicationInterviewerDataProvider from './MentorApplicationInterviewerDataProvider'

import SponsorInvitationDataProvider from './SponsorInvitationDataProvider'
import SponsorTransactionDataProvider from './SponsorTransactionDataProvider'
import SponsorRegistrationDataProvider from './SponsorRegistrationDataProvider'

import ContactRequestDataProvider from './ContactRequestDataProvider'

import MentorDataProvider from './MentorDataProvider'
import MentorMatchingRequestDataProvider from './MentorMatchingRequestDataProvider'

const mapDataProvider = {
  getList: {
    'user': UserDataProvider.getList,
    'user-role': UserRoleDataProvider.getList,

    'province': ProvinceDataProvider.getList,
    'nation': NationDataProvider.getList,
    'university': UniversityDataProvider.getList,
    'occupation-tag': OccupationTagDataProvider.getList,

    'article-category': ArticleCategoryDataProvider.getList,
    'article': ArticleDataProvider.getList,

    'student': StudentDataProvider.getList,

    'student-application': StudentApplicationDataProvider.getList,
    'student-application-reviewer': StudentApplicationReviewerDataProvider.getList,
    'student-application-interviewer': StudentApplicationInterviewerDataProvider.getList,
    'student-application-assessor': StudentApplicationAssessorDataProvider.getList,

    'mentor-application': MentorApplicationDataProvider.getList,
    'mentor-application-reviewer': MentorApplicationReviewerDataProvider.getList,
    'mentor-application-interviewer': MentorApplicationInterviewerDataProvider.getList,

    'sponsor-invitation': SponsorInvitationDataProvider.getList,
    'sponsor-transaction': SponsorTransactionDataProvider.getList,
    'sponsor-registration': SponsorRegistrationDataProvider.getList,

    'contact-request': ContactRequestDataProvider.getList,

    'mentor': MentorDataProvider.getList,
    'mentor-matching-request': MentorMatchingRequestDataProvider.getList,
  },
  getOne: {
    'user': UserDataProvider.getOne,
    'user-role': UserRoleDataProvider.getOne,
    
    'general': GeneralDataProvider.getOne,
    'university': UniversityDataProvider.getOne,
    'occupation-tag': OccupationTagDataProvider.getOne,

    'article-category': ArticleCategoryDataProvider.getOne,
    'article': ArticleDataProvider.getOne,
  
    'student': StudentDataProvider.getOne,

    'student-application': StudentApplicationDataProvider.getOne,
    
    'mentor-application': MentorApplicationDataProvider.getOne,

    'sponsor-invitation': SponsorInvitationDataProvider.getOne,
    'sponsor-transaction': SponsorTransactionDataProvider.getOne,
    'sponsor-registration': SponsorRegistrationDataProvider.getOne,

    'contact-request': ContactRequestDataProvider.getOne,

    'mentor': MentorDataProvider.getOne,
    'mentor-matching-request': MentorMatchingRequestDataProvider.getOne,
  },
  getMany: {
    'user': UserDataProvider.getMany,
    'user-role': UserRoleDataProvider.getMany,

    'province': ProvinceDataProvider.getMany,
    'nation': NationDataProvider.getMany,
    'university': UniversityDataProvider.getMany,
    'occupation-tag': OccupationTagDataProvider.getMany,

    'article-category': ArticleCategoryDataProvider.getMany,
    'article': ArticleDataProvider.getMany,

    'student': StudentDataProvider.getMany,

    'student-application': StudentApplicationDataProvider.getMany,
    'student-application-reviewer': StudentApplicationReviewerDataProvider.getMany,
    'student-application-interviewer': StudentApplicationInterviewerDataProvider.getMany,
    'student-application-assessor': StudentApplicationAssessorDataProvider.getMany,

    'mentor-application': MentorApplicationDataProvider.getMany,
    'mentor-application-reviewer': MentorApplicationReviewerDataProvider.getMany,
    'mentor-application-interviewer': MentorApplicationInterviewerDataProvider.getMany,

    'sponsor-invitation': SponsorInvitationDataProvider.getMany,
    'sponsor-transaction': SponsorTransactionDataProvider.getMany,

    'contact-request': ContactRequestDataProvider.getMany,

    'mentor': MentorDataProvider.getMany,
    'mentor-matching-request': MentorMatchingRequestDataProvider.getMany,
  },
  update: {
    'user': UserDataProvider.update,
  
    'university': UniversityDataProvider.update,
    'occupation-tag': OccupationTagDataProvider.update,

    'article': ArticleDataProvider.update,

    'student': StudentDataProvider.update,
  
    'student-application': StudentApplicationDataProvider.update,

    'mentor-application': MentorApplicationDataProvider.update,

    'sponsor-invitation': SponsorInvitationDataProvider.update,
    'sponsor-transaction': SponsorTransactionDataProvider.update,
    'sponsor-registration': SponsorRegistrationDataProvider.update,

    'contact-request': ContactRequestDataProvider.update,

    'mentor': MentorDataProvider.update,
    'mentor-matching-request': MentorMatchingRequestDataProvider.update,
  },
  updateMany: {
    'student-application-reviewer': StudentApplicationReviewerDataProvider.updateMany,
    'student-application-interviewer': StudentApplicationInterviewerDataProvider.updateMany,
    'student-application-assessor': StudentApplicationAssessorDataProvider.updateMany,

    'mentor-application-reviewer': MentorApplicationReviewerDataProvider.updateMany,
    'mentor-application-interviewer': MentorApplicationInterviewerDataProvider.updateMany,
  },
  create: {
    'user': UserDataProvider.create,

    'university': UniversityDataProvider.create,
    'occupation-tag': OccupationTagDataProvider.create,

    'article': ArticleDataProvider.create,

    'sponsor-invitation': SponsorInvitationDataProvider.create,
    'sponsor-transaction': SponsorTransactionDataProvider.create,

    'mentor-matching-request': MentorMatchingRequestDataProvider.create,
  }
}

const dataProvider = {
  getList: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.getList[resource](params)
      .then((result) => {
        return resolve(result)
      })
      .catch((error) => {
        return reject(error)
      })
    })
  },

  getOne: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.getOne[resource](params)
      .then((result) => {
        return resolve(result)
      })
      .catch(error => {
        return reject(error)
      })
    })
  },

  getMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.getMany[resource](params)
        .then((result) => {
          return resolve(result)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

  update: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.update[resource](params)
        .then((result) => {
          return resolve(result)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

  updateMany: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.updateMany[resource](params)
        .then((result) => {
          return resolve(result)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

  create: (resource, params) => {
    return new Promise((resolve, reject) => {
      return mapDataProvider.create[resource](params)
        .then((result) => {
          return resolve(result)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

  // getManyReference: (resource, params) => {
  //   const { page, perPage } = params.pagination;
  //   const { field, order } = params.sort;
  //   const query = {
  //     sort: JSON.stringify([field, order]),
  //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
  //     filter: JSON.stringify({
  //       ...params.filter,
  //       [params.target]: params.id,
  //     }),
  //   };
  //   const url = `${apiUrl}/${resource}?${stringify(query)}`;

  //   return httpClient(url).then(({ headers, json }) => ({
  //     data: json,
  //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
  //   }));
  // },

  // delete: (resource, params) =>
  //   httpClient(`${apiUrl}/${resource}/${params.id}`, {
  //     method: 'DELETE',
  //   }).then(({ json }) => ({ data: json })),

  // deleteMany: (resource, params) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params.ids }),
  //   };
  //   return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
  //     method: 'DELETE',
  //     body: JSON.stringify(params.data),
  //   }).then(({ json }) => ({ data: json }));
  // }
};

export default dataProvider
