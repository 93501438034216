import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FunctionField } from 'react-admin'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import DocumentPreview from '../../../../../Components/DocumentPreview'

const useStyles = makeStyles((theme) => ({
  functionField: {
    '& >div': {
      width: '100%'
    }
  }
}));

const RecommendationLetterSection = (props) => {
  const { record, ...other } = props
  const classes = useStyles()

  return (
    <SectionWrapper title='J. Thư giới thiệu' record={record} {...other}>
      <FunctionField
        className={classes.functionField}
        label='Thư giới thiệu'
        source='recommendation_letter.reference_url'
        render={record => <DocumentPreview fileUrl={get(record, 'recommendation_letter.reference_url', '')} />}
      />
    </SectionWrapper>
  )
}

export default RecommendationLetterSection