import React from 'react'
import {
  TextInput,
  required as requiredValidator,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import FromF10PersonalStory from './FromF10PersonalStory'

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& > div': {
      lineHeight: 2,
    }
  }
}));

const OtherFields = [
  { 
    label_en: 'Family story', label_vi: 'Câu chuyện gia đình', source: 'family.family_story', 
    component: TextInput, required: true, 
    multiline: true, rows: 5,
  },
  {
    label_en: 'Essay', label_vi: 'Câu chuyện bản thân', source: 'essay.essay',
    component: TextInput, required: true,
    multiline: true, rows: 5,
  },
]

const PersonalStorySection = (props) => {
  const { record, ...otherProps } = props
  const classes = useStyles()

  const generation = get(record, 'vietseeds_generation', 0)

  if (generation < 10) {
    return (
      <SectionWrapper title='C. Other' {...otherProps}>
        {
          OtherFields.map(field => {
            const { label_en, label_vi, source, component: Component, required, ...others } = field
            return (
              <div key={`extra_data.other.${source}`}>
                <Component
                  record={record} source={`extra_data.${source}`} label={label_vi}
                  variant='outlined'
                  required={required}
                  {...required ? { validate: requiredValidator() } : {}}
                  className={classes.textInput}
                  fullWidth
                  {...others}
                />
                <Component
                  record={record} source={`extra_data_en.${source}`} label={label_en}
                  variant='outlined'
                  // TODO: temporarily not requiring english fields
                  // required={required}
                  // {...required ? { validate: requiredValidator() } : {}}
                  className={classes.textInput}
                  fullWidth
                  {...others}
                />
              </div>
            )
          })
        }

      </SectionWrapper>
    )
  }
  return (
    <FromF10PersonalStory {...props}/>
  )
};

export default PersonalStorySection