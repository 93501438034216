import React from 'react'
import {
  List, Datagrid,
  TextField, SelectField, ReferenceArrayField,
  SingleFieldList, ChipField, EmailField, Pagination, NumberField,
} from 'react-admin'
import { makeStyles }  from '@material-ui/core/styles'

import MentorFilter from './components/MentorFilter'

import colors from '../../../constants/colors'

import { RegionChoices, GenderChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        '& td:nth-of-type(-n+4)': {
          position: 'sticky',
          background: 'white',
          zIndex: 100
        },
        '& th:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 200
        },
        '& th': {
          background: colors.primaryLight,
          fontWeight: 'bold'
        },
        '& td:nth-of-type(1), th:nth-of-type(1)': { // Checkbox
          '& span': {
            width: 20,
          },
          left: 0,
        },
        '& .column-id, th:nth-of-type(2)': { // STT
          '& span': {
            width: 20
          },
          left: 48,
        },
        '& td:nth-of-type(3), th:nth-of-type(3)': { // Khu vực
          '& span': {
            width: 40
          },
          left: 108
        },
        '& .column-display_name, th:nth-of-type(4)': { // Họ và tên
          '& span': {
            width: 200
          },
          left: 188,
        },
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const mentorListShowFields = [
  {
    label: 'STT', source: 'id', component: TextField,
  },
  {
    label: 'Khu vực', source: 'vietseeds_region', component: SelectField,
    choices: RegionChoices,
  },
  { label: 'Họ và tên', source: 'display_name', component: TextField },
  { 
    label: 'Giới tính', source: 'gender', component: SelectField,
    choices: GenderChoices,
  },
  { label: 'Công việc', source: 'job_title', component: TextField, },
  { label: 'Công ty', source: 'company', component: TextField, },
  { 
    label: 'Ngành nghề', source: 'occupation_tag_ids',
    component: (props) =>
      <ReferenceArrayField {...props} reference="occupation-tag">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
  },
  { label: '# mentees', source: 'number_of_mentees', component: NumberField, },
  { label: 'Email', source: 'email', component: EmailField, },
  { label: 'Phone', source: 'phone', component: TextField, },
]

const MentorPagination = props => {
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  )
}

const MentorList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Mentor List'}
      filters={<MentorFilter/>}
      pagination={<MentorPagination className={classes.pagination}/>}
      perPage={100}
    >
      <Datagrid rowClick='edit' className={classes.table}>
        {
          mentorListShowFields.map((field, id) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={source} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default MentorList