import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { cloneDeep, get } from 'lodash'

import { GenderChoices, RegionChoices, frontendURL, } from '../../../constants/constants'
import { ReviewResultChoices, InterviewResultChoices } from '../constants'
import mapChoicesToValue from '../../../helpers/mapChoicesToValue'

const getTotalScore = (scoreData) => {
  const scoreboard = get(scoreData, 'scoreboard', [])
  return scoreboard.reduce((total, row) => {
    const score = get(row, 'section_summary.score', 0)
    return total + score
  }, 0)
}

const exporter = async (records, fetchRelatedRecords) => {
  const address = records.map(record => record.personal_info.address)
  // TODO: merge to improve performance
  const reviewers_1 = await fetchRelatedRecords(records, 'reviewer_1', 'student-application-reviewer')
  const reviewers_2 = await fetchRelatedRecords(records, 'reviewer_2', 'student-application-reviewer')
  const interviewers_1 = await fetchRelatedRecords(records, 'interviewer_1', 'student-application-interviewer')
  const interviewers_2 = await fetchRelatedRecords(records, 'interviewer_2', 'student-application-interviewer')
  const assessors_1 = await fetchRelatedRecords(records, 'assessor_1', 'student-application-assessor')
  const assessors_2 = await fetchRelatedRecords(records, 'assessor_2', 'student-application-assessor')
  const assessors_3 = await fetchRelatedRecords(records, 'assessor_3', 'student-application-assessor')
  const provinces = await fetchRelatedRecords(address, 'province_id', 'province')

  let applicationsForExport = cloneDeep(records).map(application => {
    const {
      id, region, display_name, email,
      personal_info: {
        phone_number,
        address: { province_id },
        gender: { gender_type },
        facebook_url,
      },
      reviewer_1: reviewer_1_id,
      reviewer_1_data: { review_result: reviewer_1_result },
      reviewer_2: reviewer_2_id,
      reviewer_2_data: { review_result: reviewer_2_result },
      review_status,

      interviewer_1: interviewer_1_id,
      interviewer_1_data,
      interviewer_2: interviewer_2_id,
      interviewer_2_data,
      interview_status,

      assessor_1: assessor_1_id,
      assessor_1_data,
      assessor_2: assessor_2_id,
      assessor_2_data,
      assessor_3: assessor_3_id,
      assessor_3_data,
    } = application;

    return {
      id, 
      region: mapChoicesToValue(RegionChoices, region), 
      province: get(provinces, `[${province_id}].name`, ''),
      display_name, 
      email,
      gender: mapChoicesToValue(GenderChoices, gender_type),
      phone_number: phone_number[0].phone,
      facebook_url,
      url: `${frontendURL}/student-application/${id}`,

      reviewer_1: get(reviewers_1, `[${reviewer_1_id}].name`, ''),
      reviewer_1_result: mapChoicesToValue(ReviewResultChoices, reviewer_1_result),
      reviewer_2: get(reviewers_2, `[${reviewer_2_id}].name`, ''),
      reviewer_2_result: mapChoicesToValue(ReviewResultChoices, reviewer_2_result),
      final_review_result: mapChoicesToValue(ReviewResultChoices, review_status),

      interviewer_1: get(interviewers_1, `[${interviewer_1_id}].name`, ''),
      interviewer_1_result: mapChoicesToValue(InterviewResultChoices, get(interviewer_1_data, 'interview_result', '')),
      interviewer_1_score: getTotalScore(interviewer_1_data),
      interviewer_1_overall_feeling: get(interviewer_1_data, 'overall_feeling', ''),
      interviewer_2: get(interviewers_2, `[${interviewer_2_id}].name`, ''),
      interviewer_2_result: mapChoicesToValue(InterviewResultChoices, get(interviewer_2_data, 'interview_result', '')),
      interviewer_2_score: getTotalScore(interviewer_2_data),
      interviewer_2_overall_feeling: get(interviewer_2_data, 'overall_feeling', ''),
      final_interview_result: mapChoicesToValue(InterviewResultChoices, interview_status),


      assessor_1: get(assessors_1, `[${assessor_1_id}].name`, ''),
      score_1_introduction_0: get(assessor_1_data, 'assessment_detail.scoreboard[0].rows[0].score', 0),
      score_1_introduction_1: get(assessor_1_data, 'assessment_detail.scoreboard[0].rows[1].score', 0),
      score_1_presentation_0: get(assessor_1_data, 'assessment_detail.scoreboard[1].rows[0].score', 0),
      score_1_presentation_1: get(assessor_1_data, 'assessment_detail.scoreboard[1].rows[1].score', 0),
      score_1_presentation_2: get(assessor_1_data, 'assessment_detail.scoreboard[1].rows[2].score', 0),
      score_1_presentation_3: get(assessor_1_data, 'assessment_detail.scoreboard[1].rows[3].score', 0),
      score_1_presentation_4: get(assessor_1_data, 'assessment_detail.scoreboard[1].rows[4].score', 0),
      score_1_teamwork_0: get(assessor_1_data, 'assessment_detail.scoreboard[2].rows[0].score', 0),
      score_1_teamwork_1: get(assessor_1_data, 'assessment_detail.scoreboard[2].rows[1].score', 0),
      score_1_teamwork_2: get(assessor_1_data, 'assessment_detail.scoreboard[2].rows[2].score', 0),
      score_1_teamwork_3: get(assessor_1_data, 'assessment_detail.scoreboard[2].rows[3].score', 0),
      assessment_total_score_1: get(assessor_1_data, 'assessment_detail.total_score', 0),
      assessment_score_1: get(assessor_1_data, 'assessment_score', 0),
      assessment_note_1: get(assessor_1_data, 'assessment_detail.note', ''),

      assessor_2: get(assessors_2, `[${assessor_2_id}].name`, ''),
      score_2_introduction_0: get(assessor_2_data, 'assessment_detail.scoreboard[0].rows[0].score', 0),
      score_2_introduction_1: get(assessor_2_data, 'assessment_detail.scoreboard[0].rows[1].score', 0),
      score_2_presentation_0: get(assessor_2_data, 'assessment_detail.scoreboard[1].rows[0].score', 0),
      score_2_presentation_1: get(assessor_2_data, 'assessment_detail.scoreboard[1].rows[1].score', 0),
      score_2_presentation_2: get(assessor_2_data, 'assessment_detail.scoreboard[1].rows[2].score', 0),
      score_2_presentation_3: get(assessor_2_data, 'assessment_detail.scoreboard[1].rows[3].score', 0),
      score_2_presentation_4: get(assessor_2_data, 'assessment_detail.scoreboard[1].rows[4].score', 0),
      score_2_teamwork_0: get(assessor_2_data, 'assessment_detail.scoreboard[2].rows[0].score', 0),
      score_2_teamwork_1: get(assessor_2_data, 'assessment_detail.scoreboard[2].rows[1].score', 0),
      score_2_teamwork_2: get(assessor_2_data, 'assessment_detail.scoreboard[2].rows[2].score', 0),
      score_2_teamwork_3: get(assessor_2_data, 'assessment_detail.scoreboard[2].rows[3].score', 0),
      assessment_total_score_2: get(assessor_2_data, 'assessment_detail.total_score', 0),
      assessment_score_2: get(assessor_2_data, 'assessment_score', 0),
      assessment_note_2: get(assessor_2_data, 'assessment_detail.note', ''),

      assessor_3: get(assessors_3, `[${assessor_3_id}].name`, ''),
      score_3_introduction_0: get(assessor_3_data, 'assessment_detail.scoreboard[0].rows[0].score', 0),
      score_3_introduction_1: get(assessor_3_data, 'assessment_detail.scoreboard[0].rows[1].score', 0),
      score_3_presentation_0: get(assessor_3_data, 'assessment_detail.scoreboard[1].rows[0].score', 0),
      score_3_presentation_1: get(assessor_3_data, 'assessment_detail.scoreboard[1].rows[1].score', 0),
      score_3_presentation_2: get(assessor_3_data, 'assessment_detail.scoreboard[1].rows[2].score', 0),
      score_3_presentation_3: get(assessor_3_data, 'assessment_detail.scoreboard[1].rows[3].score', 0),
      score_3_presentation_4: get(assessor_3_data, 'assessment_detail.scoreboard[1].rows[4].score', 0),
      score_3_teamwork_0: get(assessor_3_data, 'assessment_detail.scoreboard[2].rows[0].score', 0),
      score_3_teamwork_1: get(assessor_3_data, 'assessment_detail.scoreboard[2].rows[1].score', 0),
      score_3_teamwork_2: get(assessor_3_data, 'assessment_detail.scoreboard[2].rows[2].score', 0),
      score_3_teamwork_3: get(assessor_3_data, 'assessment_detail.scoreboard[2].rows[3].score', 0),
      assessment_total_score_3: get(assessor_3_data, 'assessment_detail.total_score', 0),
      assessment_score_3: get(assessor_3_data, 'assessment_score', 0),
      assessment_note_3: get(assessor_3_data, 'assessment_detail.note', ''),

    }
  })

  jsonExport(applicationsForExport, {
    header: ['id', 'region', 'province', 'display_name', 'email','gender', 'phone_number',
      'facebook_url', 'url',
      'reviewer_1', 'reviewer_1_result', 
      'reviewer_2', 'reviewer_2_result', 'final_review_result',
      'interviewer_1', 'interviewer_1_result', 'interviewer_1_score', 'interviewer_1_overall_feeling',
      'interviewer_2', 'interviewer_2_result', 'interviewer_2_score', 'interviewer_2_overall_feeling', 'final_interview_result',

      'assessor_1', 'score_1_introduction_0', 'score_1_introduction_1', 'score_1_presentation_0', 'score_1_presentation_1', 'score_1_presentation_2', 'score_1_presentation_3', 'score_1_presentation_4', 'score_1_teamwork_0', 'score_1_teamwork_1', 'score_1_teamwork_2', 'score_1_teamwork_3', 'assessment_total_score_1', 'assessment_score_1', 'assessment_note_1',
      'assessor_2', 'score_2_introduction_0', 'score_2_introduction_1', 'score_2_presentation_0', 'score_2_presentation_1', 'score_2_presentation_2', 'score_2_presentation_3', 'score_1_presentation_4', 'score_2_teamwork_0', 'score_2_teamwork_1', 'score_2_teamwork_2', 'score_2_teamwork_3', 'assessment_total_score_2', 'assessment_score_2', 'assessment_note_2',
      'assessor_3', 'score_3_introduction_0', 'score_3_introduction_1', 'score_3_presentation_0', 'score_3_presentation_1', 'score_3_presentation_2', 'score_3_presentation_3', 'score_1_presentation_4', 'score_3_teamwork_0', 'score_3_teamwork_1', 'score_3_teamwork_2', 'score_3_teamwork_3', 'assessment_total_score_3', 'assessment_score_3', 'assessment_note_3',
    ],
    rename: ['id', 'Region', 'Tỉnh/Thành', 'Full name', 'Email', 'Gender', 'Phone', 
      'Facebook', 'URL',
      'Reviewer 1', 'Reviewer 1 result', 
      'Reviewer 2', 'Reviewer 2 result', 'Final review result',
      'Interviewer 1', 'Interviewer 1 result', 'Interviewer 1 score', 'Interviewer 1 overall feeling',
      'Interviewer 2', 'Interviewer 2 result', 'Interviewer 2 score', 'Interviewer 2 overall feeling', 'Final interview result',

      'Assessor 1', 'Introduce yourself(5)', "Note - candidate's introduction)", 'Presentation topic (note)', 'Homework prepared(20)', 'Positive mindset(10)', 'Presentation skill(5)', 'Maturity(5)', 'Teamwork capability(20)', 'Influencing skill/Attitude(10)', 'Leadership skill(10)', 'Potential social changemaker(15)', 'Total score(100)', 'Overall assessment score(5)', 'Note',
      'Assessor 2', 'Introduce yourself(5)', "Note - candidate's introduction)", 'Presentation topic (note)', 'Homework prepared(20)', 'Positive mindset(10)', 'Presentation skill(5)', 'Maturity(5)', 'Teamwork capability(20)', 'Influencing skill/Attitude(10)', 'Leadership skill(10)', 'Potential social changemaker(15)', 'Total score(100)', 'Overall assessment score(5)', 'Note',
      'Supporter', 'Introduce yourself(5)', "Note - candidate's introduction)", 'Presentation topic (note)', 'Homework prepared(20)', 'Positive mindset(10)', 'Presentation skill(5)', 'Maturity(5)', 'Teamwork capability(20)', 'Influencing skill/Attitude(10)', 'Leadership skill(10)', 'Potential social changemaker(15)', 'Total score(100)', 'Overall assessment score(5)', 'Note',
    ],
  }, (err, csv) => {
    ;
    downloadCSV(csv, 'student-applications');
  });
};

export default exporter