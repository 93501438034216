import { merge, get, cloneDeep, find } from 'lodash'

import httpClient from './httpClient'

import studentApplicationDefaultValue from './defaultValues/studentApplicationDefaultValue'
import StudentApplicationReviewerDataProvider from './StudentApplicationReviewerDataProvider'
import StudentApplicationInterviewerDataProvider from './StudentApplicationInterviewerDataProvider'
import StudentApplicationAssessorDataProvider from './StudentApplicationAssessorDataProvider'
import authProvider from './authProvider'

const getReviewerResultByUid = (application, uid) => {
  return find(get(application, 'review.reviewer', []), {
    reviewer_uid: uid
  }) || {}
}

const getInterviewerResultByUid = (application, uid) => {
  return find(get(application, 'interview.interviewer', []), {
    interviewer_uid: uid
  }) || {}
}

const getAssessorResultByUid = (application, uid) => {
  return find(get(application, 'assessment.assessor', []), {
    assessor_uid: uid
  }) || {}
}


const studentApplicationDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    let filter = get(params, 'filter', {})

    // clear empty filters
    Object.keys(filter).forEach(key => {
      if (filter[key] === null || filter[key] === undefined) {
        delete filter[key]
      }
    })

    const getStudentApplicationIdsUrl = `/admin/get_student_application_ids`;
    const getStudentApplicationInfosUrl = `/admin/get_student_application_infos`;

    return httpClient(getStudentApplicationIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_application_ids = get(json, 'reply.application_ids', [])
      const paged_application_ids = perPage ? all_application_ids.slice((page - 1) * perPage, page * perPage) : all_application_ids
      
      return {
        application_ids: paged_application_ids,
        total: all_application_ids.length
      }
    }).then(({ application_ids, total }) => {
      return httpClient(getStudentApplicationInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          application_ids
        })
      }).then(({ json }) => {
        const applications = get(json, 'reply.applications', [])
        const merged_applications = applications.map((application) => {
          let merged_application = merge(cloneDeep(studentApplicationDefaultValue), application)

          merged_application.reviewer_1_data = getReviewerResultByUid(merged_application, get(application, 'reviewer_1', ''))
          merged_application.reviewer_2_data = getReviewerResultByUid(merged_application, get(application, 'reviewer_2', ''))

          merged_application.interviewer_1_data = getInterviewerResultByUid(merged_application, get(application, 'interviewer_1', ''))
          merged_application.interviewer_2_data = getInterviewerResultByUid(merged_application, get(application, 'interviewer_2', ''))
          
          merged_application.my_assessment = getAssessorResultByUid(merged_application, authProvider.getUid())
          merged_application.assessor_1_data = getAssessorResultByUid(merged_application, get(application, 'assessor_1', ''))
          merged_application.assessor_2_data = getAssessorResultByUid(merged_application, get(application, 'assessor_2', ''))
          merged_application.assessor_3_data = getAssessorResultByUid(merged_application, get(application, 'assessor_3', ''))

          return merged_application
        })

        return {
          data: merged_applications,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getStudentApplicationInfosUrl = `/admin/get_student_application_infos`;

    return httpClient(getStudentApplicationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let application = get(json, 'reply.applications[0]', {})

      application.my_review = getReviewerResultByUid(application, authProvider.getUid())
      application.reviewer_1_data = getReviewerResultByUid(application, get(application, 'reviewer_1', ''))
      application.reviewer_2_data = getReviewerResultByUid(application, get(application, 'reviewer_2', ''))

      application.my_interview = getInterviewerResultByUid(application, authProvider.getUid())
      application.interviewer_1_data = getInterviewerResultByUid(application, get(application, 'interviewer_1', ''))
      application.interviewer_2_data = getInterviewerResultByUid(application, get(application, 'interviewer_2', ''))

      application.my_assessment = getAssessorResultByUid(application, authProvider.getUid())
      application.assessor_1_data = getAssessorResultByUid(application, get(application, 'assessor_1', ''))
      application.assessor_2_data = getAssessorResultByUid(application, get(application, 'assessor_2', ''))
      application.assessor_3_data = getAssessorResultByUid(application, get(application, 'assessor_3', ''))

      return {
        data: merge(cloneDeep(studentApplicationDefaultValue), application)
      }
    })
  },

  getMany: (params) => {
    const { ids } = params
    const getStudentApplicationInfosUrl = `/admin/get_student_application_infos`;

    return httpClient(getStudentApplicationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids
      })
    }).then(({ headers, json }) => {
      const applications = get(json, 'reply.applications', [])

      const merged_applications = applications.map((application) => {
        application.reviewer_1_data = getReviewerResultByUid(application, get(application, 'reviewer_1', ''))
        application.reviewer_2_data = getReviewerResultByUid(application, get(application, 'reviewer_2', ''))

        application.interviewer_1_data = getInterviewerResultByUid(application, get(application, 'interviewer_1', ''))
        application.interviewer_2_data = getInterviewerResultByUid(application, get(application, 'interviewer_2', ''))

        application.assessor_1_data = getAssessorResultByUid(application, get(application, 'assessor_1', ''))
        application.assessor_2_data = getAssessorResultByUid(application, get(application, 'assessor_2', ''))
        application.assessor_3_data = getAssessorResultByUid(application, get(application, 'assessor_3', ''))

        return merge(cloneDeep(studentApplicationDefaultValue), application)
      })
      return {
        data: merged_applications
      }
    })
  },

  update: async (params) => {
    const { id, data , previousData} = params
    const parsedId = typeof(id) === "string" ? JSON.parse(id) : id

    await Promise.all([
      StudentApplicationReviewerDataProvider.update({ id: parsedId, data, previousData }),
      StudentApplicationInterviewerDataProvider.update({ id: parsedId, data, previousData }),
      StudentApplicationAssessorDataProvider.update({ id: parsedId, data, previousData }),
    ])
      
    return Promise.resolve({ data })
  },
};

export default studentApplicationDataProvider
