import React from 'react'
import { SelectField, useGetList } from 'react-admin'

const ProvinceField = (props) => {
  const { record, source, label, provinceList, ...others } = props


  const { data, loading } = useGetList('province');
  const provinces = Object.keys(data).map(key => data[key])

  if (loading) {
    return null
  }

  return (
    <SelectField 
      record={record}
      source={source}
      label={label}
      choices={provinces}
      {...others}
    />
  )
}

export default ProvinceField