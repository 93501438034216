const colors = {
  primary: '#008640',
  primaryDark: '#357a38',
  primaryLight: '#6fbf73',
  primaryContrastText: '#fff',
  secondary: '#ef8a6f',
  secondaryLight: '#f2a18b',
  secondaryDark: '#a7604d',
  secondaryContrastText: '#000',
  error: '#f0443c',
}

export default colors