import { get } from 'lodash'

import httpClient from './httpClient'
import { ResponseCode } from '../constants/constants'
import parseTimeFields from '../helpers/parseTimeFields'

const ArticleDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter

    const getArticleIdsUrl = `/admin/homepage/get_article_ids`;
    const getArticleInfosUrl = `/admin/homepage/get_article_infos`;

    return httpClient(getArticleIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_article_ids = get(json, 'reply.article_ids', [])
      const paged_article_ids = (perPage && !fullSearch) ? all_article_ids.slice((page - 1) * perPage, page * perPage) : all_article_ids

      return {
        article_ids: paged_article_ids,
        total: all_article_ids.length
      }
    }).then(({ article_ids, total }) => {
      return httpClient(getArticleInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          article_ids
        })
      }).then(({ json }) => {
        let article_infos = get(json, 'reply.article_infos', [])
        article_infos = article_infos.map(article => parseTimeFields(article, ['created_on', 'modified_on']))

        return {
          data: article_infos,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getArticleInfosUrl = `/admin/homepage/get_article_infos`;

    return httpClient(getArticleInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        article_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let article = get(json, 'reply.article_infos[0]', {})
      article = parseTimeFields(article, ['created_on', 'modified_on'])

      return {
        data: article
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getArticleInfosUrl = `/admin/homepage/get_article_infos`

    return httpClient(getArticleInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        article_ids: ids
      })
    }).then(({ json }) => {
      let article_infos = get(json, 'reply.article_infos', [])
      article_infos = article_infos.map(article => parseTimeFields(article, ['created_on', 'modified_on']))

      return {
        data: article_infos
      }
    })
  },

  update: (params) => {
    const { id, data } = params

    const setArticleUrl = `/admin/homepage/set_article`;

    return httpClient(setArticleUrl, {
      method: 'POST',
      body: JSON.stringify({
        id,
        ...data
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      const data = get(json, 'reply', {})
      if (result === ResponseCode.SUCCESS) {
        const article_id = get(json, 'reply.article_id', '')
        return {
          data: {
            ...data,
            id: article_id
          }
        }
      } else {
        throw new Error(result)
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setArticleUrl = `/admin/homepage/set_article`;

    return httpClient(setArticleUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const article_id = get(json, 'reply.article_id', {})
      return {
        data: {
          ...data,
          id: article_id,
        }
      }
    })
  },
};

export default ArticleDataProvider
