import { get } from 'lodash'

import httpClient from './httpClient'

const provinceDataProvider = {
  getList: (params) => {
    const getProvinceListUrl = `/general/get_province_list`;
    return httpClient(getProvinceListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const provinces = get(json, 'reply.provinces', [])

      return {
        data: provinces,
        total: provinces.length,
      }
    })
  },

  getMany: (params) => {
    const getProvinceListUrl = `/general/get_province_list`;
    return httpClient(getProvinceListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const provinces = get(json, 'reply.provinces', [])

      return {
        data: provinces,
      }
    })
  }
};

export default provinceDataProvider
