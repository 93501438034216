import React from 'react'
import { FormTab } from 'react-admin'

import ShowSectionWrapper from '../../../../Components/ShowSectionWrapper'

import profileFields from './MentorApplicationShowFields/PersonalInformationFields'
import experienceFields from './MentorApplicationShowFields/ExperienceInformationFields'
import majorAndPreferenceFields from './MentorApplicationShowFields/MajorAndPreferenceFields'
import essayFields from './MentorApplicationShowFields/EssayFields'

const MentorApplicationShowTab = (props) => {
  return (
    <FormTab label='Applicant profile' {...props}>
      <ShowSectionWrapper title='A. Thông tin cá nhân' fields={profileFields}/>
      <ShowSectionWrapper title='B. Kinh nghiệm' fields={experienceFields}/>
      <ShowSectionWrapper title='C. Công việc và mong đợi' fields={majorAndPreferenceFields}/>
      <ShowSectionWrapper title='D. Giới thiệu về bản thân' fields={essayFields}/>
    </FormTab>
  )
}

export default MentorApplicationShowTab