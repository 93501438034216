import React from 'react'
import { Pagination } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const ListPagination = props => {
  const classes = useStyles()
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} className={classes.pagination} {...props} />
  )
}

export default ListPagination