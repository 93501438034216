const summariesDefaultValue = {
  id: '',
  display_name: '',
  display_name_en: '',
  province_id: '',
  university_id: '',
  study_major: '',
  study_major_en: '',
  vietseeds_generation: '',
  university_year: ''
}

const infosDefaultValue = {
  id: '',
  display_name: '',
  display_name_en: '',
  gender: '',
  dob: '',
  province_id: '',
  university_id: '',
  study_major: '',
  study_major_en: '',
  vietseeds_generation: '',
  university_year: '',
  sponsor_status: '',
  profile_picture_url: '',
  extra_data: {
    first_name: '',
    last_name: '',
    family: {
      family_story: '',
    },
    university: {
      major: '',
      gpa: '',
      achievement: '',
      scholarship: '',
      extra_activity: '',
      next_year_goal: '',
      post_graduation_goal: ''
    },
    essay: {
      essay: ''
    },
  },
  extra_data_en: {
    first_name_en: '',
    last_name_en: '',
    family: {
      family_story: '',
    },
    university: {
      major: '',
      gpa: '',
      achievement: '',
      scholarship: '',
      extra_activity: '',
      next_year_goal: '',
      post_graduation_goal: ''
    },
    essay: {
      essay: ''
    },
  },
}

export {
  summariesDefaultValue,
  infosDefaultValue,
}