import React from 'react'
import { cloneElement } from 'react';
import {
  List, Datagrid,
  ReferenceField, TextField, SelectField, DateField,
  Pagination, TopToolbar, CreateButton, ExportButton,
} from 'react-admin'
import UserField from '../../../Components/CustomFields/UserField'
import { makeStyles }  from '@material-ui/core/styles'
import { get } from 'lodash'

import MentorMatchingRequestFilter from './components/MentorMatchingRequestFilter'
import MentorMatchingRequestExpand from './components/MentorMatchingRequestExpand'
import ExportExcel from './components/ExportExcel'

import colors from '../../../constants/colors'

import { MentorMatchingStatusChoices } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tbody, thead': {
      '& tr': {
        '& td:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 100
        },
        '& th:nth-of-type(-n+4)': {
          position: 'sticky',
          zIndex: 200
        },
        '& th': {
          background: colors.primaryLight,
          fontWeight: 'bold'
        },
        '& td:nth-of-type(1), th:nth-of-type(1)': { // Checkbox
          '& span': {
            width: 20,
          },
          left: 0,
        },
        '& .column-id, th:nth-of-type(2)': { // STT
          '& span': {
            width: 20
          },
          left: 48,
        },
        '& td:nth-of-type(3), th:nth-of-type(3)': { // Khu vực
          '& span': {
            width: 40
          },
          left: 108
        },
        '& .column-display_name, th:nth-of-type(4)': { // Họ và tên
          '& span': {
            width: 200
          },
          left: 188,
        },
      },
    },
  },
  list: {
    '& >.MuiToolbar-gutters': { // actions css
      justifyContent: 'flex-start',
    }
  },
  main: {
    '& > div >.MuiToolbar-gutters': { // bulk actions css
      justifyContent: 'flex-start',
      '& :first-child	': {
        position: 'sticky',
        left: 0
      },
      '& >.MuiToolbar-gutters': {  
        position: 'sticky',
        left: 175
      }
    }
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: 0
    }
  }
}));

const mentorMatchingRequestListShowFields = [
  { label: 'STT', source: 'id', component: TextField, },
  {
    label: 'Student', source: 'student_id',
    component: (props) =>
      <ReferenceField {...props} reference='student'>
        <UserField source='display_name' />
      </ReferenceField>,
  },
  {
    label: 'Mentor', source: 'mentor_id',
    component: (props) =>
      <ReferenceField {...props} reference='mentor'>
        <UserField source='display_name' />
      </ReferenceField>,
  },
  { 
    label: 'Status', source: 'status', 
    component: SelectField, choices: MentorMatchingStatusChoices
  },
  {
    label: 'Date time', source: 'update_time',
    component: DateField, showTime: true,
  }
]

const MentorPagination = props => {
  return (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  )
}

const ListActions = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: 'button' })}
    <CreateButton />
    <ExportButton />
    <ExportExcel {...props}/>
  </TopToolbar>
);

const matchingRequestRowStyle = (record) => {
  const status = get(record, 'status', 0)

  if (status === MentorMatchingStatusChoices[0].id) { // booked
    return {}
  }
  if (status === MentorMatchingStatusChoices[1].id) { // requested
    return {
      background: '#fffeed'
    }
  }
  if (status === MentorMatchingStatusChoices[2].id) { // approved
    return {
      background: '#d7fae5'
    }
  }
  if (status === MentorMatchingStatusChoices[3].id) { // rejected
    return {
      background: '#ffbdbd'
    }
  }
}

const MentorMatchingRequestList = (props) => {
  const classes = useStyles()

  return (
    <List 
      {...props}
      classes={{ root: classes.list, main: classes.main}}
      title={'Mentor matching request list'}
      filters={<MentorMatchingRequestFilter/>}
      pagination={<MentorPagination className={classes.pagination}/>}
      actions={<ListActions/>}
      perPage={100}
    >
      <Datagrid 
        rowClick='expand'
        expand={<MentorMatchingRequestExpand/>}
        rowStyle={matchingRequestRowStyle}
        className={classes.table}
      >
        {
          mentorMatchingRequestListShowFields.map((field) => {
            const { label, source, component: Component, ...others } = field
            return (
              <Component key={source} source={source} label={label} {...others}/>
            )
          })
        }
      </Datagrid>
    </List>
  );
}

export default MentorMatchingRequestList