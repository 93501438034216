import React, { useState } from 'react'
import { useRedirect, useNotify, required } from 'react-admin';
import { Form, Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import authProvider from '../../Providers/authProvider';

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20,
  },
  textField: {
    marginTop: 20,
    marginBottom: 20,
  },
  loginContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  loginLink: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const InputNewPasswordForm = (props) => {
  const classes = useStyles()
  const notify = useNotify();
  const redirect = useRedirect()
  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(val => !val)

  const onSubmit = async (values) => {
    return authProvider.changePasswordWithToken({
      ...values,
      token: values.token.trim(),
    })
      .then(() => {
        notify(`Reset password thành công! Vui lòng đăng nhập`, 'success', undefined, false, 0);
        redirect('/login');
      })
      .catch((error) =>
        notify(error.message, 'error')
      )
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          token: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validate={(values) => {
          const errors = {};
          if (values.newPassword && values.newPassword.length < 8) {
            errors.confirmPassword = "Ít nhất 8 ký tự";
          }
          if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = "Mật khẩu không khớp";
          }
          return errors;
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>New password</div>
            <Field name="token" validate={required('Nhận mã đăng nhập qua email của tài khoản')}>
              {({ input, meta }) => (
                <TextField {...input} label='Mã reset password' required fullWidth
                  className={classes.textField}
                  placeholder='Nhập mã có 8 kí tự'
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="token"
                        >
                          <AccountCircle />
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              )}
            </Field>
            <Field name="newPassword" validate={required('Cần nhập mật khẩu mới')}>
              {({ input, meta }) => (
                <TextField {...input} label='Password' required fullWidth
                  className={classes.textField}
                  placeholder='Mật khẩu ít nhất 8 kí tự'
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="password"
                        >
                          <LockIcon />
                        </IconButton>
                      </InputAdornment>,
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              )}
            </Field>
            <Field name="confirmPassword" validate={required('Cần nhập lại mật khẩu mới')}>
              {({ input, meta }) => (
                <TextField {...input} label='Confirm password' required fullWidth
                  className={classes.textField}
                  placeholder='Mật khẩu ít nhất 8 kí tự'
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="confirm-password"
                        >
                          <LockIcon />
                        </IconButton>
                      </InputAdornment>,
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm-password visibility"
                          onClick={toggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              )}
            </Field>
            <Button
              color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}
              disabled={submitting}
            >
              Confirm
            </Button>
          </form>
        )}
      />
      <div className={classes.loginContainer}>
        <a className={classes.loginLink} href='/#/login'>Login</a>
      </div>
    </>
  )
}


export default InputNewPasswordForm