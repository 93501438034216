import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ReCAPTCHA from 'react-google-recaptcha'

import { reCaptchaSiteKey } from '../constants/constants'

const styles = (theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
}))

function ReCaptcha(props) {
  const { classes, onChange, recaptchaRef, ...others } = props;

  return (
    <div className={classes.root}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={reCaptchaSiteKey}
        onChange={onChange}
        hl='vi'
        {...others}
      />
    </div>
  )
}

export default withStyles(styles)(ReCaptcha)