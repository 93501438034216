import React, { useState } from 'react'
import { useLogin, useNotify, Notification, required } from 'react-admin';
import { Form, Field } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import BackgroundLogin from '../../static/images/bg-login.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundPosition: 'center', /* Center the image */
    backgroundRepeat: 'no-repeat', /* Do not repeat the image */
    backgroundSize: 'cover' /* Resize the background image to cover the entire container */
  },
  container: {
    marginTop: 20,
    width: 400,
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 10,
    }
  },
  header: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20,
  },
  error: {
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  noAccountContainer: {
    textAlign: 'center',
    marginTop: 30,
  },
  prefix: {
    color: '#464646',
  },
  createAccount: {
    marginLeft: 10,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.primary.dark
  }
}))

const Login = (props) => {
  const classes = useStyles()
  const login = useLogin();
  const notify = useNotify();

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(val => !val)

  const onSubmit = async (values) => {
    return login({
      username: values.username.trim(),
      password: values.password.trim()
    }).catch(() =>
      notify('Invalid email or password', 'warning')
    );
  };

  return (
    <div className={classes.root}>
      <Paper elevation={5} className={classes.container}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            username: '',
            password: ''
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.header}>Login</div>
              <Field name="username" validate={required('Username or email is required')}>
                {({ input, meta }) => (
                  <TextField {...input} label='Username/Email' required fullWidth
                    placeholder='Username/Email'
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="email"
                          >
                            <AccountCircle />
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                )}
              </Field>
              <Field name="password" validate={required('Password is required')}>
                {({ input, meta }) => (
                  <TextField {...input} label='Password' required fullWidth
                    placeholder='Password'
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched && <span>{meta.error}</span>}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="invitation_code"
                          >
                            <LockIcon />
                          </IconButton>
                        </InputAdornment>,
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle invitation_code visibility"
                            onClick={toggleShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                )}
              </Field>
              <Button
                color="primary" type="submit" variant="contained" fullWidth style={{ marginTop: 20 }}
                disabled={submitting}
              >
                Login
              </Button>
            </form>
          )}
        />
        <div className={classes.noAccountContainer}>
          <span className={classes.prefix}>Forgot password?</span>
          <a className={classes.createAccount} href='/#/forgot-password'>Reset password</a>
        </div>
      </Paper>
      <Notification />
    </div>
  )
}


export default Login