import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { cloneDeep, get } from 'lodash'

import { GenderChoices, frontendURL, } from '../../../constants/constants'
import { MentorRegionChoices } from '../constants'
import { ReviewResultChoices, InterviewResultChoices } from '../constants'
import mapChoicesToValue from '../../../helpers/mapChoicesToValue'

const getTotalScore = (scoreData) => {
  const scoreboard = get(scoreData, 'scoreboard', [])
  return scoreboard.reduce((total, row) => {
    const score = get(row, 'section_summary.score', 0)
    return total + score
  }, 0)
}

const exporter = async (records, fetchRelatedRecords) => {
  // TODO: merge to improve performance
  const reviewers_1 = await fetchRelatedRecords(records, 'reviewer_1', 'mentor-application-reviewer')
  const reviewers_2 = await fetchRelatedRecords(records, 'reviewer_2', 'mentor-application-reviewer')
  const interviewers_1 = await fetchRelatedRecords(records, 'interviewer_1', 'mentor-application-interviewer')
  const interviewers_2 = await fetchRelatedRecords(records, 'interviewer_2', 'mentor-application-interviewer')

  let applicationsForExport = cloneDeep(records).map(application => {
    const {
      id, region, display_name, email,
      personal_info: {
        phone_number,
        address,
        gender: { gender_type },
      },
      reviewer_1: reviewer_1_id,
      reviewer_1_data: { review_result: reviewer_1_result },
      reviewer_2: reviewer_2_id,
      reviewer_2_data: { review_result: reviewer_2_result },
      review_status,

      interviewer_1: interviewer_1_id,
      interviewer_1_data,
      interviewer_2: interviewer_2_id,
      interviewer_2_data,
      interview_status,
    } = application;

    return {
      id, 
      region: mapChoicesToValue(MentorRegionChoices, region),
      display_name, 
      email,
      gender: mapChoicesToValue(GenderChoices, gender_type),
      phone_number: phone_number,
      address,
      url: `${frontendURL}/mentor-application/${id}`,

      reviewer_1: get(reviewers_1, `[${reviewer_1_id}].name`, ''),
      reviewer_1_result: mapChoicesToValue(ReviewResultChoices, reviewer_1_result),
      reviewer_2: get(reviewers_2, `[${reviewer_2_id}].name`, ''),
      reviewer_2_result: mapChoicesToValue(ReviewResultChoices, reviewer_2_result),
      final_review_result: mapChoicesToValue(ReviewResultChoices, review_status),

      interviewer_1: get(interviewers_1, `[${interviewer_1_id}].name`, ''),
      interviewer_1_result: mapChoicesToValue(InterviewResultChoices, get(interviewer_1_data, 'interview_result', '')),
      interviewer_1_score: getTotalScore(interviewer_1_data),
      interviewer_1_note: get(interviewer_1_data, 'note', ''),
      interviewer_2: get(interviewers_2, `[${interviewer_2_id}].name`, ''),
      interviewer_2_result: mapChoicesToValue(InterviewResultChoices, get(interviewer_2_data, 'interview_result', '')),
      interviewer_2_score: getTotalScore(interviewer_2_data),
      interviewer_2_note: get(interviewer_2_data, 'note', ''),
      final_interview_result: mapChoicesToValue(InterviewResultChoices, interview_status),
    }
  })

  jsonExport(applicationsForExport, {
    header: ['id', 'region', 'display_name', 'email', 'gender', 'phone_number', 'address',
      'url',
      'reviewer_1', 'reviewer_1_result', 
      'reviewer_2', 'reviewer_2_result', 'final_review_result',
      'interviewer_1', 'interviewer_1_result', 'interviewer_1_score', 'interviewer_1_note',
      'interviewer_2', 'interviewer_2_result', 'interviewer_2_score', 'interviewer_2_note', 'final_interview_result',
    ],
    rename: ['id', 'Region', 'Full name', 'Email', 'Gender', 'Phone', 'Thành phố (khác)',
      'URL',
      'Reviewer 1', 'Reviewer 1 result', 
      'Reviewer 2', 'Reviewer 2 result', 'Final review result',
      'Interviewer 1', 'Interviewer 1 result', 'Interviewer 1 score', 'Interviewer 1 note',
      'Interviewer 2', 'Interviewer 2 result', 'Interviewer 2 score', 'Interviewer 2 note', 'Final interview result',
    ],
  }, (err, csv) => {
    ;
    downloadCSV(csv, 'mentor-applications');
  });
};

export default exporter