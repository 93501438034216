import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles'
import {
  useUpdateMany,
  useGetList,
  useRefresh,
  useNotify,
  useUnselectAll,
  Button as AdminButton
} from 'react-admin';
import { set, cloneDeep } from 'lodash'
import { RateReview } from '@material-ui/icons';

import Select from '../../../../Components/Select'


const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 10,
    marginRight: 10,
    background: '#fff940',
  }
}));

const SetReviewerDialog = (props) => {
  const { selectedIds } = props
  const [reviewers, setReviewers] = useState({ 
    reviewer_1: '',
    reviewer_2: '',
  })
  
  const [open, setOpen] = useState(false)
  const refresh = useRefresh();
  const notify = useNotify();

  const unselectAll = useUnselectAll();
  const { data: reviewerListObject = {} } = useGetList('student-application-reviewer')
  const reviewerList = Object.keys(reviewerListObject).map(key => reviewerListObject[key])

  const [updateReviewers] = useUpdateMany(
    'student-application-reviewer',
    selectedIds,
    {
      ...reviewers
    },
    {
      onSuccess: () => {
        refresh();
        notify(`Set reviewers for ${selectedIds.length} applications successfully`, 'success');
        unselectAll('student-application');
      },
      onFailure: error => notify('Error: can not set reviewers', 'warning'),
    }
  );

  const handleOpen = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSave = () => {
    // call API to set reviewers
    updateReviewers()
    setOpen(false)
  }


  const handleSelectReviewers = (e) => {
    const newReviewers = cloneDeep(reviewers)
    set(newReviewers, e.target.name, e.target.value)
    setReviewers(newReviewers)
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <AdminButton label='Set reviewers' onClick={handleOpen} variant='outlined' className={classes.button}>
        <RateReview/>
      </AdminButton>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Set reviewers</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Leave blank to keep old data of that field
          </DialogContentText>
          <Select 
            label='Reviewers 1' 
            name='reviewer_1' 
            value={reviewers.reviewer_1} 
            onChange={handleSelectReviewers}
            choices={reviewerList}
          />
          <Select 
            label='Reviewers 2' 
            name='reviewer_2' 
            value={reviewers.reviewer_2} 
            onChange={handleSelectReviewers}
            choices={reviewerList}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default SetReviewerDialog