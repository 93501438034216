import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(10),
  },
}));

export default function CustomSelect(props) {
  const classes = useStyles();
  const { name, value, label, choices, onChange } = props

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          inputProps={{
            name,
            id: name,
          }}
        >
          <MenuItem value=""></MenuItem>
          {
            choices.map(choice => {
              return (
                <MenuItem key={`${name}.${choice.id}`} value={choice.id}>{choice.name}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}
