import React from 'react'
import {
  TextField, SelectField, BooleanField
} from 'react-admin'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import GridTableShow from '../../../../../Components/GridTableShow'
import HighSchoolResult from './HighschoolResult'
import UniversityResult from './UniversityResult'

const academicInfoFields = [
  {
    label: 'Tình trạng học vấn', source: 'academic_info.academic_level', component: SelectField,
    choices: [
      { id: 1, name: 'A. Học sinh lớp 12, chuẩn bị thi tốt nghiệp THPT 2021' },
      { id: 2, name: 'B. Đã tốt nghiệp THPT từ năm ngoái trở về trước, đang ôn thi, chuẩn bị thi lại Đại học' },
      { id: 3, name: 'C. Đang là sinh viên và cũng đang ôn thi lại Đại học vì muốn đổi ngành' },
      { id: 4, name: 'D. Khác' },
    ]
  },
  {
    label: 'Tình trạng học vấn khác', source: 'academic_info.other_academic_level', component: TextField,
  },
]


const AcademicInfoSection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='B. Kết quả học tập' record={record} {...other}>
      {
        academicInfoFields.map(field => {
          const { label, source, component: Component, ...others } = field
          return (
            <Component key={source} source={source} label={label} {...others} />
          )
        })
      }

      {/* Highschool section */}
      <HighSchoolResult/>
      <GridTableShow
        source='academic_info.high_school.graduation_exam.scores'
        title='Điểm thi tốt nghiệp THPT'
        columns={[
          { label: 'Tên Môn thi TN THPT', key: 'subject', size: 6 },
          { label: 'Điểm thi (thang điểm 10)', key: 'score', size: 6, type: 'number' },
        ]}
      />

      <GridTableShow
        source='academic_info.high_school.achievements'
        title={'Các giải thưởng tại trường THPT'}
        columns={[
          { label: 'Giải thưởng', key: 'name', size: 6 },
          { label: 'Mô tả', key: 'description', size: 6 },
        ]}
      />
      <GridTableShow
        source='academic_info.candidate_universities'
        title={`Kết quả thi đại học`}
        columns={[
          { label: 'Tên trường', key: 'name', size: 3  },
          { label: 'Ngành', key: 'major', size: 4 },
          { label: 'Tổng điểm', key: 'total_score', size: 2, type: 'number' },
          { label: 'Đậu?', key: 'is_accepted', component: BooleanField, size: 2 },
          { label: 'Dự định học', key: 'is_applying', component: BooleanField, size: 1 },
        ]}
      />

      {/* University section */}
      {(get(record, 'academic_info.academic_level', 0) === 3) && 
        <React.Fragment>
          <UniversityResult />
          <GridTableShow
            source='academic_info.university.achievements'
            title={`Các giải thưởng tại trường ĐH`}
            columns={[
              { label: 'Giải thưởng', key: 'name', size: 6 },
              { label: 'Mô tả', key: 'description', size: 6 },
            ]}
          />
        </React.Fragment>
      }
    </SectionWrapper>
  )
};

export default AcademicInfoSection