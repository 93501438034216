import React from 'react'
import { Notification, useRedirect, Loading, useAuthState } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { get } from 'lodash'

import BackgroundLogin from '../../static/images/bg-login.jpg'
import InputUsernameForm from './InputUsernameForm';
import InputNewPasswordForm from './InputNewPasswordForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundPosition: 'center', /* Center the image */
    backgroundRepeat: 'no-repeat', /* Do not repeat the image */
    backgroundSize: 'cover' /* Resize the background image to cover the entire container */
  },
  container: {
    marginTop: 20,
    width: 400,
    padding: 30,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 10,
    }
  }
}))

const ForgotPassword = (props) => {
  const step = get(props, 'match.params.step', "1")
  const classes = useStyles()
  const redirect = useRedirect()

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (authenticated) {
    redirect('/')
  }

  return (
    <div className={classes.root}>
      <Paper elevation={5} className={classes.container}>
        {step === "1" ?
          <InputUsernameForm/>
          : <InputNewPasswordForm/>
        }
      </Paper>
      <Notification />
    </div>
  )
}


export default ForgotPassword