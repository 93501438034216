import SponsorTransactionList from './SponsorTransactionList'
import SponsorTransactionEdit from './SponsorTransactionEdit'
import SponsorTransactionCreate from './SponsorTransactionCreate'
import SponsorTransactionIcon from '@material-ui/icons/Receipt';

export default {
  list: SponsorTransactionList,
  edit: SponsorTransactionEdit,
  create: SponsorTransactionCreate,
  icon: SponsorTransactionIcon,
  options: { label: 'Sponsor Transaction' }
}