import React from 'react'

import SectionWrapper from './SectionWrapper'
import GridTableShow from '../../../../../Components/GridTableShow'

const ExperienceSection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='D. Kinh nghiệm' record={record} {...other}>
      <GridTableShow
        source='experience_info.work_experiences'
        title='Kinh nghiệm làm việc'
        instruction={`
          Liệt kê tối đa 5 công việc (nếu có), bắt đầu với công việc 
          gần đây nhất cùng với các thông tin được yêu cầu.
        `}
        columns={[
          { label: 'Thời gian', key: 'start_date', size: 2, type: 'date' },
          { label: 'Vị trí', key: 'position', size: 3 },
          { label: 'Mô tả công việc', key: 'description', size: 4 },
          { label: 'Địa điểm', key: 'work_place', size: 2 },
          { label: 'Số giờ làm việc / tuần', key: 'weekly_hours', size: 1, type: 'number' },
        ]}
      />

      <GridTableShow
        source='experience_info.volunteer_experiences'
        title='Hoạt động ngoại khóa / Công việc tình nguyện'
        instruction={`
          Liệt kê (tối đa) 3 hoạt động ngoại khóa/công việc tình nguyện 
          theo thứ tự mức độ quan trọng giảm dần đối với bản thân em và điền đầy đủ 
          các thông tin đi kèm (nếu có).
        `}
        columns={[
          { label: 'Thời gian', key: 'start_date', size: 2, type: 'date' },
          { label: 'Hoạt động', key: 'title', size: 2 },
          { label: 'Mô tả công việc', key: 'description', size: 4 },
          { label: 'Vị trí', key: 'position', size: 2 },
          { label: 'Lý do tham gia', key: 'reason', size: 2 },
        ]}
      />
    </SectionWrapper>
  );
}

export default ExperienceSection;