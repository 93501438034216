import { defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import { merge } from 'lodash'

const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: '#6fbf73',
      main: '#357a38',
      dark: '#357a38',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc4b5',
      main: '#ff8769',
      dark: '#ff4819',
      contrastText: '#000000',
    },
    background: {
      default: '#fcfcfe',
    },
  },
  overrides: {
    RaMenuItemLink: {
      active: {
        borderLeft: '3px solid #357a38',
      },
      root: {
        borderLeft: '3px solid #fff', // invisible menu when not active, to avoid scrolling the text when selecting the menu
      },
    },
  },
  sidebar: {
    width: 250,
  },
});

const createdTheme = createTheme(theme)

export default theme
export {
  createdTheme
}