import React from 'react'
import { 
  Create, SimpleForm, TextInput
} from 'react-admin';

import renderInputFields from '../../../helpers/renderInputFields'


const occupationTagCreateFields = [
  {
    label: 'Ngành nghề', source: 'name', component: TextInput, required: true,
  },
  { 
    label: 'Occupation tag', source: 'name_en', component: TextInput, required: true,
  },
]

const OccupationTagCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm variant='outlined'>
        {
          renderInputFields(occupationTagCreateFields)
        }
      </SimpleForm>
    </Create>
  );
}

export default OccupationTagCreate