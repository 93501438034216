const ContactRequestType = [
  { id: 1, name: 'INDIVIDUAL_SPONSOR' },
  { id: 2, name: 'CORPORATE_SPONSOR' },
  { id: 3, name: 'MENTOR' },
  { id: 4, name: 'STUDENT' },
]

const ContactRequestStatus = [
  { id: 1, name: 'Created' },
  { id: 2, name: 'Processing' },
  { id: 3, name: 'Done' },
]

const PreferredContactMethodChoices = [
  { id: 1, name: 'phone' },
  { id: 2, name: 'email' },
]

export {
  ContactRequestType,
  ContactRequestStatus,
  PreferredContactMethodChoices,
}