import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  // NumberInput,
  // SelectInput,
} from 'react-admin';

// import { SponsorStatusChoices } from '../../constants'

const styles = theme => ({
})

const SponsorTransactionFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      {/* <NumberInput source='sponsor_invitation_id' label={'ID'} alwaysOn/>
      <NumberInput source='status' label={'Status'} choices={SponsorStatusChoices} alwaysOn/> */}
    </Filter>
  )
}

export default withStyles(styles)(SponsorTransactionFilter)