import React from 'react'
import { 
  Edit, SimpleForm, TextInput,
  TopToolbar, ListButton, CreateButton
} from 'react-admin';

import renderInputFields from '../../../helpers/renderInputFields'


const occupationTagEditFields = [
  {
    label: 'STT', source: 'id', component: TextInput, disabled: true,
  },
  {
    label: 'Ngành nghề', source: 'name', component: TextInput, required: true,
  },
  {
    label: 'Occupation tag', source: 'name_en', component: TextInput, required: true,
  },
]

const OccupationTagEditActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const OccupationTagEdit = (props) => {
  return (
    <Edit 
      undoable={false}
      actions={<OccupationTagEditActions/>}
      {...props}
    >
      <SimpleForm variant='outlined'>
        {
          renderInputFields(occupationTagEditFields)
        }
      </SimpleForm>
    </Edit>
  );
}

export default OccupationTagEdit