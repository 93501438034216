import { find, get } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const universityDataProvider = {
  getList: (params) => {
    const getUniversityListUrl = `/general/get_university_list`;

    return httpClient(getUniversityListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const universities = get(json, 'reply.universities', [])

      return {
        data: universities,
        total: universities.length,
      }
    })
  },

  getMany: (params) => {
    const getUniversityListUrl = `/general/get_university_list`;

    return httpClient(getUniversityListUrl, {
      method: 'GET'
    }).then(({ json }) => {
      const universities = get(json, 'reply.universities', [])

      return {
        data: universities,
      }
    })
  },

  getOne: (params) => {
    const { id } = params
    const parsedId = JSON.parse(id)

    const getUniversityListUrl = `/general/get_university_list`;

    return httpClient(getUniversityListUrl, {
      method: 'GET',
    }).then(({ json }) => {
      const universities = get(json, 'reply.universities', [])
      const university = find(universities, { id: parsedId })
      return {
        data: university,
      }
    })
  },

  update: (params) => {
    const { data } = params

    const setUniversityUrl = `/admin/set_university`;

    return httpClient(setUniversityUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      const data = get(json, 'reply', {})
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setUniversityUrl = `/admin/set_university`;

    return httpClient(setUniversityUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const university_id = get(json, 'reply.university_id', {})
      return { 
        data: {
          ...data,
          id: university_id, 
        }
      }
    })
  },
};

export default universityDataProvider
