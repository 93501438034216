import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StatusButton from './StatusButton'

import BookedIcon from '@material-ui/icons/TurnedInNot';
import RequestedIcon from '@material-ui/icons/AssignmentLate';
import ApprovedIcon from '@material-ui/icons/ThumbUp';
import RejectedIcon from '@material-ui/icons/HighlightOff';

import { MentorMatchingStatusChoices } from '../../../../constants/constants'

const SetStatusButtons = ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom align="left">
          Set status:
        </Typography>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <StatusButton
            record={record}
            status={MentorMatchingStatusChoices[0].id}
            label={MentorMatchingStatusChoices[0].name}
            icon={BookedIcon}
          />
        </Grid>
        <Grid item xs={3}>
          <StatusButton
            record={record}
            status={MentorMatchingStatusChoices[1].id}
            label={MentorMatchingStatusChoices[1].name}
            icon={RequestedIcon}
          />
        </Grid>
        <Grid item xs={3}>
          <StatusButton
            record={record}
            status={MentorMatchingStatusChoices[2].id}
            label={MentorMatchingStatusChoices[2].name}
            icon={ApprovedIcon}
          />
        </Grid>
        <Grid item xs={3}>
          <StatusButton
            record={record}
            status={MentorMatchingStatusChoices[3].id}
            label={MentorMatchingStatusChoices[3].name}
            icon={RejectedIcon}
            color="secondary"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}


export default SetStatusButtons