import React from 'react'
import { TextField } from 'react-admin';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import { get } from 'lodash'

import colors from '../constants/colors'

const styles = (theme => ({
  root: {
    marginBottom: 40
  },
  title: {
    backgroundColor: colors.primaryDark,
    color: '#FFF',
    fontWeight: 'bold',
    padding: 20,
  },
  instruction: {
    padding: 15,
    lineHeight: 1.5
  },
  header: {
    textAlign: 'center',
    backgroundColor: colors.primaryLight,
    fontWeight: 'bold',
    lineHeight: 1.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    height: '100%',
    padding: 10,
    border: 'solid #f5f5f5 0.1px',
    display: 'flex',
    justifyContent: 'center',
  },
  removeButton: {
    cursor: 'pointer'
  },
  addButton: {
    marginTop: 20,
    width: '100%'
  },
}))

const GridShowTable = (props) => {
  const { 
    classes,
    record,
    source,
    title,
    instruction, 
    columns, // columns label, key, & size
  } = props

  const allData = get(record, source, [])

  return (
    <Grid container className={classes.root}>
      {title && <Grid item className={classes.title} xs={12}>{title}</Grid>}
      {instruction && <Grid item className={classes.instruction} xs={12}>{instruction}</Grid>}
      <Grid container item> 
        {columns.map(column => {
            const { label, key, size } = column;
            return (
              <Grid key={key} item xs={size} className={`${classes.header} ${classes.item}`}>{label}</Grid>
            )
          })
        }
      </Grid>
      {
        allData.map((data, index) => {
          return (
            <Grid container item key={`${source}_${index}`}>
              {
                columns.map(column => {
                  const { key, size, component: Component = TextField, label, ...others } = column
                  return (
                    <Grid item xs={size} className={classes.item} key={`${source}[${index}].${key}`}>
                      <Component label='' {...others} source={key} record={data}/>
                    </Grid>
                  )
                })
              }
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default withStyles(styles)(GridShowTable)