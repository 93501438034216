import React from 'react'
import { required as requiredValidator } from 'react-admin'
import { merge, cloneDeep } from 'lodash'

const renderInputFields = (fields, sharedProps) => {
  return fields.map(field => {
    const { label, source, component: Component, fullWidth, required, validate = [], ...others } = merge(cloneDeep(sharedProps), field)

    return (
      <Component
        key={source} source={source} label={label}
        fullWidth={fullWidth}
        validate={[
          ...validate,
          ...!!required ? [requiredValidator()] : []
        ]}
        {...others}
      />
    )
  })
}

export default renderInputFields