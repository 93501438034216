import { get, cloneDeep, } from 'lodash'
import moment from 'moment'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const parseTransaction = (transaction) => {
  let parsedTransaction = cloneDeep(transaction)

  var expiry_time = moment.unix(transaction.expiry_time).toDate();
  parsedTransaction.expiry_time = expiry_time

  var createdTimeDate = moment.unix(transaction.create_time).toDate();
  parsedTransaction.create_time = createdTimeDate

  var updatedTimeDate = moment.unix(transaction.update_time).toDate();
  parsedTransaction.update_time = updatedTimeDate

  return parsedTransaction
}


const sponsorTransactionDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params

    const getSponsorTransactionIdsUrl = `/admin/get_sponsor_transaction_ids`;
    const getSponsorTransactionInfosUrl = `/admin/get_sponsor_transaction_infos`;

    return httpClient(getSponsorTransactionIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_transaction_ids = get(json, 'reply.transaction_ids', [])
      let paged_transaction_ids = []
      if (page) {
        paged_transaction_ids = all_transaction_ids.slice((page - 1) * perPage, page * perPage)
      } else {
        paged_transaction_ids = all_transaction_ids
      }

      return {
        transaction_ids: paged_transaction_ids,
        total: all_transaction_ids.length
      }
    }).then(({ transaction_ids, total }) => {
      return httpClient(getSponsorTransactionInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          transaction_ids
        })
      }).then(({ json }) => {
        let transactions = get(json, 'reply.sponsor_transactions', [])
        transactions = transactions.map(transaction => parseTransaction(transaction))

        return {
          data: transactions,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getSponsorTransactionInfosUrl = `/admin/get_sponsor_transaction_infos`;

    return httpClient(getSponsorTransactionInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        transaction_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let transaction = get(json, 'reply.sponsor_transactions[0]', {})
      transaction = parseTransaction(transaction)

      return {
        data: transaction
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getSponsorTransactionInfosUrl = `/admin/get_sponsor_transaction_infos`;

    return httpClient(getSponsorTransactionInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        transaction_ids: ids
      })
    }).then(({ json }) => {
      let transactions = get(json, 'reply.sponsor_transactions', [])
      transactions = transactions.map(transaction => parseTransaction(transaction))

      return {
        data: transactions
      }
    })
  },

  create: (params) => {
    const { data } = params

    const createSponsorTransactionUrl = `/admin/set_sponsor_transaction`;

    return httpClient(createSponsorTransactionUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        expiry_time: moment(data.expiry_time).unix()
      })
    }).then(({ json }) => {
      const transaction_id = get(json, 'reply.transaction_id', {})
      return {
        data: {
          ...data,
          id: transaction_id,
        }
      }
    })
  },

  update: (params) => {
    const { data } = params

    const setSponsorTransactionUrl = `/admin/set_sponsor_transaction`;

    return httpClient(setSponsorTransactionUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        expiry_time: moment(data.expiry_time).unix(),
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default sponsorTransactionDataProvider
