import { get, cloneDeep, } from 'lodash'
import moment from 'moment'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const parseContactRequest = (request) => {
  let parsedRequest = cloneDeep(request)

  let createdTimeDate = moment.unix(request.create_time).toDate();
  parsedRequest.create_time = createdTimeDate

  return parsedRequest
}

const ContactRequestDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter
    
    const getContactRequestIdsUrl = `/admin/get_contact_request_ids`;
    const getContactRequestInfosUrl = `/admin/get_contact_request_infos`;

    return httpClient(getContactRequestIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_contact_request_ids = get(json, 'reply.contact_request_ids', [])
      const paged_contact_request_ids = (page && !fullSearch) ? all_contact_request_ids.slice((page - 1) * perPage, page * perPage) : all_contact_request_ids

      return {
        contact_request_ids: paged_contact_request_ids,
        total: all_contact_request_ids.length
      }
    }).then(({ contact_request_ids, total }) => {
      return httpClient(getContactRequestInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          contact_request_ids
        })
      }).then(({ json }) => {
        let contact_requests = get(json, 'reply.contact_request_infos', [])

        return {
          data: contact_requests.map(request => parseContactRequest(request)),
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getContactRequestInfosUrl = `/admin/get_contact_request_infos`;

    return httpClient(getContactRequestInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        contact_request_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let contact_request = get(json, 'reply.contact_request_infos[0]', {})

      return {
        data: parseContactRequest(contact_request)
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getContactRequestInfosUrl = `/admin/get_contact_request_infos`;

    return httpClient(getContactRequestInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        contact_request_ids: ids
      })
    }).then(({ json }) => {
      let contact_requests = get(json, 'reply.contact_request_infos', [])

      return {
        data: contact_requests.map(request => parseContactRequest(request))
      }
    })
  },

  update: (params) => {
    const { data } = params

    const setContactRequestUrl = `/admin/set_contact_request_infos`;

    return httpClient(setContactRequestUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        create_time: moment(data.create_time).unix(),
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default ContactRequestDataProvider
