import * as React from 'react';
import Button from '@material-ui/core/Button';
import { useUpdate, useNotify, useRefresh } from 'react-admin';

const StatusButton = ({ record, status, label, icon: Icon, color='primary' }) => {
  const notify = useNotify();
  const refresh = useRefresh()
  const { status: currentStatus } = record;
  
  const [setStatus, { loading }] = useUpdate(
    'mentor-matching-request',
    JSON.parse(record.id),
    { status: status },
    record,
    {
      undoable: false,
      onSuccess: () => {
        notify(
          'Update status success',
          { type: 'success' }
        );
        refresh()
      },
      onFailure: (error) => {
        notify(
          `Update error: ${error.message}`,
          { type: 'warning' },
          undefined, false, 0
        );
      },
    }
  );
  return record ? (
    <Button
      variant={status !== currentStatus ? "outlined" : "contained"}
      color={color}
      onClick={setStatus}
      style={{ width: '100%' }}
      disabled={loading}
      disableElevation
    >
      <Icon
        color={status !== currentStatus ? color : undefined }
        style={{ paddingRight: '0.5em' }}
      />
      {label}
    </Button>
  ) : (
    <span />
  );
};

export default StatusButton;
