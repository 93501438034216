const exportJSON = (data, filename) => {
  let savedData = JSON.stringify(data, undefined, 4)

  const blob = new Blob([savedData], { type: 'text/json' }),
  e = document.createEvent('MouseEvents'),
  a = document.createElement('a')

  a.download = `${filename}.json`
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
  e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}

export default exportJSON