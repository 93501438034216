import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import UserField from '../../../../Components/CustomFields/UserField';

import { RegionChoices } from '../../../../constants/constants'
import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'

const MentorField = (props) => {
  const { record } = props
  return (
    record ? (
      <Typography>
        <UserField {...props} source='display_name' />
        <br />
        <b>Number of mentees:</b> {record.number_of_mentees}
        <br />
        <b>Region:</b> {mapChoicesToValue(RegionChoices, record.vietseeds_region)}
        <br />
        <b>Job title:</b> {record.job_title} - <b>Company:</b> {record.company}
        <br />
        <b>Email:</b> {record.email}
        <br/>
        <b>Phone:</b> {record.phone}
      </Typography>
    ) : null
  )
}

export default MentorField