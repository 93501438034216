import * as React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AvatarField from './AvatarField';
import { get } from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

const UserField = (props) => {
  const { record, source = 'display_name', size } = props;
  const classes = useStyles();
  return record ? (
    <div className={classes.root}>
      <AvatarField
        className={classes.avatar}
        record={record}
        size={size}
      />
      {get(record, source, '')}
    </div>
  ) : null;
};

export default memo(UserField);