import { cloneDeep, get, } from 'lodash'
import moment from 'moment'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'


const parseInvitation = (invitation) => {
  let parsedInvitation = cloneDeep(invitation)
  
  let createdTimeDate = moment.unix(invitation.create_time).toDate();
  let expiryTimeDate = moment.unix(invitation.expiry_time).toDate();
  parsedInvitation.create_time = createdTimeDate
  parsedInvitation.expiry_time = expiryTimeDate

  return parsedInvitation
}

const sponsorInvitationDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter

    const getSponsorInvitationIdsUrl = `/admin/get_sponsor_invitation_ids`;
    const getSponsorInvitationInfosUrl = `/admin/get_sponsor_invitation_infos`;

    return httpClient(getSponsorInvitationIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_invitation_ids = get(json, 'reply.invitation_ids', [])
      const paged_invitation_ids = (page && !fullSearch) ? all_invitation_ids.slice((page - 1) * perPage, page * perPage) : all_invitation_ids

      return {
        invitation_ids: paged_invitation_ids,
        total: all_invitation_ids.length
      }
    }).then(({ invitation_ids, total }) => {
      return httpClient(getSponsorInvitationInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          invitation_ids
        })
      }).then(({ json }) => {
        let invitations = get(json, 'reply.invitations', [])
        invitations = invitations.map(invitation => parseInvitation(invitation))

        return {
          data: invitations,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getSponsorInvitationInfosUrl = `/admin/get_sponsor_invitation_infos`;

    return httpClient(getSponsorInvitationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        invitation_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let invitation = get(json, 'reply.invitations[0]', {})
      invitation = parseInvitation(invitation)

      return {
        data: invitation
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getSponsorInvitationInfosUrl = `/admin/get_sponsor_invitation_infos`;

    return httpClient(getSponsorInvitationInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        invitation_ids: ids
      })
    }).then(({ json }) => {
      let invitations = get(json, 'reply.invitations', [])

      return {
        data: invitations
      }
    })
  },

  create: (params) => {
    const { data } = params

    const setSponsorInvitationUrl = `/admin/set_sponsor_invitation`;

    return httpClient(setSponsorInvitationUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const invitation_id = get(json, 'reply.invitation_id', {})
      return {
        data: {
          ...data,
          id: invitation_id,
        }
      }
    })
  },

  update: (params) => {
    const { data } = params

    const setSponsorInvitationUrl = `/admin/set_sponsor_invitation`;

    return httpClient(setSponsorInvitationUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        expiry_time: moment(data.expiry_time).unix(),
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default sponsorInvitationDataProvider
