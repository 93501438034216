import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  SearchInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';

const styles = theme => ({
})

const UserFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <ReferenceArrayInput label='Role' source="role_ids" reference="user-role" alwaysOn>
        <SelectArrayInput optionText="role_name" />
      </ReferenceArrayInput>
    </Filter>
  )
}

export default withStyles(styles)(UserFilter)