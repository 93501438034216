import React, { useState, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import ViewIcon from '@material-ui/icons/Pageview';
import { cloneDeep, set, unionBy, findIndex } from 'lodash'
import { useHistory } from "react-router-dom"
import { useNotify, } from 'react-admin'

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import StudentApplicationInterviewerDataProvider from '../../../../Providers/StudentApplicationInterviewerDataProvider'

import EventDialog from './EventDialog'
import StatusDrawer from './StatusDrawer'

const DnDCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  configurationContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  fab: {
    position: 'fixed',
    right: 10,
    bottom: 10,
  },
}));

// props: interviewer 1 events, interviewer 2 events => merge => busy time
// props: selected applications' id and data => auto fill => create new event
// call api to set event to interviewer
// call api to set interview datetime to application
const InterviewDatetimePicker = (props) => {
  const { applications = [], assignedApplications = [], assignedInterviewers, interviewerList } = props

  const [events, setEvents] = useState([])
  const [unassignedApplications, setUnassignedApplications] = useState([])
  const [assignedEvents, setAssignedEvents] = useState([])
  const [config, setConfig] = useState({ step: 45 }) // default 45 minutes slot
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  let history = useHistory()
  const notify = useNotify();

  useEffect(() => {
    setEvents([])
    setUnassignedApplications(applications)
  }, [applications]);

  useEffect(() => {
    setAssignedEvents(mapApplicationsToEvents(assignedApplications))
  }, [assignedApplications]);

  const mapApplicationsToEvents = (applications) => {
    return applications.map(application => {
      const { id, display_name, interview_datetime, interviewer_1, interviewer_2 } = application
      return ({
        id,
        title: `${id}. ${display_name}`,
        start: new Date(interview_datetime),
        end: moment(interview_datetime).add(config.step, 'minutes').toDate(),
        resource: {
          interviewers: {
            interviewer_1,
            interviewer_2,
          },
        }
      })
    })
  }

  const assignInterviewersForApplication = (id, interviewers) => {
    StudentApplicationInterviewerDataProvider.updateMany({ 
      ids: [id], 
      data: interviewers,
    })
      .then(({ data }) => {
      })
      .catch(error => {
        notify('Error: can not set interviewers', 'warning')
        console.log(error)
      })
  }

  const handleSelect = ({ start, end }) => {
    // auto fill applications to slots
    let newEvents = cloneDeep(events)
    let newUnassignedApplications = cloneDeep(unassignedApplications)
    while (newUnassignedApplications.length > 0 && moment(start).add(config.step, 'minutes').isSameOrBefore(end)) {
      const application = newUnassignedApplications.pop()
      const { id, display_name } = application
      const nextStart = moment(start).add(config.step, 'minutes')
      newEvents = newEvents.concat(
        {
          id: id,
          title: `${id}. ${display_name}`,
          start: moment(start).toDate(),
          end: nextStart.toDate(),
          resource: {
            interviewers: assignedInterviewers,
          }
        }
      )
      assignInterviewersForApplication(id, 
        {
          ...assignedInterviewers,
          interview_datetime: moment(start).toDate(),
        }
      )
      start = nextStart
    }

    setEvents(newEvents)
    setUnassignedApplications(newUnassignedApplications)
  }

  const onEventChange = (data) => {
    // edit event
    // TODO: handle 2 cases: in events list or assigned events list
    // If in assigned events list => ignore
    const { start, event } = data;

    let newEvents = cloneDeep(events)
    let index = findIndex(newEvents, { id: event.id })
    if (index !== -1) {
      set(newEvents, `[${index}].start`, start)
      set(newEvents, `[${index}].end`, moment(start).add(config.step, 'minutes').toDate())

      assignInterviewersForApplication(event.id,
        {
          ...assignedInterviewers,
          interview_datetime: moment(start).toDate(),
        }
      )

      setEvents(newEvents)
    } else {
      let newAssignedEvents = cloneDeep(assignedEvents)
      let index = findIndex(newAssignedEvents, { id: event.id })
      if (index !== -1) {
        set(newAssignedEvents, `[${index}].start`, start)
        set(newAssignedEvents, `[${index}].end`, moment(start).add(config.step, 'minutes').toDate())
  
        assignInterviewersForApplication(event.id,
          {
            interview_datetime: moment(start).toDate(),
          }
        )

        setAssignedEvents(newAssignedEvents)
      }

    }
  };

  const handleChange = (e) => {
    const newConfig = cloneDeep(config)
    set(newConfig, e.target.name, e.target.value)
    setConfig(newConfig)
  }

  const [activeEvent, setActiveEvent] = useState(null)
  const handleClick = (event) => {
    setActiveEvent(event)
  }

  const handleCloseEventDialog = () => {
    setActiveEvent(null)
  }

  const handleDoubleClick = (event) => {
    history.push(`/student-application/${event.id}`)
  }

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.configurationContainer}>
        <TextField 
          label='Interview slot' 
          type='number' 
          name='step' 
          value={config.step}
          onChange={handleChange}
          helperText="minutes"  
        />
      </div>
      <DnDCalendar
        selectable
        localizer={localizer}
        step={config.step || 45}
        events={unionBy(events, assignedEvents, 'id')}
        defaultView={Views.WEEK}
        // scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={moment().toDate()}
        onSelectEvent={handleClick}
        onDoubleClickEvent={handleDoubleClick}
        onSelectSlot={handleSelect}
        onEventResize={onEventChange}
        onEventDrop={onEventChange}
        style={{ height: "100vh" }}
      />

      <EventDialog 
        event={activeEvent} 
        interviewerList={interviewerList}
        handleClose={handleCloseEventDialog}
        // handleDelete={handleDelete}
        // not allow to delete event in assigned event list
        // disabledDelete={findIndex(events, { id: activeEvent ? activeEvent.id : null}) === -1}
      />
      
      <Fab color="primary" variant="extended" aria-label="view-status" 
        className={classes.fab}
        onClick={() => setIsOpenDrawer(true)}
      >
        <ViewIcon />
        View status
      </Fab>

      <StatusDrawer 
        open={isOpenDrawer}
        unassignedApplications={unassignedApplications}
        events={events}
        handleClose={() => setIsOpenDrawer(false)}
        handleClickEvent={handleClick}
        // handleDelete={handleDelete}
        step={config.step || 45}
      />
    </div>
  )
}

export default InterviewDatetimePicker