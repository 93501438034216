import httpClient from './httpClient'
import { ResponseCode } from '../constants/constants'
import { get } from 'lodash'

const uploadImage = async (url, file) => {
  const formData = new FormData();
  formData.append('image', file);

  return await httpClient(url, {
    method: 'POST',
    body: formData,
  }).then(({ json }) => {
    const result = get(json, 'result', 'error')

    if (result === ResponseCode.SUCCESS) {
      const image = get(json, 'reply.image')
      return image
    } else {
      throw new Error(result)
    }
  })
}

export {
  uploadImage
}