import React from 'react'
import { 
  Edit, SimpleForm, TextInput, SelectInput
} from 'react-admin';

import { RegionChoices } from '../../../constants/constants'
import renderInputFields from '../../../helpers/renderInputFields'


const universityEditFields = [
  {
    label: 'STT', source: 'id', component: TextInput, disabled: true,
  },
  {
    label: 'Đại học', source: 'name', component: TextInput, required: true,
  },
  {
    label: 'University', source: 'name_en', component: TextInput, required: true,
  },
  {
    label: 'Abbreviation', source: 'abbreviation', component: TextInput, required: true,
  },
  {
    label: 'Region', source: 'vietseeds_region', component: SelectInput, required: true,
    choices: RegionChoices,
  },
]

const UniversityEdit = (props) => {
  return (
    <Edit 
      undoable={false}
      {...props}
    >
      <SimpleForm variant='outlined'>
        {
          renderInputFields(universityEditFields)
        }
      </SimpleForm>
    </Edit>
  );
}

export default UniversityEdit