import { get } from 'lodash'

import httpClient from './httpClient'

const generalDataProvider = {
  getOne: (params) => {
    const { id } = params
    const getGeneralFieldUrl = `/general/get_${id}`;
    return httpClient(getGeneralFieldUrl, {
      method: 'GET'
    }).then(({ json }) => {
      return {
        data: {
          id,
          ...get(json, 'reply', {}),
        },
      }
    })
  }
};

export default generalDataProvider
