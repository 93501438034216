import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  SearchInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

import { MentorMatchingStatusChoices } from '../../../../constants/constants'

const styles = theme => ({
})

const MentorMatchingRequestFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <SelectInput source='status' label='Status' choices={MentorMatchingStatusChoices} alwaysOn/>
      <ReferenceInput label='Student' source="student_id" reference="student">
        <SelectInput optionText="display_name" />
      </ReferenceInput>
      <ReferenceInput label='Mentor' source="mentor_id" reference="mentor">
        <SelectInput optionText="display_name" />
      </ReferenceInput>
    </Filter>
  )
}

export default withStyles(styles)(MentorMatchingRequestFilter)