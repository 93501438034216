import React from 'react'
import { 
  Edit, SimpleForm, SaveButton, Toolbar,
  ReferenceInput, TextInput, NumberInput, SelectInput, DateTimeInput, AutocompleteInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';
import renderInputFields from '../../../../helpers/renderInputFields'

import {
  SponsorStatusChoices, 
  SponsorTypeChoices,
  SponsorAmountChoices, 
  DonationTypeChoices, 
  PreferredContactMethodChoices, 
} from '../constants'
import mapChoicesToValue from '../../../../helpers/mapChoicesToValue'

const useStyles = makeStyles((theme) => ({

}));

const Title = ({ record }) => {
  return <span>{`Sponsor transaction: ${record.id}`}</span>
};

// Reference fields
const sponsorTransactionFields = [
  { 
    label: 'ID', source: 'id', 
    component: NumberInput, 
    disabled: true, 
    required: true, 
  },
  {
    label: 'Invitation', source: 'sponsor_invitation_id', component: (props) =>
      <ReferenceInput {...props} reference="sponsor-invitation"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={record => `${record?.id}. ${record?.display_name}`}
        />
      </ReferenceInput>,
    required: true, disabled: true,
  },
  {
    label: 'Student', source: 'student_id', component: (props) =>
      <ReferenceInput {...props} reference="student"
        filterToQuery={(searchText) => ({ name: searchText, fullSearch: true })}
      >
        <AutocompleteInput variant="outlined" fullWidth
          optionText={record => `${record?.id}. ${record?.display_name} - F${record?.vietseeds_generation}  ${mapChoicesToValue(SponsorAmountChoices, record?.sponsor_status)}`}
        />
      </ReferenceInput>,
    required: true, disabled: true,
  },
  {
    label: 'Transaction type', source: 'transaction_type',
    choices: SponsorTypeChoices,
    component: SelectInput, 
    required: true,
  },
  {
    label: 'Transaction amount', source: 'transaction_amount',
    choices: SponsorAmountChoices,
    component: SelectInput, 
    required: true,
  },
  {
    label: 'Transaction status', source: 'transaction_status',
    choices: SponsorStatusChoices,
    component: SelectInput, 
    required: true,
  },
  {
    label: 'Donation type', source: 'extra_data.donation_type',
    component: SelectInput,
    choices: DonationTypeChoices,
    required: true,
  },
  {
    label: 'Preferred contact method', source: 'extra_data.preferred_contact_method', 
    component: SelectInput,
    choices: PreferredContactMethodChoices,
    required: true,
  },
  {
    label: 'Living country', source: 'extra_data.country_id',
    component: (props) =>
      <ReferenceInput {...props} reference="nation">
        <AutocompleteInput variant="outlined" fullWidth
          optionText={'name'}
        />
      </ReferenceInput>,  
    required: true,
  },
  {
    label: 'Province', source: 'extra_data.province_id', 
    component: (props) =>
      <ReferenceInput {...props} reference="province">
        <AutocompleteInput variant="outlined" fullWidth
          optionText={'name'}
        />
      </ReferenceInput>,  
  },
  {
    label: 'Note', source: 'extra_data.note', component: TextInput,
    fullWidth: true, multiline: true, rows: 5,
  },
  {
    label: 'Expiry time', source: 'expiry_time', component: DateTimeInput,
  },
  {
    label: 'Created by', source: 'create_uid', component: (props) =>
      <ReferenceInput {...props} reference="user">
        <AutocompleteInput variant="outlined" fullWidth disabled 
          optionText={record => `${record?.display_name}`}
        />
      </ReferenceInput>,
    disabled: true
  },
  { label: 'Created at', source: 'create_time', component: DateTimeInput, disabled: true },
  {
    label: 'Updated by', source: 'update_uid', component: (props) =>
      <ReferenceInput {...props} reference="user">
        <AutocompleteInput variant="outlined" fullWidth disabled
          optionText={record => `${record?.display_name}`}
        />
      </ReferenceInput>,
    disabled: true
  },
  { label: 'Updated time', source: 'update_time', component: DateTimeInput, disabled: true },
]

const SponsorTransactionToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton undoable={false}/>
  </Toolbar>
);

const SponsorTransactionEdit = (props) => (
  <Edit 
    undoable={false}
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm 
      warnWhenUnsavedChanges
      toolbar={<SponsorTransactionToolbar />}
    >
      {
        renderInputFields(sponsorTransactionFields)
      }
    </SimpleForm>
  </Edit>
);

export default SponsorTransactionEdit