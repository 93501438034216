import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNotify, Loading } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { cloneDeep, set, get, debounce } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import StudentApplicationDataProvider from '../../../Providers/StudentApplicationDataProvider'
import StudentApplicationAssessorDataProvider from '../../../Providers/StudentApplicationAssessorDataProvider'

import Select from '../../../Components/Select'
import { SelectViewer, SelectEditor } from '../../../Components/DataSheetComponents/SelectComponents'
import { TextViewer, TextEditor } from '../../../Components/DataSheetComponents/TextComponents'
import { NumberViewer, NumberEditor } from '../../../Components/DataSheetComponents/NumberComponents'
import { GenderChoices } from '../../../constants/constants'
import exportJSON from '../../../helpers/JSON/exporter'
import asyncAllArray from '../../../helpers/asyncAllArray'
import assessmentTableFieldsByYear from '../assessmentTableFields'

import { ResponseCode, RegionChoices } from '../../../constants/constants'

const assessmentTableFields = get(assessmentTableFieldsByYear, moment().year(), assessmentTableFieldsByYear[Object.keys(assessmentTableFieldsByYear)[0]])

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  root: {

  }
}))

const scoreHeaderCells = () => {
  let cells = []
  assessmentTableFields.forEach((section, sectionId) => {
    const { section_summary: { name }, rows } = section
    cells.push({
      label: name,
      colSpan: rows.length,
    })
  })
  return cells
}

const headerOfColumns = [
  { label: '', colSpan: '1' },
  { label: '', colSpan: '1' },
  { label: '', colSpan: '1' },
  { label: '', colSpan: '1' },
  ...scoreHeaderCells(),
  { label: 'Result', colSpan: '3' },
]

const scoreCells = () => {
  let cells = []
  assessmentTableFields.forEach((section, sectionId) => {
    section.rows.forEach((field, fieldId) => {
      const { max_score } = field
      cells.push({
        label: max_score || '',
      })
    })
  })
  return cells
}

const subHeader = [
  { label: '', },
  { label: '', },
  { label: '', },
  { label: 'Maximum scores:', },
  ...scoreCells(),
  { label: '100', },
  { label: '5', },
  { label: '', },
]

const scoreLabelCells = () => {
  let cells = []
  assessmentTableFields.forEach((section, sectionId) => {
    section.rows.forEach((field, fieldId) => {
      const { name, isText, max_score = 0 } = field
      cells.push({
        label: name,
        name: `scoreboard[${sectionId}].rows[${fieldId}].score`,
        valueViewer: isText ? TextViewer : NumberViewer,
        dataEditor: isText ? TextEditor : NumberEditor,
        width: 120,
        validateSchema: isText ? Yup.string() : Yup.number().max(max_score).min(0),
      })
    })
  })
  return cells
}

const columns = () => ([
  { label: 'ID', name: 'id', valueViewer: NumberViewer, dataEditor: NumberEditor, width: 50, },
  { label: 'Gender', name: 'gender', valueViewer: SelectViewer(GenderChoices), dataEditor: SelectEditor(GenderChoices), width: 50, isSelect: true, },
  { label: 'Name', name: 'display_name', valueViewer: TextViewer, dataEditor: TextEditor, width: 300, },

  ...scoreLabelCells(),

  { label: `TOTAL detail score`, name: 'total_score', valueViewer: NumberViewer, dataEditor: NumberEditor, width: 50, readOnly: true },
  { label: `Overall Score (Assessor's feeling)`, name: 'assessment_score', valueViewer: NumberViewer, dataEditor: NumberEditor, width: 50, },
  { label: `Note`, name: 'note', valueViewer: TextViewer, dataEditor: TextEditor, width: 400, overflow: 'wrap' },

])


const SetAssessmentResult = ({ theme }) => {
  const classes = useStyles()
  const notify = useNotify();
  const [data, setData] = useState([])
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [region, setRegion] = useState(null)

  const initEmptyData = async () => {
    const { data: applicationList } = await StudentApplicationDataProvider.getList({
      filter: {
        my_assessment: true,
        region
      }
    }).catch(error => {
      notify(get(error, 'message', 'error'), 'error')
      return { data: [] }
    })

    const parsedApplicationList = applicationList.map(application => ({
      id: get(application, 'id', ''),
      gender: get(application, 'personal_info.gender.gender_type', ''),
      display_name: get(application, 'display_name', ''),
      assessment_score: get(application, 'my_assessment.assessment_score', 0),
      total_score: get(application, 'my_assessment.assessment_detail.total_score', 0),
      scoreboard: get(application, 'my_assessment.assessment_detail.scoreboard', cloneDeep(assessmentTableFields)),
      note: get(application, 'my_assessment.assessment_detail.note', ''),
    }))

    setData(parsedApplicationList)
    setIsLoadingData(false)
  }

  useEffect(() => {
    initEmptyData()
  }, [])
  const parsedColumns = columns()

  const mapDataToDataSheet = (data) => {
    return data.map(row => {
      return parsedColumns.map(col => {
        const { label, name, ...others } = col
        return {
          value: get(row, name, ''),
          ...others,
        }
      })
    })
  }

  const displayData = mapDataToDataSheet(data)

  const sheetRenderer = (props) => {
    const { className, children } = props
    return (
      <table className={className}>
        <thead>
          <tr>
            {
              headerOfColumns.map((col, index) => (
                <th className='cell read-only row-handle' key={`section-header-${index}-${col.label}`} style={{ minWidth: col.width }} colSpan={col.colSpan}>{col.label}</th>
              ))
            }
          </tr>
          <tr>
            <th className='cell read-only row-handle'></th>
            {
              parsedColumns.map(col => (
                <th className='cell read-only row-handle' key={`header-${col.name}`} style={{ minWidth: col.width }}>{col.label}</th>
              ))
            }
            <th className='action-cell'></th>
          </tr>
          <tr>
            {
              subHeader.map((col, index) => (
                <th className='cell read-only row-handle' key={`subheader-${index}-${col.label}`} style={{ minWidth: col.width }}>{col.label}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    )
  }

  const rowRenderer = (props) => {
    const { row, children, className } = props
    return (
      <tr className={className}>
        <td className='action-cell' style={{ minWidth: 30, textAlign: 'center' }}>
          <a href={`#/student-application/${data[row].id}`}>
            {row + 1}
          </a>
        </td>
        {children}
      </tr>
    )
  }

  const onCellsChanged = async changes => {
    const newData = cloneDeep(data)

    await asyncAllArray(changes, async ({ cell, row, col, value }) => {
      if (parsedColumns[col].name === 'id') {
        // get application data
        await StudentApplicationDataProvider.getOne({ id: value })
          .then(({ data: application }) => {
            if (!application) return;

            set(newData, `${row}.id`, get(application, 'id', ''))
            set(newData, `${row}.gender`, get(application, 'personal_info.gender.gender_type', ''))
            set(newData, `${row}.display_name`, get(application, 'display_name', ''))

            set(newData, `${row}.assessment_score`, get(application, 'my_assessment.assessment_score', 0))
            set(newData, `${row}.total_score`, get(application, 'my_assessment.assessment_detail.total_score', 0))
            set(newData, `${row}.scoreboard`, get(application, 'my_assessment.assessment_detail.scoreboard', cloneDeep(assessmentTableFields)))
            set(newData, `${row}.note`, get(application, 'my_assessment.assessment_detail.note', ''))
          })
      } else {
        if (parsedColumns[col].isSelect || cell.valueViewer === NumberViewer) {
          set(newData, `${row}.${parsedColumns[col].name}`, value !== '' ? JSON.parse(value) : '')
        } else {
          set(newData, `${row}.${parsedColumns[col].name}`, value)
        }

        // recalculate total_score
        const newScoreboard = get(newData, `${row}.scoreboard`, [])
        let newTotalScore = 0
        newScoreboard.forEach(section => {
          section.rows.forEach(field => {
            if (typeof(field.score) === "number") {
              newTotalScore += field.score
            }
          })
        })
        set(newData, `${row}.total_score`, newTotalScore)
      }
    })

    setData(newData)
  }

  const handleSubmit = async ({ isAutoSave = false }) => {
    try {
      await asyncAllArray(data, async (row) => {
        const { id, assessment_score, total_score, scoreboard, note, } = row
        if (id) {
          const { json } = await StudentApplicationAssessorDataProvider.setStudentApplicationAssessorResult({
            id,
            data: {
              assessment_score,
              assessment_detail: {
                total_score,
                scoreboard,
                note
              }
            },
          })
          const result = get(json, 'result', null)
          if (result !== ResponseCode.SUCCESS) {
            throw new Error(result)
          }
        }
      })
      if (!isAutoSave) {
        notify('Changes are saved', 'success')
      } else {
        notify('Auto saved', 'info')
      }
    } catch (error) {
      if (!isAutoSave) {
        notify('Error: can not set assessment result - ' + get(error, 'message', ''), 'warning')
      }
    }
  }

  const debounceAutoSave = debounce(async () => {
    await handleSubmit({ isAutoSave: true })
  }, 1000 * 60 * 5, { leading: false })

  useEffect(() => {
    debounceAutoSave()
  }, [data])

  const onUploadJSON = (event) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
  }

  function onReaderLoad(event) {
    const uploadedData = JSON.parse(event.target.result);
    setData(uploadedData)
  }

  if (isLoadingData) {
    return <Loading />
  }

  return (
    <div>
      <div className={classes.header}>SET ASSESSMENT RESULT</div>
      <div>
        <div style={{ display: 'inline-block', width: 200 }}>
          <Select
            name='region-filter'
            value={region}
            label='Region'
            choices={RegionChoices}
            onChange={(e) => {
              setRegion(e.target.value)
              initEmptyData()
            }}
          />
        </div>
      </div>
      <div>
        <button onClick={() => exportJSON(data, 'assign-assessor')} style={{ margin: 20 }}>
          Export to JSON
        </button>
        <input
          type="file" accept="application/json"
          onChange={onUploadJSON}
          title='Import JSON'
          style={{ margin: 20 }}
        />
      </div>
      <ReactDataSheet
        className={classes.root}
        sheetRenderer={sheetRenderer}
        rowRenderer={rowRenderer}
        onCellsChanged={onCellsChanged}
        valueRenderer={(cell) => cell.value}
        attributesRenderer={(cell) => ({ 'data-hint': 'test hint' })}
        data={displayData}
      />
      <Button onClick={handleSubmit} variant='contained' color='primary' style={{ margin: 30 }} size='large'>
        Submit
      </Button>
    </div>
  )
};

export default SetAssessmentResult;