import StudentApplicationList from './StudentApplicationList/StudentApplicationList'
// import StudentApplicationShow from './StudentApplicationShow/StudentApplicationShow'
import StudentApplicationEdit from './StudentApplicationEdit/StudentApplicationEdit'
import StudentApplicationIcon from '@material-ui/icons/RateReview'

export default {
  list: StudentApplicationList,
  // show: StudentApplicationShow,
  edit: StudentApplicationEdit,
  icon: StudentApplicationIcon,
  options: { label: 'Student Application' }
}