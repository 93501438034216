const evaluationTableFields = [
  {
    section_summary: {
      max_score: 20,
      name: 'A. Warm up and introduction',
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Ability to break the ice (being proactive in relationship with mentee)',
        note: '',
      },
      {
        max_score: 10,
        name: 'Understanding about VietSeeds and VietSeeds students',
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 30,
      name: `B. Candidate's attitude`,
      note: ''
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Motivation to become a mentor',
        note: '',
      },
      {
        max_score: 10,
        name: 'How regular of meeting with mentee',
        note: '',
      },
      {
        max_score: 10,
        name: `Understanding Mentor-Mentee relationship`,
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 20,
      name: 'C. Understanding about Mentoring program?',
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Expectation about Mentee? (Will it match with Seeds)',
        note: '',
      },
      {
        max_score: 10,
        name: 'Specific Plan for Mentoring',
        note: '',
      },
    ]
  },
  {
    section_summary: {
      max_score: 30,
      name: `D. Mentor's qualification`,
      note: '',
    },
    rows: [ // max_score, name, score, note
      {
        max_score: 10,
        name: 'Self-evaluation/Working & Networking',
        note: '',
      },
      {
        max_score: 10,
        name: `Ability to mentor with different background`,
        note: '',
      },
      {
        max_score: 10,
        name: `Mentoring experience`,
        note: '',
      },
    ]
  },
]

export default evaluationTableFields