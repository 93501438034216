import { get, find, } from 'lodash'

import {} from '../constants/constants'
import httpClient from './httpClient'


const userRoleDataProvider = {
  getList: (params) => {
    const getUserRoleListUrl = `/admin/get_role_list`;

    return httpClient(getUserRoleListUrl, {
      method: 'POST'
    }).then(({ json }) => {
      let roles = get(json, 'reply.roles', [])

      return {
        data: roles,
        total: roles.length,
      }
    })
  },

  getMany: (params) => {
    const getUserRoleListUrl = `/admin/get_role_list`;

    return httpClient(getUserRoleListUrl, {
      method: 'POST'
    }).then(({ json }) => {
      let roles = get(json, 'reply.roles', [])

      return {
        data: roles,
        total: roles.length,
      }
    })
  },

  getOne: (params) => {
    const { id } = params
    const parsedId = JSON.parse(id)

    const getUserRoleListUrl = `/admin/get_role_list`;

    return httpClient(getUserRoleListUrl, {
      method: 'POST',
    }).then(({ json }) => {
      let roles = get(json, 'reply.roles', [])
      roles = roles.forEach(role => { role.name = role.role_name })

      const role = find(roles, { id: parsedId })
      return {
        data: role,
      }
    })
  },
};

export default userRoleDataProvider
