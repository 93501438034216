import { get, } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

import asyncAllArray from '../helpers/asyncAllArray'

const userDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter

    const getUserIdsUrl = `/admin/get_uids`;
    const getUserInfosUrl = `/admin/get_user_basic_infos`;

    return httpClient(getUserIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_uids = get(json, 'reply.uids', [])
      const paged_uids = (perPage && !fullSearch) ? all_uids.slice((page - 1) * perPage, page * perPage) : all_uids

      return {
        uids: paged_uids,
        total: all_uids.length
      }
    }).then(({ uids, total }) => {
      return httpClient(getUserInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          uids
        })
      }).then(async ({ json }) => {
        let usersInfos = get(json, 'reply.users', [])

        await asyncAllArray(usersInfos, async ({ id }, index) => {
          usersInfos[index].role_ids = await userDataProvider.getUserRoleIds({ id })
        })

        return {
          data: usersInfos,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getUserInfosUrl = `/admin/get_user_basic_infos`;

    return httpClient(getUserInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        uids: [Number.parseInt(params.id)]
      }),
    }).then(async ({ json }) => {
      let user = get(json, 'reply.users[0]', {})
      user.role_ids = await userDataProvider.getUserRoleIds({ id: user.id })

      return {
        data: user
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getUserInfosUrl = `/admin/get_user_basic_infos`;

    return httpClient(getUserInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        uids: ids
      })
    }).then(async ({ json }) => {
      let usersInfos = get(json, 'reply.users', [])
      await asyncAllArray(usersInfos, async ({ id }, index) => {
        usersInfos[index].role_ids = await userDataProvider.getUserRoleIds({ id })
      })

      return {
        data: usersInfos
      }
    })
  },

  getUserRoleIds: async (params) => {
    let { id } = params
    const getUserRolesUrl = `/admin/get_user_roles`;

    return await httpClient(getUserRolesUrl, {
      method: 'POST',
      body: JSON.stringify({
        uid: id
      }),
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        let user_roles = get(json, 'reply.user_roles', [])
        return user_roles.map(role => role.id)
      } else {
        throw new Error(result)
      }
    })
  },

  update: (params) => {
    const { data } = params
    const { id } = data

    // Update roles of user
    const { role_ids } = data
    const setUserRolesUrl = `/admin/set_user_roles`;
    return httpClient(setUserRolesUrl, {
      method: 'POST',
      body: JSON.stringify({
        uid: id,
        role_ids
      })
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      if (result === ResponseCode.SUCCESS) {
        return { data }
      } else {
        throw new Error(result)
      }
    })
  },

  create: (params) => {
    const { data } = params

    const createNewAccountUrl = `/admin/create_new_account`;

    return httpClient(createNewAccountUrl, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(({ json }) => {
      const result = get(json, 'result', 'error')
      const user_id = get(json, 'reply.user_id', {})

      if (result === ResponseCode.SUCCESS) {
        return {
          data: {
            ...data,
            id: user_id,
          }
        }
      } else {
        throw new Error(result)
      }
    })
  },
};

export default userDataProvider
