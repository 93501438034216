import React, { useRef, useMemo } from "react";
import { useInput } from 'react-admin';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react'
import 'react-quill/dist/quill.snow.css';
import Typography from '@material-ui/core/Typography'
import { get } from 'lodash'

import { uploadImage } from '../../Providers/fileProvider'

Quill.register('modules/imageResize', ImageResize);

const HtmlInput = props => {
  const { label, uploadUrl } = props
  const {
    input: { name, value, onChange, ...others },
  } = useInput(props);
  const quillRef = useRef(null)

  /**
   * Step1. select local image
   *
   */
  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = async () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        const imageUrl = await saveToServer(file)
        insertToEditor(imageUrl)
      } else {
        alert('You could only upload images.');
      }
    };
  }

  /**
   * Step2. save to server
   *
   * @param {File} file
   */
  const saveToServer = async (file) => {
    const uploadedImage = await uploadImage(uploadUrl, file)
    const imageUrl = get(uploadedImage, 'image_url', '')

    return imageUrl
  }

  const insertToEditor = (url) => {
    // push image url to rich editor.
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection();
    editor.insertEmbed(range.index, 'image', url);
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [] }, { 'font': [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ size: [] }, { color: [] }, { background: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  }), [])

  const formats = [
    'header', 'font', 'size', 'color',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align', 'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]


  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={onChange}
        {...others}
      />
    </>
  )
};


export default HtmlInput