import React from 'react'
import {
  TextField, SelectField, EmailField, UrlField, DateField, ArrayField, Datagrid
} from 'react-admin'

import SectionWrapper from './SectionWrapper'
import ProvinceField from '../../../../../Components/ProvinceField'
import { RegionChoices } from '../../../../../constants/constants'

const profileFields = [
  { label: 'STT', source: 'id', component: TextField },
  {
    label: 'Khu vực (HCM/HN/Huế)', source: 'region', component: SelectField,
    choices: RegionChoices,
  },
  { label: 'Họ và tên đệm', source: 'personal_info.last_name', component: TextField },
  { label: 'Tên', source: 'personal_info.first_name', component: TextField },
  { label: 'Email', source: 'email', component: EmailField },
  { label: 'Ngày sinh', source: 'personal_info.dob', component: DateField },
  { label: 'Dân tộc', source: 'personal_info.ethnic', component: TextField },
  {
    label: 'Giới tính', source: 'personal_info.gender.gender_type', component: SelectField,
    choices: [
      { id: 1, name: 'Nam' },
      { id: 2, name: 'Nữ' },
      { id: 3, name: 'Khác' },
    ]
  },
  { label: 'Chia sẻ khác về giới tính', source: 'personal_info.gender.description', component: TextField },

  { label: 'Địa chỉ liên lạc', source: 'personal_info.address.address_text', component: TextField },
  {
    label: 'Tỉnh/Thành phố', source: 'personal_info.address.province_id', component: ProvinceField
  },
  { label: 'Số điện thoại', source: 'personal_info.phone_number', 
    component: (props) => <ArrayField {...props}>
      <Datagrid>
        <TextField source="phone" />
        <TextField source="owner" />
        <TextField source="relationship" />
      </Datagrid>
    </ArrayField>
  },
  { label: 'Link Facebook', source: 'personal_info.facebook_url', component: UrlField },
]


const ProfileInformationSection = (props) => {
  const { record, ...other } = props

  return (
    <SectionWrapper title='A. Thông tin cá nhân' record={record} {...other}>
      {
        profileFields.map(field => {
          const { label, source, component: Component, ...others } = field
          return (
            <Component key={source} source={source} label={label} {...others} />
          )
        })
      }
    </SectionWrapper>
  )
};

export default ProfileInformationSection