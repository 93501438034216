import React from 'react'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({

})

class Dashboard extends React.Component {
  render() {
    return (
      <div>
        {/* <iframe src="http://vietseeds.org/" title="VietSeeds facebook" style={{ width: '100%', height: '90vh'}}/> */}
      </div>
    )
  }
}

export default withStyles(styles)(Dashboard)

