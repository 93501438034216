import React from 'react';
import { withStyles } from  '@material-ui/styles'
import {
  Filter,
  SearchInput,
  TextInput,
} from 'react-admin';

const styles = theme => ({
})

const SponsorInvitationFilter = (props) => {
  const { ...others } = props
  return (
    <Filter
      {...others}
    >
      <SearchInput source='name' alwaysOn />
      <TextInput source='email' label={'Email'} />
    </Filter>
  )
}

export default withStyles(styles)(SponsorInvitationFilter)