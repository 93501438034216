import React from 'react'
import { 
  Edit, SimpleForm, Toolbar, SaveButton,
  ReferenceInput, TextInput, AutocompleteInput, DateTimeInput,
  email
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';

import renderInputFields from '../../../../helpers/renderInputFields'

const useStyles = makeStyles((theme) => ({

}));

const Title = ({ record }) => {
  return <span>{`Sponsor invitation: ${record.id}. ${record.display_name}`}</span>
};


const sponsorInvitationFields = [
  {
    label: 'ID', source: 'id', component: TextInput, required: true, disabled: true,
  },
  {
    label: 'Invitation code', source: 'invitation_code', component: TextInput, 
    required: true, disabled: true,
  },
  { label: 'Name', source: 'display_name', component: TextInput,required: true, },
  { 
    label: 'Email', source: 'email', component: TextInput, required: true, 
    validate: [email()],
  },
  { label: 'Phone', source: 'phone', component: TextInput, required: true, },
  { 
    label: 'Note', source: 'note', component: TextInput,
    multiline: true, rows: 5,
  },
  {
    label: 'Expiry time', source: 'expiry_time', component: DateTimeInput,
  },
  { 
    label: 'Created by', source: 'create_uid',
    component: (props) => 
      <ReferenceInput {...props} reference="user">
        <AutocompleteInput variant="outlined" fullWidth disabled
          optionText={"display_name"}
        />
      </ReferenceInput>,
    disabled: true, 
  },
  {
    label: 'Created time', source: 'create_time',
    component: DateTimeInput,
    disabled: true,
  },
]

const SponsorInvitationToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton undoable={false} />
  </Toolbar>
);


const SponsorInvitationEdit = (props) => (
  <Edit 
    undoable={false}
    title={<Title />} 
    variant="outlined"
    {...props}
  >
    <SimpleForm 
      warnWhenUnsavedChanges
      toolbar={<SponsorInvitationToolbar />}
    >
      {
        renderInputFields(sponsorInvitationFields)
      }
    </SimpleForm>
  </Edit>
);

export default SponsorInvitationEdit