import React from 'react'
import {
  Show, SimpleShowLayout, ReferenceField,
  SelectField, DateField,
} from 'react-admin'
import UserField from '../../../Components/CustomFields/UserField'
import { Typography } from '@material-ui/core'

import renderInputFields from '../../../helpers/renderInputFields'
import { MentorMatchingStatusChoices } from '../../../constants/constants';

const Title = ({ record }) => {
  return <span>{`Show mentor matching request`}</span>
};

const mentorMatchingRequestFields = [
  {
    label: 'Student', source: 'student_id', component: (props) =>
      <ReferenceField {...props} reference="student">
        <UserField source="display_name"/>
      </ReferenceField>,
    fullWidth: true, required: true, addLabel: true,
  },
  {
    label: 'Mentor', source: 'mentor_id', component: (props) =>
      <ReferenceField {...props} reference="mentor">
        <UserField source="display_name"/>
      </ReferenceField>,
    fullWidth: true, required: true, addLabel: true,
  },
  {
    label: 'Status', source: 'status',
    choices: MentorMatchingStatusChoices,
    fullWidth: true, component: SelectField, required: true, addLabel: true,
  },
  {
    label: 'Created by', source: 'create_uid', component: (props) =>
      <ReferenceField {...props} reference="user">
        <UserField source="display_name" />
      </ReferenceField>,
    disabled: true, addLabel: true,
  },
  { label: 'Created at', source: 'create_time', component: DateField, showTime: true, disabled: true },
  {
    label: 'Updated by', source: 'update_uid', component: (props) =>
      <ReferenceField {...props} reference="user">
        <UserField source="display_name" />
      </ReferenceField>,
    disabled: true, addLabel: true,
  },
  { label: 'Updated time', source: 'update_time', component: DateField, showTime: true, disabled: true, addLabel: true,},
]

const MentorMatchingRequestShow = (props) => {
  return (
    <Show
      undoable={false}
      title={<Title />}
      variant="standard"
      {...props}
    >
      <SimpleShowLayout>
        <Typography variant="h5" gutterBottom fullWidth>
          Show mentor matching request
        </Typography>
        {
          renderInputFields(mentorMatchingRequestFields)
        }
      </SimpleShowLayout>
    </Show>
  )
}


export default MentorMatchingRequestShow