import { get } from 'lodash'

import httpClient from './httpClient'

const ArticleCategoryDataProvider = {
  getList: (params) => {
    const { page, perPage } = get(params, 'pagination', {})
    const { filter } = params
    const { fullSearch } = filter

    const getCategoryIdsUrl = `/admin/homepage/get_article_category_ids`;
    const getCategoryInfosUrl = `/admin/homepage/get_article_category_infos`;

    return httpClient(getCategoryIdsUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...filter
      }),
    }).then(({ json }) => {
      const all_article_category_ids = get(json, 'reply.article_category_ids', [])
      const paged_article_category_ids = (perPage && !fullSearch) ? all_article_category_ids.slice((page - 1) * perPage, page * perPage) : all_article_category_ids

      return {
        article_category_ids: paged_article_category_ids,
        total: all_article_category_ids.length
      }
    }).then(({ article_category_ids, total }) => {
      return httpClient(getCategoryInfosUrl, {
        method: 'POST',
        body: JSON.stringify({
          article_category_ids
        })
      }).then(({ json }) => {
        const article_category_infos = get(json, 'reply.article_category_infos', [])

        return {
          data: article_category_infos,
          total: total,
        }
      })
    });
  },

  getOne: (params) => {
    const getCategoryInfosUrl = `/admin/homepage/get_article_category_infos`;

    return httpClient(getCategoryInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        article_category_ids: [Number.parseInt(params.id)]
      }),
    }).then(({ json }) => {
      let article_category = get(json, 'reply.article_category_infos[0]', {})

      return {
        data: article_category
      }
    })
  },

  getMany: (params) => {
    let { ids } = params
    ids = ids.map(id => parseInt(id))
    const getCategoryInfosUrl = `/admin/homepage/get_article_category_infos`;

    return httpClient(getCategoryInfosUrl, {
      method: 'POST',
      body: JSON.stringify({
        article_category_ids: ids
      })
    }).then(({ json }) => {
      let article_category_infos = get(json, 'reply.article_category_infos', [])

      return {
        data: article_category_infos
      }
    })
  },
};

export default ArticleCategoryDataProvider
