import React from 'react'
import {
  TextInput, NumberInput, ReferenceInput, AutocompleteInput,
  required as requiredValidator,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'

import SectionWrapper from './SectionWrapper'
import FromF10AcademicInfoSection from './FromF10AcademicInfoSection'

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
}));

const UniversityMainFields = [
  {
    label: 'Đại học', source: 'university_id', required: true,
    component: (props) =>
      <ReferenceInput {...props} reference="university">
        <AutocompleteInput variant="outlined" fullWidth optionText={`name`} />
      </ReferenceInput>
  },
  { label: 'Ngành', source: 'study_major', component: TextInput, required: true, },
  { label: 'Major', source: 'study_major_en', component: TextInput, required: true, },
  { label: 'Đại học năm', source: 'university_year', component: NumberInput, required: true, },
]

const UniversityFields = [
  { label_en: 'GPA (max 4.0)', label_vi: 'Điểm trung bình (tối đa 10.0)', source: 'gpa', component:  TextInput, required: true, },
  { 
    label_en: 'Achievement', label_vi: 'Giải thưởng', source: 'achievement', 
    component: TextInput, required: true, multiline: true,
  },
  { 
    label_en: 'Scholarship', label_vi: 'Học bổng', source: 'scholarship', 
    component: TextInput, required: true, multiline: true,
  },
  { 
    label_en: 'Extra activity', label_vi: 'Hoạt động ngoại khoá', source: 'extra_activity', 
    component: TextInput, required: true, multiline: true,
  },
  { 
    label_en: 'Next year goal', label_vi: 'Mục tiêu trong năm học tới', source: 'next_year_goal', 
    component: TextInput, required: true, multiline: true,
  },
  { 
    label_en: 'Post graduation goal', label_vi: 'Mục tiêu sau khi tốt nghiệp', source: 'post_graduation_goal', 
    component: TextInput, required: true, multiline: true,
  },
]

const AcademicInfoSection = (props) => {
  const { record, ...otherProps } = props
  const classes = useStyles()

  
  const generation = get(record, 'vietseeds_generation', 0)

  if (generation < 10) {
    return (
      <SectionWrapper title='B. Kết quả học tập' {...otherProps}>
        {
          UniversityMainFields.map(field => {
            const { label, source, component: Component, required, ...others } = field
            return (
              <Component
                key={source} source={source} label={label}
                fullWidth
                variant='outlined'
                required={required}
                {...required ? { validate: requiredValidator() } : {}}
                {...others}
              />
            )
          })
        }
        {
          UniversityFields.map(field => {
            const { label_en, label_vi, source, component: Component, required, ...others } = field
            return (
              <div key={`extra_data.university.${source}`} className={classes.fieldsContainer}>
                <Component 
                  record={record} source={`extra_data.university.${source}`} label={label_vi} 
                  variant='outlined'
                  required={required}
                  style={{ flex: 1, margin: 5, }}
                  {...required ? { validate: requiredValidator() } : {}}
                  {...others}   
                />
                <Component 
                  record={record} source={`extra_data_en.university.${source}`} label={label_en}
                  variant='outlined'
                  required={required}
                  {...required ? { validate: requiredValidator() } : {}} 
                  style={{ flex: 1, margin: 5, }}
                  {...others}   
                />
              </div>
            )
          })
        }

      </SectionWrapper>
    )
  }
  
  return (
    <FromF10AcademicInfoSection {...props}/>
  )
};

export default AcademicInfoSection