import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles'
import {
  useGetMany,
  useRefresh,
  useUnselectAll,
  useGetList,
  Button as AdminButton
} from 'react-admin';
import { set, cloneDeep, unionBy } from 'lodash'
import { ContactPhone } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import InterviewDatetimePicker from './InterviewDatetimePicker'

import Select from '../../../../Components/Select'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 10,
    marginRight: 10,
    background: '#c9f7ff'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SetInterviewerDialog = (props) => {
  const { selectedIds } = props
  const [open, setOpen] = useState(false)
  const [interviewers, setInterviewers] = useState({
    interviewer_1: '',
    interviewer_2: '',
  })

  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    refresh()
    unselectAll('student-application')
    setOpen(false)
  }



  const handleSelectInterviewers = (e) => {
    const newInterviewers = cloneDeep(interviewers)
    set(newInterviewers, e.target.name, e.target.value)
    setInterviewers(newInterviewers)
  }

  const classes = useStyles()

  const { data: applications } = useGetMany('student-application', selectedIds)

  const { data: interviewerListObject = {} } = useGetList('student-application-interviewer')
  const interviewerList = Object.keys(interviewerListObject).map(key => interviewerListObject[key])

  const get_filter = (name, interviewer_id) => {
    return interviewer_id ? { [name]: interviewer_id } : { name: 'Default for empty response' }
  }

  // get booked interview datetime of interviewers
  const { data: interviewer_1_applications_1 = [] } = useGetList('student-application', {} , { },
    get_filter('interviewer_1', interviewers.interviewer_1)
  )
  const interviewer_1_applications_list_1 = Object.keys(interviewer_1_applications_1).map(key => interviewer_1_applications_1[key])
  const { data: interviewer_1_applications_2 = [] } = useGetList('student-application', {}, {},
    get_filter('interviewer_2', interviewers.interviewer_1)
  )
  const interviewer_1_applications_list_2 = Object.keys(interviewer_1_applications_2).map(key => interviewer_1_applications_2[key])

  const { data: interviewer_2_applications_1 = [] } = useGetList('student-application', {}, {},
    get_filter('interviewer_1', interviewers.interviewer_2)
  )
  const interviewer_2_applications_list_1 = Object.keys(interviewer_2_applications_1).map(key => interviewer_2_applications_1[key])
  const { data: interviewer_2_applications_2 = [] } = useGetList('student-application', {}, {},
    get_filter('interviewer_2', interviewers.interviewer_1)
  )
  const interviewer_2_applications_list_2 = Object.keys(interviewer_2_applications_2).map(key => interviewer_2_applications_2[key])


  return (
    <React.Fragment>
      <AdminButton label='Set interviewers' onClick={handleOpen} variant='outlined' className={classes.button}>
        <ContactPhone/>
      </AdminButton>
      <Dialog fullScreen scroll='body' open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Set interviewers
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleSave}>
              Save
            </Button> */}
          </Toolbar>
        </AppBar>

        <DialogContent>
          <div>
            <DialogContentText>
              Leave blank to keep old data of that field
            </DialogContentText>
            <Select
              label='Interviewer 1'
              name='interviewer_1'
              value={interviewers.interviewer_1}
              onChange={handleSelectInterviewers}
              choices={interviewerList}
            />
            <Select
              label='Interviewer 2'
              name='interviewer_2'
              value={interviewers.interviewer_2}
              onChange={handleSelectInterviewers}
              choices={interviewerList}
            />
          </div>

          <InterviewDatetimePicker
            interviewerList={interviewerList}
            assignedInterviewers={interviewers}
            assignedApplications={unionBy(
              interviewer_1_applications_list_1,
              interviewer_1_applications_list_2,
              interviewer_2_applications_list_1,
              interviewer_2_applications_list_2,
              'id'
            )}
            applications={applications || []}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default SetInterviewerDialog