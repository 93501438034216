import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  usePermissions,
  DashboardMenuItem,
  MenuItemLink,
} from 'react-admin';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import authProvider from '../Providers/authProvider'

import SubMenu from './SubMenu';
import { menuItemList, appBarMenuItemList } from '../constants/rolesResources'

const Menu = ({ dense = false, logout }) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change
  const { permissions } = usePermissions()
  const isXSmall = useMediaQuery((theme) =>
    theme.breakpoints.down('xs')
  );

  const classes = useStyles();

  const handleToggle = (menu) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {' '}
      <DashboardMenuItem />
      {
        menuItemList
          .filter(({ roles }) => authProvider.checkUserPermission(permissions, roles))
          .map((menuItem) => {
            const { to, label, name, icon, subMenu } = menuItem
            return (!subMenu ?
              <MenuItemLink
                key={name}
                to={{
                  pathname: to,
                  state: { _scrollToTop: true },
                }}
                primaryText={label}
                leftIcon={icon}
                dense={dense}
              />
              :
              <SubMenu
                key={name}
                handleToggle={() => handleToggle(name)}
                isOpen={state[name]}
                sidebarIsOpen={open}
                name={label}
                icon={icon}
                dense={dense}
              >
                {
                  subMenu
                    .filter(({ subRoles }) => authProvider.checkUserPermission(permissions, subRoles))
                    .map((subMenuItem) => {
                      const { subTo, subLabel, subName, subIcon } = subMenuItem
                      return (
                        <MenuItemLink
                          key={subName}
                          to={{
                            pathname: subTo,
                            state: { _scrollToTop: true },
                          }}
                          primaryText={subLabel}
                          leftIcon={subIcon}
                          sidebarIsOpen={open}
                          dense={dense}
                        />
                      )
                    })
                }
              </SubMenu>
            )
          })
      }
      {isXSmall &&
        <SubMenu
          key='profile'
          handleToggle={() => handleToggle('profile')}
          isOpen={state['profile']}
          sidebarIsOpen={open}
          name={'Profile'}
          icon={<AccountCircleIcon />}
          dense={dense}
        >
          {
            appBarMenuItemList
              .filter(({ roles }) => authProvider.checkUserPermission(permissions, roles))
              .map((menuItem) => {
                const { to, label, name, icon } = menuItem
                return (
                  <MenuItemLink
                    key={name}
                    to={to}
                    primaryText={label}
                    leftIcon={icon}
                    sidebarIsOpen={open}
                    dense={dense}
                  />
                )
              })
          }
        </SubMenu>
      }
      {isXSmall && logout}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 250,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;