import { isEqual, get, cloneDeep } from 'lodash'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

const studentApplicationReviewerDataProvider = {
  getList: (params) => {
    const getStudentApplicationReviewersUrl = `/admin/get_student_application_reviewers`;

    return httpClient(getStudentApplicationReviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const reviewers = get(json, 'reply.reviewers', []).map(reviewer => {
        return {
          ...reviewer,
          name: reviewer.display_name || reviewer.email, // map field for displaying choices
        }
      })
      return {
        data: reviewers,
        total: reviewers.length
      }
    })
  },

  getOne: (params) => {
    
  },

  getMany: (params) => {
    const getStudentApplicationReviewersUrl = `/admin/get_student_application_reviewers`;

    return httpClient(getStudentApplicationReviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const reviewers = get(json, 'reply.reviewers', []).map(reviewer => {
        return {
          ...reviewer,
          name: reviewer.display_name || reviewer.email, // map field for displaying choices
        }
      })
      return {
        data: reviewers,
      }
    })
  },

  update: async (params) => {
    const { id, data, previousData } = params

    if (!isEqual(get(data, 'review.review_result', ''), get(previousData, 'review.review_result', ''))) {
      await studentApplicationReviewerDataProvider.setStudentApplicationReviewOverallResult({
        id,
        data: {
          review_result: data.review.review_result,
        }
      })
    }

    if (!isEqual(data.reviewer_1, previousData.reviewer_1) || !isEqual(data.reviewer_2, previousData.reviewer_2)) {
      await studentApplicationReviewerDataProvider.updateMany({
        ids: [id],
        data: {
          reviewer_1: data.reviewer_1,
          reviewer_2: data.reviewer_2
        }
      })
    }

    if (!isEqual(data.my_review, previousData.my_review)) {
      await studentApplicationReviewerDataProvider.setStudentApplicationReviewerResult({ id , data: data.my_review })
    }

    return Promise.resolve({ data })
  },

  updateMany: async (params) => {
    const { ids, data } = params

    const assignStudentApplicationReviewersUrl = `/admin/assign_student_application_reviewers`;

    let reviewersData = cloneDeep(data)
    if (!reviewersData.reviewer_1) delete reviewersData.reviewer_1
    if (!reviewersData.reviewer_2) delete reviewersData.reviewer_2

    return httpClient(assignStudentApplicationReviewersUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids,
        ...reviewersData,
      })
    }).then(({ json }) => {
      const result = get(json, 'result', null)
      if (result === ResponseCode.SUCCESS) {
        return {
          data: ids
        }
      } else {
        throw new Error(result)
      }
    })
  },

  setStudentApplicationReviewerResult: async ({ id, data}) => {
    const setStudentApplicationReviewerResultUrl = `/admin/set_student_application_reviewer_result`;

    return httpClient(setStudentApplicationReviewerResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        review_result: data
      })
    })
  },

  setStudentApplicationReviewOverallResult: async ({ id, data }) => {
    const setStudentApplicationReviewOverallResultUrl = `/admin/set_student_application_review_overall_result`;

    return httpClient(setStudentApplicationReviewOverallResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        review_result: data
      })
    })
  }
};

export default studentApplicationReviewerDataProvider
