import React from 'react';
import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';

import ChangePassword from './Pages/ChangePassword/index';
import ForgotPassword from './Pages/ForgotPassword/index'
import StudentInterviewCalendarView from './Pages/InterviewCalendar/StudentInterviewCalendarView/index';
import StudentInterviewCalendarEdit from './Pages/InterviewCalendar/StudentInterviewCalendarEdit/index';
import MentorInterviewCalendarView from './Pages/InterviewCalendar/MentorInterviewCalendarView/index';
import MentorInterviewCalendarEdit from './Pages/InterviewCalendar/MentorInterviewCalendarEdit/index';
import AssignAssessor from './Pages/AssessmentDay/AssignAssessor/index';
import SetAssessmentResult from './Pages/AssessmentDay/SetAssessmentResult/index';

export default [
  <Route exact path="/change-password" render={() => <ChangePassword />} />,
  <RouteWithoutLayout noLayout exact path="/forgot-password" component={ForgotPassword} />,
  <RouteWithoutLayout noLayout exact path="/forgot-password/:step" component={ForgotPassword} />,
  <Route exact path="/interview-calendar/student/view" render={() => <StudentInterviewCalendarView />} />,
  <Route exact path="/interview-calendar/student/edit" render={() => <StudentInterviewCalendarEdit />} />,
  <Route exact path="/interview-calendar/mentor/view" render={() => <MentorInterviewCalendarView />} />,
  <Route exact path="/interview-calendar/mentor/edit" render={() => <MentorInterviewCalendarEdit />} />,
  <Route exact path="/assessment-day/assign-assessor" render={() => <AssignAssessor />} />,
  <Route exact path="/assessment-day/set-assessment-result" render={() => <SetAssessmentResult />} />,
];
