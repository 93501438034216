import * as React from 'react';
import { useState } from 'react';
import { useNotify, useGetList } from 'react-admin';
import { Button, makeStyles } from '@material-ui/core'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { cloneDeep, set, get } from 'lodash'

import StudentApplicationDataProvider from '../../../Providers/StudentApplicationDataProvider'
import StudentApplicationAssessorDataProvider from '../../../Providers/StudentApplicationAssessorDataProvider'

import { SelectViewer, SelectEditor } from '../../../Components/DataSheetComponents/SelectComponents'
import { NumberViewer, NumberEditor } from '../../../Components/DataSheetComponents/NumberComponents'
import { TextEditor, TextViewer } from '../../../Components/DataSheetComponents/TextComponents';
import { GenderChoices } from '../../../constants/constants'
import exportJSON from '../../../helpers/JSON/exporter'
import asyncForEach from '../../../helpers/asyncForEach'
import asyncAllArray from '../../../helpers/asyncAllArray'


const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20
  },
  root: {

  },
}))

const columns = ({ assessorList }) => ([
  { label: 'ID', name: 'id', valueViewer: NumberViewer, dataEditor: NumberEditor, width: 50, },
  { label: 'Gender', name: 'gender', valueViewer: SelectViewer(GenderChoices), width: 50, isSelect: true, disableEvents: true, },
  { label: 'Name', name: 'display_name', valueViewer: TextViewer, dataEditor: TextEditor, width: 200, disableEvents: true, },
  { 
    label: 'Assessor 1', name: 'assessor_1', 
    valueViewer: SelectViewer(assessorList), dataEditor: SelectEditor(assessorList), 
    width: 200, isSelect: true, 
  },
  { 
    label: `Assessor 1's Score`, name: 'assessor_1_score', 
    valueViewer: NumberViewer, width: 50, disableEvents: true,
  },
  { 
    label: 'Assessor 2', name: 'assessor_2', 
    valueViewer: SelectViewer(assessorList), dataEditor: SelectEditor(assessorList), 
    width: 200, isSelect: true, 
  },
  {
    label: `Assessor 2's Score`, name: 'assessor_2_score',
    valueViewer: NumberViewer, width: 50, disableEvents: true,
  },
  {
    label: 'Supporter', name: 'assessor_3',
    valueViewer: SelectViewer(assessorList), dataEditor: SelectEditor(assessorList),
    width: 200, isSelect: true,
  },
  {
    label: `Supporter's Score`, name: 'assessor_3_score',
    valueViewer: NumberViewer, width: 50, disableEvents: true,
  },
])


const AssignAssessor = ({ theme }) => {
  const classes = useStyles()
  const notify = useNotify();

  const initEmptyData = () => {
    let array = []
    for (let i = 0; i < 20; ++i) {
      array.push({})
    }
    return array
  }

  const [data, setData] = useState(initEmptyData())

  const { data: assessorListObject = {} } = useGetList('student-application-assessor')
  const assessorList = Object.keys(assessorListObject).map(key => assessorListObject[key])

  const parsedColumns = columns({ assessorList, })

  const handleChangeRowNumber = (e) => {
    const nRows = e.target.value
    let newData = cloneDeep(data)
    while (newData.length < nRows) {
      newData.push({})
    }
    if (newData.length > nRows) {
      newData = newData.slice(0, nRows)
    }
    setData(newData)
  }

  const mapDataToDataSheet = (data) => {
    return data.map(row => {
      return parsedColumns.map(col => {
        const { label, name, ...others } = col
        return { 
          value: row[name],
          ...others,
        }
      })
    })
  }

  const displayData = mapDataToDataSheet(data)

  const sheetRenderer = (props) => {
    const { className, children } = props
    return (
      <table className={className}>
        <thead>
          <tr>
            <th className='action-cell'>
              <button onClick={() => {
                setData([...data, {}])
              }}
              >
                Insert
              </button>
            </th>
            {parsedColumns.map(col => (
                <th className='cell read-only row-handle' key={col.name} style={{ minWidth: col.width }}>{col.label}</th>
              ))
            }
            <th className='action-cell'></th>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    )
  }

  const rowRenderer = (props) => {
    const { row, children, className } = props
    return (
      <tr className={className}>
        <td className='action-cell'>
          <a href={`#/student-application/${data[row].id}`}>
            {row + 1}
          </a>
        </td>
        {children}
        <td className='action-cell'>
          <button onClick={() => {
            let newData = cloneDeep(data)
            newData.splice(row, 1)
            setData(newData)
          }}>
            Delete
          </button>
        </td>
      </tr>
    )
  }

  const onCellsChanged = async changes => {
    const newData = cloneDeep(data)

    await asyncForEach(changes, async ({ cell, row, col, value }) => {
      if (parsedColumns[col].name === 'id') {
        // get application data
        await StudentApplicationDataProvider.getOne({ id: value })
          .then(({ data: application }) => {
            if (!application) return;

            set(newData, `${row}.id`, get(application, 'id', ''))
            set(newData, `${row}.gender`, get(application, 'personal_info.gender.gender_type', ''))
            set(newData, `${row}.display_name`, get(application, 'display_name', ''))

            set(newData, `${row}.assessor_1`, get(application, 'assessor_1', ''))
            set(newData, `${row}.assessor_1_score`, get(application, 'assessor_1_data.assessment_score', ''))
            set(newData, `${row}.assessor_2`, get(application, 'assessor_2', ''))
            set(newData, `${row}.assessor_2_score`, get(application, 'assessor_2_data.assessment_score', ''))
            set(newData, `${row}.assessor_3`, get(application, 'assessor_3', ''))
            set(newData, `${row}.assessor_3_score`, get(application, 'assessor_3_data.assessment_score', ''))
          })
      } else {
        if (parsedColumns[col].isSelect || cell.valueViewer === NumberViewer) {
          set(newData, `${row}.${parsedColumns[col].name}`, value !== '' ? JSON.parse(value) : '')
        } else {
          set(newData, `${row}.${parsedColumns[col].name}`, value)
        }
      }
    })

    setData(newData)
  }

  const handleSubmit = async () => {
    try {
      await asyncAllArray(data, async (row) => {
        const { id, assessor_1, assessor_2, assessor_3 } = row
        if (id) {
          // call updateMany of student application assessor
          await StudentApplicationAssessorDataProvider.updateMany({
            ids: [id],
            data: {
              assessor_1,
              assessor_2,
              assessor_3,
            },
          })
        }
      })
      notify('Changes are saved', 'success')
    } catch (error) {
      notify('Error: can not set assessors' + JSON.stringify(error), 'warning')
    }
  }

  const onUploadJSON = (event) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(event.target.files[0]);
  }

  function onReaderLoad(event) {
    const uploadedData = JSON.parse(event.target.result);
    setData(uploadedData)
  }

  return (
    <div>
      <div className={classes.header}>ASSIGN ASSESSOR</div>
      
      <div>
        <input type='number' placeholder='# rows' onBlur={handleChangeRowNumber} />
        <button onClick={() => exportJSON(data, 'assign-assessor')} style={{ margin: 20 }}>
          Export to JSON
        </button>
        <input 
          type="file" accept="application/json" 
          onChange={onUploadJSON} 
          title='Import JSON'
          style={{ margin: 20 }}
        />
      </div>
      <ReactDataSheet
        className={classes.root}
        sheetRenderer={sheetRenderer}
        rowRenderer={rowRenderer}
        onCellsChanged={onCellsChanged}
        valueRenderer={(cell) => cell.value}
        data={displayData}
      />
      <Button onClick={handleSubmit} variant='contained' color='primary' style={{ margin: 30 }} size='large'>
        Submit
      </Button>
    </div>
  )
};

export default AssignAssessor;