import React, { useState, useEffect } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { makeStyles } from '@material-ui/core/styles'

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import EventDialog from './EventDialog'

const DnDCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
}));

const InterviewCalendar = (props) => {
  const { interviewDuration, applicationType } = props
  const { applications = [], interviewerList } = props

  const [events, setEvents] = useState([])

  useEffect(() => {
    setEvents(mapApplicationsToEvents(applications))
  }, [applications]);

  const mapApplicationsToEvents = (applications) => {
    return applications.map(application => {
      const { id, display_name, interview_datetime, interviewer_1, interviewer_2 } = application
      return ({
        id,
        title: `${id}. ${display_name}`,
        start: new Date(interview_datetime),
        end: moment(interview_datetime).add(interviewDuration, 'minutes').toDate(),
        resource: {
          interviewers: {
            interviewer_1,
            interviewer_2,
          },
        }
      })
    })
  }

  const [activeEvent, setActiveEvent] = useState(null)
  const handleClick = (event) => {
    setActiveEvent(event)
  }

  const handleCloseEventDialog = () => {
    setActiveEvent(null)
  }

  const handleDoubleClick = (event) => {
    window.open(`/${applicationType}/${event.id}`)
  }

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <DnDCalendar
        selectable
        localizer={localizer}
        step={interviewDuration}
        events={events}
        defaultView={Views.WEEK}
        // scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={moment().toDate()}
        onSelectEvent={handleClick}
        onDoubleClickEvent={handleDoubleClick}
        style={{ height: "100vh" }}

        onSelectSlot={() => {}}
        onEventResize={() => {}}
        onEventDrop={() => {}}
      />

      <EventDialog 
        applicationType={applicationType}
        event={activeEvent} 
        interviewerList={interviewerList}
        handleClose={handleCloseEventDialog}
      />
    </div>
  )
}

export default InterviewCalendar