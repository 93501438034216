import { isEqual, get, cloneDeep, set } from 'lodash'
import moment from 'moment'

import { ResponseCode } from '../constants/constants'
import httpClient from './httpClient'

import evaluationTableFields from '../Pages/MentorApplication/MentorApplicationEdit/components/EvaluationTables/evaluationTableFields'

const mentorApplicationInterviewerDataProvider = {
  getList: (params) => {
    const getMentorApplicationInterviewersUrl = `/admin/mentor/get_application_interviewers`;

    return httpClient(getMentorApplicationInterviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const interviewers = get(json, 'reply.interviewers', []).map(interviewer => {
        return {
          ...interviewer,
          name: interviewer.display_name || interviewer.email, // map field for displaying choices
        }
      })
      return {
        data: interviewers,
        total: interviewers.length
      }
    })
  },

  getOne: (params) => {
    
  },

  getMany: (params) => {
    const getMentorApplicationInterviewersUrl = `/admin/mentor/get_application_interviewers`;

    return httpClient(getMentorApplicationInterviewersUrl, {
      method: 'GET',
    }).then(({ headers, json }) => {
      const interviewers = get(json, 'reply.interviewers', []).map(interviewer => {
        return {
          ...interviewer,
          name: interviewer.display_name || interviewer.email, // map field for displaying choices
        }
      })
      return {
        data: interviewers,
      }
    })
  },

  update: async (params) => {
    const { id, data, previousData } = params

    if (!isEqual(get(data, 'interview.interview_result', ''), get(previousData, 'interview.interview_result', ''))) {
      await mentorApplicationInterviewerDataProvider.setMentorApplicationInterviewOverallResult({
        id,
        data: {
          interview_result: data.interview.interview_result,
        }
      })
    }

    if (!isEqual(data.interviewer_1, previousData.interviewer_1) 
      || !isEqual(data.interviewer_2, previousData.interviewer_2)
      || !isEqual(data.interview_datetime, previousData.interview_datetime)
    ) {
      await mentorApplicationInterviewerDataProvider.updateMany({
        ids: [id],
        data: {
          interviewer_1: data.interviewer_1,
          interviewer_2: data.interviewer_2,
          interview_datetime: data.interview_datetime,
        }
      })
    }

    if (!isEqual(data.my_interview, previousData.my_interview)) {
      await mentorApplicationInterviewerDataProvider.setMentorApplicationInterviewerResult({ id, data: data.my_interview })
    }
  },

  updateMany: async (params) => {
    const { ids, data } = params

    const assignMentorApplicationInterviewersUrl = `/admin/mentor/assign_application_interviewers`;

    let interviewersData = cloneDeep(data)
    if (!interviewersData.interviewer_1) delete interviewersData.interviewer_1
    if (!interviewersData.interviewer_2) delete interviewersData.interviewer_2
    if (!interviewersData.interview_datetime) {
      delete interviewersData.interview_datetime
    } else {
      interviewersData.interview_datetime = moment(interviewersData.interview_datetime).format().slice(0, -6)
    }

    return httpClient(assignMentorApplicationInterviewersUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_ids: ids,
        ...interviewersData,
      })
    }).then(({ json }) => {
      const result = get(json, 'result', null)
      if (result === ResponseCode.SUCCESS) {
        return {
          data: ids
        }
      } else {
        throw new Error(result)
      }
    })
  },

  setMentorApplicationInterviewerResult: async ({ id, data}) => {
    const setMentorApplicationInterviewerResultUrl = `/admin/mentor/set_application_interviewer_result`;
      
    // fill with default value
    const inputScoreboard = get(data, 'scoreboard', [])
    const scoreboard = cloneDeep(evaluationTableFields)
    scoreboard.forEach((section, sectionId) => {
      section.rows.forEach((row, rowId) => {
        set(scoreboard, `[${sectionId}].rows[${rowId}].score`, get(inputScoreboard, `[${sectionId}].rows[${rowId}].score`, 0))
        set(scoreboard, `[${sectionId}].rows[${rowId}].note`, get(inputScoreboard, `[${sectionId}].rows[${rowId}].note`, ''))
      })
      if (section.section_summary.max_score) {
        const section_score = section.rows.reduce((total, currentValue) => (total + currentValue.score || 0), 0)
        set(section, 'section_summary.score', section_score)
      } else {
        // Yes/No field
        set(section, 'section_summary.score', get(inputScoreboard, `[${sectionId}].section_summary.score`, 0))
      }
      set(section, 'section_summary.note', get(inputScoreboard, `[${sectionId}].section_summary.note`, section.section_summary.note) || '')
    })

    return httpClient(setMentorApplicationInterviewerResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        interview_result: {
          ...data,
          scoreboard,
        }
      })
    })
  },

  setMentorApplicationInterviewOverallResult: async ({ id, data }) => {
    const setMentorApplicationInterviewOverallResultUrl = `/admin/mentor/set_application_interview_overall_result`;

    return httpClient(setMentorApplicationInterviewOverallResultUrl, {
      method: 'POST',
      body: JSON.stringify({
        application_id: id,
        interview_result: data
      })
    })
  }
};

export default mentorApplicationInterviewerDataProvider
