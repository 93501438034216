import React from 'react'
import {
  FormTab,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { get } from 'lodash'

const RedirectToApplication = (props) => {
  const { record } = props
  const id = get(record, 'id', 0)
  const vietseeds_generation = get(record, 'vietseeds_generation', 0)
  if (vietseeds_generation >= 10) {
    // TODO: application url relatively to generation
    return <Button variant='contained' color='primary' href={`/#/student-application/${id % 1000}`}>GO</Button>
  } else {
    return (<div>
      Only available from F10
    </div>)
  }
}

const StudentApplicationInfosTab = (props) => {
  return (
    <FormTab label='Student application infos' variant="outlined" {...props}>
      <RedirectToApplication/>
    </FormTab>
  )
}

export default StudentApplicationInfosTab